import React, { Component } from "react";
import { connect } from "react-redux";
import { Card, Segment, List } from "semantic-ui-react";
import { Row, Col } from "reactstrap";
import { styles } from "../../styles";
import {
  getLatestForumActivity,
  getAllChatrooms,
  setCurrentChatroom
} from "../../actions/Posts";
import { selectUserAction } from "../../actions/users";
import TimeAgo from "react-timeago";
import { Link } from "react-router-dom";

class LatestForum extends Component {
  componentDidMount = () => {
    getLatestForumActivity();
    getAllChatrooms();
  };

  handleSelectClick = key => {
    setCurrentChatroom(key);
  };

  selectUser = uid => {
    selectUserAction(uid);
  };

  render() {
    const { latestPostsList } = this.props;
    return (
      <Card color="teal" fluid style={styles.profileComponentStyle}>
        <Card.Content style={styles.cardContentStyle}>
          <div style={styles.cardHeaderStyle}>Latest Chatroom Activity</div>
          <div style={styles.divStyleRightbox} />
          <Segment style={styles.segmentStyle}>
            <List divided verticalAlign="middle">
              {Object.values(latestPostsList).map((post, idx) => (
                <List.Item key={idx}>
                  <Row>
                    <Col sm="6">
                      {post.displayName ? (
                        <Link
                          onClick={() => this.selectUser(post.uid)}
                          // to={`/Profile/${post.displayName}`}
                          to=""
                        >
                          {post.displayName}
                        </Link>
                      ) : (
                        ""
                      )}{" "}
                      posted in{" "}
                      {/* If posts.key matches a key from this.props.AllChatrooms 
                      then callhandleSelectClick with this.props.Allchatrooms.key */}
                      <Link
                        to="/Forum"
                        onClick={() => this.handleSelectClick(post.chatroomKey)}
                      >
                        {post.chatroomTitle}
                      </Link>
                    </Col>
                    <Col sm="3">
                      <TimeAgo
                        date={post.time}
                        style={{ textAlign: "right" }}
                      />
                    </Col>
                    <Col sm="3">{post.likeCount} Likes</Col>
                  </Row>
                </List.Item>
              ))}
            </List>
          </Segment>
        </Card.Content>
      </Card>
    );
  }
}

const mapStateToProps = state => ({
  latestPostsList: state.postState.latestPosts,
  AllChatrooms: state.chatroomsState.currentAllChatroomsProps
});

export default connect(mapStateToProps)(LatestForum);
