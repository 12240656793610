import { db } from "../FireBase/firebase";
import store from "../store";
import {} from "./types";
import fecha from "fecha";
export const submitevent = (
  eid,
  uid,
  start,
  end,
  title,
  desc,
  descEditor,
  type,
  typeSwitch
) => {
  const id = eid ? eid : db.ref(`users/${uid}/myevents`).push().key;
  const event = { id, title, start, end, type, desc, descEditor, uid };
  if (type === "own") {
    db.ref(`users/${uid}/myevents/${id}`).set(event);
    if (typeSwitch) {
      db.ref(`calendar/adminEvents/${id}`).remove();
    }
  } else {
    db.ref(`calendar/adminEvents/${id}`).set(event);
    if (typeSwitch) {
      db.ref(`users/${uid}/myevents/${id}`).remove();
    }
  }
};
export const deleteEvent = (id, uid, type) => {
  if (type === "own") {
    // If type is own remove from userObject
    db.ref(`users/${uid}/myevents/${id}`).remove();
  } else {
    // If type is other delete from global events
    db.ref(`calendar/adminEvents/${id}`).remove();
  }
};
// get all the global admin events
export const getAdminEvents = () => {
  db.ref("calendar/adminEvents").on("value", snap => {
    // Create a constant of the raw data from firebase
    const adminEventsRaw = snap.val();
    // Create an empty Array
    const adminEvents = [];
    // Map over the raw data
    if (adminEventsRaw) {
      Object.values(adminEventsRaw).map(event => {
        let { title, desc, descEditor, id, type } = event;
        // Convert the raw times into a timestamp that the calendar can understand
        let start = fecha.parse(event.start, "YYYY-MM-DD hh:mm:ss.SSS A");
        let end = fecha.parse(event.end, "YYYY-MM-DD hh:mm:ss.SSS A");
        // Create an Array of Objects from the mapped
        // Objects and push them back together again
        const eventArray = { start, end, title, desc, descEditor, id, type };
        adminEvents.push(eventArray);
        return "";
      });
    }
    // Dispatch the Calendar readable data to the reducer
    store.dispatch({
      type: "GET_ADMIN_EVENTS",
      adminEvents
    });
  });
};
// get the user events
export const getUserEvents = uid => {
  db.ref(`users/${uid}/myevents`).on("value", snap => {
    const userEventsRaw = snap.val();
    // Create an empty Array
    const adminEvents = [];
    // Map over the raw data
    if (userEventsRaw) {
      Object.values(userEventsRaw).map(event => {
        let { title, desc, descEditor, id, type } = event;
        // Convert the raw times into a timestamp that the calendar can understand
        let start = fecha.parse(event.start, "YYYY-MM-DD hh:mm:ss.SSS A");
        let end = fecha.parse(event.end, "YYYY-MM-DD hh:mm:ss.SSS A");
        // Create an Array of Objects from the mapped
        // Objects and push them back together again
        const eventArray = { start, end, title, desc, descEditor, id, type };
        adminEvents.push(eventArray);
        return "";
      });
    }
    // Dispatch the Calendar readable data to the reducer
    store.dispatch({
      type: "GET_ADMIN_EVENTS",
      adminEvents
    });
  });
};
// get all calendar events
export const getAllevents = uid => {
  // Define the Arrays
  const userEvents = [];
  let globalEvents = [];
  if (uid) {
    // Get the user array
    db.ref(`users/${uid}/myevents`).on("value", snap => {
      const userEventsRaw = snap.val();
      // Map over the raw data
      if (userEventsRaw) {
        Object.values(userEventsRaw).map(event => {
          let { title, desc, descEditor, id, type } = event;
          // Convert the raw times into a timestamp that the calendar can understand
          let start = fecha.parse(event.start, "YYYY-MM-DD hh:mm:ss.SSS A");
          let end = fecha.parse(event.end, "YYYY-MM-DD hh:mm:ss.SSS A");
          // Create an Array of Objects from the mapped
          // Objects and push them back together again
          const eventArray = { start, end, title, desc, descEditor, id, type };
          userEvents.push(eventArray);
          return "";
        });
      }
    });
    // Get the global array
    db.ref("calendar/adminEvents").on("value", snap => {
      // Create a constant of the raw data from firebase
      const adminEventsRaw = snap.val();
      // Map over the raw data
      if (adminEventsRaw) {
        Object.values(adminEventsRaw).map(event => {
          let { title, desc, descEditor, id, type } = event;
          // Convert the raw times into a timestamp that the calendar can understand
          let start = fecha.parse(event.start, "YYYY-MM-DD hh:mm:ss.SSS A");
          let end = fecha.parse(event.end, "YYYY-MM-DD hh:mm:ss.SSS A");
          // Create an Array of Objects from the mapped
          // Objects and push them back together again
          const eventArray = { start, end, title, desc, descEditor, id, type };
          globalEvents.push(eventArray);
          return "";
        });
      }
    });
  }
  let adminEvents = globalEvents.concat(userEvents);
  // Dispatch the Calendar readable data to the reducer
  store.dispatch({
    type: "GET_ADMIN_EVENTS",
    adminEvents
  });
};
