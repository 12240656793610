import React, { Component } from "react";
import { connect } from "react-redux";
import store from "../../store";
import {
  removeAttachedFile,
  toggleEditState,
  updateInsight
} from "../../actions/insights";
import { Label, Form, Input, Col, Row } from "reactstrap";
import {
  Button,
  Segment,
  Icon,
  Card,
  Loader,
  Dropdown,
  Input as InputSemantic,
  Label as LabelSemantic
} from "semantic-ui-react";
import {
  MegadraftEditor,
  editorStateFromRaw,
  editorStateToJSON
} from "megadraft";
import "../MegaDraft/megadraft.css";
import { styles } from "../../styles";
import styled from "styled-components";
import {
  createNotification,
  NOTIFICATION_TYPE_SUCCESS,
  NOTIFICATION_TYPE_WARNING
} from "react-redux-notify";
import ReactGA from "react-ga";

// const ContentGrid = styled.div`
//   display: grid;
//   grid-template-columns: 1fr 1fr;
// `;
const GridItem = styled.div`
  padding: 3px;
`;
const mySuccessNotification = {
  message: `Insight Successfully Updated`,
  type: NOTIFICATION_TYPE_SUCCESS,
  duration: 3000,
  canDismiss: true,
  icon: <Icon name="check" />
};
const emptyTitleWarning = {
  message: "Sorry cannot submit an empty title",
  type: NOTIFICATION_TYPE_WARNING,
  duration: 3000,
  canDismiss: true,
  icon: <Icon name="warning sign" />
};
const emptySegment = {
  message: "Insight needs at least one segment",
  type: NOTIFICATION_TYPE_WARNING,
  duration: 3000,
  canDismiss: true,
  icon: <Icon name="warning sign" />
};

const invalidFileSize = {
  message: "Please upload a valid file size",
  type: NOTIFICATION_TYPE_WARNING,
  duration: 3000,
  canDismiss: true,
  icon: <Icon name="warning sign" />
};

const INITIAL_STATE = {
  key: "",
  title: "",
  subTitle: "",
  category: "",
  file: null,
  featuredImage: "",
  content: "",
  editorState: editorStateFromRaw(null),
  selectedOption: "",
  currentFileClass: "show",
  videoUrl: "",
  dropDownOptionsState: [],
  applicableTo: "",
  errors: "",
  template: null,
  previewImage: "",
  hovering: false,
  options: [
    { key: "0", text: "No file chosen", value: null },
    {
      key: "1",
      text: "Insight General.png",
      value:
        "https://firebasestorage.googleapis.com/v0/b/icon-dev-413c0.appspot.com/o/InsightTemplateImages%2FInsight%20General.png?alt=media&token=e5f3bbdf-df3a-4807-9d5e-178ce9acb069"
    },
    {
      key: "2",
      text: "Policy Update.png",
      value:
        "https://firebasestorage.googleapis.com/v0/b/icon-dev-413c0.appspot.com/o/InsightTemplateImages%2FPolicy%20Update.png?alt=media&token=184ff164-5a44-42cb-b13c-4989fc972991"
    },
    {
      key: "3",
      text: "Procurement.png",
      value:
        "https://firebasestorage.googleapis.com/v0/b/icon-dev-413c0.appspot.com/o/InsightTemplateImages%2FProcurement.png?alt=media&token=509e690a-b503-4933-8770-f5ca7ce3bed9"
    },
    {
      key: "4",
      text: "Rep & Policy.png",
      value:
        "https://firebasestorage.googleapis.com/v0/b/icon-dev-413c0.appspot.com/o/InsightTemplateImages%2FRep%26Policy.png?alt=media&token=7457a26c-7b82-490b-af44-a11be3c7999a"
    },
    {
      key: "5",
      text: "Toolkit.png",
      value:
        "https://firebasestorage.googleapis.com/v0/b/icon-dev-413c0.appspot.com/o/InsightTemplateImages%2FToolkit.png?alt=media&token=2d5aa684-a1b4-487a-b2e8-38174d2513d5"
    },
    {
      key: "6",
      text: "Water.png",
      value:
        "https://firebasestorage.googleapis.com/v0/b/icon-dev-413c0.appspot.com/o/InsightTemplateImages%2FWater.png?alt=media&token=f4548f5d-cde0-439d-854c-8aaaa54d88d2"
    }
  ]
};

class EditInsight extends Component {
  constructor(props) {
    super(props);
    this.state = INITIAL_STATE;
  }

  componentDidMount = () => {
    window.scrollTo(0, 0);
    const editItem = this.props.currentEditInsight;
    const { categories } = this.props;
    // converting categories in props into a readable format for the display component
    const X = categories.map((cat, idx) => ({
      id: idx,
      key: cat.key,
      text: cat.categoryName,
      value: cat.categorySlug
    }));
    if (editItem.contentJSON) {
      this.setState({
        editorState: editorStateFromRaw(JSON.parse(editItem.contentJSON))
      });
    }
    this.setState({
      key: editItem.key,
      title: editItem.title,
      subTitle: editItem.subTitle === undefined ? null : editItem.subTitle,
      category: editItem.category,
      applicableTo: editItem.tags,
      videoUrl: editItem.videoUrl,
      dropDownOptionsState: X
    });
  };

  handleInputChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  handleFileSelect = e => {
    this.setState({
      file: e.target.files[0]
    });
  };

  handleImageSelect = e => {
    console.log("e.target.files ", e.target.files[0]);
    // if file is greater than 5mb
    if (e.target.files[0] && e.target.files[0].size > 5000000) {
      return this.setState({
        errors: "File size is too big!",
        featuredImage: e.target.files[0] ? e.target.files[0] : null,
        template: null
      });
    }

    // if file is less than 5kb
    if (e.target.files[0] && e.target.files[0].size < 5000) {
      return this.setState({
        errors: "File size is too small!",
        featuredImage: e.target.files[0] ? e.target.files[0] : null,
        template: null
      });
    }

    this.setState(
      {
        featuredImage: e.target.files[0] ? e.target.files[0] : null,
        template: null,
        errors: ""
      },
      function() {
        console.log(this.state.featuredImage, " Featured Image");
      }
    );
  };

  handleTemplateFile = (e, data) => {
    this.setState({
      featuredImage: data,
      template: data.value,
      errors: ""
    });
  };

  handleReactSelectChange = (e, data) => {
    this.setState({ category: data.value });
  };

  handleDeleteFile = () => {
    removeAttachedFile(this.props.currentEditInsight);
    this.setState({ CurrentFileClass: "hide" });
  };

  handleReactSelectChange = (e, data) => {
    this.setState({ category: data.value });
  };

  handleCategory = (e, data) => {
    this.setState({ category: data.value });
  };

  handleSelect = (e, data) => {
    this.setState({ applicableTo: data.value });
  };

  onChange = editorState => {
    this.setState({ editorState });
  };

  handleSubmit = async e => {
    e.preventDefault();
    const {
      key,
      title,
      subTitle,
      category,
      applicableTo,
      featuredImage,
      file,
      videoUrl,
      editorState
    } = this.state;
    const { uid, displayName, profilePhoto } = this.props.currentUser;
    const contentJSON = editorStateToJSON(editorState);

    const { createNotification } = this.props;
    if (
      this.state.title === "" ||
      this.state.title.replace(/\s+/g, "").length === 0
    ) {
      return createNotification(emptyTitleWarning);
    }

    if (this.state.applicableTo[0] === undefined) {
      return createNotification(emptySegment);
    }

    if (this.state.errors) {
      return createNotification(invalidFileSize);
    }

    updateInsight(
      key,
      uid,
      title,
      subTitle,
      category,
      displayName,
      profilePhoto,
      featuredImage,
      file,
      applicableTo,
      videoUrl,
      contentJSON
    );
    store.dispatch(toggleEditState());
    createNotification(mySuccessNotification);
    this.setState(INITIAL_STATE);
  };

  render() {
    const insightCategoriesArray = [
      { key: 0, text: "General", value: "General" }
    ];

    this.props.categories.map((category, i) => {
      return (
        category.categoryVisable &&
        insightCategoriesArray.push({
          key: i + 1,
          text: category.categoryName,
          value: category.categoryName
        })
      );
    });

    const insightIndustriesArray = [];

    this.props.industries &&
      this.props.industries.map((industry, i) => {
        return (
          industry.isActive &&
          insightIndustriesArray.push({
            key: i,
            text: industry.industryName,
            value: industry.industryName
          })
        );
      });

    return (
      <Card fluid style={styles.profileComponentStyle} color="teal">
        <Card.Content style={styles.cardContentStyle}>
          <div style={styles.cardHeaderStyle}>EDIT INSIGHT</div>
          <div style={styles.divStyleLeftbox} />
          <Segment style={styles.segmentStyle}>
            <Form onSubmit={this.handleSubmit} style={styles.formStyle}>
              <Row>
                <Col>
                  <Input
                    name="title"
                    value={this.state.title}
                    onChange={this.handleInputChange}
                    placeholder="Title"
                  />
                </Col>
                <Col>
                  <Input
                    onChange={this.handleInputChange}
                    name="subTitle"
                    value={this.state.subTitle}
                    placeholder="Sub Title"
                  />
                </Col>
                <Col>
                  <Dropdown
                    placeholder="Choose a Category ..."
                    fluid
                    selection
                    options={insightCategoriesArray}
                    onChange={this.handleCategory}
                    value={this.state.category}
                  />
                </Col>
              </Row>
              <hr />
              <Row>
                <Col>
                  <div>
                    <p>Current uploaded image:</p>
                    <img
                      src={this.props.currentEditInsight.featuredImage}
                      alt="None"
                      style={
                        this.props.currentEditInsight.featuredImage
                          ? { width: 179.3, height: 100, marginBottom: 5 }
                          : {
                              border: "0.5px dotted black",
                              width: 179.3,
                              height: 100,
                              marginBottom: 5
                            }
                      }
                    />
                  </div>
                  <Input
                    type="file"
                    name="featuredImage"
                    id="featuredImage"
                    onChange={this.handleImageSelect}
                    value={this.state.template && ""}
                    style={
                      this.state.errors
                        ? {
                            border: "1px solid red",
                            borderRadius: 7,
                            padding: 2
                          }
                        : this.state.featuredImage &&
                          !this.state.featuredImage.value &&
                          this.state.featuredImage.value !== null
                        ? {
                            border: "1px solid green",
                            borderRadius: 7,
                            padding: 2,
                            color: "green"
                          }
                        : {}
                    }
                  />
                  {/* recommended file size and error message */}
                  <i
                    style={{
                      color: "grey",
                      display: "inline-block",
                      paddingTop: 5
                    }}
                  >
                    File must be between 5kb and 5mb
                  </i>
                  <p style={{ color: "red" }}>{this.state.errors}</p>
                  <p style={{ marginTop: 5 }}>...or</p>
                  <div
                    style={
                      this.state.template !== null
                        ? {
                            border: "1px solid green",
                            borderRadius: 7,
                            padding: 2,
                            width: 250
                          }
                        : {}
                    }
                  >
                    <span style={{ fontWeight: 500 }}>Template file:</span>{" "}
                    <Dropdown
                      style={
                        this.state.template !== null
                          ? {
                              color: "green"
                            }
                          : {}
                      }
                      placeholder="No file chosen"
                      options={this.state.options}
                      onChange={this.handleTemplateFile}
                      onMouseOver={e => {
                        this.state.options.map((item, i) => {
                          return (
                            item.text.includes(e.target.innerText) &&
                            this.setState({
                              previewImage: item,
                              hovering: true
                            })
                          );
                        });
                      }}
                      onMouseOut={() => {
                        this.setState({ previewImage: "", hovering: false });
                      }}
                      value={this.state.template}
                    />
                  </div>
                </Col>
                <Col>
                  <p
                    style={
                      !this.state.previewImage ? { visibility: "hidden" } : {}
                    }
                  >
                    Template preview:
                  </p>

                  <div
                    style={{
                      width: 179.3,
                      height: 100
                    }}
                  >
                    <div
                      alt=""
                      style={
                        this.state.previewImage
                          ? {
                              width: 179.3,
                              height: 100,
                              marginBottom: 5,
                              backgroundSize: "cover",
                              position: "relative",
                              backgroundImage: `url(${
                                this.state.previewImage.value
                              })`,
                              zIndex: 2
                            }
                          : {
                              width: 179.3,
                              height: 100,
                              marginBottom: 5,
                              position: "relative",
                              visibility: "hidden",
                              zIndex: 2
                            }
                      }
                    />
                    {this.state.previewImage && (
                      <Loader
                        style={
                          this.state.hovering &&
                          this.state.previewImage !== null
                            ? {
                                position: "relative",
                                zIndex: 0,
                                top: -80,
                                left: 50
                              }
                            : {}
                        }
                        active
                        inline
                      />
                    )}
                  </div>
                </Col>
              </Row>

              <hr />

              <Segment style={{ margin: 3, boxShadow: "none" }}>
                {this.props.currentEditInsight.fileName ? (
                  <div>
                    <GridItem>
                      <LabelSemantic color="blue">
                        <Icon name="download" />
                        <ReactGA.OutboundLink
                          eventLabel={
                            this.props.currentEditInsight.fileName || "no file"
                          }
                          to={this.props.currentEditInsight.fileLocation}
                          target="_blank"
                        >
                          {" " + this.props.currentEditInsight.fileName + " "}
                        </ReactGA.OutboundLink>
                      </LabelSemantic>
                    </GridItem>

                    <GridItem>
                      <Button compact onClick={this.handleDeleteFile}>
                        Remove
                      </Button>
                    </GridItem>
                  </div>
                ) : (
                  <GridItem>
                    <Label>TIP: Give your file a sensible name!</Label>
                    <Input
                      type="file"
                      name="file"
                      placeholder={this.props.currentEditInsight.fileName || ""}
                      id="exampleFile"
                      onChange={this.handleFileSelect}
                    />
                  </GridItem>
                )}
              </Segment>

              <div>
                <GridItem>
                  <Dropdown
                    placeholder="Choose an industry segment ..."
                    fluid
                    multiple
                    selection
                    options={insightIndustriesArray}
                    value={this.state.applicableTo}
                    onChange={this.handleSelect}
                  />
                </GridItem>
              </div>

              <GridItem>
                <InputSemantic
                  fluid
                  label="Video Url"
                  name="videoUrl"
                  placeholder="https://www..."
                  onChange={this.handleInputChange}
                />
              </GridItem>

              <GridItem>
                <Segment style={{ minHeight: 100, boxShadow: "none" }}>
                  <MegadraftEditor
                    editorState={this.state.editorState}
                    onChange={this.onChange}
                    placeholder="WARNING: anything you enter here will override the current insight content section"
                  />
                </Segment>
              </GridItem>
              <GridItem>
                <Button type="submit">Update</Button>
                <Button
                  onClick={() => {
                    store.dispatch(toggleEditState());
                  }}
                >
                  Cancel
                </Button>
              </GridItem>
            </Form>
          </Segment>
        </Card.Content>
      </Card>
    );
  }
}

const mapStateToProps = state => ({
  currentUser: state.user.currentUserProps,
  currentEditInsight: state.insights.currentEditInsight,
  currentAllInsights: state.insights.currentAllInsightsProps,
  categories: state.appConfig.categories,
  industries: state.appConfig.industries
});

const mapDispatchToProps = dispatch => ({
  createNotification: config => {
    dispatch(createNotification(config));
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditInsight);
