import React, { Component } from "react";
import { connect } from "react-redux";
import {
  // Card,
  Row,
  Col,
  Container,
  TabContent,
  TabPane
} from "reactstrap";
import LegalRegisterUserTable from "../LegalRegister/AdminView/LegalRegisterUserTable";
import { setLegalRegisterCurrentLegal } from "../../actions/legalRegister";
import LegalCategoryUpload from "./LegalCategoryUpload";
import LegalRegulatorUpload from "./LegalRegulatorUpload";

class LegalRegisterManager extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: "1"
    };
  }

  componentDidMount() {
    setLegalRegisterCurrentLegal(null);
  }

  render() {
    return (
      <Container fluid>
        <Row>
          <Col md="12" sm="12">
            <TabContent activeTab={this.state.activeTab}>
              <TabPane tabId="1">
                <div className="animated fadeIn">
                  <LegalRegisterUserTable />
                  <Row style={{ marginBottom: 20 }}>
                    <Col sm="6">
                      <LegalCategoryUpload />
                    </Col>
                    <Col sm="6">
                      <LegalRegulatorUpload />
                    </Col>
                  </Row>
                </div>
              </TabPane>
            </TabContent>
          </Col>
        </Row>
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  authUser: state.sessionState.authUser,
  currentUser: state.user.currentUserProps
});

export default connect(mapStateToProps)(LegalRegisterManager);
