import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Form,
  Button,
  TextArea,
  Input,
  Select,
  List,
  Icon
} from "semantic-ui-react";
import styled from "styled-components";
// Actions
import { createCourse, removeCourse } from "../../../../actions/university";
// import { object } from "firebase-functions/lib/providers/storage";

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-top: 25px;
`;

const GridItem = styled.div`
  padding: 10px;
  test-align: center;
  margin: 25px;
  border-style: solid;
  border-width: 1px 1px;
  border-radius: 7px;
  border-color: rgba(0, 0, 0, 0.3);
  background-color: white;
  box-shadow: 10px 10px 33px -12px rgba(0, 0, 0, 0.75);
`;

class Courses extends Component {
  state = {
    courseTitle: "",
    courseDescription: "",
    courseSkillLevel: "",
    courseInstructors: [],
    courseImage: "",
    courseErrors: false,
    courseDocument: ""
  };

  handleCourseSubmit = e => {
    if (
      !this.state.courseSkillLevel ||
      this.state.courseInstructors.length === 0
    ) {
      return this.setState({ courseErrors: true });
    }
    e.preventDefault();
    createCourse(
      this.state.courseTitle,
      this.state.courseDescription,
      this.state.courseSkillLevel,
      this.state.courseInstructors,
      this.state.courseImage
    );
    this.setState({
      courseTitle: "",
      courseTopic: "",
      courseDescription: "",
      courseSkillLevel: "",
      courseInstructors: [],
      courseDocument: "",
      courseErrors: false
    });
  };

  handleInputChange = (e, data) => {
    this.setState({ [data.name]: data.value });
  };

  handleRemoveCourse = key => {
    removeCourse(key);
  };

  handleImageSelect = e => {
    this.setState({
      courseImage: e.target.files[0]
    });
  };

  render() {
    const allCourses = [];
    const allSkillLevels = [];
    const allInstructors = [];

    this.props.courses &&
      this.props.courses.map((course, i) => {
        return allCourses.push({
          key: i,
          text: course.courseTitle,
          value: course.key
        });
      });

    this.props.skillLevels &&
      Object.values(this.props.skillLevels).map((skill, i) => {
        return allSkillLevels.push({
          key: i,
          text: skill.name,
          value: skill.name
        });
      });

    this.props.instructors &&
      Object.values(this.props.instructors).map((instructor, i) => {
        return allInstructors.push({
          key: i,
          text: instructor.firstName + " " + instructor.lastName,
          value: instructor.firstName + " " + instructor.lastName
        });
      });

    return (
      <Grid>
        <GridItem>
          <Form onSubmit={this.handleCourseSubmit}>
            <h2>Create a Course</h2>
            <Form.Field
              control={Input}
              label="Title"
              name="courseTitle"
              placeholder="Enter course title ..."
              onChange={this.handleInputChange}
              value={this.state.courseTitle}
              // required
            />
            <Form.Field
              control={Input}
              label="Topic"
              name="courseTopic"
              placeholder="Enter course topic ..."
              onChange={this.handleInputChange}
              value={this.state.courseTopic}
            />
            <Form.Field
              control={TextArea}
              label="Description"
              name="courseDescription"
              placeholder="Enter course description ..."
              onChange={this.handleInputChange}
              value={this.state.courseDescription}
            />
            <Form.Field
              control={Select}
              multiple
              label="Instructors"
              name="courseInstructors"
              options={allInstructors}
              placeholder="Choose an instructor ..."
              onChange={this.handleInputChange}
              value={this.state.courseInstructors}
              required
              error={
                this.state.courseErrors &&
                this.state.courseInstructors.length === 0
                  ? true
                  : false
              }
            />
            <Form.Field
              control={Select}
              label="Skill Level"
              name="courseSkillLevel"
              options={allSkillLevels}
              placeholder="Choose a skill level ..."
              onChange={this.handleInputChange}
              value={this.state.courseSkillLevel}
              required
              error={
                this.state.courseErrors && !this.state.courseSkillLevel
                  ? true
                  : false
              }
            />
            <p style={{ fontFamily: "Avenir Next", fontWeight: "bold" }}>
              Featured Image
            </p>
            <input
              style={{ display: "block", marginBottom: 15 }}
              type="file"
              name="courseImage"
              id="courseImage"
              onChange={this.handleImageSelect}
            />
            <p style={{ fontFamily: "Avenir Next", fontWeight: "bold" }}>
              Final Document
            </p>
            <input
              style={{ display: "block", marginBottom: 15 }}
              type="file"
              name="courseDocumment"
              id="courseDocument"
              // onChange={}
            />
            <Button type="submit">Submit</Button>
          </Form>
        </GridItem>
        <GridItem>
          <h3>Courses</h3>
          <List divided relaxed>
            {this.props.courses &&
              Object.values(this.props.courses).map((course, idx) => {
                return (
                  <List.Item key={idx}>
                    <List.Content>
                      <h5 style={{ color: "green" }}>
                        {course.courseTitle}
                        <Icon
                          name="delete"
                          style={{ color: "red", cursor: "pointer" }}
                          onClick={() => this.handleRemoveCourse(course.key)}
                        />
                      </h5>
                      <p>{course.courseDescription}</p>
                      <p>Difficulty: {course.courseSkillLevel}</p>

                      {course.courseInstructors && (
                        <div>
                          <span>Taught by:</span>
                          <ul>
                            {course.courseInstructors.map((instructor, i) => {
                              return <li key={i}>{instructor} </li>;
                            })}
                          </ul>
                        </div>
                      )}
                    </List.Content>
                  </List.Item>
                );
              })}
          </List>
        </GridItem>
      </Grid>
    );
  }
}

const mapStateToProps = state => ({
  courses: state.university.courses,
  instructors: state.university.instructors,
  skillLevels: state.university.skillLevels
});

export default connect(mapStateToProps)(Courses);
