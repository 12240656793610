import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Card,
  Icon,
  Button,
  Input as SemanticInput,
  Dropdown,
  Flag,
  Popup,
  Loader,
} from "semantic-ui-react";
import { countries } from "../../constants";
import InfiniteScroll from "react-infinite-scroll-component";
import { DebounceInput } from "react-debounce-input";
import {
  setLegalItem,
  getAllLegalRegisterItems,
  getMyLegalRegisterItems,
  addMyLegalRegisterItem,
  updateLegalRegisterTimeAdded,
} from "../../actions/legalRegister";
import "../MegaDraft/megadraft.css";
import LegalItemModal from "../Modals/LegalItemModal";
import {
  Container,
  Col,
  Row,
  ListGroupItem,
  ListGroupItemHeading,
  ListGroupItemText,
  Button as Btn,
} from "reactstrap";
import { styles, buttonStyles } from "../../styles";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import { getLegalCategories } from "../../actions/configActions";

import { renderFlag } from "./flag";

// Notifications
import {
  createNotification,
  NOTIFICATION_TYPE_SUCCESS,
} from "react-redux-notify";

const mySuccessNotification = {
  message: `Legal Item added to your register!`,
  type: NOTIFICATION_TYPE_SUCCESS,
  duration: 3000,
  canDismiss: true,
  icon: <Icon name="check" />,
};

class AllLegalItemsList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      items: this.props.currentLegalItems,
      limit: 10,
      itemAmount: 0,
    };
  }

  componentDidMount() {
    getAllLegalRegisterItems();
    getLegalCategories();
  }

  fetchMoreData = () => {
    const itemAmount = this.state.itemAmount + 10;
    const limit = this.state.limit + 10;

    setTimeout(() => {
      this.setState({
        limit: limit,
        items: this.props.currentLegalItems.slice(0, itemAmount),
        itemAmount: itemAmount,
      });
    }, 1000);
  };

  handleSearch = e => {
    getAllLegalRegisterItems(
      this.props.allCategories,
      e.target.value,
      this.props.allCountries
    );
  };

  handleCategoryFilter = (e, data) => {
    getAllLegalRegisterItems(
      data.value,
      this.props.allQuery,
      this.props.allCountries
    );
  };

  handleCountryFilter = (e, data) => {
    getAllLegalRegisterItems(
      this.props.allCategories,
      this.props.allQuery,
      data.value
    );
  };

  toggle = key => {
    if (typeof key === "string") {
      setLegalItem(key);
    }
    this.setState({ modal: !this.state.modal });
  };

  handleAddItem = legalItem => {
    updateLegalRegisterTimeAdded(this.props.currentUser.uid, legalItem);

    addMyLegalRegisterItem(this.props.currentUser.uid, legalItem);
    getAllLegalRegisterItems(
      this.props.allCategories,
      this.props.allQuery,
      this.props.allCountries
    );
    getMyLegalRegisterItems(
      this.props.myCategories,
      this.props.myQuery,
      this.props.myCountries
    );

    const { createNotification } = this.props;
    createNotification(mySuccessNotification);
  };

  renderAllLegalItems() {
    return this.props.currentLegalItems.length > 0 ? (
      this.props.currentLegalItems.map((item, index) => {
        return (
          <ListGroupItem
            key={index}
            style={index >= this.state.limit ? { display: "none" } : {}}
          >
            <Row>
              <Col>
                <ListGroupItemHeading>{item.title}</ListGroupItemHeading>
              </Col>
            </Row>
            <Row>
              <Col>
                <ListGroupItemText>{item.filterByArea}</ListGroupItemText>

                {item.country &&
                  item.country.map((flag, i) => renderFlag(flag, i))}
              </Col>

              <Btn onClick={() => this.handleAddItem(item.key)} color="link">
                {" "}
                <Icon name="add" />
                Add
              </Btn>

              <Button
                style={buttonStyles.primaryButton}
                color="teal"
                onClick={() => this.toggle(item.key)}
              >
                View
              </Button>
            </Row>
          </ListGroupItem>
        );
      })
    ) : this.props.allQuery !== "" ? (
      <p style={{ textAlign: "center", fontSize: 18 }}>
        We're sorry, no results for {<strong>"{this.props.allQuery}"</strong>}{" "}
        were found.
      </p>
    ) : (
      <p style={{ textAlign: "center", fontSize: 18 }}>
        We're sorry, no results were found.
      </p>
    );
  }

  render() {
    const legalCategoriesArray = [];

    this.props.legalCategories &&
      this.props.legalCategories.map((category, i) => {
        return (
          category.isActive &&
          legalCategoriesArray.push({
            key: i,
            text: category.legalCategoryName,
            value: category.legalCategoryName,
          })
        );
      });

    console.log("LEGAL REGISTER", { props: this.props });

    return (
      <Container>
        <Card color="teal" fluid style={styles.profileComponentStyle}>
          <Card.Content style={styles.cardContentStyle}>
            <div style={styles.cardHeaderStyle}>
              All Legal Register
              {` (${this.props.currentLegalItems.length})`}
            </div>
            <div style={styles.divStyleLeftbox} />
            <Row>
              <Col sm="12">
                <div style={{ padding: 15 }}>
                  <Card.Meta style={styles.textElementStyle}>
                    • Click 'View' on an item to view the detail
                  </Card.Meta>
                  <Card.Meta style={styles.textElementStyle}>
                    • Click "Add" an item from the All Legal Register to your
                    'My Legal Register' to add to your legal register.
                  </Card.Meta>

                  <div>
                    <SemanticInput icon="search">
                      <DebounceInput
                        style={{ borderRadius: 20, marginTop: 35, width: 250 }}
                        type="Search"
                        debounceTimeout={500}
                        minLength={2}
                        onChange={this.handleSearch}
                        placeholder=" Search ... "
                      />
                      <Icon name="search" style={{ marginTop: 15 }} />
                    </SemanticInput>

                    <Dropdown
                      style={{ marginTop: 15, marginBottom: 15 }}
                      placeholder="Filter By Legal Category"
                      fluid
                      multiple
                      selection
                      options={legalCategoriesArray}
                      onChange={this.handleCategoryFilter}
                    />

                    <Dropdown
                      style={{ marginTop: 15, marginBottom: 15 }}
                      placeholder="Filter By Country"
                      fluid
                      multiple
                      selection
                      options={countries}
                      onChange={this.handleCountryFilter}
                    />
                  </div>

                  <InfiniteScroll
                    style={{ overflowX: "hidden" }}
                    height={550}
                    dataLength={this.state.items.length}
                    next={this.fetchMoreData}
                    hasMore={true}
                    loader={
                      this.state.limit >=
                      this.props.currentLegalItems.length ? (
                        <h4> </h4>
                      ) : (
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            marginTop: 20,
                          }}
                        >
                          <Loader active inline content="Loading..." />
                        </div>
                      )
                    }
                  >
                    {this.renderAllLegalItems()}
                  </InfiniteScroll>
                </div>
              </Col>
            </Row>
          </Card.Content>
          {this.state.modal && (
            <LegalItemModal modal={this.state.modal} toggle={this.toggle} />
          )}
        </Card>
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  currentUser: state.user.currentUserProps,
  currentLegalItems: state.legalItems.currentAllLegalItemsProps,
  currentMyLegalItems: state.user.currentUserProps.MyLegalItems,
  legalCategories: state.appConfig.legalCategories,
  // filters
  myCategories: state.legalItems.currentMyLegalItemsCategories,
  myCountries: state.legalItems.currentMyLegalItemsCountries,
  myQuery: state.legalItems.currentMyLegalItemsQuery,
  allCategories: state.legalItems.currentAllLegalItemsCategories,
  allCountries: state.legalItems.currentAllLegalItemsCountries,
  allQuery: state.legalItems.currentAllLegalItemsQuery,
});

const mapDispatchToProps = dispatch => ({
  createNotification: config => {
    dispatch(createNotification(config));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(AllLegalItemsList);
