import React, { Component } from "react";
import { connect } from "react-redux";
import store from "../../store";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Container
} from "reactstrap";
import { Card, Button, Icon, Segment, Image } from "semantic-ui-react";
import Logo_Light from "../../img/Logo_A.png";
import ScrollAnimation from "react-animate-on-scroll";

import {
  getAllInsights,
  setCurrentInsightProps,
  setInsight
} from "../../actions/insights";
import Background from "../../img/pattern-bg.jpg";

class InsightModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false
    };

    this.toggle = this.toggle.bind(this);
  }

  componentWillMount() {
    getAllInsights();
  }

  toggle() {
    this.setState({
      modal: !this.state.modal
    });
    store.dispatch(setCurrentInsightProps({}));
  }

  handleSelectClick = key => {
    setInsight(key);
  };

  render() {
    return (
      <div>
        <Button color="teal" onClick={this.toggle}>
          {this.props.buttonLabel}
          <Icon name="right chevron" />
        </Button>
        <Modal
          isOpen={this.state.modal}
          toggle={this.toggle}
          className={this.props.className}
          size="lg"
        >
          <ModalHeader toggle={this.toggle}>
            Oh, looks like you are trying to view content for Subscribers!
          </ModalHeader>
          <ModalBody>
            <Card fluid style={styles.profileComponentStyle}>
              <Card.Content style={styles.cardContentStyle}>
                <Segment style={styles.segmentStyle}>
                  <Container>
                    <h1>
                      You are currently registered as a ghost{" "}
                      {/* <span role="img" alt="ghost" aira-label="ghost"> */}
                      👻
                      {/* </span> */}
                    </h1>
                    <ScrollAnimation animateIn="bounceInDown" offset={1}>
                      <Image
                        src={Logo_Light}
                        alt="logo-light"
                        // style={{ marginLeft: 15 }}
                        style={{ height: 75 }}
                        floated="right"
                      />
                    </ScrollAnimation>
                    <h2>
                      .... I mean <i>'Guest'</i>
                    </h2>
                    <h4>
                      Why not consider joining Icon as a <i>'subscriber'</i>{" "}
                      {/* <span role="img" alt="grad cap"> */}
                      🎓
                      {/* </span> */}
                    </h4>
                    <p>Here are the benefits</p>
                    <ul>
                      <li>
                        <strong>Influence -</strong> We have strong
                        relationships with BEIS, Ofgem and other key agencies.
                        Be amongst the first to hear about and have chance to
                        contribute to policy making in the earliest stages{" "}
                      </li>
                      <li>
                        <strong>Compliance -</strong> Our online tool explains
                        legislation in plain English and helps you track changes
                        or build a Legal Register to support ISO14001 and
                        ISO50001. We’ll update the Regs so you can focus on
                        performance.{" "}
                      </li>
                      <li>
                        <strong>Operational Efficiency –</strong> Why spend
                        hours looking for the right information when you can
                        have it to hand, saved in your own account? We provide
                        cutting edge insight on the technologies and policies
                        you really need to know about.{" "}
                      </li>
                      <li>
                        <strong>Networking -</strong> Energy Management can be
                        an isolated role at times. icon lets members exchange
                        ideas and provide mutual support, without giving away
                        commercial sensitivities. It's the perfect platform for
                        building professional relationships.
                      </li>
                    </ul>
                    <p>
                      For more information on subscription, please email:{" "}
                      <a href="mailto:georgina@theicon.org.uk?subject=Icon Subscription Enquiry">
                        georgina@theicon.org.uk
                      </a>
                    </p>
                  </Container>
                </Segment>
              </Card.Content>
            </Card>
          </ModalBody>
          <ModalFooter>
            <Button color="yellow" onClick={this.toggle}>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

const styles = {
  profileComponentStyle: {
    boxShadow: "none",
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    borderColor: "#000",
    borderBottomWidth: 0,
    shadowColor: "#000",
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.8,
    shadowRadius: 10,
    backgroundImage: `url(${Background})`,
    borderWidth: 0
  },
  cardHeaderStyle: {
    backgroundColor: "#26647F",
    color: "white",
    padding: 3,
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    paddingLeft: 25,
    margin: 0,
    boxShadow: 5,
    shadowOffset: { width: 10, height: 10 },
    shadowColor: "black",
    shadowOpacity: 1.0
  },
  cardContentStyle: {
    padding: 0,
    margin: 0,
    borderWidth: 0
  },
  segmentStyle: {
    borderWidth: 0,
    backgroundImage: `url(${Background})`,
    borderColor: "transparent"
  },
  authorNameStyle: {
    fontWeight: "bold",
    textAlign: "right"
  },
  iframeStyle: {
    padding: 3
  }
};

const mapStateToProps = state => ({
  currentInsight: state.insights.currentInsightProps
});

export default connect(mapStateToProps)(InsightModal);
