import React, { Component } from "react";
import { connect } from "react-redux";
import { editorStateFromRaw } from "megadraft";
import LegalItemModal from "../Modals/LegalItemModal";
import InfiniteScroll from "react-infinite-scroll-component";
// Styles
import { buttonStyles, styles } from "../../styles";
import {
  Icon,
  Button,
  Flag,
  Popup,
  Card,
  Dropdown,
  Input as SemanticInput,
  Loader
} from "semantic-ui-react";
import { DebounceInput } from "react-debounce-input";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import {
  Container,
  Col,
  Row,
  ListGroup,
  ListGroupItem,
  ListGroupItemHeading,
  ListGroupItemText
} from "reactstrap";
import { countries } from "../../constants";
// Actions
import {
  setLegalItem,
  getAllLegalItems,
  deleteLegalItemAction
} from "../../actions/legalRegister";
import { getLegalCategories } from "../../actions/configActions";

import {
  createNotification,
  NOTIFICATION_TYPE_WARNING
} from "react-redux-notify"; //  Notifications

class LegalRegisterTable extends Component {
  constructor(props) {
    super(props);

    console.log(this.props);
    this.state = {
      applicableTo: [],
      consequence: "",
      description: "",
      filterByArea: "",
      headlineDetails: "",
      link: "",
      regulator: [],
      title: "",
      redirect: false,
      loaderState: false,
      headlineEditorState: editorStateFromRaw(null),
      descriptionEditorState: editorStateFromRaw(null),
      consequenceEditorState: editorStateFromRaw(null),
      howThisAppliesEditorState: editorStateFromRaw(null),
      row: "",
      query: "",
      categories: "",
      countries: "",
      modal: false,
      items: this.props.allLegalItems,
      limit: 10,
      itemAmount: 0
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    getAllLegalItems();
    getLegalCategories();
  }

  handleSelectEdit = item => {
    const key = item;
    setLegalItem(key);
    this.props.handleEditToggle();
  };

  toggle = key => {
    if (typeof key === "string") {
      setLegalItem(key);
    }
    this.setState({ modal: !this.state.modal });
  };

  handleRemoveClick = item => {
    const { createNotification } = this.props;
    const sureDeleteNotification = {
      message: `Sure you want to delete this beautifully constructed legal item?`,
      type: NOTIFICATION_TYPE_WARNING,
      duration: 0,
      canDismiss: false,
      icon: <Icon name="warning sign" />,
      acceptBtn: {
        handler: (e, notification) => {
          deleteLegalItemAction(item);
          notification.handleDismiss(notification.id);
        },
        title: "Yup",
        icon: <Icon name="thumbs up" />
      },
      denyBtn: {
        handler: (e, notification) => {
          notification.handleDismiss(notification.id);
        },
        title: "Nope",
        icon: <Icon name="thumbs down" />
      }
    };
    createNotification(sureDeleteNotification);
  };

  handleSearch = e => {
    this.setState({ query: e.target.value });
    getAllLegalItems(
      this.state.categories,
      e.target.value,
      this.state.countries
    );
  };

  handleCategoryFilter = (e, data) => {
    this.setState({ categories: data.value });
    getAllLegalItems(data.value, this.state.query, this.state.countries);
  };

  handleCountryFilter = (e, data) => {
    this.setState({ countries: data.value });
    getAllLegalItems(this.state.categories, this.state.query, data.value);
  };

  fetchMoreData = () => {
    const itemAmount = this.state.itemAmount + 10;
    const limit = this.state.limit + 10;

    setTimeout(() => {
      this.setState({
        limit: limit,
        items: this.props.allLegalItems.slice(0, itemAmount),
        itemAmount: itemAmount
      });
    }, 1000);
  };

  renderAllLegalItems() {
    return this.props.allLegalItems.length > 0 ? (
      this.props.allLegalItems.map((item, index) => {
        return (
          <ListGroupItem
            key={index}
            style={index >= this.state.limit ? { display: "none" } : {}}
          >
            <Row>
              <Col>
                <ListGroupItemHeading>{item.title}</ListGroupItemHeading>
              </Col>
            </Row>
            <Row>
              <Col>
                <ListGroupItemText style={{ marginTop: 5 }}>
                  {item.filterByArea}
                </ListGroupItemText>
                {item.country &&
                  item.country.map((flag, i) => {
                    return (
                      <Popup
                        trigger={
                          <Flag
                            name={
                              flag !== "Northern Ireland"
                                ? flag.toLowerCase()
                                : "niflag"
                            }
                          />
                        }
                        content={flag}
                      />
                    );
                  })}
              </Col>
              <Button
                style={buttonStyles.primaryButton}
                color="teal"
                onClick={() => this.toggle(item.key)}
              >
                View
              </Button>
              <Button
                onClick={() => {
                  this.handleSelectEdit(item.key);
                }}
                style={buttonStyles.editButton}
                color="grey"
              >
                Edit
              </Button>
              <Button
                onClick={() => this.handleRemoveClick(item)}
                style={buttonStyles.deleteButton}
                color="grey"
              >
                <Icon name="remove circle" />
                Remove
              </Button>
            </Row>
          </ListGroupItem>
        );
      })
    ) : this.state.query !== "" ? (
      <p style={{ textAlign: "center", fontSize: 18 }}>
        We're sorry, no results for {<strong>"{this.state.query}"</strong>} were
        found.
      </p>
    ) : (
      <p style={{ textAlign: "center", fontSize: 18 }}>
        We're sorry, no results were found.
      </p>
    );
  }

  render() {
    const legalCategoriesArray = [];

    this.props.legalCategories &&
      this.props.legalCategories.map((category, i) => {
        return (
          category.isActive &&
          legalCategoriesArray.push({
            key: i,
            text: category.legalCategoryName,
            value: category.legalCategoryName
          })
        );
      });

    return (
      <Container>
        <Card color="teal" fluid style={styles.profileComponentStyle}>
          <Card.Content style={styles.cardContentStyle}>
            <ListGroupItem style={{ backgroundColor: "#dfeff4" }}>
              <SemanticInput icon="search">
                <DebounceInput
                  style={{ borderRadius: 20, marginTop: 30, width: 250 }}
                  type="Search"
                  debounceTimeout={500}
                  minLength={2}
                  onChange={this.handleSearch}
                  placeholder=" Search ... "
                />
                <Icon name="search" style={{ marginTop: 15 }} />
              </SemanticInput>

              <Dropdown
                style={{ marginTop: 15, marginBottom: 15 }}
                placeholder="Filter By Legal Category"
                fluid
                multiple
                selection
                options={legalCategoriesArray}
                onChange={this.handleCategoryFilter}
              />

              <Dropdown
                style={{ marginTop: 15, marginBottom: 15 }}
                placeholder="Filter By Country"
                fluid
                multiple
                selection
                options={countries}
                onChange={this.handleCountryFilter}
              />

              <InfiniteScroll
                style={{ overflowX: "hidden" }}
                height={930}
                dataLength={this.state.items.length}
                next={this.fetchMoreData}
                hasMore={true}
                loader={
                  this.state.limit >= this.props.allLegalItems.length ? (
                    <h4> </h4>
                  ) : (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        marginTop: 20
                      }}
                    >
                      <Loader active inline content="Loading..." />
                    </div>
                  )
                }
              >
                <Row>
                  <Col sm="12">
                    <ListGroup>{this.renderAllLegalItems()}</ListGroup>
                  </Col>
                </Row>
              </InfiniteScroll>
            </ListGroupItem>
          </Card.Content>
          {this.state.modal && (
            <LegalItemModal modal={this.state.modal} toggle={this.toggle} />
          )}
        </Card>
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  currentUser: state.user.currentUserProps,
  currentLegalItemProps: state.legalItems.currentLegalItemProps,
  legalCategories: state.appConfig.legalCategories,
  allLegalItems: state.legalItems.allLegalItems
});

const mapDispatchToProps = dispatch => ({
  createNotification: config => {
    dispatch(createNotification(config));
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LegalRegisterTable);
