import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import {
  Row,
  Col,
  Container,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink
} from "reactstrap";
import { Segment } from "semantic-ui-react";
import classnames from "classnames";
import InsightManager from "./InsightManager";
import AdminMessage from "./Message";
import LegalRegisterManager from "./LegalRegisterManager";
import IndustrySectorUpload from "./IndustrySectorUpload";
import UserManager from "./UserManager";
import { changeActiveItem } from "../../actions/configActions";

class Dashboard extends Component {
  render() {
    if (this.props.authUser === null) {
      return <Redirect to="/" />;
    } else if (this.props.currentUser.userType !== "admin") {
      if (this.props.currentUser.userType !== "jedi") {
        return <Redirect to="/" />;
      }
    }

    return (
      <Container fluid>
        <div className="animated fadeIn">
          <Row>
            <Col md="12" sm="12">
              <Segment>
                <Nav pills>
                  <NavItem>
                    <NavLink
                      className={classnames({
                        active: this.props.activeItemProps === "1"
                      })}
                      onClick={() => {
                        changeActiveItem("1");
                      }}
                    >
                      <span style={{ cursor: "pointer" }}>User Manager</span>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({
                        active: this.props.activeItemProps === "2"
                      })}
                      onClick={() => {
                        changeActiveItem("2");
                      }}
                    >
                      <span style={{ cursor: "pointer" }}>
                        Legal Register Manager
                      </span>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({
                        active: this.props.activeItemProps === "3"
                      })}
                      onClick={() => {
                        changeActiveItem("3");
                      }}
                    >
                      <span style={{ cursor: "pointer" }}>
                        Insight Category Manager
                      </span>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({
                        active: this.props.activeItemProps === "4"
                      })}
                      onClick={() => {
                        changeActiveItem("4");
                      }}
                    >
                      <span style={{ cursor: "pointer" }}>
                        Industry Manager
                      </span>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({
                        active: this.props.activeItemProps === "5"
                      })}
                      onClick={() => {
                        changeActiveItem("5");
                      }}
                    >
                      <span style={{ cursor: "pointer" }}>Message</span>
                    </NavLink>
                  </NavItem>
                </Nav>
              </Segment>

              <TabContent
                activeTab={this.props.activeItemProps}
                style={{ marginTop: 7 }}
              >
                <TabPane tabId="1">
                  <UserManager />
                </TabPane>
                <TabPane tabId="2">
                  <LegalRegisterManager />
                </TabPane>
                <TabPane tabId="3">
                  <InsightManager />
                </TabPane>
                <TabPane tabId="4">
                  <IndustrySectorUpload />
                </TabPane>
                <TabPane tabId="5">
                  <AdminMessage />
                </TabPane>
              </TabContent>
            </Col>
          </Row>
        </div>
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  authUser: state.sessionState.authUser,
  currentUser: state.user.currentUserProps,
  activeItemProps: state.appConfig.activeItem
});

export default connect(mapStateToProps)(Dashboard);
