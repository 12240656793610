import React from "react";
import { Container, Row, Col } from "reactstrap";
import "./index.css";
import ScrollAnimation from "react-animate-on-scroll";

// images
import email from "../../img/LandingPageIcons/marketing-email.png";
import blog from "../../img/LandingPageIcons/blog-commenting.png";

const StayInformed = () => (
  <div className="DarkBacking" style={{ padding: 15, paddingTop: 25 }}>
    {/* <h1>Stay Informed</h1> */}
    <Container>
      <Row style={{ justifyContent: "center" }}>
        <Col sm="3">
          <ScrollAnimation animateIn="bounceInLeft" offset={1}>
            <img
              src={email}
              className="img"
              style={styles.imgStyle}
              alt="email"
            />
          </ScrollAnimation>
        </Col>
        <Col sm="9" style={{ textAlign: "left" }}>
          <h3>Stay Informed</h3>
          <p>
            We keep members informed about movement in policy and protocol,
            energy and estate management news and upcoming events from across
            the industry. Exclusive negotiated rates for members to attend third
            party events are an added little benefit to help you access
            information on the latest changes in your sector
          </p>
        </Col>
      </Row>
    </Container>
    <Container>
      <Row style={{ justifyContent: "center" }}>
        <Col sm="9" style={{ textAlign: "right" }}>
          <h3>Share</h3>
          <p>
            Energy Management can be a lonely job at times, so it helps to get
            together with others who know how you feel. We openly share and
            discuss how policy changes or new technologies can impact the way
            you do your job – for better or worse – and talk about the
            challenges we face so we can improve together and reduce energy
            consumption across the UK.
          </p>
        </Col>
        <Col sm="3">
          <ScrollAnimation animateIn="bounceInRight" offset={1}>
            <img
              src={blog}
              className="img"
              style={styles.imgStyle}
              alt="blog"
            />
          </ScrollAnimation>
        </Col>
      </Row>
    </Container>
  </div>
);

const styles = {
  imgStyle: {
    width: 150,
    padding: 15
    // shadowOffset: { height: 20, width: 20 },
    // shadowColor: "black",
    // shadowOpacity: 1.0
  }
};

export default StayInformed;
