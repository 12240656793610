import _ from "lodash";

const initialState = {
  currentInsightProps: {},
  currentAllInsightsProps: [],
  myInsightsProps: [],
  currentAuthorInsights: [],
  currentEditInsight: {},
  editInsight: false,
  latestInsights: {},
  filteredInsightsArray: [],
  query: "",
  tags: [],
  savedInsights: [],
  fileUploadProgress: null,
  fileUploadProgressMessage: null
};

export default function insightReducer(state = initialState, action) {
  switch (action.type) {
    case "INSIGHT_FILE_UPLOAD_PROGRESS_MESSAGE": {
      return {
        ...state,
        fileUploadProgressMessage: action.payload
      };
    }
    case "INSIGHT_FILE_UPLOAD_PROGRESS": {
      return {
        ...state,
        fileUploadProgress: action.payload
      };
    }
    case "SET_CURRENT_INSIGHT": {
      return {
        ...state,
        currentInsightProps: action.insight
      };
    }
    case "REMOVE_CURRENT_INSIGHT": {
      return {
        ...state,
        currentInsightProps: null
      };
    }

    case "SET_CURRENT_ALL_MY_INSIGHTS": {
      return {
        ...state,
        myInsightsProps: [...action.myInsights]
      };
    }

    case "SET_CURRENT_ALL_INSIGHTS": {
      // if filters passed in, filter the data accordingly
      if (action.tags || action.query || action.categories) {
        const allInsights = action.allInsights;

        const regex = new RegExp(action.query, "i");
        const filterInsightsTitle = _.filter(allInsights, insight =>
          regex.test(insight.title)
        );
        const filteredInsightsTags = _.filter(filterInsightsTitle, insight =>
          // eslint-disable-next-line
          action.tags == ""
            ? insight
            : insight.tags &&
              action.tags.some(tag => insight.tags.includes(tag))
        );

        const filteredInsightsArray = _.filter(filteredInsightsTags, insight =>
          // eslint-disable-next-line
          action.categories == ""
            ? insight
            : insight.category &&
              action.categories &&
              action.categories.some(category => {
                return insight.category.includes(category);
              })
        );

        return {
          ...state,
          currentAllInsightsProps: [...filteredInsightsArray]
        };
      }
      // else just return all insights
      return {
        ...state,
        currentAllInsightsProps: [...action.allInsights]
      };
    }

    case "SET_SAVED_INSIGHTS": {
      const savedInsights = [];

      const myInsights = state.myInsightsProps;
      const allInsights = Array.from(action.allInsights);

      // loop over all insights and return all the insights that match the key of your saved insights. These are then pushed into the savedInsights array
      for (const myKey of myInsights) {
        for (var i = 0; i < allInsights.length; i++) {
          if (allInsights[i].key === myKey.key) {
            savedInsights.push(allInsights[i]);
          }
        }
      }

      // if there are filters, filter the data
      if (action.tags || action.query || action.categories) {
        const regex = new RegExp(action.query, "i");
        const filterInsightsTitle = _.filter(savedInsights, insight =>
          regex.test(insight.title)
        );
        const filteredInsightsTags = _.filter(filterInsightsTitle, insight =>
          // eslint-disable-next-line
          action.tags == ""
            ? insight
            : insight.tags &&
              action.tags.some(tag => insight.tags.includes(tag))
        );

        const filteredInsightsArray = _.filter(filteredInsightsTags, insight =>
          // eslint-disable-next-line
          action.categories == ""
            ? insight
            : insight.category &&
              action.categories &&
              action.categories.some(category => {
                return insight.category.includes(category);
              })
        );

        return {
          ...state,
          savedInsights: [...filteredInsightsArray]
        };
      }

      return {
        ...state,
        savedInsights: [...savedInsights]
      };
    }

    case "SET_CURRENT_AUTHOR_INSIGHTS": {
      return {
        ...state,
        currentAuthorInsights: [...action.authoredInsights]
      };
    }
    case "ADD_MY_INSIGHT": {
      return {
        ...state,
        myInsightsProps: [...state.myInsightsProps, action.myInsight]
      };
    }
    case "REMOVE_MY_INSIGHT": {
      return {
        ...state,
        currentAllInsightsProps: state.currentAllInsightsProps.filter(
          insight => insight.key !== action.myInsight
        )
      };
    }
    case "REMOVE_INSIGHT": {
      return {
        ...state,
        currentAllInsightsProps: state.currentAllInsightsProps.filter(
          insightkey => insightkey !== action.insightKey
        )
      };
    }
    case "SET_CURRENT_EDIT_INSIGHT": {
      return {
        ...state,
        currentEditInsight: action.insight
      };
    }
    case "TOGGLE_EDIT_STATE": {
      return {
        ...state,
        editInsight: !state.editInsight
      };
    }
    case "SET_LATEST_INSIGHTS": {
      return {
        ...state,
        latestInsights: action.latestInsights
      };
    }

    default:
      return state;
  }
}
