import React, { Component } from "react";
import { connect } from "react-redux";
import { getLatestLegalItems, setLegalItem } from "../../actions/legalRegister";
import { Card, Segment, List, Button, Flag, Popup } from "semantic-ui-react";
import {
  Row,
  Col,
  ListGroupItem,
  ListGroupItemText,
  ListGroupItemHeading,
} from "reactstrap";
import { styles, buttonStyles } from "../../styles";
import LegalItemModal from "../Modals/LegalItemModal";

import { renderFlag } from "../LegalRegister/flag";

class LegalRegister extends Component {
  state = {
    modal: false,
  };

  componentDidMount = () => {
    getLatestLegalItems();
  };

  toggle = key => {
    // eslint-disable-next-line
    if (typeof key == "string") {
      setLegalItem(key);
    }
    this.setState({ modal: !this.state.modal });
  };

  render() {
    const { legalRegisterItems } = this.props;

    return (
      <Card color="teal" fluid style={styles.profileComponentStyle}>
        <Card.Content style={styles.cardContentStyle}>
          <div style={styles.cardHeaderStyle}>Latest Legal Items Activity</div>
          <div style={styles.divStyleLeftbox} />
          <Segment style={styles.segmentStyle}>
            <List divided verticalAlign="middle">
              {Object.values(legalRegisterItems).map((item, idx) => (
                <ListGroupItem key={idx}>
                  <Row>
                    <Col sm="11">
                      <ListGroupItemHeading>{item.title}</ListGroupItemHeading>
                    </Col>
                  </Row>

                  <Row>
                    <Col sm="11">
                      <ListGroupItemText style={{ marginTop: 5 }}>
                        {item.filterByArea}
                      </ListGroupItemText>

                      {item.country &&
                        item.country.map((flag, i) => renderFlag(flag, i))}
                    </Col>

                    <Button
                      compact
                      style={buttonStyles.primaryButton}
                      color="teal"
                      onClick={() => this.toggle(item.key)}
                    >
                      View
                    </Button>
                  </Row>
                </ListGroupItem>
              ))}
            </List>
          </Segment>
          {this.state.modal && (
            <LegalItemModal modal={this.state.modal} toggle={this.toggle} />
          )}
        </Card.Content>
      </Card>
    );
  }
}

const mapStateToProps = state => ({
  legalRegisterItems: state.legalItems.latestLegalItems,
});

export default connect(mapStateToProps)(LegalRegister);
