import { auth } from "../FireBase/index";
import { doCreateUser } from "../FireBase/db";
import { subscribeToAllChatrooms } from "../actions/subscribers";
import store from "../store";

export const SET_CURRENT_USER = "SET_CURRENT_USER";
export const setCurrentUser = user => ({
  type: SET_CURRENT_USER,
  user
});

export const THROW_REGISTER_ERROR = "THROW_REGISTER_ERROR";
export const throwRegisterError = error => ({
  type: THROW_REGISTER_ERROR,
  error
});

export const registerUser = (email, password, firstName, lastName) => {
  return auth
    .doCreateUserWithEmailAndPassword(email, password)
    .then(authUser => {
      doCreateUser(authUser.user.uid, email, firstName, lastName).then(() => {
        // subscribe the user to all chatrooms by default when they register
        subscribeToAllChatrooms(authUser.user.uid);
        store.dispatch(throwRegisterError(false));
      });
    })
    .catch(error => {
      store.dispatch(throwRegisterError(error));
      console.log(error.message);
    });
};
