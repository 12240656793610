import React, { Component } from "react";
import { connect } from "react-redux";
import { Col, Row, Container } from "reactstrap";
import { Segment, Card, Form, Button, Icon, Dropdown } from "semantic-ui-react";
// import { Sidebar } from "./Sidebar";
import { styles } from "../../styles";
import {
  setCategory,
  getCategories,
  deleteCategory
} from "../../actions/configActions";

import {
  createNotification,
  NOTIFICATION_TYPE_WARNING,
  NOTIFICATION_TYPE_SUCCESS
} from "react-redux-notify";

const mySuccessNotification = {
  message: `Category added`,
  type: NOTIFICATION_TYPE_SUCCESS,
  duration: 3000,
  canDismiss: true,
  icon: <Icon name="check" />
};

class CategoryUpload extends Component {
  constructor() {
    super();
    // create a state that can handle the change in the form input values
    this.state = {
      categoryName: "",
      categorySlug: "",
      categoryVisable: false,
      errors: {},
      options: [
        { key: "1", text: "Active", value: true },
        { key: "2", text: "Inactive", value: false }
      ]
    };
  }

  componentDidMount() {
    // get the list of categories from where they are stored in firebase, and show in the list
    getCategories();
  }

  handleValidation = () => {
    let { categoryName, categorySlug } = this.state;
    let errors = {};
    let formIsValid = true;

    // categoryName
    if (categoryName === "") {
      formIsValid = false;
      errors["categoryName"] = (
        <p style={{ color: "red" }}>Please enter a Category Name</p>
      );
    }

    // categorySlug
    if (categorySlug === "") {
      formIsValid = false;
      errors["categorySlug"] = (
        <p style={{ color: "red" }}>Please enter a #Hashtag</p>
      );
    }

    this.setState({ errors: errors });
    return formIsValid;
  };

  // This fat arrow function sets the state of multiple event "names" to be their inputed value by the user
  handleInputChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleActivity = (e, data, categoryName, categorySlug) => {
    setCategory(categoryName, categorySlug, data.value);
  };

  handleDelete = categorySlug => {
    const sureDeleteNotification = {
      message: "Do you really want to delete this category?",
      type: NOTIFICATION_TYPE_WARNING,
      duration: 0,
      canDismiss: false,
      icon: <Icon name="warning sign" />,
      acceptBtn: {
        handler: (e, notification) => {
          deleteCategory(categorySlug);
          notification.handleDismiss(notification.id);
        },
        title: "Yup",
        icon: <Icon name="thumbs up" />
      },
      denyBtn: {
        handler: (e, notification) => {
          notification.handleDismiss(notification.id);
        },
        title: "Nope",
        icon: <Icon name="thumbs down" />
      }
    };
    this.props.createNotification(sureDeleteNotification);
  };

  // This fat arrow function handles the entire object of the form
  handleSubmit = e => {
    e.preventDefault();
    if (this.handleValidation()) {
      // console.log("This.handleValidation is true and has been run");
      const { categoryName, categorySlug } = this.state;

      if (
        categorySlug.includes("/") ||
        categorySlug.includes(".") ||
        categorySlug.includes("#") ||
        categorySlug.includes("$") ||
        categorySlug.includes("[") ||
        categorySlug.includes("]")
      ) {
        const myArray = categorySlug.split("");
        const newArray = [];

        myArray.map((char, i) => {
          if (
            char === "." ||
            char === "/" ||
            char === "#" ||
            char === "$" ||
            char === "[" ||
            char === "]"
          ) {
            console.log("not operator !== is not working, wtf");
          } else {
            newArray.push(char);
          }
          return newArray;
        });

        const backToSting = newArray.join("");

        setCategory(categoryName, backToSting, true);
      } else {
        setCategory(categoryName, categorySlug, true);
      }

      this.props.createNotification(mySuccessNotification);

      // make div scroll to bottom when adding new item
      const scroller = document.querySelector(".scroller2");
      function scrollToBottom() {
        scroller.scrollTop = scroller.scrollHeight;
      }
      const observer = new MutationObserver(scrollToBottom);
      const config = { childList: true };
      observer.observe(scroller, config);
      scrollToBottom();

      // clear out the state after sending the data to the firebase database
      this.setState({
        categoryName: "",
        categorySlug: ""
      });
    } else {
      console.log(
        "Ooops, you have missed some required fields. Check the warnings!"
      );
    }
  };

  render() {
    const { categories } = this.props;

    console.log("CATEGORIES ", categories);

    return (
      <Row>
        <Col>
          <Container>
            <Card fluid style={styles.profileComponentStyle} color="teal">
              <Card.Content style={styles.cardContentStyle}>
                <div style={styles.cardHeaderStyle}>
                  Insight Category Upload
                </div>
                <div style={styles.divStyleLeftbox} />
                <Segment style={styles.segmentStyle}>
                  {/* Category Upload Form */}
                  <Form
                    id="categoryUploadForm"
                    onSubmit={this.handleSubmit}
                    style={styles.formStyle}
                  >
                    <Form.Field>
                      <label>Category Name</label>
                      {this.state.errors["categoryName"]}
                      <input
                        placeholder="Enter Name of category ..."
                        name="categoryName"
                        value={this.state.categoryName}
                        onChange={this.handleInputChange}
                      />
                    </Form.Field>
                    <Form.Field>
                      <label>Category #HashTag</label>
                      {this.state.errors["categorySlug"]}
                      <input
                        placeholder="The #HashTag must use CamelCasing and be one word ... e.g. AllOneWordWithCaps&Ampersands "
                        name="categorySlug"
                        value={this.state.categorySlug}
                        onChange={this.handleInputChange}
                      />
                    </Form.Field>
                    {/* This button submits the entire form as an object into local state */}
                    <Button type="submit">Submit</Button>
                  </Form>
                </Segment>
              </Card.Content>
            </Card>
            <Card fluid style={styles.profileComponentStyle} color="teal">
              <Card.Content style={styles.cardContentStyle}>
                <div style={styles.cardHeaderStyle}>Categories</div>
                <div style={styles.divStyleLeftbox} />
                <div
                  className="scroller2"
                  style={{
                    height: 280,
                    overflowY: "scroll",
                    overflowX: "hidden"
                  }}
                >
                  <Segment style={styles.segmentStyle}>
                    {/* Map over the array of objects that are brought into the component in the componentDidMount,
                    display this list here. */}
                    {/* <h1>List</h1> */}
                    {categories.map((category, idx) => (
                      <div key={idx}>
                        <Row>
                          <Col style={{ marginBottom: 15 }}>
                            <strong
                              style={
                                category.categoryVisable
                                  ? {}
                                  : {
                                      fontStyle: "italic",
                                      textDecoration: "line-through",
                                      color: "grey"
                                    }
                              }
                            >
                              {category.categoryName}:
                            </strong>
                          </Col>
                          <Col
                            sm="4"
                            style={
                              category.categoryVisable
                                ? {}
                                : {
                                    fontStyle: "italic",
                                    textDecoration: "line-through",
                                    color: "grey"
                                  }
                            }
                          >
                            #{category.categorySlug}
                          </Col>
                          <Col sm="4">
                            <Dropdown
                              onChange={(e, data) => {
                                this.handleActivity(
                                  e,
                                  data,
                                  category.categoryName,
                                  category.categorySlug
                                );
                              }}
                              value={category.categoryVisable}
                              options={this.state.options}
                              style={
                                category.categoryVisable
                                  ? { color: "green" }
                                  : { color: "red" }
                              }
                            />
                          </Col>
                          <Col>
                            <Icon
                              style={{ marginBottom: 10, cursor: "pointer" }}
                              color="red"
                              name="remove circle"
                              onClick={() => {
                                this.handleDelete(category.categorySlug);
                              }}
                            />
                          </Col>
                        </Row>
                      </div>
                    ))}
                  </Segment>
                </div>
              </Card.Content>
            </Card>
          </Container>
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = state => ({
  categories: state.appConfig.categories
});

const mapDispatchToProps = dispatch => ({
  createNotification: config => {
    dispatch(createNotification(config));
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CategoryUpload);
