import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Container
} from "reactstrap";
import { Card, Segment, Button, Icon } from "semantic-ui-react";
import { editorStateFromRaw, editorStateToJSON } from "megadraft";
import "../MegaDraft/megadraft.css";
import IconEditor from "./Editor";
import { styles } from "../../styles";

import {
  getAllposts,
  updateMessage,
  DeleteSelectedPost
} from "../../actions/Posts";

import {
  createNotification,
  NOTIFICATION_TYPE_WARNING
} from "react-redux-notify";

const emptyCommentWarning = {
  message: "Sorry cannot submit an empty comment",
  type: NOTIFICATION_TYPE_WARNING,
  duration: 3000,
  canDismiss: true,
  icon: <Icon name="warning sign" />
};

class EditPostModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      modal: false,
      editorState: editorStateFromRaw(null),
      userName: "",
      profilePhoto: "",
      initialComment: "",
      currentComment: ""
    };
  }

  onChange = editorState => {
    const currentContent = this.state.editorState.getCurrentContent();
    const currentComment = currentContent.getPlainText();
    this.setState({ editorState, currentComment });
  };

  onSaveClick = () => {
    // Deconstruct the text from the editorState object
    const currentContent = this.state.editorState.getCurrentContent();
    const comment = currentContent.getPlainText();
    // Check its isn't empty
    const { createNotification } = this.props;
    if (comment === "" || comment.replace(/\s+/g, "").length === 0) {
      // If it is invoke notification
      return createNotification(emptyCommentWarning);
    } else {
      const { editorState } = this.state;
      const content = editorStateToJSON(editorState);
      const key = this.props.item.key;
      updateMessage(content, key);
      this.setState({
        modal: false,
        initialComment: this.state.currentComment
      });
      getAllposts();
    }
  };

  onDeleteClick = () => {
    this.setState({ modal: false });
    DeleteSelectedPost(this.props.item.key, this.props.item.chatroomKey);
  };

  toggle = () => {
    this.setState({
      modal: !this.state.modal
    });
  };

  toggleEdit = message => {
    // This is the trick //
    // Set the edit state with the props message
    this.setState({
      modal: !this.state.modal,
      editorState: editorStateFromRaw(JSON.parse(message))
    });
  };

  render() {
    return (
      <div>
        <Button
          style={{ backgroundColor: "transparent" }}
          onClick={() => this.toggleEdit(this.props.item.message)}
          compact
        >
          {this.props.buttonLabel}
        </Button>
        <Modal
          isOpen={this.state.modal}
          toggle={this.toggle}
          className={this.props.className}
          size="lg"
        >
          <ModalHeader toggle={this.toggle}>
            Edit post by: {this.props.item.displayName}
          </ModalHeader>
          <ModalBody>
            <Card fluid style={styles.profileComponentStyle}>
              <Card.Content style={styles.cardContentStyle}>
                <Segment style={styles.segmentStyle}>
                  <Container>
                    {/* <MegadraftEditor
                      editorState={this.state.editorState}
                      onChange={this.onChange}
                    /> */}
                    <IconEditor
                      editorState={this.state.editorState}
                      onChange={this.onChange}
                      ref={element => {
                        console.log(element);
                        this.editor = element;
                      }}
                    />
                  </Container>
                </Segment>
              </Card.Content>
            </Card>
          </ModalBody>
          <ModalFooter>
            <Button color="red" onClick={this.onDeleteClick}>
              Delete
            </Button>
            <Button color="yellow" onClick={this.toggle}>
              Cancel
            </Button>
            <Button
              circular
              primary
              onClick={this.onSaveClick}
              style={{ borderRadius: 7 }}
              icon="send"
            />
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  PostItem: state.postState.CurrentPostProps
});

const mapDispatchToProps = dispatch => ({
  createNotification: config => {
    dispatch(createNotification(config));
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditPostModal);
