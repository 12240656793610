import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect, Link } from "react-router-dom";
import styled from "styled-components";
import { registerUser } from "../actions/auth";
import { Button } from "semantic-ui-react";
import { Container, Row, Col } from "reactstrap";

// images
import Logo_Light from "../img/Logo_A.png";

const StyledInputDiv = styled.div`
  padding: 5;
  width: 100%;
`;

const StyledInputLabel = styled.label`
  font-weight: 200;
  font-size: 1rem;
  width: 80%;
  @media (max-width: 500px) {
    width: 100%;
  }
`;

const StyledInput = styled.input`
  font-weight: 100;
  border: 1px solid #e0e4e4;
  border-radius: 7px;
  box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.05) inset;
  font-size: 1rem;
  padding: 0.75rem;
  width: 100%;
  &:focus {
    outline: none;
    box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.05) inset, 0 0 1.5pt 1.5pt #4488dd;
  }
`;

const StyledWarning = styled.p`
  color: red;
  font-size: 95%;
`;

export class RegistrationForm extends Component {
  constructor() {
    super();
    this.state = {
      emailIsPersonal: false,
      emailError: "",
      firstName: "",
      lastName: "",
      passwordError: "",
      error: "",
      deletedUserError: "",
      Email: "",
      EmailConfirm: "",
      Password: "",
      PasswordConfirm: "",
      privacy_policy: false,
      terms_and_conditions: false,
      redirectToNewPage: false
    };
  }

  handleSubmit = e => {
    e.preventDefault();
    const { Email, Password, firstName, lastName } = this.state;
    registerUser(Email, Password, firstName, lastName);
    this.setState({ redirectToNewPage: true });
  };

  handleChange = event => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
  };

  aol = () => {
    const { email } = this.state;
    if (email && email.toLowerCase().includes("aol.com")) {
      return (
        <StyledWarning>Really? You still use AOL for your email?</StyledWarning>
      );
    } else {
      return null;
    }
  };

  email = value => {
    if (
      value &&
      (value.toLowerCase().includes("@yahoo") ||
        value.toLowerCase().includes("@gmail") ||
        value.toLowerCase().includes("@hotmail") ||
        value.toLowerCase().includes("@outlook") ||
        value.toLowerCase().includes("@zoho") ||
        value.toLowerCase().includes("@yandex") ||
        value.toLowerCase().includes("@btconnect"))
    ) {
      if (!this.state.emailIsPersonal) {
        this.setState({ emailIsPersonal: true });
      }
      return (
        <StyledWarning>
          Sorry it looks like you're trying to register with a personal email
          address, please use your current business email instead
        </StyledWarning>
      );
    } else if (this.state.emailIsPersonal) {
      this.setState({ emailIsPersonal: false });
      return null;
    }
  };

  confirmMatch = item => {
    if (this.state[item] !== "") {
      if (this.state[item] !== this.state[`${item}Confirm`]) {
        return <StyledWarning>{`${item}s do not match.`}</StyledWarning>;
      }
    }
  };

  validate = () => {
    const {
      Email,
      EmailConfirm,
      emailIsPersonal,
      firstName,
      lastName,
      Password,
      PasswordConfirm,
      privacy_policy,
      terms_and_conditions
    } = this.state;

    if (
      firstName === "" ||
      lastName === "" ||
      emailIsPersonal ||
      Email !== EmailConfirm ||
      Password !== PasswordConfirm ||
      !terms_and_conditions ||
      !privacy_policy
    ) {
      return true;
    } else {
      return false;
    }
  };

  render() {
    if (
      this.state.redirectToNewPage &&
      this.props.currentUser.uid !== null &&
      !this.props.registrationError
    ) {
      return <Redirect to="/" />;
    }
    return (
      <Container style={{ padding: 30 }}>
        <Row>
          <Col style={{ textAlign: "center" }}>
            <img style={{ padding: 30 }} src={Logo_Light} alt="LogoNotext" />
          </Col>
          <Col>
            <form
              className="login-form"
              onSubmit={e => this.handleSubmit(e)}
              style={{ padding: 30, backgroundColor: "" }}
            >
              {this.props.registrationError && (
                <StyledWarning>
                  {this.props.registrationError.message}
                </StyledWarning>
              )}
              <StyledInputDiv>
                <StyledInputLabel htmlFor="email">
                  First Name
                  <StyledInput
                    name="firstName"
                    onChange={this.handleChange}
                    placeholder="First name"
                    required
                    type="text"
                  />
                </StyledInputLabel>
              </StyledInputDiv>
              <StyledInputDiv>
                <StyledInputLabel htmlFor="email">
                  Last Name
                  <StyledInput
                    name="lastName"
                    onChange={this.handleChange}
                    placeholder="Last name"
                    required
                    type="text"
                  />
                </StyledInputLabel>
              </StyledInputDiv>
              <StyledInputDiv>
                <StyledInputLabel htmlFor="email">
                  Business Email
                  <StyledInput
                    name="Email"
                    autoComplete="email"
                    onChange={this.handleChange}
                    placeholder="Your email"
                    required
                    type="email"
                  />
                </StyledInputLabel>
              </StyledInputDiv>
              {this.email(this.state.email)}
              {this.aol()}
              <StyledInputDiv>
                <StyledInputLabel htmlFor="email">
                  Confirm Email
                  <StyledInput
                    autoComplete="email"
                    name="EmailConfirm"
                    onChange={this.handleChange}
                    placeholder="Confirm your email"
                    required
                    type="email"
                  />
                </StyledInputLabel>
              </StyledInputDiv>
              {this.confirmMatch("Email")}
              <StyledInputDiv>
                <StyledInputLabel htmlFor="password">
                  Password
                  <StyledInput
                    autoComplete="new-password"
                    id="password"
                    name="Password"
                    onChange={this.handleChange}
                    required
                    type="password"
                  />
                </StyledInputLabel>
              </StyledInputDiv>
              <StyledInputDiv>
                <StyledInputLabel htmlFor="password">
                  Password
                  <StyledInput
                    autoComplete="new-password"
                    name="PasswordConfirm"
                    onChange={this.handleChange}
                    placeholder="Confirm password"
                    required
                    type="password"
                  />
                </StyledInputLabel>
              </StyledInputDiv>
              {this.confirmMatch("Password")}
              <Row style={styles.checkboxRowStyle}>
                <Col sm="1" style={styles.checkboxColStyle}>
                  <div style={styles.checkboxFieldDiv}>
                    <input
                      type="checkbox"
                      name="terms_and_conditions"
                      checked={this.state.terms_and_conditions}
                      onChange={this.handleChange}
                      required
                    />
                  </div>
                </Col>
                <Col sm="11" style={styles.checkboxTextStyle}>
                  <p>
                    I agree to the{" "}
                    <Link to="/TermsAndConditions">Terms and Conditions </Link>
                  </p>
                </Col>
              </Row>

              <Row style={styles.checkboxRowStyle}>
                <Col sm="1" style={styles.checkboxColStyle}>
                  <div style={styles.checkboxFieldDiv}>
                    <input
                      type="checkbox"
                      name="privacy_policy"
                      onChange={this.handleChange}
                      checked={this.state.privacy_policy}
                      required
                    />
                  </div>
                </Col>
                <Col sm="11" style={styles.checkboxTextStyle}>
                  <p>
                    I agree to the{" "}
                    <Link to="/PrivacyPolicy">Privacy Policy</Link>
                  </p>
                </Col>
              </Row>

              <div style={styles.buttonStyle}>
                <Button
                  className="form-button"
                  type="submit"
                  disabled={this.validate()}
                >
                  Register
                </Button>
              </div>
            </form>
          </Col>
        </Row>
      </Container>
    );
  }
}

const styles = {
  fieldStyle: {
    padding: 5
  },
  buttonStyle: {
    padding: 5,
    paddingTop: 15
  },
  checkboxRowStyle: {
    alignItems: "center",
    padding: 5,
    backgroundColor: "",
    marginLeft: 5,
    marginRight: 5
  },
  checkboxColStyle: {
    margin: 0,
    backgroundColor: "",
    // justifyContent: "center",
    alignItems: "center",
    padding: 0
  },
  checkboxFieldDiv: {
    backgroundColor: "",
    alignItems: "center",
    justifyContent: "center",
    marginTop: 10,
    marginLeft: 0
  },
  checkboxTextStyle: {
    marginLeft: 0
  }
};

const mapStateToProps = state => ({
  authUser: state.sessionState.authUser,
  currentUser: state.user.currentUserProps,
  registrationError: state.sessionState.registrationError
});

export default connect(mapStateToProps)(RegistrationForm);
