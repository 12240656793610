import _ from "lodash";
import store from "../store";

const initialState = {
  // currentAllLegalItemsProps is only for allLegalRegisterList use allLegalItems
  currentAllLegalItemsProps: [],
  currentMyLegalItemsProps: [],
  currentLegalItemProps: {},
  currentLegalItemUserEdit: {},
  currentLegalSubscriber: {},
  currentLegalKey: {},
  latestLegalItems: {},
  // get all legal items from entire app
  allLegalItems: [],
  // MyLegalRegisterList filters
  currentMyLegalItemsCategories: "",
  currentMyLegalItemsCountries: "",
  currentMyLegalItemsQuery: "",
  // AllLegalRegisterList filters
  currentAllLegalItemsCategories: "",
  currentAllLegalItemsCountries: "",
  currentAllLegalItemsQuery: "",
};

export default function legalItemReducer(state = initialState, action) {
  switch (action.type) {
    case "SET_CURRENT_ALL_LEGAL_REGISTER_ITEMS": {
      return {
        ...state,
        currentAllLegalItemsProps: action.finalResult,
        currentAllLegalItemsCategories: action.categories,
        currentAllLegalItemsCountries: action.countries,
        currentAllLegalItemsQuery: action.query,
      };
    }

    // logic for this case is pretty much the same as above but for MyLegalRegisterList, these components need to be seperate because if they are not, when you filter one, the filters apply to both because they would be using the same data from the reducer.

    case "SET_CURRENT_MY_LEGAL_REGISTER_ITEMS": {
      return {
        ...state,
        currentMyLegalItemsProps: action.finalResult,
        currentMyLegalItemsCategories: action.categories,
        currentMyLegalItemsCountries: action.countries,
        currentMyLegalItemsQuery: action.query,
      };
    }

    // returns all legal items (for legal register table in admin tools or whenever you need to get all the legal items that currently exist on the app)
    // eslint-disable-next-line
    case "SET_ALL_LEGAL_ITEMS": {
      if (
        action.categories ||
        action.countries ||
        action.query ||
        action.query === ""
      ) {
        const regex = new RegExp(action.query, "i");
        const filterLegalItemTitle = _.filter(action.allLegalItems, item =>
          regex.test(item.title)
        );
        const filterCountries = _.filter(filterLegalItemTitle, item =>
          // eslint-disable-next-line
          action.countries == ""
            ? item
            : item.country &&
              action.countries.some(countries =>
                item.country.includes(countries)
              )
        );
        const filteredLegalItemArray = _.filter(filterCountries, item =>
          // eslint-disable-next-line
          action.categories == ""
            ? item
            : item.filterByArea &&
              action.categories.some(category =>
                item.filterByArea.includes(category)
              )
        );
        return {
          ...state,
          allLegalItems: [...filteredLegalItemArray],
        };
      }
      return {
        ...state,
        allLegalItems: [...action.allLegalItems],
      };
    }

    case "SET_CURRENT_LEGAL_REGISTER_ITEM": {
      return {
        ...state,
        currentLegalItemProps: action.legalItem,
      };
    }
    case "SET_CURRENT_LEGAL_ITEM_EDIT": {
      return {
        ...state,
        currentLegalItemUserEdit: action.editItem,
      };
    }
    case "SET_CURRENT_LEGAL_SUBSCRIBER_EDIT": {
      return {
        ...state,
        currentLegalSubscriber: action.userObject,
      };
    }
    case "SET_CURRENT_SUBSCRIBER_LEGAL_KEY": {
      return {
        ...state,
        currentLegalKey: action.key,
      };
    }
    case "DELETE_LEGALITEM": {
      return {
        ...state,
        currentAllLegalItemsProps: state.currentAllLegalItemsProps.filter(
          legalItemKey => legalItemKey !== action.legalItemKey
        ),
      };
    }

    case "SET_LATEST_LEGAL_ITEMS": {
      return {
        ...state,
        latestLegalItems: action.latestLegalItems,
      };
    }

    default:
      return state;
  }
}
