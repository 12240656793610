import React, { Component, useEffect } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { Icon, Menu, Segment, Container } from "semantic-ui-react";
import Dashboard from "./dashboard";
import Profile from "./Profile";
import WishList from "../Insights/WishList";
import Calendar from "../Calendar";
import LegalRegister from "../LegalRegister";
import { getCategories, changeActiveItem } from "../../actions/configActions";
import { getAllMyInsights, getAllInsights } from "../../actions/insights";

const MyAccount = props => {
  const [state, setState] = React.useState({
    visible: false,
    activeItem: "Dashboard",
    auth: props && props.authUser ? props.authUser.uid : "",
  });

  // componentWillReceiveProps(nextProps) {
  //   const updateActiveItem =
  //     nextProps.activeItem && nextProps.activeItem !== this.state.activeItem;

  //   if (updateActiveItem) {
  //     this.setState({ activeItem: nextProps.activeItem });
  //   }
  // }

  const handleButtonClick = () =>
    setState({ ...state, visible: !state.visible });

  const handleSidebarHide = () => setState({ ...state, visible: false });

  const handleItemClick = (e, { name }) => {
    setState({ ...state, activeItem: name });
    setState({ ...state, visible: false });
    changeActiveItem(name);
  };

  const handleChange = e => {
    setState({ ...state, [e.target.name]: e.target.value });
  };

  const renderPages = () => {
    const { activeItemProps } = props;

    switch (activeItemProps) {
      case "Dashboard":
        return <Dashboard />;
      case "My Profile":
        return (
          <Container>
            <Profile />
          </Container>
        );
      case "Saved Insights":
        return (
          <Container>
            <WishList />
          </Container>
        );
      case "Legal Register":
        return <LegalRegister />;
      case "Calendar":
        return <Calendar />;
      default:
        return <Dashboard />;
    }
  };

  const { activeItem } = state;
  const { activeItemProps } = props;

  useEffect(() => {
    window.scrollTo(0, 0);
    getCategories();
    getAllMyInsights(state.auth);
    getAllInsights();

    // setTimeout(() => {
    //   if (props.currentUser && props.currentUser.uid === null) {
    //     console.log("We need to log this user out", props);
    //   }
    // }, 10000);
  }, []);

  useEffect(() => {
    if (props.currentUser && props.currentUser.userType === "newUser") {
      return <Redirect to="/" />;
    }
  }, [props.currentUser.userType]);

  return (
    <div>
      <div
        style={{
          marginTop: 7,
          paddingLeft: 15,
          paddingRight: 15,
        }}
      >
        <Menu
          pointing
          stackable
          // fixed="top"
          style={{ borderTopLeftRadius: 0 }}
        >
          <Menu.Item
            name="Dashboard"
            active={activeItemProps === "Dashboard"}
            onClick={handleItemClick}
            style={{ textAlign: "left" }}
          >
            <div style={{ float: "left" }}>
              <Icon name="home" position="left" />
            </div>
            <div>Dashboard</div>
          </Menu.Item>

          <Menu.Item
            name="My Profile"
            active={activeItemProps === "My Profile"}
            onClick={handleItemClick}
            style={{ textAlign: "left" }}
          >
            <div style={{ float: "left" }}>
              <Icon name="user" position="left" />
            </div>
            <div>My Profile</div>
          </Menu.Item>

          <Menu.Item
            name="Saved Insights"
            active={activeItemProps === "Saved Insights"}
            onClick={handleItemClick}
            style={{ textAlign: "left" }}
          >
            <div style={{ float: "left" }}>
              <Icon name="info" position="left" />
            </div>
            <div>Saved Insights</div>
          </Menu.Item>

          <Menu.Item
            name="Legal Register"
            active={activeItemProps === "Legal Register"}
            onClick={handleItemClick}
            style={{ textAlign: "left" }}
          >
            <div style={{ float: "left" }}>
              <Icon name="legal" position="left" />
            </div>
            <div>Legal Register</div>
          </Menu.Item>

          <Menu.Item
            name="Calendar"
            active={activeItemProps === "Calendar"}
            onClick={handleItemClick}
            style={{ textAlign: "left" }}
          >
            <div style={{ float: "left" }}>
              <Icon name="calendar" position="left" />
            </div>
            <div>Calendar</div>
          </Menu.Item>
        </Menu>
      </div>

      <Segment
        style={{
          minHeight: "100vh",
          borderWidth: 0,
          backgroundColor: "#dfeff4",
          padding: 10,
        }}
      >
        {renderPages(activeItem)}
      </Segment>
    </div>
  );
};

const mapStateToProps = state => ({
  authUser: state.sessionState.authUser,
  currentUser: state.user.currentUserProps,
  currentAllInsights: state.insights.currentAllInsightsProps,
  activeItemProps: state.appConfig.activeItem,
  myInsights: state.insights.myInsightsProps,
});

const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, mapDispatchToProps)(MyAccount);
