import React, { Component } from "react";
import { connect } from "react-redux";
import store from "../../store";
import {
  Card,
  Segment,
  Label,
  Button,
  Icon,
  Input as SemanticInput,
  Item,
  Dropdown,
  Loader
} from "semantic-ui-react";
import { DebounceInput } from "react-debounce-input";
import { Row, Col, Button as Btn } from "reactstrap";
import {
  addUserWishlistItem,
  removeInsightAction,
  setEditInsight,
  setInsight,
  toggleEditState,
  getAllInsights
} from "../../actions/insights";
import StoryImg from "../../img/StoryImg.png";
import TimeAgo from "react-timeago";
import InsightModal from "./InsightModal";
import { styles, buttonStyles } from "../../styles";
import {
  createNotification,
  NOTIFICATION_TYPE_WARNING,
  NOTIFICATION_TYPE_SUCCESS
} from "react-redux-notify";
import styled from "styled-components";
import InfiniteScroll from "react-infinite-scroll-component";
import { getIndustries, changeActiveItem } from "../../actions/configActions";
import { withRouter } from "react-router-dom";

const ContentGrid = styled.div`
  display: grid;
  grid-template-columns: 3fr 1fr;
`;

const Heading = styled.div`
  font-weight: 400;
  font-size: 18px;
`;
const SubHeading = styled.p`
  font-weight: 100;
  font-size: 14px;
`;
const GridItem = styled.div`
  display: grid;
`;

class AuthorInsights extends Component {
  state = {
    query: "",
    tags: "",
    category: "",
    items: this.props.authoredInsights,
    limit: 10,
    itemAmount: 0,
    modal: false
  };

  componentDidMount() {
    getAllInsights();
    getIndustries();
  }

  handleSearch = e => {
    this.setState({ query: e.target.value });
    getAllInsights(this.state.category, e.target.value, this.state.tags);
  };

  handleSelect = (e, data) => {
    this.setState({ tags: data.value });
    getAllInsights(this.state.category, this.state.query, data.value);
  };

  handleCategory = (e, data) => {
    this.setState({ category: data.value });
    getAllInsights(data.value, this.state.query, this.state.tags);
  };

  fetchMoreData = () => {
    const itemAmount = this.state.itemAmount + 10;
    const limit = this.state.limit + 10;

    setTimeout(() => {
      this.setState({
        limit: limit,
        items: this.props.authoredInsights.slice(0, itemAmount),
        itemAmount: itemAmount
      });
    }, 1000);
  };

  handleEditClick = insight => {
    store.dispatch(toggleEditState());
    setEditInsight(insight);
  };

  handleDeleteClick = insight => {
    const { createNotification } = this.props;
    const sureDeleteNotification = {
      message: "Do you really want to delete this insight?",
      type: NOTIFICATION_TYPE_WARNING,
      duration: 0,
      canDismiss: false,
      icon: <Icon name="warning sign" />,
      acceptBtn: {
        handler: (e, notification) => {
          removeInsightAction(insight);
          notification.handleDismiss(notification.id);
        },
        title: "Yup",
        icon: <Icon name="thumbs up" />
      },
      denyBtn: {
        handler: (e, notification) => {
          notification.handleDismiss(notification.id);
        },
        title: "Nope",
        icon: <Icon name="thumbs down" />
      }
    };
    createNotification(sureDeleteNotification);
  };

  // handleDeleteAccept = insight => {};

  handleAddClick = insight => {
    const myInsights = this.props.myInsights;

    const mySuccessNotification = {
      message: `Insight added!`,
      type: NOTIFICATION_TYPE_SUCCESS,
      duration: 3000,
      canDismiss: false,
      icon: <Icon name="check" />,
      acceptBtn: {
        handler: (e, notification) => {
          changeActiveItem("Saved Insights");
          this.props.history.push("/myAccount");
          notification.handleDismiss(notification.id);
        },
        title: "Go to 'Saved Insights'"
      }
    };

    if (myInsights.indexOf(insight.key) === -1) {
      addUserWishlistItem(this.props.currentUser.uid, insight);
      this.props.createNotification(mySuccessNotification);
    } else {
      window.alert("Sorry you already have this one saved");
    }
  };

  toggle = key => {
    // eslint-disable-next-line
    if (typeof key == "string") {
      setInsight(key);
    }
    this.setState({ modal: !this.state.modal });
  };

  render() {
    const insightCategoriesArray = [];

    this.props.categories.map((category, i) => {
      return (
        category.categoryVisable &&
        insightCategoriesArray.push({
          key: i,
          text: category.categoryName,
          value: category.categorySlug
        })
      );
    });

    const insightIndustriesArray = [];

    this.props.industries &&
      this.props.industries.map((industry, i) => {
        return (
          industry.isActive &&
          insightIndustriesArray.push({
            key: i,
            text: industry.industryName,
            value: industry.industryName
          })
        );
      });

    return (
      <Card color="teal" fluid style={styles.profileComponentStyle}>
        <Card.Content style={styles.cardContentStyle}>
          <div style={styles.cardHeaderStyle}>
            My Published Insights
            {/* displays how many insights you have */}
            {` (${this.props.authoredInsights.length})`}
          </div>
          <div style={styles.divStyleRightbox} />
          <Col style={{ marginLeft: 15 }}>
            <Row style={{ width: "100%", marginTop: 25 }}>
              <Col>
                <SemanticInput icon="search" style={{ marginLeft: 0 }}>
                  <DebounceInput
                    style={{
                      borderRadius: 20,
                      width: "100%"
                    }}
                    type="Search"
                    debounceTimeout={500}
                    minLength={2}
                    onChange={this.handleSearch}
                    placeholder=" Search ... "
                  />
                  <Icon name="search" />
                </SemanticInput>
              </Col>
              <Col>
                <Dropdown
                  placeholder="Choose a Category ..."
                  fluid
                  multiple
                  selection
                  options={insightCategoriesArray}
                  onChange={this.handleCategory}
                  value={this.state.category}
                />
              </Col>
            </Row>
            <Row style={{ width: "100%", marginTop: 15 }}>
              <Dropdown
                placeholder="Choose an industry segment ..."
                fluid
                multiple
                selection
                options={insightIndustriesArray}
                onChange={this.handleSelect}
              />
            </Row>
          </Col>

          <Segment style={styles.segmentStyle}>
            <InfiniteScroll
              style={{ overflowX: "hidden" }}
              dataLength={this.state.items.length}
              next={this.fetchMoreData}
              hasMore={true}
              height={520}
              loader={
                this.state.limit >= this.props.authoredInsights.length ? (
                  <h4> </h4>
                ) : (
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <Loader active inline content="Loading..." />
                  </div>
                )
              }
            >
              <Item.Group divided>
                {this.props.authoredInsights.length > 0 ? (
                  this.props.authoredInsights.map((insight, index) => (
                    <Item
                      key={index}
                      style={
                        index >= this.state.limit ? { display: "none" } : {}
                      }
                    >
                      <Item.Image
                        size="tiny"
                        alt="Energy insights feature"
                        src={
                          this.props.authoredInsights[index].featuredImage ||
                          StoryImg
                        }
                      />
                      <Item.Content>
                        <ContentGrid>
                          <Heading>
                            {this.props.authoredInsights[index].title}
                            <SubHeading>
                              {this.props.authoredInsights[index].subTitle}
                            </SubHeading>
                          </Heading>

                          <GridItem>
                            <div>
                              <Label>
                                <Icon
                                  name="hashtag"
                                  style={{ marginRight: 0 }}
                                />
                                {this.props.authoredInsights[index].category}
                              </Label>
                            </div>

                            <TimeAgo
                              date={this.props.authoredInsights[index].time}
                            />
                          </GridItem>
                        </ContentGrid>

                        <Item.Extra>
                          <Row>
                            <Btn
                              icon
                              floated="left"
                              color="link"
                              onClick={() =>
                                this.handleAddClick(
                                  this.props.authoredInsights[index]
                                )
                              }
                            >
                              <Icon name="add" />
                              Add
                            </Btn>
                            <Button
                              onClick={() => this.toggle(insight.key)}
                              style={buttonStyles.primaryButton}
                              color="teal"
                            >
                              View
                            </Button>
                            <Button
                              style={buttonStyles.editButton}
                              onClick={() =>
                                this.handleEditClick(
                                  this.props.authoredInsights[index]
                                )
                              }
                              floated="left"
                              color="green"
                            >
                              <Icon name="edit" />
                              Edit
                            </Button>
                            <Button
                              style={buttonStyles.deleteButton}
                              onClick={() =>
                                this.handleDeleteClick(
                                  this.props.authoredInsights[index]
                                )
                              }
                              floated="left"
                              color="red"
                            >
                              <Icon name="remove circle" />
                              Delete
                            </Button>
                          </Row>
                        </Item.Extra>
                      </Item.Content>
                    </Item>
                  ))
                ) : this.state.query !== "" ? (
                  <p style={{ textAlign: "center", fontSize: 18 }}>
                    We're sorry, no results for{" "}
                    {<strong>"{this.state.query}"</strong>} were found.
                  </p>
                ) : (
                  <p style={{ textAlign: "center", fontSize: 18 }}>
                    We're sorry, no results were found.
                  </p>
                )}
              </Item.Group>
              {
                <InsightModal
                  tags={this.state.tags}
                  modal={this.state.modal}
                  toggle={this.toggle}
                />
              }
            </InfiniteScroll>
          </Segment>
        </Card.Content>
      </Card>
    );
  }
}

const mapStateToProps = state => ({
  userId: state.sessionState.authUser.uid,
  currentUser: state.user.currentUserProps,
  myInsights: state.insights.myInsightsProps,
  currentAllInsights: state.insights.currentAllInsightsProps,
  authoredInsights: state.insights.currentAuthorInsights,
  categories: state.appConfig.categories,
  industries: state.appConfig.industries
});

const mapDispatchToProps = dispatch => ({
  createNotification: config => {
    dispatch(createNotification(config));
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(AuthorInsights));
