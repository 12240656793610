import React from "react";
import { Container, Row, Col } from "reactstrap";
import "./index.css";
import ScrollAnimation from "react-animate-on-scroll";

// images
import connect from "../../img/LandingPageIcons/mobile-marketing.png";

const Connect = () => (
  <div className="DarkBacking" style={{ padding: 15, paddingTop: 25 }}>
    {/* <h1>Stay Informed</h1> */}
    <Container>
      <Row>
        <Col>
          <ScrollAnimation animateIn="shake" offset={1}>
            <img
              src={connect}
              className="img"
              style={styles.imgStyle}
              alt="connect"
            />
          </ScrollAnimation>
        </Col>
      </Row>
      <Row style={{ paddingTop: 15 }}>
        <Col>
          <h3>Connect</h3>
          <p>
            Meet highly respected industry personnel and network with potential
            employers, suppliers, customers and collaborators at our events and
            social activities. Find new career opportunities via the job
            vacancies section of our website and seek out industry talent by
            posting jobs for free. Share news, participate in discussions and
            build new relationships in the members forum. Submit case studies
            and help boost your career by sharing your successes with our
            network; we will also promote your achievements via our social media
            networks.
          </p>
        </Col>
      </Row>
    </Container>
  </div>
);

const styles = {
  imgStyle: {
    width: 150,
    // height: 150,
    padding: 15
    // shadowOffset: { height: 20, width: 20 },
    // shadowColor: "black",
    // shadowOpacity: 1.0
  }
};

export default Connect;
