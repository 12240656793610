import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Row, Col, Table } from "reactstrap";
import { Card } from "semantic-ui-react";
import { styles } from "../../../styles";
import { getAllSubscribers } from "../../../actions/subscribers";
import {
  setLegalRegisterSubscriber,
  getAllLegalItems
} from "../../../actions/legalRegister";
import { renderIconSymbol } from "../../../constants";

class LegalRegisterUserTable extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: {},
      loading: true,
      activeTab: "1"
    };
  }

  toggle = tab => {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      });
    }
  };

  componentDidMount() {
    // getAllLegalRegisterItems();
    getAllLegalItems();
    getAllSubscribers();
  }

  setSubscriberEdit = key => {
    setLegalRegisterSubscriber(key);
  };

  compare = (firstArray, secondArray) => {
    let finalResult = firstArray.filter(e1 =>
      secondArray.find(e2 => e1.key === e2.key)
    );
    return finalResult;
  };

  renderChildren(profiles, AllLegalItems) {
    let counter;
    let counterOpen;
    let complianceFinal;
    let profilesFiltered = Object.values(profiles).filter(
      profile => profile.MyLegalItems != null || undefined
    );
    return profilesFiltered.map((profile, index) => {
      if (profile.MyLegalItems) {
        counter = 0;
        counterOpen = 0;
        complianceFinal = "Compliant";
        let allItems = Object.values(profile.MyLegalItems);

        let result = this.compare(allItems, AllLegalItems);
        for (let item of result) {
          counter = counter + 1;
          if (item.userStatus === "Compliant") {
          } else if (item.userStatus === "In Progress") {
            counterOpen = counterOpen + 1;
          } else if (item.userStatus === "Not Compliant") {
            counterOpen = counterOpen + 1;
            complianceFinal = "Not Compliant";
          }
        }
      }
      return (
        <tr key={index}>
          <td style={{ textAlign: "center" }}>
            <Link
              to={`/LegalRegister/user/${profile.key}`}
              onClick={e => this.setSubscriberEdit(profile.key)}
            >
              {renderIconSymbol(complianceFinal)}
            </Link>
          </td>

          <td>
            <Link
              to={`/LegalRegister/user/${profile.key}`}
              onClick={e => this.setSubscriberEdit(profile.key)}
            >
              {counter}
            </Link>
          </td>
          <td>
            <Link
              to={`/LegalRegister/user/${profile.key}`}
              onClick={e => this.setSubscriberEdit(profile.key)}
            >
              {counterOpen}
            </Link>
          </td>
          <td>
            <Link
              to={`/LegalRegister/user/${profile.key}`}
              onClick={e => this.setSubscriberEdit(profile.key)}
            >
              {profile["firstName"]}
            </Link>
          </td>
          <td>
            <Link
              to={`/LegalRegister/user/${profile.key}`}
              onClick={e => this.setSubscriberEdit(profile.key)}
            >
              {profile["lastName"]}
            </Link>
          </td>
          <td>
            <Link
              to={`/LegalRegister/user/${profile.key}`}
              onClick={e => this.setSubscriberEdit(profile.key)}
            >
              {profile["email"]}
            </Link>
          </td>
        </tr>
      );
    });
  }

  render() {
    let allProfiles = this.props.subscribers;

    return (
      <div className="animated bounceInLeft" style={{ marginLeft: 10 }}>
        <Row>
          <Col>
            <Card fluid style={styles.profileComponentStyle}>
              <Card.Content style={styles.cardContentStyle}>
                <div style={styles.cardHeaderStyle}>Legal Register Manager</div>
                <div style={styles.divStyle} />
                <div style={{ padding: 15 }}>
                  <Card.Description className="card-body">
                    <Table hover bordered striped responsive size="sm">
                      <thead>
                        <tr>
                          <th>User's Compliant</th>
                          <th>Total Items</th>
                          <th>Open Items</th>
                          <th>First Name</th>
                          <th>Last Name</th>
                          <th>Email Address</th>
                        </tr>
                      </thead>
                      {this.props.subscribers ? (
                        <tbody>
                          {this.renderChildren(
                            allProfiles,
                            this.props.currentLegalItems
                          )}
                        </tbody>
                      ) : null}
                    </Table>
                  </Card.Description>
                </div>
              </Card.Content>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}
function mapStateToProps(state, props) {
  return {
    currentLegalItems: state.legalItems.allLegalItems,
    currentUser: state.user.currentUserProps,
    subscribers: state.subscriber.subscribers,
    legalSubscriber: state.legalItems.currentLegalSubscriber
  };
}

export default connect(mapStateToProps)(LegalRegisterUserTable);

//saving as option to use toggles on user records later

//   handleComplianceChange(e, profile) {
//     console.log(e.target.name);

//     console.log("Profile " + profile.uid);
//     const updatedType = e.target.value;
//     updateUserType(profile.uid, updatedType);
//   }

// <Input
// type="select"
// name="userType"
// onChange={e => this.handleTypeChange(e, profile)}
// size="md"
// >
// <option value="Status"> {profile["userType"]} </option>
// <hr />
// {/* <option value="admin">admin</option> */}
// <option value="subscriber">subscriber</option>
// <option value="guest">guest</option>
// </Input>
