import React, { Component } from "react";
import { connect } from "react-redux";
import { stateToHTML } from "draft-js-export-html";
import { addInsight } from "../../actions/insights";
import { Row, Col, Form, FormGroup, Input } from "reactstrap";
import {
  Button,
  Card,
  Segment,
  Icon,
  Dropdown,
  Loader,
  Input as InputSemantic,
  Progress
} from "semantic-ui-react";
import {
  MegadraftEditor,
  editorStateFromRaw,
  editorStateToJSON
} from "megadraft";
import { styles } from "../../styles";
import { getCategories, getIndustries } from "../../actions/configActions";
import styled from "styled-components";

//  Notifications
import {
  createNotification,
  NOTIFICATION_TYPE_SUCCESS,
  NOTIFICATION_TYPE_WARNING
} from "react-redux-notify";

const mySuccessNotification = {
  message: `Insight Successfully uploaded`,
  type: NOTIFICATION_TYPE_SUCCESS,
  duration: 3000,
  canDismiss: true,
  icon: <Icon name="check" />
};

const emptyInsightWarning = {
  message: "Insight needs a title",
  type: NOTIFICATION_TYPE_WARNING,
  duration: 3000,
  canDismiss: true,
  icon: <Icon name="warning sign" />
};

const emptySegment = {
  message: "Insight needs at least one segment",
  type: NOTIFICATION_TYPE_WARNING,
  duration: 3000,
  canDismiss: true,
  icon: <Icon name="warning sign" />
};

const invalidFileSize = {
  message: "Please upload a valid file size",
  type: NOTIFICATION_TYPE_WARNING,
  duration: 3000,
  canDismiss: true,
  icon: <Icon name="warning sign" />
};

const Label = styled.p`
  font-weight: 400;
`;

class UploadForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: null,
      subTitle: null,
      category: "General",
      template: null,
      tags: [],
      file: null,
      featuredImage: null,
      content: null,
      editorState: editorStateFromRaw(null),
      value: "",
      videoUrl: "",
      loaderState: false,
      dropDownOptionsState: [],
      applicableTo: [],
      errors: "",
      previewImage: "",
      hovering: false,
      options: [
        { key: "0", text: "No file chosen", value: null },
        {
          key: "1",
          text: "Insight General.png",
          value:
            "https://firebasestorage.googleapis.com/v0/b/icon-dev-413c0.appspot.com/o/InsightTemplateImages%2FInsight%20General.png?alt=media&token=e5f3bbdf-df3a-4807-9d5e-178ce9acb069"
        },
        {
          key: "2",
          text: "Policy Update.png",
          value:
            "https://firebasestorage.googleapis.com/v0/b/icon-dev-413c0.appspot.com/o/InsightTemplateImages%2FPolicy%20Update.png?alt=media&token=184ff164-5a44-42cb-b13c-4989fc972991"
        },
        {
          key: "3",
          text: "Procurement.png",
          value:
            "https://firebasestorage.googleapis.com/v0/b/icon-dev-413c0.appspot.com/o/InsightTemplateImages%2FProcurement.png?alt=media&token=509e690a-b503-4933-8770-f5ca7ce3bed9"
        },
        {
          key: "4",
          text: "Rep & Policy.png",
          value:
            "https://firebasestorage.googleapis.com/v0/b/icon-dev-413c0.appspot.com/o/InsightTemplateImages%2FRep%26Policy.png?alt=media&token=7457a26c-7b82-490b-af44-a11be3c7999a"
        },
        {
          key: "5",
          text: "Toolkit.png",
          value:
            "https://firebasestorage.googleapis.com/v0/b/icon-dev-413c0.appspot.com/o/InsightTemplateImages%2FToolkit.png?alt=media&token=2d5aa684-a1b4-487a-b2e8-38174d2513d5"
        },
        {
          key: "6",
          text: "Water.png",
          value:
            "https://firebasestorage.googleapis.com/v0/b/icon-dev-413c0.appspot.com/o/InsightTemplateImages%2FWater.png?alt=media&token=f4548f5d-cde0-439d-854c-8aaaa54d88d2"
        }
      ]
    };
  }

  componentDidMount = async () => {
    getCategories();
    getIndustries();
    const { categories } = this.props;
    const X = categories.map((cat, idx) => ({
      id: idx,
      key: cat.key,
      text: cat.categoryName,
      value: cat.categorySlug
    }));
    this.setState({
      dropDownOptionsState: X
    });
  };

  componentDidUpdate = (prevProps, prevState) => {
    const { fileUploadProgress, createNotification } = this.props;
    if (fileUploadProgress === 100 && prevProps.fileUploadProgress !== 100) {
      console.log("Now we send the success notification");
      createNotification(mySuccessNotification); // this need to happen when fileUploadProgress === 100
    }
  };

  handleSelect = (e, data) => {
    this.setState({ applicableTo: data.value });
  };

  handleInputChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };
  handleFileSelect = e => {
    this.setState({
      file: e.target.files[0]
    });
  };

  handleImageSelect = e => {
    // if file is greater than 5mb
    if (e.target.files[0] && e.target.files[0].size > 5000000) {
      return this.setState({
        errors: "File size is too big!",
        featuredImage: e.target.files[0] ? e.target.files[0] : null,
        template: null
      });
    }

    // if file is less than 5kb
    if (e.target.files[0] && e.target.files[0].size < 5000) {
      return this.setState({
        errors: "File size is too small!",
        featuredImage: e.target.files[0] ? e.target.files[0] : null,
        template: null
      });
    }

    this.setState(
      {
        featuredImage: e.target.files[0] ? e.target.files[0] : null,
        template: null,
        errors: ""
      },
      function() {
        console.log(this.state.featuredImage.name, " Featured Image");
      }
    );
  };

  handleTagSelect = value => {
    console.log("You've selected:", value);
    this.setState({ tags: value });
  };

  handleCategory = (e, data) => {
    this.setState({ category: data.value });
  };

  onChange = editorState => {
    this.setState({ editorState });
  };

  handleTemplateFile = (e, data) => {
    this.setState({
      featuredImage: data,
      template: data.value,
      errors: ""
    });
  };

  handleSubmit = e => {
    e.preventDefault();
    const { createNotification, currentUser } = this.props;
    const authorName = this.props.currentUser.displayName;
    const {
      applicableTo,
      editorState,
      title,
      subTitle,
      category,
      featuredImage,
      file,
      videoUrl
    } = this.state;

    this.setState({ loaderState: true });
    // If empty title invoke notification
    if (
      title === null ||
      title === "" ||
      title.replace(/\s+/g, "").length === 0
    ) {
      this.setState({ loaderState: false });
      return createNotification(emptyInsightWarning);
    }

    if (
      this.state.applicableTo[0] === undefined ||
      this.state.applicableTo[0] === ""
    ) {
      this.setState({ loaderState: false });
      return createNotification(emptySegment);
    }

    if (this.state.errors) {
      return createNotification(invalidFileSize);
    }

    // Convert the Editor state to HTML
    const contentAsHtml = stateToHTML(editorState.getCurrentContent());
    //  Convert the Editor State to JSON
    const contentJSON = editorStateToJSON(editorState);
    // Send data to db

    /**
     * Calls addInsight Action Creator
     */
    addInsight(
      this.props.currentUser.uid,
      title,
      subTitle,
      category,
      authorName,
      featuredImage,
      contentAsHtml,
      file,
      currentUser.profilePhoto || null,
      applicableTo,
      videoUrl,
      contentJSON
    );
    document.getElementById("theUploadForm").reset();
    this.setState({
      title: null,
      subTitle: null,
      category: "General",
      file: null,
      featuredImage: null,
      content: null,
      editorState: editorStateFromRaw(null),
      value: "",
      tags: [],
      loaderState: false,
      applicableTo: [],
      template: null
    });

    this.setState({ errors: "" });
  };

  render() {
    const { fileUploadProgress } = this.props;

    const insightCategoriesArray = [
      { key: 0, text: "General", value: "General" }
    ];

    this.props.categories.map((category, i) => {
      return (
        category.categoryVisable &&
        insightCategoriesArray.push({
          key: i + 1,
          text: category.categoryName,
          value: category.categorySlug
        })
      );
    });

    const insightIndustriesArray = [];

    this.props.industries &&
      this.props.industries.map((industry, i) => {
        return (
          industry.isActive &&
          insightIndustriesArray.push({
            key: i,
            text: industry.industryName,
            value: industry.industryName
          })
        );
      });

    return (
      <Card fluid style={styles.profileComponentStyle} color="teal">
        <Card.Content style={styles.cardContentStyle}>
          <div style={styles.cardHeaderStyle}>Insight Upload</div>
          <div style={styles.divStyleLeftbox} />
          <Segment style={styles.segmentStyle}>
            <Form
              id="theUploadForm"
              onSubmit={this.handleSubmit}
              style={styles.formStyle}
            >
              <Row style={{ marginBottom: 5 }}>
                <Col>
                  <FormGroup>
                    <Input
                      placeholder="Title"
                      name="title"
                      onChange={this.handleInputChange}
                    />
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup>
                    <Input
                      placeholder="Sub Title"
                      onChange={this.handleInputChange}
                      name="subTitle"
                    />
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup>
                    <InputSemantic
                      label="https://"
                      placeholder="paste youtube/vimeo url or planet 9 embed code"
                      name="videoUrl"
                      onChange={this.handleInputChange}
                    />
                  </FormGroup>
                </Col>
              </Row>

              <Row style={{ marginBottom: 5 }}>
                <Col>
                  <FormGroup>
                    <Dropdown
                      placeholder="Choose a Category ..."
                      fluid
                      selection
                      options={insightCategoriesArray}
                      onChange={this.handleCategory}
                      value={this.state.category}
                    />
                  </FormGroup>

                  <FormGroup>
                    <Label htmlFor="">File</Label>
                    <Label>TIP: Give your file a sensible name!</Label>
                    <Input
                      type="file"
                      name="file"
                      id="exampleFile"
                      onChange={this.handleFileSelect}
                    />
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup>
                    <Dropdown
                      placeholder="Choose an industry segment ..."
                      fluid
                      multiple
                      selection
                      options={insightIndustriesArray}
                      onChange={this.handleSelect}
                      value={this.state.applicableTo}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <hr />
              <Row style={{ marginBottom: 5 }}>
                <Col>
                  <FormGroup>
                    <Label htmlFor="featuredImage">Featured Image</Label>
                    <Input
                      style={
                        this.state.errors
                          ? {
                              border: "1px solid red",
                              borderRadius: 7,
                              padding: 2
                            }
                          : this.state.featuredImage &&
                            !this.state.featuredImage.value &&
                            this.state.featuredImage.value !== null
                          ? {
                              border: "1px solid green",
                              borderRadius: 7,
                              padding: 2,
                              color: "green"
                            }
                          : {}
                      }
                      type="file"
                      name="featuredImage"
                      id="featuredImage"
                      // if template file is already selected, removes file
                      value={this.state.template && ""}
                      onChange={this.handleImageSelect}
                    />
                    {/* recommended file size and error message */}
                    <i
                      style={{
                        color: "grey",
                        display: "inline-block",
                        paddingTop: 5
                      }}
                    >
                      File must be between 5kb and 5mb
                    </i>
                    <p style={{ color: "red" }}>{this.state.errors}</p>
                    <p style={{ marginTop: 5 }}>...or</p>
                    <div
                      style={
                        this.state.template !== null
                          ? {
                              border: "1px solid green",
                              borderRadius: 7,
                              padding: 2,
                              width: 250
                            }
                          : {}
                      }
                    >
                      <span style={{ fontWeight: 500 }}>Template file: </span>
                      <Dropdown
                        style={
                          this.state.template !== null
                            ? {
                                color: "green"
                              }
                            : {}
                        }
                        options={this.state.options}
                        placeholder="No file chosen"
                        onChange={this.handleTemplateFile}
                        onMouseOver={e => {
                          this.state.options.map((item, i) => {
                            return (
                              item.text.includes(e.target.innerText) &&
                              this.setState({
                                previewImage: item,
                                hovering: true
                              })
                            );
                          });
                        }}
                        onMouseOut={() => {
                          this.setState({ previewImage: "", hovering: false });
                        }}
                        value={this.state.template}
                      />
                    </div>
                  </FormGroup>
                </Col>
                <Col>
                  <p
                    style={
                      !this.state.previewImage ? { visibility: "hidden" } : {}
                    }
                  >
                    Template preview:
                  </p>
                  {/*  this container div allows the loading icon with zindex to take place */}
                  <div
                    style={{
                      width: 179.3,
                      height: 100
                    }}
                  >
                    <div
                      alt=""
                      style={
                        this.state.previewImage
                          ? {
                              width: 179.3,
                              height: 100,
                              marginBottom: 5,
                              backgroundSize: "cover",
                              position: "relative",
                              backgroundImage: `url(${
                                this.state.previewImage.value
                              })`,
                              zIndex: 2
                            }
                          : {
                              width: 179.3,
                              height: 100,
                              marginBottom: 5,
                              position: "relative",
                              visibility: "hidden",
                              zIndex: 2
                            }
                      }
                    />
                    {this.state.previewImage && (
                      <Loader
                        style={
                          this.state.hovering &&
                          this.state.previewImage !== null
                            ? {
                                position: "relative",
                                zIndex: 0,
                                top: -80,
                                left: 50
                              }
                            : {}
                        }
                        active
                        inline
                      />
                    )}
                  </div>
                </Col>
              </Row>

              <FormGroup>
                <Segment style={{ minHeight: 100 }}>
                  <MegadraftEditor
                    editorState={this.state.editorState}
                    onChange={this.onChange}
                    placeholder="Write content here ..."
                  />
                </Segment>
              </FormGroup>

              {fileUploadProgress !== null ? (
                <Progress percent={this.props.fileUploadProgress} indicating />
              ) : null}

              <Button fluid type="submit">
                Submit
              </Button>
            </Form>
          </Segment>
        </Card.Content>
      </Card>
    );
  }
}

const mapStateToProps = state => ({
  currentUser: state.user.currentUserProps,
  categories: state.appConfig.categories,
  industries: state.appConfig.industries,
  fileUploadProgress: state.insights.fileUploadProgress
});

const mapDispatchToProps = dispatch => ({
  createNotification: config => {
    dispatch(createNotification(config));
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UploadForm);
