import React, { Component, Fragment } from "react";
import { DebounceInput } from "react-debounce-input";
import { connect } from "react-redux";
import TimeAgo from "react-timeago";
import {
  Card,
  Segment,
  Label,
  Button,
  Icon,
  Item,
  Image,
  Loader,
  Dropdown
} from "semantic-ui-react";
import { Input as SemanticInput, Popup } from "semantic-ui-react";
import { Row, Col, Button as Btn } from "reactstrap";
import {
  addUserWishlistItem,
  getAllInsights,
  setInsight
} from "../../actions/insights";
import { changeActiveItem } from "../../actions/configActions";
import {
  getCategories,
  getParentCategories,
  getIndustries
} from "../../actions/configActions";
import InsightModal from "./InsightModal";
import BlankProfilePicture from "../../img/blank-profile-picture.png";
import StoryImg from "../../img/StoryImg.png";
import books from "../../img/books.svg";
import {
  createNotification,
  NOTIFICATION_TYPE_SUCCESS,
  NOTIFICATION_TYPE_WARNING
} from "react-redux-notify";
import { styles, buttonStyles } from "../../styles";
import InfiniteScroll from "react-infinite-scroll-component";
import SubscribeModal from "./SubscribeModal";
import { withRouter } from "react-router-dom";

const addInsightFail = {
  message: "You already have this saved!",
  type: NOTIFICATION_TYPE_WARNING,
  duration: 3000,
  canDismiss: true,
  icon: <Icon name="warning sign" />
};

class SubscribedInsights extends Component {
  state = {
    query: "",
    tags: "",
    categories: "",
    items: this.props.currentAllInsights,
    limit: 10,
    itemAmount: 0,
    modal: false,
    redirect: false
  };

  componentDidMount() {
    getCategories();
    getParentCategories();
    getIndustries();
    getAllInsights();
  }

  handleSearch = e => {
    this.setState({ query: e.target.value });
    getAllInsights(this.state.categories, e.target.value, this.state.tags);
  };

  handleSelect = (e, data) => {
    this.setState({ tags: data.value });
    getAllInsights(this.state.categories, this.state.query, data.value);
  };

  handleCategory = (e, data) => {
    this.setState({ categories: data.value });
    getAllInsights(data.value, this.state.query, this.state.tags);
  };

  handleParentCategory = childCategories => {
    this.setState({ categories: childCategories, tags: "", query: "" });
    getAllInsights(childCategories, "", "");
  };

  handleAddClick = insight => {
    const mySuccessNotification = {
      message: `Insight added!`,
      type: NOTIFICATION_TYPE_SUCCESS,
      duration: 3000,
      canDismiss: false,
      icon: <Icon name="check" />,
      acceptBtn: {
        handler: (e, notification) => {
          changeActiveItem("Saved Insights");
          this.props.history.push("/myAccount");
          notification.handleDismiss(notification.id);
        },
        title: "Go to 'Saved Insights'"
      }
    };
    const myInsights = this.props.myInsights;
    const { createNotification } = this.props;
    let myArray = [];
    let i;
    for (i = 0; i < myInsights.length; i++) {
      myArray.push(myInsights[i].key);
    }
    if (myArray.indexOf(insight.key) === -1) {
      addUserWishlistItem(this.props.currentUser.uid, insight);
      createNotification(mySuccessNotification);
    } else {
      createNotification(addInsightFail);
    }
  };

  fetchMoreData = () => {
    const itemAmount = this.state.itemAmount + 10;
    const limit = this.state.limit + 10;

    setTimeout(() => {
      this.setState({
        limit: limit,
        items: this.props.currentAllInsights.slice(0, itemAmount),
        itemAmount: itemAmount
      });
    }, 1000);
  };

  toggle = key => {
    // eslint-disable-next-line
    if (typeof key == "string") {
      setInsight(key, this.props.currentUser);
    }
    this.setState({ modal: !this.state.modal });
  };

  render() {
    const {
      currentUser,
      categories,
      parentCategories,
      industries
    } = this.props;
    const insightCategoriesArray = [];

    categories.map((category, i) => {
      return (
        category.categoryVisable &&
        insightCategoriesArray.push({
          key: i + 1,
          text: category.categoryName,
          value: category.categorySlug
        })
      );
    });

    const insightIndustriesArray = [];

    industries &&
      industries.map((industry, i) => {
        return (
          industry.isActive &&
          insightIndustriesArray.push({
            key: i,
            text: industry.industryName,
            value: industry.industryName
          })
        );
      });

    return (
      <Card color="teal" fluid style={styles.profileComponentStyle}>
        <Card.Content style={styles.cardContentStyle}>
          <div style={styles.cardHeaderStyle}>
            Insights
            {/* displays number of insights */}
            {` (${this.props.currentAllInsights.length})`}
          </div>
          <div style={styles.divStyle} />
          {/* parent category containing row */}
          <Row
            style={{
              display: "flex",
              justifyContent: "center",
              margin: 25,
              border: "1px solid lightgrey",
              paddingBottom: 40,
              borderRadius: 5,
              backgroundColor: "white",
              boxShadow: "5px 5px 20px -12px rgba(0, 0, 0, 0.75)",
              boxSizing: "borderBox"
            }}
          >
            {parentCategories.map((category, i) => {
              // individual parent category column
              return category.isActive ? (
                <Fragment>
                  <Col
                    sm="2"
                    style={{
                      background: "#E8E8E8",
                      color: "rgba(0,0,0,0.6)",
                      fontWeight: "bold",
                      cursor: "pointer",
                      margin: "40px 40px 0 40px",
                      padding: 8,
                      border: "1px solid lightgrey",
                      borderRadius: "0 0 6px 6px",
                      backgroundColor: "white",
                      boxShadow: "5px 5px 20px -12px rgba(0, 0, 0, 0.75)",
                      boxSizing: "borderBox",
                      maxWidth: 180,
                      minWidth: 180
                    }}
                    key={i}
                    onClick={() => {
                      this.handleParentCategory(category.childCategories);
                    }}
                  >
                    <div
                      style={{
                        height: 2,
                        backgroundImage:
                          "linear-gradient(to right, #FFDF00, #F63800, #6400B5)",
                        position: "relative",
                        maxWidth: 180,
                        minWidth: 180,
                        top: -9,
                        marginLeft: -9.25,
                        opacity: 0.6,
                        boxSizing: "borderBox"
                      }}
                    />
                    <img
                      src={books}
                      alt="books"
                      style={{
                        display: "block",
                        width: 50,
                        margin: "0 auto",
                        opacity: 0.5
                      }}
                    />
                    <p
                      style={{
                        textAlign: "center",
                        color: "rgba(0, 0, 0, 0.5)",
                        fontFamily: "Avenir Next",
                        fontWeight: 500
                      }}
                    >
                      {category.parentCategoryName}
                    </p>
                  </Col>
                </Fragment>
              ) : (
                ""
              );
            })}
          </Row>

          <div
            style={{
              padding: "0 15px 15px 15px",
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              alignItems: "flex-start",
              justifyContent: "space-between",
              marginBottom: -20
            }}
          >
            <SemanticInput icon>
              <DebounceInput
                style={{
                  borderRadius: 20,
                  maxWidth: 300,
                  marginBottom: 10
                }}
                type="Search"
                value={this.state.query}
                debounceTimeout={500}
                minLength={2}
                onChange={this.handleSearch}
                placeholder=" Search ... "
              />
              <Icon name="search" />
            </SemanticInput>

            <Dropdown
              style={{
                maxWidth: 500,
                marginBottom: 10
              }}
              placeholder="Choose a Category ..."
              fluid
              multiple
              selection
              options={insightCategoriesArray}
              onChange={this.handleCategory}
              value={this.state.categories}
            />

            <Dropdown
              style={{
                maxWidth: 300,
                marginBottom: 10
              }}
              placeholder="Choose an industry segment ..."
              fluid
              multiple
              selection
              options={insightIndustriesArray}
              onChange={this.handleSelect}
              value={this.state.tags}
            />
          </div>

          <Segment style={styles.segmentStyle}>
            {/* add logic that makes sure that infinite scrolling has enough data before being rendered to prevent it getting stuck if you scroll down too fast */}
            <InfiniteScroll
              style={{ overflowX: "hidden" }}
              dataLength={this.state.items.length}
              next={this.fetchMoreData}
              hasMore={true}
              loader={
                this.state.limit >= this.props.currentAllInsights.length ? (
                  <h4> </h4>
                ) : (
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <Loader active inline content=" " />
                  </div>
                )
              }
            >
              <Item.Group divided>
                {this.props.currentAllInsights.length > 0 ? (
                  this.props.currentAllInsights.map((insight, index) => (
                    <Item
                      key={index}
                      style={
                        index >= this.state.limit ? { display: "none" } : {}
                      }
                    >
                      <Item.Image
                        size="small"
                        alt="Energy insights feature"
                        src={insight.featuredImage || StoryImg}
                      />
                      <Item.Content>
                        <Row>
                          <Col>
                            <Item.Header>{insight.title}</Item.Header>
                            <Item.Description>
                              {insight.subTitle}
                            </Item.Description>
                            <Item.Meta>
                              <div style={{ padding: 3 }}>
                                <TimeAgo
                                  date={insight.time}
                                  style={{ textAlign: "right" }}
                                />
                              </div>
                              {insight.category && (
                                <Label
                                  style={{ marginTop: 10 }}
                                  icon="hashtag"
                                  content={insight.category}
                                />
                              )}
                            </Item.Meta>
                          </Col>
                          <Col>
                            <div
                              style={{
                                textAlign: "right",
                                paddingRight: 15
                              }}
                            >
                              <Row>
                                <Col>
                                  <span style={{ fontWeight: "bold" }}>
                                    {insight.authorDisplayName}
                                  </span>{" "}
                                  <Image
                                    src={
                                      insight.authorProfilePhoto ||
                                      BlankProfilePicture
                                    }
                                    circular
                                    avatar
                                    alt="insight author pic"
                                  />
                                </Col>
                              </Row>
                              <Row
                                style={{
                                  float: "right",
                                  justifyContent: "flex-end",
                                  marginTop: 2
                                }}
                              >
                                {currentUser.userType === "guest" ? (
                                  <div>
                                    <SubscribeModal buttonLabel="Subscribe" />
                                  </div>
                                ) : (
                                  <div>
                                    <Popup
                                      trigger={
                                        <Btn
                                          icon
                                          color="link"
                                          onClick={() =>
                                            this.handleAddClick(insight)
                                          }
                                          style={{ cursor: "pointer" }}
                                        >
                                          <Icon name="add" />
                                          Add
                                        </Btn>
                                      }
                                      content={
                                        <p style={{ textAlign: "center" }}>
                                          Click to add this insight to your
                                          Saved Insights page
                                        </p>
                                      }
                                      position="bottom right"
                                    />

                                    <Button
                                      onClick={() => this.toggle(insight.key)}
                                      style={buttonStyles.primaryButton}
                                      color="teal"
                                    >
                                      View
                                    </Button>
                                  </div>
                                )}
                              </Row>
                            </div>
                          </Col>
                        </Row>
                      </Item.Content>
                    </Item>
                  ))
                ) : this.state.query !== "" ? (
                  <p style={{ textAlign: "center", fontSize: 18 }}>
                    We're sorry, no results for{" "}
                    {<strong>"{this.state.query}"</strong>} were found.
                  </p>
                ) : (
                  <p style={{ textAlign: "center", fontSize: 18 }}>
                    We're sorry, no results were found.
                  </p>
                )}
                <InsightModal
                  tags={this.state.tags}
                  modal={this.state.modal}
                  toggle={this.toggle}
                />
              </Item.Group>
            </InfiniteScroll>
          </Segment>
        </Card.Content>
      </Card>
    );
  }
}

const mapStateToProps = state => ({
  currentUser: state.user.currentUserProps,
  myInsights: state.insights.myInsightsProps,
  currentAllInsights: state.insights.currentAllInsightsProps,
  categories: state.appConfig.categories,
  parentCategories: state.appConfig.parentCategories,
  industries: state.appConfig.industries
});

// if you define mapDispatchToProps, cannot use this.props.dispatch to dispatch an action
const mapDispatchToProps = dispatch => ({
  createNotification: config => {
    dispatch(createNotification(config));
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(SubscribedInsights));
