import React, { Component } from "react";
import { Card } from "semantic-ui-react";
import { styles } from "../../../styles";
import Courses from "./config/Courses";
import CourseSections from "./config/CourseSections";
import Lessons from "./config/Lessons";
import Instructors from "./config/Instructors";
import SkillLevels from "./config/SkillLevels";

// Actions
import {
  getSkillLevels,
  getCourses,
  getInstructors
} from "../../../actions/university";
// import { object } from "firebase-functions/lib/providers/storage";

class UniConfig extends Component {
  componentDidMount = () => {
    getSkillLevels();
    getCourses();
    getInstructors();
  };

  render() {
    return (
      <div>
        <div style={styles.cardHeaderStyle}>Uni Config</div>
        <div style={styles.divStyle} />
        <Card fluid style={styles.profileComponentStyle} color="teal">
          <Courses />
          <CourseSections />
          <Lessons />
          <Instructors />
          <SkillLevels />
        </Card>
      </div>
    );
  }
}

export default UniConfig;
