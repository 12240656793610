import React, { Component } from "react";
// import { Link } from "react-router-dom";
import { connect } from "react-redux";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Container,
  Row,
  Col
} from "reactstrap";
import {
  Card,
  Icon,
  Segment,
  Button,
  Label,
  Popup,
  Flag
} from "semantic-ui-react";
import { MegadraftEditor, editorStateFromRaw } from "megadraft";
import "../MegaDraft/megadraft.css";
import { styles, modalStyles, buttonStyles } from "../../styles";

class LegalItemModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      editMode: true
    };
  }

  openEdit = () => {
    this.setState({
      editMode: !this.state.editMode
    });
  };

  render() {
    let url = `//${this.props.item.link}`; // link display logic so if url is 'www...' or 'http/(s)...'
    if (this.props.item.link && this.props.item.link.includes("http")) {
      url = `${this.props.item.link}`;
    }

    return (
      <div>
        <Modal
          isOpen={this.props.modal}
          toggle={this.props.toggle}
          className={this.props.className}
          size="lg"
        >
          <ModalHeader toggle={this.toggle}>
            <a href={url} target="_blank">
              <h2>{this.props.item.title}</h2>
            </a>
          </ModalHeader>
          <ModalBody>
            <Card fluid style={{ background: "blue" }}>
              <Card.Content style={styles.segmentStyle}>
                <Container>
                  <Row>
                    <Col>
                      <Segment.Group raised>
                        <Segment style={modalStyles.headerModalStyle}>
                          <Label floated="right" disabled>
                            <Icon name="hashtag" />
                            {this.props.item.filterByArea}
                          </Label>
                        </Segment>
                        <div style={styles.divStyle} />
                        <Segment style={modalStyles.segmentStyle} clearing>
                          <MegadraftEditor
                            editorState={editorStateFromRaw(
                              JSON.parse(this.props.item.headlineDetails)
                            )}
                            readOnly={true}
                          />
                        </Segment>
                      </Segment.Group>
                      <Segment.Group horizontal>
                        <Segment>
                          <Segment inverted tertiary compact>
                            <h4>COUNTRIES:</h4>
                          </Segment>
                          {this.props.item.country
                            ? this.props.item.country.map((flag, i) => {
                                return (
                                  <Popup
                                    trigger={
                                      <Flag
                                        name={
                                          flag !== "Northern Ireland"
                                            ? flag.toLowerCase()
                                            : "niflag"
                                        }
                                      />
                                    }
                                    content={flag}
                                  />
                                );
                              })
                            : ""}
                        </Segment>

                        <Segment>
                          <Segment inverted tertiary compact>
                            <h4>APPLICABLE TO:</h4>
                          </Segment>
                          {this.props.item.applicableTo ? (
                            this.props.item.applicableTo.map((tag, idx) => (
                              <div key={idx}>
                                <Label style={{ margin: 1 }}>
                                  <Icon name="tag" />
                                  {tag}
                                </Label>
                              </div>
                            ))
                          ) : (
                            <div />
                          )}
                        </Segment>
                        <Segment>
                          <Segment inverted tertiary compact>
                            <h4>REGULATED BY:</h4>
                          </Segment>
                          {this.props.item.regulator ? (
                            this.props.item.regulator.map((tag, idx) => (
                              <div key={idx}>
                                <Label style={{ margin: 1 }}>
                                  <Icon name="tag" />
                                  {tag}
                                </Label>
                              </div>
                            ))
                          ) : (
                            <div />
                          )}
                        </Segment>
                      </Segment.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Segment.Group>
                        <Segment>
                          <Segment inverted tertiary>
                            <h4>DESCRIPTION:</h4>
                          </Segment>
                          <MegadraftEditor
                            editorState={editorStateFromRaw(
                              JSON.parse(this.props.item.description)
                            )}
                            readOnly={true}
                          />
                        </Segment>
                        <Segment>
                          <Segment inverted tertiary>
                            <h4>CONSEQUENCES:</h4>
                          </Segment>
                          <MegadraftEditor
                            editorState={editorStateFromRaw(
                              JSON.parse(this.props.item.consequence)
                            )}
                            readOnly={true}
                          />
                        </Segment>
                      </Segment.Group>
                    </Col>
                  </Row>
                </Container>
              </Card.Content>
            </Card>
          </ModalBody>
          <div style={styles.divStyle} />
          <ModalFooter>
            <Button
              color="yellow"
              onClick={this.props.toggle}
              style={buttonStyles.primaryButton}
            >
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  item: state.legalItems.currentLegalItemProps
});

export default connect(mapStateToProps)(LegalItemModal);
