import {
  SET_CURRENT_ALL_POSTS,
  SELECT_POST,
  DELETE_POST,
  UPDATE_REPLIES,
  SET_LATEST_POSTS,
  SET_INSIGHT_COMMENTS,
  SELECT_INSIGHT_COMMENT
} from "../actions/types";

const INITIAL_STATE = {
  currentAllPostsProps: [],
  CurrentPostProps: {},
  PostRepliesProps: {},
  latestPosts: {},
  // Insight comments
  insightComments: [],
  insightComment: {}
};

export default function postReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case SET_CURRENT_ALL_POSTS: {
      return {
        ...state,
        currentAllPostsProps: [...action.allPosts]
      };
    }
    case SELECT_POST: {
      return {
        ...state,
        CurrentPostProps: action.CurrentPost
      };
    }
    case DELETE_POST: {
      return {
        ...state,
        CurrentPostProps: [...action.postKey]
      };
    }
    case UPDATE_REPLIES: {
      return {
        ...state,
        PostRepliesProps: action.replyData
      };
    }
    case SET_LATEST_POSTS: {
      return {
        ...state,
        latestPosts: action.latestPosts
      };
    }

    case SET_INSIGHT_COMMENTS: {
      return {
        ...state,
        insightComments: action.comments
      };
    }

    case SELECT_INSIGHT_COMMENT: {
      return {
        ...state,
        insightComment: action.comment
      };
    }

    default:
      return state;
  }
}
