import { firebase } from "../FireBase/firebase";
import { snapshotToArray } from "./utils";
import store from "../store";
import {
  SET_SKILL_LEVELS,
  SET_COURSES,
  SET_INSTRUCTORS,
  SET_ACTIVE_COURSE_SECTION,
  SET_ACTIVE_LESSON_COURSE
} from "./types";

// SKILLS
export const createSkillLevel = skillLevel => {
  const newPostKey = firebase
    .database()
    .ref()
    .child("university/skillLevels/")
    .push().key;
  firebase
    .database()
    .ref(`/university/skillLevels/${newPostKey}`)
    .update({ name: skillLevel, key: newPostKey });
};

export const removeSkillLevel = key => {
  firebase
    .database()
    .ref(`university/skillLevels/${key}`)
    .remove();
};

export const getSkillLevels = () => {
  firebase
    .database()
    .ref("/university/skillLevels")
    .on("value", snap => {
      const skillLevels = snap.val();
      store.dispatch({ type: SET_SKILL_LEVELS, skillLevels });
    });
};

// COURSES
export const getCourses = () => {
  firebase
    .database()
    .ref("university/courses")
    .on("value", snap => {
      const coursesArray = snapshotToArray(snap);
      const courses = Array.from(coursesArray.reverse());
      store.dispatch({ type: SET_COURSES, courses });
    });
};

export const createCourse = (
  courseTitle,
  courseDescription,
  courseSkillLevel,
  courseInstructors,
  courseImage
) => {
  const newPostKey = firebase
    .database()
    .ref()
    .child("university/courses/")
    .push().key;

  if (courseImage) {
    const imageFolder = newPostKey;
    firebase
      .storage()
      .ref()
      .child(`university/images/${imageFolder}/${courseImage.name}`)
      .put(courseImage)
      .then(snapshot => {
        return snapshot.ref.getDownloadURL(); // Will return a promise with the download link
      })
      .then(downloadURL => {
        const imageUrl = downloadURL;
        firebase
          .database()
          .ref("university/courses/" + newPostKey)
          .update({
            courseTitle,
            courseDescription,
            courseSkillLevel,
            courseInstructors,
            key: newPostKey,
            courseImage: imageUrl
          })
          .catch(e => console.log("update course error: ", e));
      });
  } else {
    firebase
      .database()
      .ref("university/courses/" + newPostKey)
      .update({
        courseTitle,
        courseDescription,
        courseSkillLevel,
        courseInstructors,
        key: newPostKey
      })
      .catch(e => console.log("update course error: ", e));
  }
};

export const removeCourse = key => {
  firebase
    .database()
    .ref(`university/courses/${key}`)
    .remove();
};

/***
 * SECTIONS
 */

export const getCourseSections = () => {
  console.log("Gonna get the course sections");
};

export const createSection = (sectionTitle, courseKey) => {
  const path = `university/courses/${courseKey}/sections/`;
  const newPostKey = firebase
    .database()
    .ref()
    .child(path)
    .push().key;
  firebase
    .database()
    .ref(path + newPostKey)
    .update({ sectionTitle, key: newPostKey, courseKey })
    .then(() => {
      firebase
        .database()
        .ref(`university/courses/${courseKey}`)
        .on("value", snap => {
          const activeCourseSection = snap.val();
          store.dispatch({
            type: SET_ACTIVE_COURSE_SECTION,
            activeCourseSection
          });
        });
    })
    .catch(e => console.log("update section error: ", e));
};

export const removeSection = (courseKey, sectionKey) => {
  firebase
    .database()
    .ref(`university/courses/${courseKey}/sections/${sectionKey}`)
    .remove();
};

/***
 * LESSONS
 */

export const createLesson = (lessonTitle, courseKey, sectionKey) => {
  console.log(lessonTitle, courseKey, sectionKey);
  const path = `university/courses/${courseKey}/sections/${sectionKey}/lessons/`;
  const newPostKey = firebase
    .database()
    .ref()
    .child(path)
    .push().key;
  firebase
    .database()
    .ref(path + newPostKey)
    .update({
      lessonTitle,
      key: newPostKey,
      sectionKey,
      courseKey
    })
    .then(() => {
      firebase
        .database()
        .ref(`university/courses/${courseKey}`)
        .on("value", snap => {
          const activeLessonCourse = snap.val();
          store.dispatch({
            type: SET_ACTIVE_LESSON_COURSE,
            activeLessonCourse
          });
        });
    })
    .catch(e => console.log("update lesson error: ", e));
};

export const removeLesson = (courseKey, sectionKey, lessonKey) => {
  firebase
    .database()
    .ref(
      `university/courses/${courseKey}/sections/${sectionKey}/lessons/${lessonKey}`
    )
    .remove();
};

// INSTRUCTORS

export const createInstructor = (firstName, lastName, description) => {
  const newPostKey = firebase
    .database()
    .ref()
    .child("university/instructors")
    .push().key;
  firebase
    .database()
    .ref("university/instructors/" + newPostKey)
    .update({ firstName, lastName, description, key: newPostKey });
};

export const removeInstructor = key => {
  firebase
    .database()
    .ref(`university/instructors/${key}`)
    .remove();
};

export const getInstructors = () => {
  firebase
    .database()
    .ref("/university/instructors")
    .on("value", snap => {
      const instructors = snap.val();
      store.dispatch({ type: SET_INSTRUCTORS, instructors });
    });
};

// ACTIVE PROPERTIES

export const setActiveSectionCourse = key => {
  firebase
    .database()
    .ref(`university/courses/${key}`)
    .on("value", snap => {
      const activeCourseSection = snap.val();
      store.dispatch({ type: SET_ACTIVE_COURSE_SECTION, activeCourseSection });
    });
};

export const setActiveLessonCourse = key => {
  firebase
    .database()
    .ref(`university/courses/${key}`)
    .on("value", snap => {
      const activeLessonCourse = snap.val();
      store.dispatch({ type: SET_ACTIVE_LESSON_COURSE, activeLessonCourse });
    });
};
