import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { Container, Row, Col } from "reactstrap";
import { connect } from "react-redux";
import { Transition } from "semantic-ui-react";
import { getAuthorInsights } from "../../actions/insights";
import {
  getCategories,
  getLegalCategories,
  getIndustries,
  getLegalRegulators
} from "../../actions/configActions";
import AuthorInsights from "../Insights/AuthorInsights";
import UploadForm from "../Insights/UploadForm";
import EditInsight from "../Insights/EditInsight";

class Admin extends Component {
  constructor() {
    super();

    this.state = {
      contextRef: {}
    };
  }

  componentDidMount = async () => {
    if (this.props.authUser === null) {
      return <Redirect to="/" />;
    }
    getCategories();
    getLegalCategories();
    getIndustries();
    getLegalRegulators();
  };

  handleContextRef = contextRef => this.setState({ contextRef });

  render() {
    /*middleware for auth routes*/
    if (this.props.authUser === null) {
      return <Redirect to="/" />;
    } else if (this.props.currentUser.userType !== "admin") {
      if (this.props.currentUser.userType !== "jedi") {
        return <Redirect to="/" />;
      }
    }
    if (this.props.authUser) {
      getAuthorInsights(this.props.currentAllInsights, this.props.authUser.uid);
    }
    const InsightForm =
      this.props.visibleState === false ? (
        <UploadForm />
      ) : (
        <Transition
          visible={this.props.visibleState}
          animation="scale"
          duration={200}
        >
          <div>
            <EditInsight />
          </div>
        </Transition>
      );
    return (
      <div>
        <Container fluid>
          <div ref={this.handleContextRef}>
            <Row style={{ marginTop: 25 }}>
              <Col md="6">{InsightForm}</Col>
              <Col md="6">
                <AuthorInsights header={"MY PUBLISHED INSIGHTS"} />
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  authUser: state.sessionState.authUser,
  currentUser: state.user.currentUserProps,
  currentAllInsights: state.insights.currentAllInsightsProps,
  visibleState: state.insights.editInsight
});
export default connect(mapStateToProps)(Admin);
