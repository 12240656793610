import React, { Component } from "react";
import { connect } from "react-redux";
import { Container, Col, Row } from "reactstrap";
import AllLegalItemsList from "./AllLegalItemsList";
import MyLegalRegisterList from "./MyLegalRegisterList";

class MyLegalRegisterPage extends Component {
  render() {
    return (
      <Container fluid style={{ marginBottom: 100, marginTop: 25 }}>
        <Row>
          <Col sm="6">
            <AllLegalItemsList />
          </Col>
          <Col sm="6">
            <MyLegalRegisterList />
          </Col>
        </Row>
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  currentUser: state.user.currentUserProps
});

export default connect(mapStateToProps)(MyLegalRegisterPage);
