import React from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";

import RegistrationForm from "./RegistrationForm.js";

export function RegistrationPage(props) {
  if (props.currentUser === !null) {
    return <Redirect to="/" />;
  }

  return (
    <div className="registration-page">
      <RegistrationForm />
    </div>
  );
}

const mapStateToProps = state => ({
  currentUser: state.user.currentUserProps
});

export default connect(mapStateToProps)(RegistrationPage);
