import React from "react";
import { PrivacyPolicy } from "./PrivacyPolicy";
import { TermsAndConditions } from "./TermsAndConditions";
import { Row, Col } from "reactstrap";

export const PrivacyPolicyPage = () => (
  <Row>
    <Col />
    <Col sm="10">
      <PrivacyPolicy />
    </Col>
    <Col />
  </Row>
);
export const TermsAndConditionPage = () => (
  <Row>
    <Col />
    <Col sm="10">
      <TermsAndConditions />
    </Col>
    <Col />
  </Row>
);
