import React, { Component } from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import HeaderNav from "./components/HeaderNav";
import Footer from "./components/Footer";
// import Browse from "./components/Browse";
import LandingPage from "./components/LandingPage/index";
// import ChristanTestPage from "./components/Tests/ChristanTestPage";
import NickTestPage from "./components/Tests/NickTestPage";
import Dashboard from "./components/Dashboard/Dashboard";
import Admin from "./components/Dashboard/Admin";
import LoginForm from "./components/LoginForm";
// import Account from "./components/MyAccount/Account";
// import EditInsight from "./components/Insights/EditInsight";
import PasswordForgetPage from "./components/Session/PasswordForget";
import RegistrationPage from "./components/RegistrationPage";
import StorageUploadForm from "./components/StorageUploadForm";
import withAuthentication from "./components/Session/Authentication";
import withTracker from "./components/Session/withTracker";
import "./App.css";

// Notifications
import { NotificationContainer } from "react-notifications";
import "react-notifications/lib/notifications.css";
import { Notify } from "react-redux-notify";
import "react-redux-notify/dist/ReactReduxNotify.css";

// Pages
import {
  PrivacyPolicyPage,
  TermsAndConditionPage,
} from "./components/TermsAndConditions";
import MyLegalRegisterPage from "./components/LegalRegister/index";
import MyLegalRegisterItemDetailPage from "./components/LegalRegister/MyLegalRegisterItemDetailPage";
import LegalRegisterUpload from "./components/LegalRegister/LegalRegisterUpload";
import LegalRegisterEdit from "./components/LegalRegister/LegalRegisterEdit";
import LegalRegisterManager from "./components/Dashboard/LegalRegisterManager";
import AdminLegalRegisterItemDetailPage from "./components/LegalRegister/AdminView/AdminLegalRegisterItemDetailPage";
import LegalRegisterUser from "./components/LegalRegister/AdminView/LegalRegisterUser";
import Chatroom from "./components/Forum/Chatroom";
import Calendar from "./components/Calendar/index";
import Chatrooms from "./components/Forum/Chatrooms";
import CreateChatroom from "./components/Forum/CreateChatroom";
import AdminCMS from "./components/Analytics";
import CMSdashboard from "./components/Dashboard/Dashboard";
import CMSForum from "./components/Analytics/forumAdmin";
import MyAccount from "./components/MyAccount";
import NewUserLanding from "./components/MyAccount/NewUserLanding";
import ExpiredUserLanding from "./components/MyAccount/expiredUserPage";
import PublicProfile from "./components/MyAccount/PublicProfile";
import InsightPage from "./components/Insights/InsightPage";
import University from "./components/University";

// Browse & Categories
import CategoryGeneral from "./components/Categories/Category";
import CategoryUpload from "./components/Categories/CategoryUpload";
import CategorySidebar1 from "./components/Categories/CategorySidebar1";

class App extends Component {
  render() {
    return (
      <Router style={{ minWidth: "100vh" }}>
        {/* <Switch> */}
        <div className="App-background" style={{ backgroundColor: "#dfeff4" }}>
          <HeaderNav />
          <div style={{ paddingTop: 0, minHeight: "90vh" }}>
            <Route exact path="/auth:id" component={withTracker(LandingPage)} />
            <Route exact path="/" component={withTracker(LandingPage)} />
            <Route
              exact
              path="/NewUser"
              component={withTracker(NewUserLanding)}
            />
            <Route
              exact
              path="/ExpiredUser"
              component={withTracker(ExpiredUserLanding)}
            />
            <Route
              exact
              path="/InsightPage/:id"
              component={withTracker(InsightPage)}
            />
            <Route exact path="/Admin" component={withTracker(Admin)} />
            <Route exact path="/LogIn" component={withTracker(LoginForm)} />
            <Route
              exact
              path="/PasswordReset"
              component={withTracker(PasswordForgetPage)}
            />
            {/* <Route exact path="/Profile" component={withTracker(Account)} /> */}
            <Route
              exact
              path="/browse"
              component={withTracker(CategorySidebar1)}
            />
            <Route
              exact
              path={`/Category/:category`}
              component={CategoryGeneral}
            />
            <Route
              exact
              path="/Categories"
              component={withTracker(CategoryUpload)}
            />
            <Route exact path={"/Profile/:id"} component={PublicProfile} />
            <Route
              exact
              path="/Register"
              component={withTracker(RegistrationPage)}
            />
            <Route
              exact
              path="/Storage"
              component={withTracker(StorageUploadForm)}
            />
            <Route exact path="/dashboard" component={withTracker(Dashboard)} />
            <Route
              exact
              path="/LegalRegister"
              component={withTracker(MyLegalRegisterPage)}
            />
            <Route
              exact
              path="/LegalRegisterDash"
              component={withTracker(LegalRegisterManager)}
            />
            <Route
              exact
              path="/LegalRegister/user/:id"
              component={withTracker(LegalRegisterUser)}
            />

            <Route
              exact
              path="/EditLegalItem"
              component={withTracker(MyLegalRegisterItemDetailPage)}
            />

            <Route
              exact
              path="/LegalRegisterUpload"
              component={withTracker(LegalRegisterUpload)}
            />
            <Route
              exact
              path="/LegalRegisterEdit"
              component={withTracker(LegalRegisterEdit)}
            />
            <Route
              exact
              path="/AdminLegalRegister"
              component={withTracker(AdminLegalRegisterItemDetailPage)}
            />
            <Route
              exact
              path="/University"
              component={withTracker(University)}
            />
            <Route exact path="/Nick" component={NickTestPage} />
            {/* <Route exact path="/Christan" component={ChristanTestPage} /> */}
            <Route exact path="/Forum" component={Chatroom} />
            <Route exact path="/Chatrooms" component={Chatrooms} />
            <Route exact path="/Calendar" component={Calendar} />
            <Route exact path="/CreateChatroom" component={CreateChatroom} />
            <Route exact path="/Analytics" component={AdminCMS} />
            <Route exact path="/CMS/dashboard" component={CMSdashboard} />
            <Route exact path="/CMS/Forum_admin" component={CMSForum} />
            <Route exact path="/MyAccount" component={MyAccount} />
            <Route exact path="/PrivacyPolicy" component={PrivacyPolicyPage} />
            <Route
              exact
              path="/TermsAndConditions"
              component={TermsAndConditionPage}
            />
          </div>
          <NotificationContainer />
          <Notify />
          <Footer />
        </div>
        {/* </Switch> */}
      </Router>
    );
  }
}

export default withAuthentication(App);
