import React, { Component } from "react";
import styled from "styled-components";
import { Container, Row, Col } from "reactstrap";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { Dimmer, Loader } from "semantic-ui-react";

const ContainerST = styled.div`
    position: absolute;
    background-color: red;
    width: 100%;
    height 125px;
    margin-top: 0;
`;
const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(186px, 1fr));
`;
const GridItem = styled.div`
  // border-style: solid;
  // border-width: 1px;
  margin: 3px;
  padding: 10px;
  box-shadow: 3px 3px 10px -1px rgba(0, 0, 0, 0.43);
  border-radius: 10px;
  text-align: center;
  align-items: center;
  justify-content: center;
  :hover {
    // background-color: grey;
    border-color: white;
    box-shadow: inset 3px 3px 10px -1px rgba(0, 0, 0, 0.43);
  }
`;
const Title = styled.p`
  font-weight: 400;
  font-size: 20px;
`;
const GridTop = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, auto minmax(186px, 1fr));
  // grid-template-columns: repeat(auto-fill, minmax(186px, 1fr));
`;

// needs to accept props by selecting a profile name, lets try from the chatrooms first.
// props need to be from a SelectedUser reducer in the state.

class PublicProfile extends Component {
  render() {
    console.log(this);

    const { selectedUser } = this.props;
    console.log(selectedUser);
    const ObjectisNotEmpty = Object.values(selectedUser).length < 1;
    if (ObjectisNotEmpty === true) {
      return (
        <Dimmer inverted active>
          <Loader size="large">
            Loading...{" "}
            <p>
              If taking a while, take me ... <Link to="/myaccount">Home</Link>
            </p>
          </Loader>
        </Dimmer>
      );
    }

    return (
      <div style={{ marginTop: 0, backgroundColor: "" }}>
        <ContainerST />
        <Container style={{ padding: 0 }}>
          <Row style={{ padding: 0, margin: 0, paddingTop: 25 }}>
            <Col style={{ margin: 0 }}>
              <GridTop>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <div style={{ width: 175, height: 175 }}>
                    <img
                      src={selectedUser.profilePhoto}
                      alt="user"
                      style={{
                        borderRadius: "100%",
                        height: "100%",
                        width: "100%"
                      }}
                    />
                  </div>
                </div>
                <div style={{ textAlign: "center" }}>
                  <h1
                    style={{
                      fontFamily: "Avenir next",
                      fontWeight: 100,
                      marginBottom: 0
                    }}
                  >
                    {selectedUser.displayName}
                  </h1>
                  <p>{selectedUser.company}</p>
                </div>
              </GridTop>
            </Col>
          </Row>
          <Row style={{ paddingTop: 25 }}>
            <Col style={{ margin: 0 }}>
              <Grid>
                <GridItem>
                  <Title>Job Role</Title>
                  <p>{selectedUser.jobRole}</p>
                </GridItem>
                <GridItem>
                  <Title>User Type</Title>
                  <p>{selectedUser.userType}</p>
                </GridItem>
                <GridItem>
                  <Title>Saved Insights</Title>
                  <p>{Object.keys(selectedUser.wishlist).length}</p>
                </GridItem>
              </Grid>
            </Col>
          </Row>
          <Row>
            <Col style={{ margin: 0 }}>
              <GridItem>
                <Title>Bio</Title>
                <p>{selectedUser.bio}</p>
              </GridItem>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  selectedUser: state.user.selectedUser
});

export default connect(mapStateToProps)(PublicProfile);
