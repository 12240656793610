import { firebase, db } from "../FireBase/firebase";
import store from "../store";
import {
  SET_CATEGORIES,
  SAVE_DASHBOARD_MESSAGE,
  SET_INDUSTRIES,
  SET_LEGAL_CATEGORIES,
  SET_LEGAL_REGULATORS,
  SET_SUPPLIER,
  SET_COMPANY_TYPE,
  CHANGE_ACTIVE_ITEM,
  SET_PARENT_CATEGORIES
} from "./types";

export const snapshotToArray = snapshot => {
  let returnArr = [];

  snapshot.forEach(childSnapshot => {
    let item = childSnapshot.val();
    item.key = childSnapshot.key;
    returnArr.push(item);
  });

  return returnArr;
};

export const setCategory = (categoryName, categorySlug, categoryVisable) => {
  db.ref(`/config/categories/${categorySlug}`).update({
    categoryName,
    categorySlug,
    categoryVisable
  });
};

export const deleteCategory = categoryPath => {
  firebase
    .database()
    .ref(`/config/categories/${categoryPath}`)
    .remove();
};

export const getCategories = () => {
  firebase
    .database()
    .ref(`/config/categories/`)
    .on("value", snap => {
      const cats = snapshotToArray(snap);
      store.dispatch(setCategoriesToReducer(Array.from(cats)));
    });
};

export const setCategoriesToReducer = categories => ({
  type: SET_CATEGORIES,
  categories
});

// parent categories
export const setParentCategory = (parentCategoryName, childCategories) => {
  db.ref(`/config/parentCategories/${parentCategoryName}`).update({
    parentCategoryName,
    childCategories,
    isActive: true
  });
};

export const setParentCategoryActivity = (parentCategoryName, isActive) => {
  db.ref(`/config/parentCategories/${parentCategoryName}`).update({
    isActive
  });
};

export const deleteParentCategory = parentCategoryPath => {
  firebase
    .database()
    .ref(`/config/parentCategories/${parentCategoryPath}`)
    .remove();
};

export const getParentCategories = () => {
  firebase
    .database()
    .ref(`/config/parentCategories/`)
    .on("value", snap => {
      const cats = snapshotToArray(snap);
      store.dispatch(setParentCategoriesToReducer(Array.from(cats)));
    });
};

export const setParentCategoriesToReducer = parentCategories => ({
  type: SET_PARENT_CATEGORIES,
  parentCategories
});

export const setDashboardMessage = DashboardMessage => {
  db.ref("config/").update({ DashboardMessage });
};

export const getDashboardMessage = () => {
  const ref = firebase.database().ref("config/DashboardMessage");
  ref.on("value", snap => {
    store.dispatch(saveDashboardMessage(snap.val()));
  });
};

export const saveDashboardMessage = dashboardMessage => ({
  type: SAVE_DASHBOARD_MESSAGE,
  dashboardMessage
});

export const getIndustries = () => {
  firebase
    .database()
    .ref(`/config/industries/`)
    .on("value", snap => {
      const cats = snapshotToArray(snap);
      store.dispatch(setIndustriesToReducer(Array.from(cats)));
    });
};

export const deleteIndustry = industryPath => {
  firebase
    .database()
    .ref(`/config/industries/${industryPath}`)
    .remove();
};

export const setIndustry = (industryPath, industryName, isActive) => {
  db.ref(`/config/industries/${industryPath}`).update({
    industryPath,
    industryName,
    isActive
  });
};

export const setIndustriesToReducer = industries => ({
  type: SET_INDUSTRIES,
  industries
});

export const getLegalCategories = () => {
  firebase
    .database()
    .ref(`/config/legalCategories/`)
    .on("value", snap => {
      const cats = snapshotToArray(snap);
      store.dispatch(setLegalCategoriesToReducer(Array.from(cats)));
    });
};

export const setLegalCategoriesToReducer = legalCategories => ({
  type: SET_LEGAL_CATEGORIES,
  legalCategories
});

export const setLegalCategory = (
  legalCategoryPath,
  legalCategoryName,
  isActive
) => {
  db.ref(`/config/legalCategories/${legalCategoryPath}`).update({
    legalCategoryPath,
    legalCategoryName,
    isActive
  });
};

export const deleteLegalCategory = legalCategoryPath => {
  firebase
    .database()
    .ref(`/config/legalCategories/${legalCategoryPath}`)
    .remove();
};

// regulators

export const getLegalRegulators = () => {
  firebase
    .database()
    .ref(`/config/legalRegulators/`)
    .on("value", snap => {
      const cats = snapshotToArray(snap);
      store.dispatch(setLegalRegulatorsToReducer(Array.from(cats)));
    });
};

export const setLegalRegulatorsToReducer = legalRegulators => ({
  type: SET_LEGAL_REGULATORS,
  legalRegulators
});

export const setLegalRegulator = (
  legalRegulatorPath,
  legalRegulatorName,
  isActive
) => {
  db.ref(`/config/legalRegulators/${legalRegulatorPath}`).update({
    legalRegulatorPath,
    legalRegulatorName,
    isActive
  });
};

export const deleteLegalRegulator = legalRegulatorPath => {
  firebase
    .database()
    .ref(`/config/legalRegulators/${legalRegulatorPath}`)
    .remove();
};

// Suppliers

export const setSupplier = (supplierPath, supplierName) => {
  db.ref(`/config/adminInfo/suppliers/${supplierPath}`).update({
    supplierPath,
    supplierName
  });
};

export const getSuppliers = () => {
  firebase
    .database()
    .ref(`/config/adminInfo/suppliers`)
    .on("value", snap => {
      const suppliers = snapshotToArray(snap);
      store.dispatch(setSuppliersToReducer(Array.from(suppliers)));
    });
};

export const setSuppliersToReducer = suppliers => ({
  type: SET_SUPPLIER,
  suppliers
});

export const deleteSupplier = supplierPath => {
  firebase
    .database()
    .ref(`/config/adminInfo/suppliers/${supplierPath}`)
    .remove();
};

// Company Types

export const setCompanyType = (companyTypePath, companyTypeName) => {
  db.ref(`/config/adminInfo/companyTypes/${companyTypePath}`).update({
    companyTypePath,
    companyTypeName
  });
};

export const getCompanyTypes = () => {
  firebase
    .database()
    .ref(`/config/adminInfo/companyTypes`)
    .on("value", snap => {
      const companyTypes = snapshotToArray(snap);
      store.dispatch(setCompanyTypesToReducer(Array.from(companyTypes)));
    });
};

export const setCompanyTypesToReducer = companyTypes => ({
  type: SET_COMPANY_TYPE,
  companyTypes
});

export const deleteCompanyType = companyTypePath => {
  firebase
    .database()
    .ref(`/config/adminInfo/companyTypes/${companyTypePath}`)
    .remove();
};

// changes the active item in /myAccount which displays a different page in the sub nav
export const changeActiveItem = activeItem => {
  store.dispatch({ type: CHANGE_ACTIVE_ITEM, activeItem });
};
