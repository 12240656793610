import React, { Component } from "react";
import { connect } from "react-redux";
import { uploadFile } from "../actions/storage";

class StorageUploadForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      file: null
      // uploading: false,
      // percent: 0,,
      // error: ""
    };
    this.handleFileSelect = this.handleFileSelect.bind(this);
    this.handleFileUpload = this.handleFileUpload.bind(this);
  }

  handleFileSelect(e) {
    this.setState({
      file: e.target.files[0]
    });
  }
  handleFileUpload(e) {
    e.preventDefault();
    // this.setState({ uploading: true });
    console.log(
      "Going to start upload of " +
        this.state.file.name +
        " by  " +
        this.props.currentUser.displayName
    );
    uploadFile(this.state.file, this.props.currentUser.displayName);
  }

  render() {
    return (
      <div>
        <form onSubmit={this.handleFileUpload}>
          <title>UploadForm</title>
          <label>Title</label>
          <input type="file" onChange={this.handleFileSelect} />
          <button type="submit">Upload</button>
        </form>
      </div>
    );
  }
}

//https://reactrocket.com/post/draft-js-persisting-content/

const mapStateToProps = state => ({
  currentUser: state.user.currentUserProps
});

export default connect(mapStateToProps)(StorageUploadForm);
