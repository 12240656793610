const initialState = {
  subscribers: []
};

export default function subscriberReducer(state = initialState, action) {
  switch (action.type) {
    case "SET_CURRENT_SUBSCRIBERS": {
      return {
        ...state,
        subscribers: [...action.subscribers]
      };
    }
    default:
      return state;
  }
}
