import React, { Component } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import UniConfig from "./admin/config";
import { Col } from "reactstrap";

// Actions
import { getCourses } from "../../actions/university";

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  margin-top: 25px;
`;

const GridItem = styled.div`
  padding: 10px;
  text-align: center;
  margin: 25px;
  border-style: solid;
  border-width: 1px 1px;
  border-radius: 7px;
  border-color: rgba(0, 0, 0, 0.3);
  background-color: white;
  box-shadow: 10px 10px 33px -12px rgba(0, 0, 0, 0.75);
  :hover {
    opacity: 0.5;
    box-shadow: 10px 10px 33px -12px rgba(0, 0, 0, 0.25);
  }
`;
const CardMeta = styled.div`
  margin-top: 15px;
`;
const CardPic = styled.div`
  padding: 0px;
  margin: -10px;
`;
const CardTitle = styled.p`
  font-weight: bold;
`;
const CardDescription = styled.p``;
// const CardProgress = styled.p``;
const CardSkillLevel = styled.p`
  color: blue;
`;

const CardInstructors = styled.p``;
// const CardSections = styled.p``;
// const CardLessons = styled.p``;

class University extends Component {
  state = { data: [] };

  componentDidMount = () => {
    getCourses();
    // this.setState({ data }); // TODO: This is just temporary dummy data I need to get rid of
  };

  renderData = () => {
    const { courses } = this.props;
    return Object.values(courses).map((course, idx) => {
      const {
        courseTitle,
        courseSkillLevel,
        courseDescription,
        courseImage,
        courseInstructors
      } = course;

      return (
        <GridItem key={idx}>
          <CardPic>
            <img
              src={
                courseImage
                  ? courseImage
                  : require(`../../img/University/2.png`)
              }
              style={{ width: "100%", borderRadius: "6px 6px 0 0" }}
              alt="testimage"
            />
          </CardPic>
          <CardMeta>
            <CardTitle>{courseTitle}</CardTitle>
            <CardDescription>{courseDescription}</CardDescription>
            <CardInstructors>
              Taught by:{" "}
              {courseInstructors
                ? courseInstructors.map((name, i) => {
                    return i === courseInstructors.length - 1 ? (
                      <span>{`${name}`}</span>
                    ) : (
                      <span>{`${name}, `}</span>
                    );
                  })
                : "Icon"}
            </CardInstructors>
            <CardSkillLevel>{`Skill Level: ${courseSkillLevel}`}</CardSkillLevel>
            {/* if user has saved this course, display their progress */}
            {/* <CardProgress>10 %</CardProgress> */}
          </CardMeta>
        </GridItem>
      );
    });
  };

  render() {
    return (
      <div>
        <Col sm="11" style={{ margin: "0 auto" }}>
          <Grid>{this.renderData()}</Grid>
        </Col>
        <Col sm="11" style={{ margin: "0 auto" }}>
          <UniConfig />
        </Col>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  courses: state.university.courses
});

export default connect(mapStateToProps)(University);
