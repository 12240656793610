import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Form,
  Button,
  Input,
  Select,
  List,
  Icon,
  TextArea
} from "semantic-ui-react";
import styled from "styled-components";

// Actions
import {
  createLesson,
  removeLesson,
  setActiveLessonCourse
} from "../../../../actions/university";
// import { object } from "firebase-functions/lib/providers/storage";

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-top: 25px;
`;

const GridItem = styled.div`
  padding: 10px;
  test-align: center;
  margin: 25px;
  border-style: solid;
  border-width: 1px 1px;
  border-radius: 7px;
  border-color: rgba(0, 0, 0, 0.3);
  background-color: white;
  box-shadow: 10px 10px 33px -12px rgba(0, 0, 0, 0.75);
`;

class Lessons extends Component {
  state = {
    activeLessonCourseKey: "",
    activeLessonSectionKey: "",
    lessonTitle: "",
    lessonVideoURL: "",
    lessonDocument: "",
    lessonContent: "",
    lessonErrors: false
  };

  handleLessonSubmit = e => {
    if (
      !this.state.activeLessonCourseKey ||
      !this.state.activeLessonSectionKey
    ) {
      return this.setState({ lessonErrors: true });
    }
    e.preventDefault();
    createLesson(
      this.state.lessonTitle,
      this.props.activeLessonCourse.key,
      this.state.activeLessonSectionKey
    );
    this.setState({
      lessonTitle: "",
      lessonContent: "",
      lessonDocument: "",
      lessonErrors: false
    });
  };

  handleInputChange = (e, data) => {
    this.setState({ [data.name]: data.value });
  };

  handleLessonCourse = (e, data) => {
    this.setState({
      activeLessonCourseKey: data.value,
      // set null every time you select a lesson course so that it meets the condition in the map below
      activeLessonSectionKey: null
    });
    setActiveLessonCourse(data.value);
  };

  handleLessonSection = (e, data) => {
    this.setState({ activeLessonSectionKey: data.value });
  };

  handleRemoveLesson = lesson => {
    removeLesson(lesson.courseKey, lesson.sectionKey, lesson.key);
  };

  render() {
    const allCourses = [];
    const lessonSections = [];

    this.props.courses &&
      this.props.courses.map((course, i) => {
        return allCourses.push({
          key: i,
          text: course.courseTitle,
          value: course.key
        });
      });

    this.props.activeLessonCourse &&
      this.props.activeLessonCourse.sections &&
      Object.values(this.props.activeLessonCourse.sections).map(
        (section, i) => {
          return lessonSections.push({
            key: i,
            text: section.sectionTitle,
            value: section.key
          });
        }
      );
    return (
      <Grid>
        <GridItem>
          <Form onSubmit={this.handleLessonSubmit}>
            <h2>Create a lesson</h2>
            <Form.Field
              control={Select}
              label="Select A Course"
              name="selectCourse"
              options={allCourses}
              placeholder="Select a course ..."
              onChange={this.handleLessonCourse}
              value={this.state.activeLessonCourseKey}
              required
              error={
                this.state.lessonErrors && !this.state.activeLessonCourseKey
                  ? true
                  : false
              }
            />
            <Form.Field
              control={Select}
              label="Select A Course Section"
              name="selectSection"
              options={lessonSections}
              placeholder="Select a course section ..."
              onChange={this.handleLessonSection}
              value={this.state.activeLessonSectionKey}
              required
              error={
                this.state.lessonErrors && !this.state.activeLessonSectionKey
                  ? true
                  : false
              }
            />
            <Form.Field
              control={Input}
              label="Lesson Title"
              name="lessonTitle"
              placeholder="Enter a lesson title ..."
              onChange={this.handleInputChange}
              value={this.state.lessonTitle}
              required
            />
            <Form.Field
              control={Input}
              label="Lesson Video"
              name="lessonVideoURL"
              placeholder="Enter a video URL ..."
              onChange={this.handleInputChange}
              value={this.state.lessonVideoURL}
              // required
            />
            <p style={{ fontFamily: "Avenir Next", fontWeight: "bold" }}>
              Document Upload
            </p>
            <input
              style={{ display: "block", marginBottom: 15 }}
              type="file"
              name="lessonDocumment"
              id="lessonDocument"
              // onChange={}
            />
            <Form.Field
              control={TextArea}
              label="Lesson Content"
              name="lessonContent"
              placeholder="Enter some text ..."
              onChange={this.handleInputChange}
              value={this.state.Content}
              // required
            />
            <Button type="submit">Submit</Button>
          </Form>
        </GridItem>
        <GridItem>
          <h3>Lessons</h3>
          <List>
            {/* if the user has only selected a lesson course, show them all course sections */}
            {this.props.activeLessonCourse &&
            !this.state.activeLessonSectionKey ? (
              <div>
                <h5 style={{ color: "green" }}>
                  {this.props.activeLessonCourse.courseTitle}
                </h5>
                {this.props.activeLessonCourse.sections ? (
                  <div>
                    <List divided relaxed>
                      {Object.values(
                        this.props.activeLessonCourse.sections
                      ).map((section, idx) => {
                        return (
                          <div key={idx}>
                            <h5>{section.sectionTitle}</h5>
                            {section.lessons ? (
                              Object.values(section.lessons).map(
                                (lesson, i) => {
                                  return (
                                    <List.Item
                                      key={i}
                                      style={{
                                        margin: "15px 0 15px 15px"
                                      }}
                                    >
                                      • {lesson.lessonTitle}
                                      <Icon
                                        name="delete"
                                        style={{
                                          color: "red",
                                          cursor: "pointer"
                                        }}
                                        onClick={() =>
                                          this.handleRemoveLesson(lesson)
                                        }
                                      />
                                    </List.Item>
                                  );
                                }
                              )
                            ) : (
                              <p style={{ marginTop: 10 }}>No Lessons ...</p>
                            )}
                            <hr />
                          </div>
                        );
                      })}
                    </List>
                  </div>
                ) : (
                  <div>No Sections ...</div>
                )}
              </div>
            ) : //  if the user has selected a particular course section, just show them that section and its lessons}
            this.props.activeLessonCourse &&
              this.state.activeLessonSectionKey ? (
              <div>
                <h5 style={{ color: "green" }}>
                  {this.props.activeLessonCourse.courseTitle}
                </h5>
                {this.props.activeLessonCourse.sections ? (
                  <div>
                    <List divided relaxed>
                      {Object.values(
                        this.props.activeLessonCourse.sections
                      ).map((section, idx) => {
                        // check and render only the section that the user has selected
                        if (section.key === this.state.activeLessonSectionKey) {
                          return (
                            <div key={idx}>
                              <h5>{section.sectionTitle}</h5>
                              {section.lessons ? (
                                Object.values(section.lessons).map(
                                  (lesson, i) => {
                                    return (
                                      <List.Item
                                        key={i}
                                        style={{
                                          margin: "15px 0 15px 15px"
                                        }}
                                      >
                                        • {lesson.lessonTitle}
                                        <Icon
                                          name="delete"
                                          style={{
                                            color: "red",
                                            cursor: "pointer"
                                          }}
                                          onClick={() =>
                                            this.handleRemoveLesson(lesson)
                                          }
                                        />
                                      </List.Item>
                                    );
                                  }
                                )
                              ) : (
                                <p style={{ marginTop: 10 }}>No Lessons ...</p>
                              )}
                              <hr />
                            </div>
                          );
                        } else {
                          return null;
                        }
                      })}
                    </List>
                  </div>
                ) : (
                  <div>No Sections ...</div>
                )}
              </div>
            ) : (
              <div>Select a course and course section</div>
            )}
          </List>
        </GridItem>
      </Grid>
    );
  }
}

const mapStateToProps = state => ({
  courses: state.university.courses,
  activeLessonCourse: state.university.activeLessonCourse
});

export default connect(mapStateToProps)(Lessons);
