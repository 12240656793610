import React, { Component } from "react";
import { connect } from "react-redux";
import { Col, Row } from "reactstrap";
import { Segment, Button, Icon } from "semantic-ui-react";
import { chatroomStyles } from "../../styles";
import { setMessage } from "../../actions/Posts";
import { setUserChatroomNotifications } from "../../actions/subscribers";
import {
  createNotification,
  NOTIFICATION_TYPE_WARNING
} from "react-redux-notify";
import { editorStateFromRaw, editorStateToJSON } from "megadraft";
import { EditorState } from "draft-js";
import IconEditor from "./Editor";

const emptyCommentWarning = {
  message: "Sorry cannot submit an empty comment",
  type: NOTIFICATION_TYPE_WARNING,
  duration: 3000,
  canDismiss: true,
  icon: <Icon name="warning sign" />
};

class PostEditor extends Component {
  constructor(props) {
    super(props);

    this.state = {
      editorState: EditorState.createEmpty()
    };
  }

  onChange = editorState => {
    // handle the update of the editor state
    this.setState({ editorState });
  };

  // focus = () => {
  //   // Handles the editor focus #won't work without this
  //   this.editor.focus();
  // };

  createPost = () => {
    // Deconstruct the text from the editorState object
    const currentContent = this.state.editorState.getCurrentContent();
    const comment = currentContent.getPlainText();
    // Check its isn't empty
    const { createNotification } = this.props;
    if (comment === "" || comment.replace(/\s+/g, "").length === 0) {
      // If it is invoke notification
      console.log("error, comment is empty");
      return createNotification(emptyCommentWarning);
    }

    // Define properties to send to FB
    const { authUser } = this.props;
    const uid = authUser.uid;
    const {
      displayName,
      profilePhoto,
      firstName,
      lastName
    } = this.props.currentUser;
    const { chatroomTitle, chatroomKey } = this.props.CurrentChatroom;
    const message = editorStateToJSON(this.state.editorState);
    // Dispatch the message Object to FB
    setMessage(
      message,
      uid,
      displayName,
      profilePhoto || "",
      chatroomTitle,
      chatroomKey,
      firstName,
      lastName
    );
    // if user has turned off notifications, dont subscribe them
    if (
      this.props.currentUser.notifications &&
      this.props.currentUser.notifications.chatrooms &&
      this.props.currentUser.notifications.chatrooms[chatroomKey] &&
      this.props.currentUser.notifications.chatrooms[chatroomKey].subscribed ===
        false
    ) {
    } else {
      setUserChatroomNotifications(chatroomKey, uid, chatroomTitle);
    }

    // Reset the state of the editor to null
    this.setState({
      editorState: editorStateFromRaw(null)
    });
  };

  render() {
    return (
      <Col style={chatroomStyles.postStyle}>
        <Row>
          <Col lg="11" sm="10" xs="9">
            <div>
              <Segment style={{ boxShadow: "none", borderRadius: 7 }}>
                <IconEditor
                  editorState={this.state.editorState}
                  onChange={this.onChange}
                  ref={element => {
                    this.editor = element;
                  }}
                />
              </Segment>
            </div>
          </Col>
          <Col
            lg="1"
            sm="2"
            xs="3"
            style={{
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column"
            }}
          >
            <Button
              primary
              circular
              icon="send"
              onClick={this.createPost}
              style={{ marginTop: 10 }}
            />
          </Col>
        </Row>
      </Col>
    );
  }
}

const mapStateToProps = state => ({
  authUser: state.sessionState.authUser,
  currentUser: state.user.currentUserProps,
  CurrentChatroom: state.chatroomsState.currentlySelectedChatroom
});

const mapDispatchToProps = dispatch => ({
  createNotification: config => {
    dispatch(createNotification(config));
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PostEditor);
