import {
  SET_CURRENT_ALL_CHATROOMS,
  CURRENTLY_SELECTED_CHATROOM,
  SET_ALL_DISPLAY_NAMES,
  SET_CURRENT_CHATROOM_POSTS,
  UPDATE_POST,
  DELETE_POST
} from "../actions/types";

const INITIAL_STATE = {
  currentAllChatroomsProps: [],
  currentlySelectedChatroom: {},
  currentlySelectedChatroomPosts: {},
  displayNames: {}
};

export default function chatroomsReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case SET_CURRENT_ALL_CHATROOMS: {
      return {
        ...state,
        currentAllChatroomsProps: [...action.allChatrooms]
      };
    }
    case CURRENTLY_SELECTED_CHATROOM: {
      return {
        ...state,
        currentlySelectedChatroom: action.CurrentChatroom
      };
    }
    case SET_CURRENT_CHATROOM_POSTS: {
      return {
        ...state,
        currentlySelectedChatroomPosts: action.currentRoomPosts
      };
    }
    case UPDATE_POST: {
      return {
        ...state,
        currentlySelectedChatroomPosts: {
          ...state.currentlySelectedChatroomPosts,
          [action.key]: {
            ...state.currentlySelectedChatroomPosts[action.key],
            message: action.content,
            updateTime: action.dateNow
          }
        }
      };
    }
    case DELETE_POST: {
      let newState = Object.keys(state.currentlySelectedChatroomPosts).reduce(
        (r, e) => {
          if (e !== action.postKey)
            r[e] = state.currentlySelectedChatroomPosts[e];
          return r;
        },
        {}
      );
      return {
        ...state,
        currentlySelectedChatroomPosts: newState
      };
    }

    case SET_ALL_DISPLAY_NAMES: {
      return {
        ...state,
        displayNames: action.namesObject
      };
    }
    default:
      return state;
  }
}
