import store from "../store";
import ReactGA from "react-ga";
import { firebase } from "../FireBase/firebase";
import uuidv4 from "uuid/v4";
import {
  addImageRecord,
  addStorageRecord,
  newStorageKey,
  removeFileFromInsight
} from "./storage";

export const snapshotToArray = snapshot => {
  let returnArr = [];

  snapshot.forEach(childSnapshot => {
    let item = childSnapshot.val();
    item.key = childSnapshot.key;
    returnArr.push(item);
  });

  return returnArr;
};

export const SET_CURRENT_INSIGHT = "SET_CURRENT_INSIGHT";
export const setCurrentInsightProps = insight => ({
  type: SET_CURRENT_INSIGHT,
  insight
});

export const REMOVE_CURRENT_INSIGHT = "REMOVE_CURRENT_INSIGHT";
export const removeCurrentInsightProps = () => ({
  type: REMOVE_CURRENT_INSIGHT
});

export const SET_CURRENT_ALL_INSIGHTS = "SET_CURRENT_ALL_INSIGHTS";
export const setCurrentAllInsightsProps = (
  allInsights,
  categories,
  query,
  tags
) => ({
  type: SET_CURRENT_ALL_INSIGHTS,
  allInsights,
  categories,
  query,
  tags
});

export const SET_CURRENT_AUTHOR_INSIGHTS = "SET_CURRENT_AUTHOR_INSIGHTS";
export const setCurrentAuthorInsights = authoredInsights => ({
  type: SET_CURRENT_AUTHOR_INSIGHTS,
  authoredInsights
});

export const SET_CURRENT_ALL_MY_INSIGHTS = "SET_CURRENT_ALL_MY_INSIGHTS";
export const setCurrentAllMyInsights = myInsights => ({
  type: SET_CURRENT_ALL_MY_INSIGHTS,
  myInsights
});

export const ADD_MY_INSIGHT = "ADD_MY_INSIGHT";
export const addMyInsight = myInsight => ({
  type: ADD_MY_INSIGHT,
  myInsight
});

export const REMOVE_MY_INSIGHT = "REMOVE_MY_INSIGHT";
export const removeMyInsight = myInsight => ({
  type: REMOVE_MY_INSIGHT,
  myInsight
});
export const SET_CURRENT_EDIT_INSIGHT = "SET_CURRENT_EDIT_INSIGHT";
export const setCurrentEditInsight = insight => ({
  type: SET_CURRENT_EDIT_INSIGHT,
  insight
});

export const TOGGLE_EDIT_STATE = "TOGGLE_EDIT_STATE";
export const toggleEditState = () => ({
  type: TOGGLE_EDIT_STATE
});

export const REMOVE_INSIGHT = "REMOVE_INSIGHT";
export const removeInsight = insightKey => ({
  type: REMOVE_INSIGHT,
  insightKey
});

export const getAllInsights = (categories, query, tags) => {
  firebase
    .database()
    .ref("insights")
    .orderByChild("time")
    .on("value", snapshot => {
      const insights = snapshotToArray(snapshot);
      const allInsights = Array.from(insights.reverse());
      store.dispatch(
        setCurrentAllInsightsProps(allInsights, categories, query, tags)
      );
    });
};

export const getSavedInsights = (categories, query, tags) => {
  firebase
    .database()
    .ref("insights")
    .orderByChild("time")
    .on("value", snapshot => {
      const insights = snapshotToArray(snapshot);
      const allInsights = Array.from(insights.reverse());
      store.dispatch({
        type: "SET_SAVED_INSIGHTS",
        allInsights,
        categories,
        query,
        tags
      });
    });
};

// Search through all insights by Matching Author ID
export const getAuthorInsights = (currentAllInsights, userId) => {
  let insights = currentAllInsights;
  let authoredInsights = [];
  let i = 0;
  for (i = 0; i < insights.length; i++) {
    if (insights[i].author === userId) {
      authoredInsights.push(insights[i]);
    }
  }
  store.dispatch(setCurrentAuthorInsights(authoredInsights));
};

export const getAllMyInsights = userId => {
  firebase
    .database()
    .ref(`users/${userId}/wishlist/`)
    .on("value", snapshot => {
      const myInsights = snapshotToArray(snapshot);

      store.dispatch(setCurrentAllMyInsights(myInsights.reverse()));
    });
};

export const setInsight = (key, user) => {
  let insightTitle;
  let insightCat;
  let userUid;

  if (user) {
    // check if there is a user
    const {
      // displayName,
      uid
    } = user;
    // userDisplayName = displayName;
    userUid = uid;
  }

  firebase
    .database()
    .ref(`insights/${key}`)
    .on("value", snap => {
      const insight = snap.val();
      store.dispatch(setCurrentInsightProps(insight));
      if (insight && insight.category) {
        const category = insight.category;
        insightCat = category;
        // Tracking - Viewed By Category
        ReactGA.event({
          category: "Viewed by Category",
          action: "Clicked Insight Category",
          label: `${category}`
        });
      }

      if (insight && insight.title) {
        const title = insight.title;
        insightTitle = title;
        // Tracking - Viewed By Title
        ReactGA.event({
          category: "Viewed by Title",
          action: "Clicked Insight Title",
          label: `${title}`
        });
      }
      if (insight && insight.authorDisplayName) {
        const author = insight.authorDisplayName;
        // Tracking - Viewed By Author
        ReactGA.event({
          category: "Viewed by Author",
          action: "Clicked Insight Name",
          label: `${author}`
        });
      }
      if (user && user.adminInfo) {
        // if there is a user and a .adminInfo track their activity
        const { supplier } = user.adminInfo;
        ReactGA.event({
          category: `${supplier} Customer by InsightName`,
          action: `${userUid}`,
          label: `${insightTitle}`
        });
        ReactGA.event({
          category: `${supplier} Customer by Category`,
          action: `${userUid}`,
          label: `${insightCat}`
        });
      }
    });
};

export const setEditInsight = insight => {
  store.dispatch(setCurrentEditInsight(insight));
};

export const removeMyInsightAction = (userId, key) => {
  store.dispatch(removeMyInsight(key));
  firebase
    .database()
    .ref(`users/${userId}/wishlist/${key}`)
    .remove()
    .then(function() {
      console.log("Remove Succeeded");
      ReactGA.event({
        category: "Insight Remove",
        action: "Removed Insight from Account"
        // label: `${author}`
      });
    })
    .catch(function(error) {
      console.log("Remove failed because: " + error.message);
    });
};

//Delete insight from firebase and redux store with window alert to confirm success
export const removeInsightAction = insight => {
  console.log("Key of deleted insight is " + insight.key);
  firebase
    .database()
    .ref(`insights/${insight.key}`)
    .remove()
    .then(function() {
      const insightKey = insight.key;
      store.dispatch(removeInsight(insightKey));
    })
    .catch(function(error) {
      console.log("Delete insight failed because: " + error.message);
    });
};

export const addUserWishlistItem = (userId, insight) => {
  firebase
    .database()
    .ref(`users/${userId}/wishlist/${insight.key}`)
    .set({
      key: insight.key,
      time: insight.time
    });
  store.dispatch(addMyInsight(insight.key));
  const author = insight.authorDisplayName;
  const category = insight.category;
  const title = insight.title;
  ReactGA.event({
    category: "Added by Category",
    action: "Added Insight to account",
    label: `${category}`
  });
  ReactGA.event({
    category: "Added by Title",
    action: "Added Insight to account",
    label: `${title}`
  });
  ReactGA.event({
    category: "Added by Author",
    action: "Added Insight to account",
    label: `${author}`
  });
  // alert(`"${title}" has been added to my insights..`);
};

/**
 * addInsight Action Crerator
 * @category Actions
 * @function addInsight
 *
 * @param {string} author
 * @param {string} title
 * @param {string} subTitle
 * @param {string} category
 * @param {string} displayName
 * @param {object} featuredImage
 * @param {string} featuredImage.value - if featured image has a value property (only template images will)
 * @param {string} featuredImage.name
 * @param {object} editorState
 * @param {object} file
 * @param {string} file.name
 * @param {string} profilePhoto
 * @param {string} tagString
 * @param {string} videoUrl
 * @param {string} contentJSON
 *
 * @todo featuredImage needs to be correctly set when using template images
 */
export const addInsight = async (
  author,
  title,
  subTitle,
  category,
  displayName,
  featuredImage,
  editorState,
  file,
  profilePhoto,
  tagString,
  videoUrl,
  contentJSON
) => {
  if (file && featuredImage) {
    // if featured image has a value property (only template images will)
    if (featuredImage.value) {
      let folder = uuidv4();
      console.log(
        "We have a file named " +
          file.name +
          " parent folder name " +
          folder +
          " And We have an image named " +
          featuredImage.name
      );
      const ref = firebase.storage().ref();
      const metadata = { contentType: file.type };
      const task = ref
        .child(`insights/${folder}/${file.name}`)
        .put(file, metadata);
      task.on(
        "state_changed",
        snapshot => {
          let progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;

          store.dispatch({
            type: "INSIGHT_FILE_UPLOAD_PROGRESS",
            payload: progress
          });
          switch (snapshot.state) {
            case firebase.storage.TaskState.PAUSED: // or 'paused'
              console.log("Upload is paused");
              break;
            case firebase.storage.TaskState.RUNNING: // or 'running'
              console.log("Upload is running");
              break;
            default:
              break;
          }
          return;
        },
        e => {
          console.log(e);
        },
        () => {
          store.dispatch({
            type: "INSIGHT_FILE_UPLOAD_PROGRESS",
            payload: null
          });
          task.snapshot.ref.getDownloadURL().then(url => {
            const fireKey = firebase
              .database()
              .ref()
              .child("storage")
              .push().key;
            addStorageRecord(fireKey, url, file, folder);
            const bddKey = firebase
              .database()
              .ref()
              .child("insights")
              .push().key;
            firebase
              .database()
              .ref("insights/" + bddKey)
              .set({
                author,
                title,
                subTitle,
                category,
                authorDisplayName: displayName,
                featuredImage: featuredImage.value,
                content: editorState,
                key: bddKey,
                time: Date.now(),
                fileLocation: url,
                fileName: file.name,
                authorProfilePhoto: profilePhoto || "",
                tags: tagString,
                videoUrl,
                contentJSON
              })
              .catch(e => {
                console.error("Upload failed:", e);
              });
          });
        }
      );
    } else {
      let folder = uuidv4();
      console.log(
        "We have a file named " +
          file.name +
          " parent folder name " +
          folder +
          " And We have an image named " +
          featuredImage.name
      );
      const ref = firebase.storage().ref();
      const metadata = { contentType: file.type };
      const task = ref
        .child(`insights/${folder}/${file.name}`)
        .put(file, metadata);
      task.on(
        "state_changed",
        snapshot => {
          let progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          store.dispatch({
            type: "INSIGHT_FILE_UPLOAD_PROGRESS",
            payload: progress
          });
          switch (snapshot.state) {
            case firebase.storage.TaskState.PAUSED: // or 'paused'
              console.log("Upload is paused");
              break;
            case firebase.storage.TaskState.RUNNING: // or 'running'
              console.log("Upload is running");
              break;
            default:
              break;
          }
          return;
        },
        e => {
          console.log(e);
        },
        () => {
          store.dispatch({
            type: "INSIGHT_FILE_UPLOAD_PROGRESS",
            payload: null
          });
          task.snapshot.ref.getDownloadURL().then(url => {
            const fireKey = firebase
              .database()
              .ref()
              .child("storage")
              .push().key;

            addStorageRecord(fireKey, url, file, folder);
            let imageFolder = uuidv4();
            firebase
              .storage()
              .ref()
              .child(`photos/${imageFolder}/${featuredImage.name}`)
              .put(featuredImage)
              .then(snapshot => snapshot.ref.getDownloadURL())
              .then(downloadURL => {
                const imageUrl = downloadURL;
                const imageFireKey = firebase
                  .database()
                  .ref()
                  .child("storage")
                  .push().key;
                addImageRecord(
                  imageFireKey,
                  imageUrl,
                  featuredImage,
                  imageFolder
                );
                const bddKey = firebase
                  .database()
                  .ref()
                  .child("insights")
                  .push().key;
                firebase
                  .database()
                  .ref("insights/" + bddKey)
                  .set({
                    author,
                    title,
                    subTitle,
                    category,
                    authorDisplayName: displayName,
                    featuredImage: imageUrl,
                    content: editorState,
                    key: bddKey,
                    time: Date.now(),
                    fileLocation: url,
                    fileName: file.name,
                    authorProfilePhoto: profilePhoto || "",
                    tags: tagString,
                    videoUrl: videoUrl,
                    contentJSON
                  })
                  .catch(e => {
                    console.error("Upload failed:", e);
                  });
              });
          });
        }
      );
    }
  } else if (file) {
    let folder = uuidv4();
    console.log("We only have a file named" + file.name);

    const ref = firebase.storage().ref();
    const metadata = { contentType: file.type };
    const task = ref
      .child(`insights/${folder}/${file.name}`)
      .put(file, metadata);

    task.on(
      "state_changed",
      snapshot => {
        let progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        store.dispatch({
          type: "INSIGHT_FILE_UPLOAD_PROGRESS",
          payload: progress
        });
        switch (snapshot.state) {
          case firebase.storage.TaskState.PAUSED: // or 'paused'
            console.log("Upload is paused");
            break;
          case firebase.storage.TaskState.RUNNING: // or 'running'
            console.log("Upload is running");
            break;
          default:
            break;
        }
        return;
      },
      e => {
        console.log(e);
      },
      () => {
        store.dispatch({
          type: "INSIGHT_FILE_UPLOAD_PROGRESS",
          payload: null
        });
        task.snapshot.ref
          .getDownloadURL()
          .then(url => {
            const fireKey = firebase
              .database()
              .ref()
              .child("storage")
              .push().key;
            addStorageRecord(fireKey, url, file, folder);
            const bdKey = firebase
              .database()
              .ref()
              .child("insights")
              .push().key;
            firebase
              .database()
              .ref("insights/" + bdKey)
              .set({
                author: author,
                title: title,
                subTitle: subTitle,
                category: category,
                authorDisplayName: displayName,
                featuredImage: featuredImage,
                content: editorState,
                key: bdKey,
                time: Date.now(),
                fileLocation: url,
                fileName: file.name,
                authorProfilePhoto: profilePhoto || "",
                tags: tagString,
                videoUrl: videoUrl,
                contentJSON
              });
            console.log("File " + fireKey + " available at " + url);
          })
          .catch(e => {
            console.error("Upload failed:", e);
          });
      }
    );
  } else if (featuredImage) {
    // if featured image has a value property (only template images will)
    if (featuredImage.value) {
      const bddKey = firebase
        .database()
        .ref()
        .child("insights")
        .push().key;
      firebase
        .database()
        .ref("insights/" + bddKey)
        .set({
          author: author,
          title: title,
          subTitle: subTitle,
          category: category,
          authorDisplayName: displayName,
          featuredImage: featuredImage.value,
          content: editorState,
          key: bddKey,
          time: Date.now(),
          authorProfilePhoto: profilePhoto || "",
          tags: tagString,
          videoUrl: videoUrl,
          contentJSON
        })
        .catch(e => {
          console.error("Upload failed:", e);
        });
    } else {
      console.log("We only have an image named" + featuredImage.name);
      let imageFolder = uuidv4();
      firebase
        .storage()
        .ref()
        .child(`photos/${imageFolder}/${featuredImage.name}`)
        .put(featuredImage)
        .then(snapshot => {
          return snapshot.ref.getDownloadURL(); // Will return a promise with the download link
        })
        .then(downloadURL => {
          const imageUrl = downloadURL;
          const imageFireKey = newStorageKey;
          addImageRecord(imageFireKey, imageUrl, featuredImage, imageFolder);
          const bddKey = firebase
            .database()
            .ref()
            .child("insights")
            .push().key;
          firebase
            .database()
            .ref("insights/" + bddKey)
            .set({
              author: author,
              title: title,
              subTitle: subTitle,
              category: category,
              authorDisplayName: displayName,
              featuredImage: imageUrl,
              content: editorState,
              key: bddKey,
              time: Date.now(),
              authorProfilePhoto: profilePhoto || "",
              tags: tagString,
              videoUrl: videoUrl,
              contentJSON
            })
            .catch(e => {
              console.error("Upload failed:", e);
            });
        });
    }
  } else {
    console.log("No File or image attached adding insight");
    const bdcdKey = firebase
      .database()
      .ref()
      .child("insights")
      .push().key;
    firebase
      .database()
      .ref("insights/" + bdcdKey)
      .set({
        author: author,
        title: title,
        subTitle: subTitle,
        category: category,
        authorDisplayName: displayName,
        featuredImage: featuredImage,
        content: editorState,
        key: bdcdKey,
        time: Date.now(),
        authorProfilePhoto: profilePhoto || "",
        tags: tagString,
        videoUrl: videoUrl,
        contentJSON
      });
  }
};

export const removeAttachedFile = insight => {
  removeFileFromInsight(insight.fileName);
  firebase
    .database()
    .ref(`insights/${insight.key}/`)
    .update({
      fileLocation: "",
      fileName: ""
    })
    .then(function() {
      console.log("Removed file reference from db insight Succeeded");
    })
    .catch(function(error) {
      console.log("Remove failed because: " + error.message);
    });
};

export const timeCop = {
  author: "Jean-Claude Van Damme",
  date: "8th Feb 2004",
  title: "Time Cop",
  subTitle: "Energy Manager",
  category: "Policy",
  time: "Distant Future",
  content:
    "When mankind perfects time travel, the government establishes the Time Enforcement Commission to thwart criminal attempts to alter the timeline. Police officer Walker (Jean-Claude Van Damme) is recruited by TEC Cmdr. Matuzak (Bruce McGill), but soon finds his investigation of Senator Aaron McComb (Ron Silver) being thwarted by elements within the government. When Walker's wife, Melissa (Mia Sara), is attacked, he must travel across time to rescue her and save the future."
};

export const updateInsight = (
  insightKey,
  author,
  title,
  subTitle,
  category,
  displayName,
  profilePhoto,
  featuredImage,
  file,
  tagString,
  video,
  contentJSON
) => {
  if (file && featuredImage) {
    if (featuredImage.value) {
      let folder = uuidv4();
      firebase
        .storage()
        .ref()
        .child(`insights/${folder}/${file.name}`)
        .put(file)
        .then(snapshot => {
          return snapshot.ref.getDownloadURL(); // Will return a promise with the download link
        })
        .then(downloadURL => {
          const url = downloadURL;
          const fireKey = firebase
            .database()
            .ref()
            .child("storage")
            .push().key;
          addStorageRecord(fireKey, url, file, folder);

          firebase
            .database()
            .ref("insights/" + insightKey)
            .update({
              author: author,
              title: title,
              subTitle: subTitle,
              category: category,
              authorDisplayName: displayName,
              featuredImage: featuredImage.value,
              time: Date.now(),
              fileLocation: url,
              fileName: file.name,
              authorProfilePhoto: profilePhoto || "",
              tags: tagString,
              videoUrl: video,
              contentJSON
            });
        });
    } else {
      let folder = uuidv4();
      console.log(
        "Updating to new file " +
          file.name +
          " And We have a new image named " +
          featuredImage.name
      );
      firebase
        .storage()
        .ref()
        .child(`insights/${folder}/${file.name}`)
        .put(file)
        .then(snapshot => {
          return snapshot.ref.getDownloadURL(); // Will return a promise with the download link
        })
        .then(downloadURL => {
          const url = downloadURL;
          const fireKey = firebase
            .database()
            .ref()
            .child("storage")
            .push().key;
          addStorageRecord(fireKey, url, file, folder);
          let imageFolder = uuidv4();
          firebase
            .storage()
            .ref()
            .child(`photos/${imageFolder}/${featuredImage.name}`)
            .put(featuredImage)
            .then(snapshot => {
              return snapshot.ref.getDownloadURL(); // Will return a promise with the download link
            })
            .then(downloadURL => {
              const imageUrl = downloadURL;
              const imageFireKey = firebase
                .database()
                .ref()
                .child("storage")
                .push().key;
              addImageRecord(
                imageFireKey,
                imageUrl,
                featuredImage,
                imageFolder
              );
              firebase
                .database()
                .ref("insights/" + insightKey)
                .update({
                  author: author,
                  title: title,
                  subTitle: subTitle,
                  category: category,
                  authorDisplayName: displayName,
                  featuredImage: imageUrl,
                  time: Date.now(),
                  fileLocation: url,
                  fileName: file.name,
                  authorProfilePhoto: profilePhoto || "",
                  tags: tagString,
                  videoUrl: video,
                  contentJSON
                });
            });
        })
        .catch(e => {
          console.error("Upload for update failed:", e);
        });
    }
  } else if (file) {
    let folder = uuidv4();
    console.log("Just updating a file named" + file.name);
    firebase
      .storage()
      .ref()
      .child(`insights/${folder}/${file.name}`)
      .put(file)
      .then(snapshot => {
        return snapshot.ref.getDownloadURL(); // Will return a promise with the download link
      })
      .then(downloadURL => {
        const url = downloadURL;
        const fireKey = firebase
          .database()
          .ref()
          .child("storage")
          .push().key;
        addStorageRecord(fireKey, url, file, folder);
        firebase
          .database()
          .ref("insights/" + insightKey)
          .update({
            author: author,
            title: title,
            subTitle: subTitle,
            category: category,
            authorDisplayName: displayName,
            // featuredImage: featuredImage,
            time: Date.now(),
            fileLocation: url,
            fileName: file.name,
            authorProfilePhoto: profilePhoto || "",
            tags: tagString,
            videoUrl: video,
            contentJSON
          });
        console.log("File " + fireKey + " available at " + url);
      })
      .catch(e => {
        console.error("Upload failed:", e);
      });
  } else if (featuredImage) {
    // if featured image has a value property (only template images will)
    if (featuredImage.value) {
      console.log("UPDATE AUTHOR ", author);
      console.log("UPDATE PROFILE PHOTO ", profilePhoto);
      firebase
        .database()
        .ref("insights/" + insightKey)
        .update({
          author: author,
          title: title,
          subTitle: subTitle,
          category: category,
          authorDisplayName: displayName,
          featuredImage: featuredImage.value,
          time: Date.now(),
          authorProfilePhoto: profilePhoto || "",
          tags: tagString,
          videoUrl: video,
          contentJSON
        })
        .catch(e => {
          console.error("Upload failed:", e);
        });
    } else {
      let imageFolder = uuidv4();
      console.log("We only have an image named" + featuredImage.name);
      firebase
        .storage()
        .ref()
        .child(`photos/${imageFolder}/${featuredImage.name}`)
        .put(featuredImage)
        .then(snapshot => {
          return snapshot.ref.getDownloadURL(); // Will return a promise with the download link
        })
        .then(downloadURL => {
          const imageUrl = downloadURL;
          const imageFireKey = newStorageKey;
          addImageRecord(imageFireKey, imageUrl, featuredImage, imageFolder);
          firebase
            .database()
            .ref("insights/" + insightKey)
            .update({
              author: author,
              title: title,
              subTitle: subTitle,
              category: category,
              authorDisplayName: displayName,
              featuredImage: imageUrl,
              time: Date.now(),
              authorProfilePhoto: profilePhoto || "",
              tags: tagString,
              videoUrl: video,
              contentJSON
            });
        })
        .catch(e => {
          console.error("Upload failed:", e);
        });
    }
  } else {
    console.log("No File or image attached updating insight");
    firebase
      .database()
      .ref("insights/" + insightKey)
      .update({
        author: author,
        title: title,
        subTitle: subTitle,
        category: category,
        authorDisplayName: displayName,
        time: Date.now(),
        tags: tagString,
        videoUrl: video,
        contentJSON
      });
  }
};

export const getLatestInsights = () => {
  const ref = firebase.database().ref("insights");
  ref
    .orderByChild("time")
    .limitToLast(5)
    .on("value", snap => {
      const latestInsights = snapshotToArray(snap);
      store.dispatch(setLatestInsights(latestInsights.reverse()));
    });
};

export const SET_LATEST_INSIGHTS = "SET_LATEST_INSIGHTS";
export const setLatestInsights = latestInsights => ({
  type: SET_LATEST_INSIGHTS,
  latestInsights
});
