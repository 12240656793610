import {
  SET_CATEGORIES,
  SAVE_DASHBOARD_MESSAGE,
  SET_INDUSTRIES,
  SET_LEGAL_CATEGORIES,
  SET_LEGAL_REGULATORS,
  SET_SUPPLIER,
  SET_COMPANY_TYPE,
  CHANGE_ACTIVE_ITEM,
  SET_PARENT_CATEGORIES
} from "../actions/types";

const INITIAL_STATE = {
  categories: [],
  dashboardMessage: {},
  activeItem: "",
  parentCategories: []
};

export default function configReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case SET_CATEGORIES: {
      return {
        ...state,
        categories: [...action.categories]
      };
    }

    case SET_PARENT_CATEGORIES: {
      return {
        ...state,
        parentCategories: [...action.parentCategories]
      };
    }

    case SAVE_DASHBOARD_MESSAGE: {
      return {
        ...state,
        dashboardMessage: action.dashboardMessage
      };
    }

    case SET_INDUSTRIES: {
      return {
        ...state,
        industries: [...action.industries]
      };
    }

    case SET_LEGAL_CATEGORIES: {
      return {
        ...state,
        legalCategories: [...action.legalCategories]
      };
    }

    case SET_LEGAL_REGULATORS: {
      return {
        ...state,
        legalRegulators: [...action.legalRegulators]
      };
    }

    case SET_SUPPLIER: {
      return {
        ...state,
        suppliers: [...action.suppliers]
      };
    }

    case SET_COMPANY_TYPE: {
      return {
        ...state,
        companyTypes: [...action.companyTypes]
      };
    }

    case CHANGE_ACTIVE_ITEM: {
      return {
        ...state,
        activeItem: action.activeItem
      };
    }

    default:
      return state;
  }
}
