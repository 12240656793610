import React, { Component } from "react";
import { connect } from "react-redux";
import { Form, FormGroup, Label, Input } from "reactstrap";
import { Segment, Icon, Button, Message, Dropdown } from "semantic-ui-react";
import {
  getLegalCategories,
  getLegalRegulators,
  getIndustries
} from "../../actions/configActions";
import {
  editorStateFromRaw,
  MegadraftEditor,
  editorStateToJSON
} from "megadraft";
import "../MegaDraft/megadraft.css";
import { buttonStyles } from "../../styles";
import { countries } from "../../constants";
import { updateLegalRegisterItem } from "../../actions/legalRegister";
//  Notifications
import {
  createNotification,
  NOTIFICATION_TYPE_SUCCESS
} from "react-redux-notify";

const mySuccessNotification = {
  message: "Legal Item Successfully Updated",
  type: NOTIFICATION_TYPE_SUCCESS,
  duration: 3000,
  canDismiss: true,
  icon: <Icon name="check" />
};

class EditLegalItem extends Component {
  constructor() {
    super();

    this.state = {
      applicableTo: [],
      filterByArea: "",
      link: "",
      regulator: [],
      title: "",
      redirect: false,
      loaderState: false,
      headlineEditorState: editorStateFromRaw(null),
      descriptionEditorState: editorStateFromRaw(null),
      consequenceEditorState: editorStateFromRaw(null),
      howThisAppliesEditorState: editorStateFromRaw(null),
      row: "",
      key: "",
      errors: {},
      country: null
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    getLegalCategories();
    getLegalRegulators();
    getIndustries();
    const editItem = this.props.currentLegalItemProps;

    this.setState({
      applicableTo: editItem.applicableTo,
      filterByArea: editItem.filterByArea,
      link: editItem.link,
      regulator: editItem.regulator,
      title: editItem.title,
      country: editItem.country,
      redirect: false,
      loaderState: false,
      headlineEditorState: editorStateFromRaw(
        JSON.parse(editItem.headlineDetails)
      ),
      descriptionEditorState: editorStateFromRaw(
        JSON.parse(editItem.description)
      ),
      consequenceEditorState: editorStateFromRaw(
        JSON.parse(editItem.consequence)
      ),
      howThisAppliesEditorState: editorStateFromRaw(
        JSON.parse(editItem.howThisApplies)
      ),
      row: "",
      key: editItem.key
    });
  }

  // error validation
  handleValidation = () => {
    let { applicableTo, regulator, title, filterByArea, country } = this.state;
    let errors = {};
    let formIsValid = true;

    // Area
    if (filterByArea === "") {
      formIsValid = false;
      errors["filterByArea"] = (
        <Message negative icon="warning" content="Choose at least one area" />
      );
    }

    // Name (the regex checks if the title only contains empty spaces)
    if (title === "" || title.replace(/\s+/g, "").length === 0) {
      formIsValid = false;
      errors["title"] = (
        <Message
          negative
          icon="warning"
          content="Legal Item must have a name"
        />
      );
    }

    // Applicable To
    if (
      applicableTo === null ||
      applicableTo === undefined ||
      !applicableTo.length
    ) {
      formIsValid = false;
      errors["applicableTo"] = (
        <Message negative icon="warning" content="Choose at least one sector" />
      );
    }

    // Regulators
    if (regulator === null || regulator === undefined || !regulator.length) {
      formIsValid = false;
      errors["regulator"] = (
        <Message negative icon="warning" content="Choose at least regulator" />
      );
    }

    // Countries
    if (country === null || country === undefined || !country.length) {
      formIsValid = false;
      errors["country"] = (
        <Message
          negative
          icon="warning"
          content="Choose at least one Country"
        />
      );
    }

    this.setState({ errors: errors });
    return formIsValid;
  };

  // Form field handlers
  handleInputChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };
  handleAreaChange = (e, data) => {
    this.setState({ filterByArea: data.value });
  };
  onChangeHeadline = headlineEditorState => {
    this.setState({ headlineEditorState });
  };
  onChangeDescription = descriptionEditorState => {
    this.setState({ descriptionEditorState });
  };
  onChangeConsequence = consequenceEditorState => {
    this.setState({ consequenceEditorState });
  };
  onChangeHowThisApplies = howThisAppliesEditorState => {
    this.setState({ howThisAppliesEditorState });
  };
  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };
  handleSelect = (e, data) => {
    this.setState({ applicableTo: data.value });
  };
  handleRegulatorSelect = (e, data) => {
    this.setState({ regulator: data.value });
  };
  handleCountrySelect = (e, data) => {
    this.setState({ country: data.value });
  };

  // Form submit to firebase with updated state
  handleUpdate = event => {
    event.preventDefault();
    if (this.handleValidation()) {
      this.setState({ loaderState: true });
      if (this.state.title === null || "") {
        this.setState({ loaderState: false });
        return window.alert("Sorry cannot submit an empty legal register item");
      }

      const headline = editorStateToJSON(this.state.headlineEditorState);
      const description = editorStateToJSON(this.state.descriptionEditorState);
      const consequence = editorStateToJSON(this.state.consequenceEditorState);
      const howThisApplies = editorStateToJSON(
        this.state.howThisAppliesEditorState
      );
      const authorName =
        this.props.currentUser.displayName ||
        `${this.props.currentUser.firstName} ${
          this.props.currentUser.lastName
        }`;

      let stateTags = this.state.regulator;
      function convert(stateTags) {
        let tags = {};
        for (var i = 0; i < stateTags.length; i++) {
          tags[i] = stateTags[i];
        }
        return tags;
      }
      const tagsString = convert(stateTags);

      updateLegalRegisterItem(
        this.state.applicableTo,
        consequence,
        description,
        this.state.filterByArea,
        headline,
        this.state.link,
        tagsString || "",
        this.state.title,
        authorName,
        this.props.currentUser.uid,
        this.state.key,
        howThisApplies,
        this.state.country
      );
      this.props.handleEditToggle();
      const { createNotification } = this.props;
      createNotification(mySuccessNotification);
      window.scrollTo(0, 0);
    } else {
      alert("Ooops, you have missed some required fields. Check the warnings!");
    }
  };

  render() {
    const legalCategoriesArray = [];

    this.props.legalCategories &&
      this.props.legalCategories.map((category, i) => {
        return (
          category.isActive &&
          legalCategoriesArray.push({
            key: i,
            text: category.legalCategoryName,
            value: category.legalCategoryName
          })
        );
      });

    const legalRegulatorsArray = [];

    this.props.legalRegulators &&
      this.props.legalRegulators.map((regulator, i) => {
        return (
          regulator.isActive &&
          legalRegulatorsArray.push({
            key: i,
            text: regulator.legalRegulatorName,
            value: regulator.legalRegulatorName
          })
        );
      });

    const insightIndustriesArray = [];

    this.props.industries &&
      this.props.industries.map((industry, i) => {
        return (
          industry.isActive &&
          insightIndustriesArray.push({
            key: i,
            text: industry.industryName,
            value: industry.industryName
          })
        );
      });

    return (
      <div>
        <Segment
          style={{
            backgroundImage:
              "linear-gradient(to bottom, #FFDF00, #F63800, #6400B5)",
            color: "white"
          }}
          raised
        >
          <Segment inverted textAlign="center">
            <Icon name="warning" />
            Edit Mode
          </Segment>
          <Form onSubmit={this.handleUpdate} id="theEditForm">
            <FormGroup>
              <Label for="filterByArea">Area</Label>
              {this.state.errors["filterByArea"]}
              <Dropdown
                placeholder="Choose an Area ..."
                fluid
                selection
                options={legalCategoriesArray}
                onChange={this.handleAreaChange}
              />
            </FormGroup>
            <FormGroup>
              <Label for="Title">Name of Legislation/Requirement</Label>
              {this.state.errors["title"]}
              <Input
                type="text"
                name="title"
                id="Title"
                value={this.state.title}
                onChange={this.handleInputChange}
              />
            </FormGroup>

            <FormGroup>
              <Label for="Headline Details">Headline Details</Label>
              <Segment style={{ minHeight: 100, boxShadow: "none" }}>
                <MegadraftEditor
                  editorState={this.state.headlineEditorState}
                  onChange={this.onChangeHeadline}
                  name="headlineDetails"
                />
              </Segment>
            </FormGroup>

            <FormGroup>
              <Label for="link">Link</Label>
              <Input
                type="text"
                name="link"
                value={this.state.link}
                onChange={this.handleInputChange}
              />
            </FormGroup>

            <FormGroup>
              <Label for="applicableTo">Countries this applies to:</Label>
              {this.state.errors["country"]}
              <Dropdown
                placeholder="Choose a Country ..."
                fluid
                multiple
                selection
                options={countries}
                value={this.state.country}
                onChange={this.handleCountrySelect}
              />
            </FormGroup>

            <FormGroup>
              <Label for="applicableTo">Might be Applicable to:</Label>
              {this.state.errors["applicableTo"]}
              <Dropdown
                placeholder="Choose an industry segment ..."
                fluid
                multiple
                selection
                options={insightIndustriesArray}
                onChange={this.handleSelect}
                value={this.state.applicableTo}
              />
            </FormGroup>

            <FormGroup>
              <Label for="description">Description</Label>
              <Segment style={{ minHeight: 100, boxShadow: "none" }}>
                <MegadraftEditor
                  editorState={this.state.descriptionEditorState}
                  onChange={this.onChangeDescription}
                  name="description"
                />
              </Segment>
            </FormGroup>
            <FormGroup>
              <Label for="consequence">Consequence of Non Compliance</Label>
              <Segment style={{ minHeight: 100, boxShadow: "none" }}>
                <MegadraftEditor
                  editorState={this.state.consequenceEditorState}
                  onChange={this.onChangeConsequence}
                  name="consequence"
                />
              </Segment>
            </FormGroup>
            <FormGroup>
              <Label for="howThisAppliesToMe">
                How this applies to my organisation
              </Label>
              <Segment style={{ minHeight: 100, boxShadow: "none" }}>
                <MegadraftEditor
                  editorState={this.state.howThisAppliesEditorState}
                  onChange={this.onChangeHowThisApplies}
                  name="howThisAppliesToMe"
                />
              </Segment>
            </FormGroup>
            <FormGroup>
              <Label for="acronym">Regulator</Label>
              {this.state.errors["regulator"]}
              <Dropdown
                placeholder="Choose Regulators..."
                fluid
                multiple
                selection
                options={legalRegulatorsArray}
                onChange={this.handleRegulatorSelect}
                value={this.state.regulator}
              />
            </FormGroup>
            <Button color="blue" style={buttonStyles.primaryButton}>
              Update Me ... <Icon name="smile outline" />
            </Button>
            <Button
              style={{ marginLeft: 5 }}
              onClick={() => {
                this.props.handleEditToggle();
              }}
            >
              Cancel
            </Button>
          </Form>
        </Segment>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  currentUser: state.user.currentUserProps,
  currentLegalItemProps: state.legalItems.currentLegalItemProps,
  legalCategories: state.appConfig.legalCategories,
  legalRegulators: state.appConfig.legalRegulators,
  industries: state.appConfig.industries
});

const mapDispatchToProps = dispatch => ({
  createNotification: config => {
    dispatch(createNotification(config));
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditLegalItem);
