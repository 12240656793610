import React, { Component } from "react";
import { connect } from "react-redux";
import { Form, Button, Input, List, Icon } from "semantic-ui-react";
import styled from "styled-components";

// Actions
import {
  createSkillLevel,
  removeSkillLevel
} from "../../../../actions/university";
// import { object } from "firebase-functions/lib/providers/storage";

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-top: 25px;
`;

const GridItem = styled.div`
  padding: 10px;
  test-align: center;
  margin: 25px;
  border-style: solid;
  border-width: 1px 1px;
  border-radius: 7px;
  border-color: rgba(0, 0, 0, 0.3);
  background-color: white;
  box-shadow: 10px 10px 33px -12px rgba(0, 0, 0, 0.75);
`;

class SkillLevels extends Component {
  state = {
    skillLevel: ""
  };

  handleSkillSubmit = e => {
    e.preventDefault();
    createSkillLevel(this.state.skillLevel);
    this.setState({ skillLevel: "" });
  };

  handleInputChange = (e, data) => {
    this.setState({ [data.name]: data.value });
  };

  handleRemoveSkill = key => {
    removeSkillLevel(key);
  };

  render() {
    const allSkillLevels = [];

    this.props.skillLevels &&
      Object.values(this.props.skillLevels).map((skill, i) => {
        return allSkillLevels.push({
          key: i,
          text: skill.name,
          value: skill.name
        });
      });

    return (
      <Grid>
        <GridItem>
          <Form onSubmit={this.handleSkillSubmit}>
            <h2>Create a Skill Level</h2>
            <Form.Field
              control={Input}
              label="Skill Level"
              name="skillLevel"
              placeholder="Enter a skill level ..."
              onChange={this.handleInputChange}
              value={this.state.skillLevel}
              required
            />
            <Button type="submit">Submit</Button>
          </Form>
        </GridItem>
        <GridItem>
          <h3>Skill levels</h3>
          <List divided relaxed>
            {this.props.skillLevels &&
              Object.values(this.props.skillLevels).map((skill, idx) => {
                return (
                  <List.Item key={idx}>
                    <h5>
                      {skill.name}{" "}
                      <Icon
                        name="delete"
                        style={{ color: "red", cursor: "pointer" }}
                        onClick={() => this.handleRemoveSkill(skill.key)}
                      />
                    </h5>
                  </List.Item>
                );
              })}
          </List>
        </GridItem>
      </Grid>
    );
  }
}

const mapStateToProps = state => ({
  skillLevels: state.university.skillLevels
});

export default connect(mapStateToProps)(SkillLevels);
