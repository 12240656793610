import React, { Component } from "react";
import { firebase } from "../../FireBase/firebase";
import { snapshotToArray } from "../../actions/insights";

class NickTestPage extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  getAllUsers = e => {
    e.preventDefault();
    firebase
      .database()
      .ref(`/users`)
      .once("value", function(snapshot) {
        const allUsers = snapshotToArray(snapshot);
        console.log(allUsers);
        // this.assignDisplayName(allUsers);
        allUsers.forEach(item => {
          if (
            item.displayName !== undefined &&
            typeof item.displayName === "string" &&
            item.displayName.length > 1
          ) {
            console.log(
              item.displayName +
                " already has a display name of length " +
                item.displayName.length
            );
          } else if (
            item.firstName !== undefined &&
            item.lastName !== undefined
          ) {
            let newDisplayName = item.firstName + " " + item.lastName;
            firebase
              .database()
              .ref(`/users/${item.uid}`)
              .update({
                displayName: newDisplayName
              });
            console.log(
              newDisplayName +
                " has been assigned as display name for " +
                item.firstName
            );
          } else if (
            item.firstName !== undefined &&
            item.lastName === undefined
          ) {
            firebase
              .database()
              .ref(`/users/${item.uid}`)
              .update({
                displayName: item.firstName
              });
            console.log(
              item.firstName +
                " has been assigned as display name for " +
                item.firstName
            );
          } else if (
            item.firstName === undefined &&
            item.lastName !== undefined
          ) {
            firebase
              .database()
              .ref(`/users/${item.uid}`)
              .update({
                displayName: item.lastName
              });
            console.log(
              item.lastName +
                " has been assigned as display name for " +
                item.lastName
            );
          } else {
            console.log(
              "**!!!!*** User with key " +
                item.uid +
                " has some issues and needs manual setting"
            );
          }
        });
        return console.log("all Done");
      });
  };

  render() {
    return (
      <div>
        <h1>Nick Page</h1>
        <button type="button" onClick={e => this.getAllUsers(e)}>
          Assign all users a display name - see console logs for results
        </button>
      </div>
    );
  }
}

export default NickTestPage;
