import React from "react";
import { Container, Row, Col } from "reactstrap";
import "./index.css";
import ScrollAnimation from "react-animate-on-scroll";

// image
import insight from "../../img/LandingPageIcons/content-management.png";
import seminar from "../../img/LandingPageIcons/marketing-seminar.png";

const Learn = () => (
  <div className="LightBacking" style={{ padding: 15 }}>
    {/* <h1>Learn</h1> */}
    <Container style={{ backgroundColor: "" }}>
      <Row>
        <Col sm="3">
          <ScrollAnimation animateIn="bounceInLeft" offset={1}>
            <img
              src={insight}
              className="img"
              style={styles.imgStyle}
              alt="insight"
            />
          </ScrollAnimation>
        </Col>
        <Col sm="9" style={{ textAlign: "left" }}>
          <h3>Insights</h3>
          <p>
            Receive cutting edge insight on the technologies and policy changes
            you really need to know about. Access information on third party
            charges, wholesale market movements and environmental levies that
            impact your budget. With detailed analysis on the big themes that
            impact you, we’ve got your back.
          </p>
        </Col>
      </Row>
    </Container>
    <Container style={{ backgroundColor: "", padding: 15 }}>
      <Row>
        <Col sm="9" style={{ textAlign: "right" }}>
          <h3>Workshops and Seminars</h3>
          <p>
            Our fun and engaging workshops help educate both new entrants to
            energy management and experienced operators on the key issues in the
            market whilst our consultation response groups are specifically
            designed to help you formulate and articulate your own thoughts on
            major legislative change and draft an appropriate response.
          </p>
        </Col>
        <Col sm="3">
          <ScrollAnimation animateIn="bounceInRight" offset={1}>
            <img
              src={seminar}
              className="img"
              style={styles.imgStyle}
              alt="seminar"
            />
          </ScrollAnimation>
        </Col>
      </Row>
    </Container>
  </div>
);

const styles = {
  imgStyle: {
    width: 150,
    padding: 15
    // shadowOffset: { height: 20, width: 20 },
    // shadowColor: "black",
    // shadowOpacity: 1.0
  }
};

export default Learn;
