import React, { Component } from "react";
import { connect } from "react-redux";
import { addLegalRegisterItem } from "../../actions/legalRegister";
import { Form, FormGroup, Label, Input } from "reactstrap";
import { Segment, Icon, Button, Message, Dropdown } from "semantic-ui-react";
import {
  getLegalCategories,
  getLegalRegulators,
  getIndustries
} from "../../actions/configActions";
import {
  editorStateFromRaw,
  MegadraftEditor,
  editorStateToJSON
} from "megadraft"; // draft.js
import { buttonStyles } from "../../styles"; // Global styling
import { countries } from "../../constants"; // Lists of Constants
import {
  createNotification,
  NOTIFICATION_TYPE_SUCCESS
} from "react-redux-notify"; //  Notifications

const mySuccessNotification = {
  message: `Legal Item Successfully Uploaded`,
  type: NOTIFICATION_TYPE_SUCCESS,
  duration: 3000,
  canDismiss: true,
  icon: <Icon name="check" />
};

class LegalRegisterUploadForm extends Component {
  constructor() {
    super();
    this.state = {
      applicableTo: null,
      consequence: "",
      description: "",
      filterByArea: null,
      headlineDetails: "",
      link: "",
      regulator: null,
      title: null,
      redirect: false,
      loaderState: false,
      headlineEditorState: editorStateFromRaw(null),
      descriptionEditorState: editorStateFromRaw(null),
      consequenceEditorState: editorStateFromRaw(null),
      howThisAppliesEditorState: editorStateFromRaw(null),
      row: "",
      editMode: false,
      errors: {},
      country: null
    };
  }

  componentDidMount() {
    getLegalCategories();
    getLegalRegulators();
    getIndustries();
  }

  handleValidation = () => {
    // error logic

    let { applicableTo, regulator, title, filterByArea, country } = this.state;
    let errors = {};
    let formIsValid = true;

    // Area
    if (filterByArea === null) {
      formIsValid = false;
      errors["filterByArea"] = (
        <Message negative icon="warning" content="Choose at least one area" />
      );
    }

    // Name
    if (title === null || title.replace(/\s+/g, "").length === 0) {
      formIsValid = false;
      errors["title"] = (
        <Message
          negative
          icon="warning"
          content="Legal Item must have a name"
        />
      );
    }
    console.log(applicableTo);
    console.log(typeof applicableTo);
    // Applicable To
    if (
      applicableTo === null ||
      applicableTo === undefined ||
      !applicableTo.length
    ) {
      formIsValid = false;
      errors["applicableTo"] = (
        <Message negative icon="warning" content="Choose at least one sector" />
      );
    }
    // Regulators
    if (regulator === null || regulator === undefined || !regulator.length) {
      formIsValid = false;
      errors["regulator"] = (
        <Message
          negative
          icon="warning"
          content="Choose at least one regulator"
        />
      );
    }

    // Countries
    if (country === null || country === undefined || !country.length) {
      formIsValid = false;
      errors["country"] = (
        <Message
          negative
          icon="warning"
          content="Choose at least one Country"
        />
      );
    }

    this.setState({ errors: errors });
    return formIsValid;
  };

  onChangeHeadline = headlineEditorState => {
    this.setState({ headlineEditorState });
  };
  onChangeDescription = descriptionEditorState => {
    this.setState({ descriptionEditorState });
  };
  onChangeConsequence = consequenceEditorState => {
    this.setState({ consequenceEditorState });
  };
  onChangeHowThisApplies = howThisAppliesEditorState => {
    this.setState({ howThisAppliesEditorState });
  };
  handleAreaChange = (e, data) => {
    this.setState({ filterByArea: data.value });
  };
  handleSelect = (e, data) => {
    this.setState({ applicableTo: data.value });
  };

  handleRegulatorSelect = (e, data) => {
    this.setState({ regulator: data.value });
  };

  handleCountrySelect = (e, data) => {
    this.setState({ country: data.value });
  };

  handleInputChange = (e, data) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleSubmit = async event => {
    event.preventDefault();
    if (this.handleValidation()) {
      console.log("This.handleValidation is true and has been run");
      this.setState({ loaderState: true });
      if (this.state.title === null || "") {
        this.setState({ loaderState: false });
        return window.alert("Sorry cannot submit an empty legal register item");
      }

      const headline = editorStateToJSON(this.state.headlineEditorState);
      const description = editorStateToJSON(this.state.descriptionEditorState);
      const consequence = editorStateToJSON(this.state.consequenceEditorState);
      const howThisApplies = editorStateToJSON(
        this.state.howThisAppliesEditorState
      );
      const authorName =
        this.props.currentUser.displayName ||
        `${this.props.currentUser.firstName} ${
          this.props.currentUser.lastName
        }`;

      let stateTags = this.state.regulator;
      function convert(stateTags) {
        let tags = {};
        for (var i = 0; i < stateTags.length; i++) {
          tags[i] = stateTags[i];
        }
        return tags;
      }

      const tagsString = convert(stateTags);

      addLegalRegisterItem(
        this.state.applicableTo,
        consequence,
        description,
        this.state.filterByArea,
        headline,
        this.state.link,
        tagsString || "",
        this.state.title,
        authorName,
        this.props.currentUser.uid,
        howThisApplies,
        this.state.country
      );

      // debugger;
      document.getElementById("theUploadForm").reset();
      this.setState({
        applicableTo: null,
        consequence: "",
        description: "",
        filterByArea: null,
        headlineDetails: "",
        link: "",
        regulator: null,
        title: "",
        headlineEditorState: editorStateFromRaw(null),
        descriptionEditorState: editorStateFromRaw(null),
        consequenceEditorState: editorStateFromRaw(null),
        howThisAppliesEditorState: editorStateFromRaw(null),
        redirect: true,
        loaderState: false,
        country: null
      });
      const { createNotification } = this.props;
      createNotification(mySuccessNotification);
      window.scrollTo(0, 0);
      console.log(this.state);
    } else {
      alert("Ooops, you have missed some required fields. Check the warnings!");
    }
  };

  render() {
    const legalCategoriesArray = [];

    this.props.legalCategories &&
      this.props.legalCategories.map((category, i) => {
        return (
          category.isActive &&
          legalCategoriesArray.push({
            key: i,
            text: category.legalCategoryName,
            value: category.legalCategoryName
          })
        );
      });

    const legalRegulatorsArray = [];

    this.props.legalRegulators &&
      this.props.legalRegulators.map((regulator, i) => {
        return (
          regulator.isActive &&
          legalRegulatorsArray.push({
            key: i,
            text: regulator.legalRegulatorName,
            value: regulator.legalRegulatorName
          })
        );
      });

    const insightIndustriesArray = [];

    this.props.industries &&
      this.props.industries.map((industry, i) => {
        return (
          industry.isActive &&
          insightIndustriesArray.push({
            key: i,
            text: industry.industryName,
            value: industry.industryName
          })
        );
      });

    return (
      <Segment style={{ backgroundColor: "#dfeff4" }} raised>
        <Form onSubmit={this.handleSubmit} id="theUploadForm">
          <FormGroup>
            <Label for="filterByArea">Area</Label>
            {this.state.errors["filterByArea"]}
            <Dropdown
              placeholder="Choose an Area ..."
              fluid
              selection
              options={legalCategoriesArray}
              onChange={this.handleAreaChange}
            />
          </FormGroup>

          <FormGroup>
            <Label for="Title">Name of Legislation/Requirement</Label>
            {this.state.errors["title"]}
            <Input
              type="text"
              name="title"
              id="Title"
              placeholder="Enter Name of Legislation/Requirement"
              onChange={this.handleInputChange}
            />
          </FormGroup>

          <FormGroup>
            <Label for="Headline Details">Headline Details</Label>
            <Segment style={{ minHeight: 100, boxShadow: "none" }}>
              <MegadraftEditor
                editorState={this.state.headlineEditorState}
                onChange={this.onChangeHeadline}
                placeholder="Write your headline here ..."
                name="headlineDetails"
              />
            </Segment>
          </FormGroup>

          <FormGroup>
            <Label for="link">Link</Label>
            <Input
              type="text"
              name="link"
              placeholder="Enter Url Link"
              onChange={this.handleInputChange}
            />
          </FormGroup>

          <FormGroup>
            <Label for="applicableTo">Countries this applies to:</Label>
            {this.state.errors["country"]}
            <Dropdown
              placeholder="Choose a Country ..."
              fluid
              multiple
              selection
              options={countries}
              onChange={this.handleCountrySelect}
            />
          </FormGroup>

          <FormGroup>
            <Label for="applicableTo">Might be Applicable to:</Label>
            {this.state.errors["applicableTo"]}
            <Dropdown
              placeholder="Choose an industry segment ..."
              fluid
              multiple
              selection
              options={insightIndustriesArray}
              onChange={this.handleSelect}
            />
          </FormGroup>

          <FormGroup>
            <Label for="description">Description</Label>
            <Segment style={{ minHeight: 100, boxShadow: "none" }}>
              <MegadraftEditor
                editorState={this.state.descriptionEditorState}
                onChange={this.onChangeDescription}
                placeholder="Write your description here"
                name="description"
              />
            </Segment>
          </FormGroup>

          <FormGroup>
            <Label for="consequence">Consequence of Non Compliance</Label>
            <Segment style={{ minHeight: 100, boxShadow: "none" }}>
              <MegadraftEditor
                editorState={this.state.consequenceEditorState}
                onChange={this.onChangeConsequence}
                placeholder="Write your consequence"
                name="consequence"
              />
            </Segment>
          </FormGroup>

          <FormGroup>
            <Label for="howThisAppliesToMe">
              How this applies to my organisation
            </Label>
            <Segment style={{ minHeight: 100, boxShadow: "none" }}>
              <MegadraftEditor
                editorState={this.state.howThisAppliesEditorState}
                onChange={this.onChangeHowThisApplies}
                placeholder="Write How this applies to my organisation ..."
                name="howThisAppliesToMe"
              />
            </Segment>
          </FormGroup>

          <FormGroup>
            <Label for="acronym">Choose Regulators</Label>
            {this.state.errors["regulator"]}

            <Dropdown
              placeholder="Choose Regulators..."
              fluid
              multiple
              selection
              options={legalRegulatorsArray}
              onChange={this.handleRegulatorSelect}
            />
          </FormGroup>

          <Button color="blue" style={buttonStyles.primaryButton}>
            Submit
          </Button>
        </Form>
      </Segment>
    );
  }
}

const mapStateToProps = state => ({
  currentUser: state.user.currentUserProps,
  currentLegalItems: state.legalItems.currentAllLegalItemsProps,
  currentMyLegalItems: state.user.currentUserProps.MyLegalItems,
  currentLegalItemProps: state.legalItems.currentLegalItemProps,
  legalCategories: state.appConfig.legalCategories,
  legalRegulators: state.appConfig.legalRegulators,
  industries: state.appConfig.industries
});

const mapDispatchToProps = dispatch => ({
  createNotification: config => {
    dispatch(createNotification(config));
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LegalRegisterUploadForm);
