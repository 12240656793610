import React, { Component } from "react";
import NewsTicker from "../NewsTicker";
import { Container, Col, Row } from "reactstrap";
import LatestInsights from "./latestInsights";
import LatestForum from "./latestForumActivity";
import LatestLegalRegister from "./latestLegalRegister";
import Messages from "./messages";
import ProfileSmall from "./ProfileSmall";

export default class Dashboard extends Component {
  render() {
    return (
      <Container>
        <Row>
          <Col sm="3">
            <ProfileSmall />
          </Col>
          <Col sm="9">
            <Messages />
          </Col>
        </Row>

        <Row style={{ marginTop: 7 }}>
          <Col sm="6">
            <LatestInsights />
          </Col>
          <Col sm="6">
            <LatestForum />
          </Col>
          <Col>
            <LatestLegalRegister />
          </Col>
        </Row>
        <NewsTicker />
      </Container>
    );
  }
}
