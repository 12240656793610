import React, { Component } from "react";
import { connect } from "react-redux";
import SubscribedInsights from "./SubscribedInsights";
import GuestInsights from "./GuestInsights";

const InsightNonAuth = props => (
  <SubscribedInsights>{props}</SubscribedInsights>
);
const InsightAdminAuth = props => (
  <SubscribedInsights>{props}</SubscribedInsights>
);
const InsightGuestAuth = props => <GuestInsights>{props}</GuestInsights>;
const InsightAuth = props => <SubscribedInsights>{props}</SubscribedInsights>;

class AllInsights extends Component {
  render() {
    let insightAuth;
    if (this.props.currentUser === null) {
      insightAuth = <InsightNonAuth header={this.props.header} />;
    } else if (this.props.currentUser.userType === "admin") {
      insightAuth = <InsightAdminAuth header={this.props.header} />;
    } else if (this.props.currentUser.userType === "guest") {
      insightAuth = <InsightGuestAuth header={this.props.header} />;
    } else if (this.props.authUser) {
      insightAuth = <InsightAuth header={this.props.header} />;
    } else {
      insightAuth = <InsightNonAuth header={this.props.header} />;
    }
    return <div>{insightAuth}</div>;
  }
}

const mapStateToProps = state => ({
  authUser: state.sessionState.authUser,
  currentUser: state.user.currentUserProps
});

export default connect(mapStateToProps)(AllInsights);
