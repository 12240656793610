import React, { Component } from "react";
import { connect } from "react-redux";
import "../../../node_modules/react-big-calendar/lib/css/react-big-calendar.css";
import {
  editorStateFromRaw,
  MegadraftEditor,
  editorStateToJSON
} from "megadraft";
import "../MegaDraft/megadraft.css";
import BigCalendar from "react-big-calendar";
import moment from "moment";
import fecha from "fecha";
import {
  submitevent,
  getAdminEvents,
  getUserEvents,
  getAllevents,
  deleteEvent
} from "../../actions/calendarActions";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Label,
  Form,
  FormGroup
} from "reactstrap";
import { Button, Dimmer, Loader, Divider, Segment } from "semantic-ui-react";

// makes caldendar begin on Monday as opposed to Sunday
moment.locale("ko", {
  week: {
    dow: 1,
    doy: 1
  }
});

// Create a localizer for the calendar with moment
const Localizer = BigCalendar.momentLocalizer(moment);

// Define an initial state for the events Array and create event form
const INITIAL_STATE = {
  createModal: false,
  detailModal: false,
  editEvent: false,
  start: new Date(),
  end: new Date(),
  allDay: false,
  title: "",
  desc: "",
  editorState: editorStateFromRaw(null),
  type: "own",
  id: "",
  activeButton: "",
  hours: [
    "00:00",
    "00:30",
    "01:00",
    "01:30",
    "02:00",
    "02:30",
    "03:00",
    "03:30",
    "04:00",
    "04:30",
    "05:00",
    "05:30",
    "06:00",
    "06:30",
    "07:00",
    "07:30",
    "08:00",
    "08:30",
    "09:00",
    "09:30",
    "10:00",
    "10:30",
    "11:00",
    "11:30",
    "12:00",
    "12:30",
    "13:00",
    "13:30",
    "14:00",
    "14:30",
    "15:00",
    "15:30",
    "16:00",
    "16:30",
    "17:00",
    "17:30",
    "18:00",
    "18:30",
    "19:00",
    "19:30",
    "20:00",
    "20:30",
    "21:00",
    "21:30",
    "22:00",
    "22:30",
    "23:00",
    "23:30"
  ]
};

class Calendar extends Component {
  constructor(props) {
    super();

    this.state = INITIAL_STATE;
  }

  // Get the adminEvents on Component Did Mount
  componentDidMount = () => {
    getAdminEvents();
  };

  getusereventsselected = () => {
    this.setState({ activeButton: "myc" });
    const { uid } = this.props.currentUser;
    getUserEvents(uid);
  };

  getAdminEventsSelected = () => {
    this.setState({ activeButton: "ind" });
    getAdminEvents();
  };

  getAllevents = () => {
    this.setState({ activeButton: "all" });
    const { uid } = this.props.currentUser;
    if (!this.props.currentUser.myevents) {
      getAdminEvents();
    } else {
      getAllevents(uid);
    }
  };

  // Toggle the create event modal On/Off
  createEventToggle = () => {
    this.setState({
      createModal: !this.state.createModal
    });
  };

  // toggle the event detail modal
  detailEventToggle = () => {
    this.setState({
      detailModal: !this.state.detailModal
    });
    // reset the state to initial state
    if (this.state.detailModal === false) {
      this.setState(INITIAL_STATE);
    }
  };

  // turn Edit event on/off
  switchEditEvent = condition => {
    this.setState({ editEvent: condition });
  };

  //
  change = (data, e) => {
    this.setState({ [data]: e.target.value });
  };

  onChange = editorState => {
    // handle the update of the editor state
    this.setState({ editorState });
  };

  // Submit a newly created event to firebase
  submitCreateEvent = () => {
    // Defined input selections from state
    const { start, end, title, desc, type, editorState } = this.state;
    // Convert Javascript Date Object into a format that firebase can store
    let startF = fecha.format(start, "YYYY-MM-DD hh:mm:ss.SSS A");
    let endF = fecha.format(end, "YYYY-MM-DD hh:mm:ss.SSS A");
    let descEditor = editorStateToJSON(editorState);
    // Define the user ID
    const { uid } = this.props.currentUser;
    // Define the action from props so that => async dispatch => works in actions
    // const { submitevent } = this.props;
    // Call the action with the data to send
    submitevent(null, uid, startF, endF, title, desc, descEditor, type);

    // reset the state
    this.setState(INITIAL_STATE);
    // this.setState({ events: [...this.state.events, this.props.adminEvents] });
    this.createEventToggle();
  };

  updateEvent = () => {
    // Defined input selections from state
    const { start, end, title, desc, editorState, type, id } = this.state;
    // Convert Javascript Date Object into a format that firebase can store
    let startF = fecha.format(start, "YYYY-MM-DD hh:mm:ss.SSS A");
    let endF = fecha.format(end, "YYYY-MM-DD hh:mm:ss.SSS A");
    let descEditor = editorStateToJSON(editorState);
    // Define the user ID
    const { uid } = this.props.currentUser;
    // Define the action from props so that => async dispatch => works in actions
    // const { submitevent } = this.props;
    // Call the action with the data to send
    submitevent(id, uid, startF, endF, title, desc, descEditor, type);
    // reset the
    this.setState(INITIAL_STATE);
    // this.setState({ events: [...this.state.events, this.props.adminEvents] });
    this.detailEventToggle();
  };

  createEventSelect = ({ start, end }) => {
    // Open up the create event modal
    // and set the start time and end time of the event to state

    this.setState({
      createModal: !this.state.createModal,
      start,
      end
    });
  };

  detailEventSelect = event => {
    const { start, end, title, desc, descEditor, id, type } = event;
    let editState;
    if (descEditor) {
      editState = editorStateFromRaw(JSON.parse(descEditor));
    } else {
      editState = editorStateFromRaw(null);
    }

    // console.log(editState);
    // console.log(typeof editState);
    // Set the state with the details of the currently selected Event
    this.setState({
      detailModal: !this.state.createModal,
      start,
      end,
      desc,
      editorState: editState,
      title,
      id,
      type
    });
  };

  // delete an event while the edit event modal is open and the events details are in state
  deleteEvent = () => {
    this.detailEventToggle();
    const { id, type } = this.state;
    const { uid } = this.props.currentUser;
    deleteEvent(id, uid, type);
    this.setState(INITIAL_STATE);
  };

  changeStartDate = (data, string) => {
    const date = moment(this.state.start).format("dddd, MMMM Do YYYY");
    const time = string;

    const addDateandTime = moment(date + " " + time);
    const newDate = moment(addDateandTime).toDate();

    this.setState({ [data]: newDate });
  };

  changeEndDate = (data, string) => {
    const date = moment(this.state.end).format("dddd, MMMM Do YYYY");
    const time = string;

    const addDateandTime = moment(date + " " + time);
    const newDate = moment(addDateandTime).toDate();

    this.setState({ [data]: newDate });
  };

  render() {
    const { adminEvents } = this.props;

    // *** A modal to render when a new event is created ***

    const renderCreateModal = (
      <div>
        <Modal isOpen={this.state.createModal} size="lg">
          <ModalHeader toggle={this.createEventToggle}>
            <h1>Create an Event</h1>
          </ModalHeader>
          <ModalBody>
            Start:{" "}
            <p style={styles.textStyle}>
              {moment(this.state.start).format("dddd, MMMM Do YYYY, h:mm a")}
            </p>
            End:
            <p style={styles.textStyle}>
              {moment(this.state.end).format("dddd, MMMM Do YYYY, h:mm a")}
            </p>
            <Divider horizontal>Enter Event Details</Divider>
            <Form
              onSubmit={e => {
                e.preventDefault();
              }}
            >
              <FormGroup>
                <Label for="name" className="mr-sm-2">
                  Title
                </Label>
                <Input
                  type="text"
                  name="title"
                  id="title"
                  placeholder="Enter a Title ..."
                  value={this.state.title}
                  onChange={e => {
                    this.change("title", e);
                  }}
                />
              </FormGroup>
              <FormGroup>
                <Label for="name" className="mr-sm-2">
                  Description
                </Label>
                <Segment style={{ boxShadow: "none" }}>
                  <MegadraftEditor
                    editorState={this.state.editorState}
                    onChange={this.onChange}
                    placeholder="Enter a description, include links if you like!"
                  />
                </Segment>
              </FormGroup>
              {(this.props.currentUser.userType === "jedi" ||
                this.props.currentUser.userType === "admin") && (
                <FormGroup>
                  <Label for="type">Type</Label>
                  <Input
                    type="select"
                    name="type"
                    id="type"
                    value={this.state.type}
                    onChange={e => this.change("type", e)}
                  >
                    <option value="own">Own calendar</option>
                    <option value="deadline">Legislative Deadlines</option>
                    <option value="event">Events and conferences’</option>
                    <option value="national">National events</option>
                  </Input>
                </FormGroup>
              )}
            </Form>
          </ModalBody>
          <ModalFooter>
            <Button color="yellow" onClick={this.createEventToggle}>
              Cancel
            </Button>
            <Button color="blue" onClick={this.submitCreateEvent}>
              Submit
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    );

    // *** A modal to render when an already existing event in clicked on
    // showing the detail of the event ***

    const renderEventDetailModal = (
      <div>
        <Modal isOpen={this.state.detailModal} size="md">
          <ModalHeader
            toggle={() => {
              this.setState(INITIAL_STATE);
              this.detailEventToggle();
            }}
          >
            <h1>{this.state.title}</h1>
          </ModalHeader>
          <ModalBody>
            Start:{" "}
            <span style={styles.textStyle}>
              <p>
                {moment(this.state.start).format("dddd, MMMM Do YYYY, HH:mm a")}
              </p>
            </span>
            End:
            <p style={styles.textStyle}>
              {moment(this.state.end).format("dddd, MMMM Do YYYY, HH:mm a")}
            </p>
            Description:
            {this.state.editorState.getCurrentContent().getPlainText() ===
            "" ? (
              <p style={styles.textStyle}>{this.state.desc}</p>
            ) : (
              <MegadraftEditor
                editorState={this.state.editorState}
                readOnly={true}
              />
            )}
            Type: <p style={styles.textStyle}>{this.state.type}</p>
            {this.state.editEvent && (
              <Form
                onSubmit={e => {
                  e.preventDefault();
                }}
              >
                <Divider horizontal>Edit Event</Divider>
                <p>Start:</p>
                {
                  <Input
                    type="select"
                    name="date"
                    id="date"
                    onChange={e =>
                      this.changeStartDate("start", e.target.value)
                    }
                  >
                    {this.state.hours.map((hour, i) => {
                      return (
                        <option
                          key={i}
                          value={hour}
                          selected={
                            hour === moment(this.state.start).format("HH:mm")
                              ? "selected"
                              : ""
                          }
                        >{`${hour}  ${
                          hour.charAt(0) === "0" ||
                          hour.substr(0, 2) === "10" ||
                          hour.substr(0, 2) === "11"
                            ? " am"
                            : " pm"
                        }`}</option>
                      );
                    })}
                  </Input>
                }
                <p>End:</p>
                <Input
                  type="select"
                  name="date"
                  id="date"
                  onChange={e => this.changeEndDate("end", e.target.value)}
                >
                  {this.state.hours.map((hour, i) => {
                    return (
                      <option
                        key={i}
                        value={hour}
                        selected={
                          hour === moment(this.state.end).format("HH:mm")
                            ? "selected"
                            : ""
                        }
                      >{`${hour}  ${
                        hour.charAt(0) === "0" ||
                        hour.substr(0, 2) === "10" ||
                        hour.substr(0, 2) === "11"
                          ? " am"
                          : " pm"
                      }`}</option>
                    );
                  })}
                </Input>

                <FormGroup>
                  <Label for="name" className="mr-sm-2">
                    Title
                  </Label>
                  <Input
                    type="text"
                    name="title"
                    id="title"
                    placeholder="Enter a Title ..."
                    value={this.state.title}
                    onChange={e => this.change("title", e)}
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="name" className="mr-sm-2">
                    Description
                  </Label>
                  {/* Here we need to decide Megadraft or plain text... */}
                  <Segment>
                    <MegadraftEditor
                      editorState={this.state.editorState}
                      onChange={this.onChange}
                    />
                  </Segment>
                </FormGroup>
                {(this.props.currentUser.userType === "jedi" ||
                  this.props.currentUser.userType === "admin") && (
                  <FormGroup>
                    <Label for="type">Type</Label>
                    <Input
                      type="select"
                      name="type"
                      id="type"
                      value={this.state.type}
                      onChange={e => this.change("type", e)}
                    >
                      <option value="own">Own calendar</option>
                      <option value="deadline">Legislative Deadlines</option>
                      <option value="event">Events and conferences’</option>
                      <option value="national">National events</option>
                    </Input>
                  </FormGroup>
                )}
              </Form>
            )}
          </ModalBody>

          <ModalFooter>
            <Button
              color="yellow"
              onClick={() => {
                this.setState(INITIAL_STATE);
                this.detailEventToggle();
              }}
            >
              Cancel
            </Button>
            {/* The Edit button only shows when we are NOT in edit mode */}
            {!this.state.editEvent && (
              <Button
                color="blue"
                onClick={() => this.switchEditEvent(true)}
                disabled={
                  this.state.type !== "own" &&
                  (this.props.currentUser.userType === "jedi" ||
                  this.props.currentUser.userType === "admin"
                    ? false
                    : true)
                }
              >
                Edit
              </Button>
            )}
            <Button
              color="red"
              onClick={this.deleteEvent}
              disabled={
                this.state.type !== "own" &&
                (this.props.currentUser.userType === "jedi" ||
                this.props.currentUser.userType === "admin"
                  ? false
                  : true)
              }
            >
              Delete Event
            </Button>
            {/* The Submit button only show when we are in edit mode */}
            {this.state.editEvent && (
              <Button color="blue" onClick={this.updateEvent}>
                Save
              </Button>
            )}
          </ModalFooter>
        </Modal>
      </div>
    );
    return (
      <div style={{ paddingLeft: 15, paddingRight: 15, height: "80vh" }}>
        <div
          style={{
            textAlign: "center"
          }}
        >
          <Button.Group compact style={{ paddingBottom: 2 }}>
            <Button
              onClick={this.getAdminEventsSelected}
              active={this.state.activeButton === "ind"}
              name="ind"
            >
              Icon Calendar
            </Button>
            <Button
              onClick={this.getusereventsselected}
              active={this.state.activeButton === "myc"}
              name="myc"
            >
              My Calendar
            </Button>

            <Button
              onClick={this.getAllevents}
              active={this.state.activeButton === "all"}
              name="all"
            >
              Show all
            </Button>
          </Button.Group>
        </div>

        {adminEvents ? (
          <BigCalendar
            selectable
            localizer={Localizer}
            events={adminEvents}
            defaultView={BigCalendar.Views.MONTH}
            scrollToTime={new Date(1970, 1, 1, 6)}
            defaultDate={new Date(new Date().setHours(new Date().getHours()))}
            onSelectEvent={event => this.detailEventSelect(event)}
            onSelectSlot={this.createEventSelect}
            eventPropGetter={event => {
              switch (event.type) {
                case "own":
                  return {
                    style: {
                      backgroundColor: "rgba(52, 52, 52, 0.8)",
                      borderRadius: 5
                    }
                  };
                case "deadline":
                  return {
                    style: {
                      backgroundColor: "rgba(100, 0, 181, 0.8)",
                      textShadow: "1px 1px 1px black",
                      borderRadius: 5
                    }
                  };
                case "event":
                  return {
                    style: {
                      backgroundColor: "rgba(246, 56, 0, 0.8)",
                      textShadow: "1px 1px 1px black",
                      borderRadius: 5
                    }
                  };
                case "national":
                  return {
                    style: {
                      backgroundColor: "rgba(225, 223, 0, 0.8)",
                      color: "white",
                      textShadow: "1px 1px 1px black",
                      borderRadius: 5
                    }
                  };
                default:
                  return {
                    style: {
                      backgroundColor: "rgba(52, 52, 52, 0.8)",
                      borderRadius: 5
                    }
                  };
              }
            }}
          />
        ) : (
          <Dimmer active inverted>
            <Loader>Loading</Loader>
          </Dimmer>
        )}

        {renderEventDetailModal}
        {renderCreateModal}
      </div>
    );
  }
}

const styles = {
  textStyle: {
    color: "grey"
  }
};

const mapStateToProps = state => ({
  authUser: state.sessionState.authUser,
  currentUser: state.user.currentUserProps,
  adminEvents: state.user.adminEvents
});

const mapDispatchToProps = {
  submitevent
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Calendar);
