import React, { Component } from "react";
import { connect } from "react-redux";
import { Checkbox } from "semantic-ui-react";
import {
  updateUserChatroomNotifications,
  updateUserInsightNotifications
} from "../../actions/subscribers";
import { Row, Col } from "reactstrap";

class ManageNotifications extends Component {
  render() {
    return (
      <div>
        <h3>Chatrooms:</h3>
        {/* {Object.entries turns each chatroom into an array with the first item [0] being the key and the second item [1] being an object containing the subscribed node etc. All of these chatroom array are contained within an outer array. [[chatroom key,{chatroom object}], [chatroom key,{chatroom object}]]   */}
        <Row style={{ padding: 20 }}>
          {this.props.currentUser.notifications &&
            this.props.currentUser.notifications.chatrooms &&
            Object.entries(this.props.currentUser.notifications.chatrooms).map(
              (chatroom, i) => {
                return (
                  <Col sm="4">
                    <div>
                      <p style={{ marginBottom: 15 }} key={i}>
                        {chatroom[1].chatroomTitle}
                      </p>
                      <Checkbox
                        toggle
                        checked={chatroom[1].subscribed}
                        onChange={() => {
                          updateUserChatroomNotifications(
                            chatroom[0],
                            this.props.currentUser.uid,
                            !chatroom[1].subscribed
                          );
                        }}
                      />
                    </div>
                  </Col>
                );
              }
            )}
        </Row>
        <hr />
        <h3>Insights:</h3>
        <Row style={{ padding: 20 }}>
          {this.props.currentUser.notifications &&
            this.props.currentUser.notifications.insights &&
            Object.entries(this.props.currentUser.notifications.insights).map(
              (insight, i) => {
                return (
                  <Col sm="4">
                    <div>
                      <p style={{ marginBottom: 15 }} key={i}>
                        {insight[1].insightTitle}
                      </p>
                      <Checkbox
                        toggle
                        checked={insight[1].subscribed}
                        onChange={() => {
                          updateUserInsightNotifications(
                            insight[0],
                            this.props.currentUser.uid,
                            !insight[1].subscribed
                          );
                        }}
                      />
                    </div>
                  </Col>
                );
              }
            )}
        </Row>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  currentUser: state.user.currentUserProps
});

export default connect(mapStateToProps)(ManageNotifications);
