import React, { Component } from "react";
import { Container, Row, Col, Button } from "reactstrap";
import axios from "axios";
import "./index.css";

const url = "https://theenergyst.com/wp-json/wp/v2/posts";

// const negativeWords = [];
export default class NewsTicker extends Component {
  state = { data: null };

  componentDidMount = async () => {
    await axios
      .get(url)
      .then(res => this.setState(res))
      .catch(e => console.log(e));
  };

  render() {
    const { data } = this.state;
    return (
      <div>
        {data ? (
          <Container className="marquee-bg">
            <Row>
              <Col sm="4" md="2" lg="2" className="marquee-button-bg">
                <Button outline color="warning">
                  BREAKING NEWS
                </Button>
              </Col>
              <Col sm="8" md="10" lg="10" className="marquee">
                {data ? (
                  <div>
                    {data.map((news, idx) => (
                      <a href={news.link} key={idx}>
                        <span>{news.title.rendered}</span>
                      </a>
                    ))}
                  </div>
                ) : (
                  <div />
                )}
              </Col>
            </Row>
          </Container>
        ) : (
          <div />
        )}
      </div>
    );
  }
}
