// This component will be the same as Sidebar but just map over all the categories in the category reducer.

import React, { Component } from "react";
// import AllInsights from "../Insights/AllInsights";
import SubscribedInsights from "../Insights/SubscribedInsights";
import { Container } from "reactstrap";

class CategorySidebar1 extends Component {
  render() {
    return (
      <Container style={{ marginTop: 15 }}>
        <SubscribedInsights />
      </Container>
    );
  }
}

export default CategorySidebar1;
