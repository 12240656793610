import React, { Component } from "react";
import { connect } from "react-redux";
import { Col, Row, Container } from "reactstrap";
import { Segment, Card, Form, Button, Icon, Dropdown } from "semantic-ui-react";
// import { Sidebar } from "./Sidebar";
import { styles } from "../../styles";
import {
  getIndustries,
  setIndustry,
  deleteIndustry
} from "../../actions/configActions";
import {
  createNotification,
  NOTIFICATION_TYPE_WARNING,
  NOTIFICATION_TYPE_SUCCESS
} from "react-redux-notify";

const mySuccessNotification = {
  message: `Industry Sector added`,
  type: NOTIFICATION_TYPE_SUCCESS,
  duration: 3000,
  canDismiss: true,
  icon: <Icon name="check" />
};

class IndustrySectorUpload extends Component {
  constructor() {
    super();
    // create a state that can handle the change in the form input values
    this.state = {
      industryName: "",
      errors: {},
      options: [
        { key: "1", text: "Active", value: true },
        { key: "2", text: "Inactive", value: false }
      ]
    };
  }

  componentDidMount() {
    getIndustries();
  }

  handleValidation = () => {
    let { industryName } = this.state;
    let errors = {};
    let formIsValid = true;

    // categoryName
    if (industryName === "") {
      formIsValid = false;
      errors["industryName"] = (
        <p style={{ color: "red" }}>Please enter an Industry Name</p>
      );
    }

    this.setState({ errors: errors });
    return formIsValid;
  };

  handleSubmit = e => {
    e.preventDefault();

    if (this.handleValidation()) {
      const { industryName } = this.state;

      // check for invalid characters
      if (
        industryName.includes("/") ||
        industryName.includes(".") ||
        industryName.includes("#") ||
        industryName.includes("$") ||
        industryName.includes("[") ||
        industryName.includes("]")
      ) {
        const myArray = industryName.split("");
        const newArray = [];

        myArray.map((char, i) => {
          if (
            char === "." ||
            char === "/" ||
            char === "#" ||
            char === "$" ||
            char === "[" ||
            char === "]"
          ) {
            console.log("not operator !== is not working, wtf");
          } else {
            newArray.push(char);
          }
          return newArray;
        });
        const backToSting = newArray.join("");
        setIndustry(backToSting, industryName, true);
      } else {
        setIndustry(industryName, industryName, true);
      }

      this.props.createNotification(mySuccessNotification);

      // make div scroll to bottom when adding new item
      const scroller = document.querySelector(".scroller1");
      function scrollToBottom() {
        scroller.scrollTop = scroller.scrollHeight;
      }
      const observer = new MutationObserver(scrollToBottom);
      const config = { childList: true };
      observer.observe(scroller, config);
      scrollToBottom();

      this.setState({
        industryName: ""
      });
    }
  };

  handleInputChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleDelete = industryPath => {
    const sureDeleteNotification = {
      message: "Do you really want to delete this industry sector?",
      type: NOTIFICATION_TYPE_WARNING,
      duration: 0,
      canDismiss: false,
      icon: <Icon name="warning sign" />,
      acceptBtn: {
        handler: (e, notification) => {
          deleteIndustry(industryPath);
          notification.handleDismiss(notification.id);
        },
        title: "Yup",
        icon: <Icon name="thumbs up" />
      },
      denyBtn: {
        handler: (e, notification) => {
          notification.handleDismiss(notification.id);
        },
        title: "Nope",
        icon: <Icon name="thumbs down" />
      }
    };
    this.props.createNotification(sureDeleteNotification);
  };

  handleActivity = (e, data, industryPath, industryName) => {
    setIndustry(industryPath, industryName, data.value);
  };

  render() {
    const { industries } = this.props;

    return (
      <div className="animated bounceInLeft" style={{ marginTop: 10 }}>
        <Row>
          <Col>
            <Container>
              <Card fluid style={styles.profileComponentStyle} color="teal">
                <div style={{ height: 260 }}>
                  <Card.Content style={styles.cardContentStyle}>
                    <div style={styles.cardHeaderStyle}>
                      Industry Sector Upload
                    </div>
                    <div style={styles.divStyleLeftbox} />
                    <Segment style={styles.segmentStyle}>
                      <Form
                        id="categoryUploadForm"
                        onSubmit={this.handleSubmit}
                        style={styles.formStyle}
                      >
                        <Form.Field>
                          <label>Industry Sector</label>
                          {this.state.errors["industryName"]}
                          <input
                            placeholder="Enter an Industry Sector name ..."
                            name="industryName"
                            value={this.state.industryName}
                            onChange={this.handleInputChange}
                          />
                        </Form.Field>
                        <Button type="submit">Submit</Button>
                      </Form>
                    </Segment>
                  </Card.Content>
                </div>
              </Card>
              <Card fluid style={styles.profileComponentStyle} color="teal">
                <Card.Content style={styles.cardContentStyle}>
                  <div style={styles.cardHeaderStyle}>Industries</div>
                  <div style={styles.divStyleLeftbox} />
                  <div
                    className="scroller1"
                    style={{
                      height: 280,
                      overflowY: "scroll",
                      overflowX: "hidden"
                    }}
                  >
                    <Segment style={styles.segmentStyle}>
                      {industries &&
                        industries.map((industry, idx) => (
                          <div key={idx}>
                            <Row>
                              <Col sm="6" style={{ marginBottom: 15 }}>
                                <strong
                                  style={
                                    industry.isActive
                                      ? {}
                                      : {
                                          fontStyle: "italic",
                                          textDecoration: "line-through",
                                          color: "grey"
                                        }
                                  }
                                >
                                  {industry.industryName}
                                </strong>
                              </Col>
                              <Col>
                                <Dropdown
                                  onChange={(e, data) => {
                                    this.handleActivity(
                                      e,
                                      data,
                                      industry.industryPath,
                                      industry.industryName
                                    );
                                  }}
                                  value={industry.isActive}
                                  options={this.state.options}
                                  style={
                                    industry.isActive
                                      ? { color: "green" }
                                      : { color: "red" }
                                  }
                                />
                              </Col>
                              <Col>
                                <Icon
                                  style={{
                                    marginBottom: 10,
                                    cursor: "pointer"
                                  }}
                                  color="red"
                                  name="remove circle"
                                  onClick={() => {
                                    this.handleDelete(industry.industryPath);
                                  }}
                                />
                              </Col>
                            </Row>
                          </div>
                        ))}
                    </Segment>
                  </div>
                </Card.Content>
              </Card>
            </Container>
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  industries: state.appConfig.industries
});

const mapDispatchToProps = dispatch => ({
  createNotification: config => {
    dispatch(createNotification(config));
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(IndustrySectorUpload);
