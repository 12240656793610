import React, { Component } from "react";
import PropTypes from "prop-types";
import DatePicker from "react-datepicker";
import moment from "moment";

import "react-datepicker/dist/react-datepicker.css";

class RenderDatePicker extends Component {
  static propTypes = {
    input: PropTypes.shape({
      onChange: PropTypes.func.isRequired,
      value: PropTypes.string.isRequired
    }).isRequired,
    meta: PropTypes.shape({
      touched: PropTypes.bool,
      error: PropTypes.bool
    }),
    placeholder: PropTypes.string
  };

  static defaultProps = {
    placeholder: ""
  };

  handleChange = date => {
    this.props.input.onChange(moment(date).format("MM-DD"));
  };

  render() {
    const {
      input,
      placeholder,
      meta: { touched, error }
    } = this.props;

    return (
      <div>
        <DatePicker
          {...input}
          placeholder={placeholder}
          dateFormat="MM-DD"
          selected={input.value ? moment(input.value, "MM-DD") : null}
          openToDate={moment("1985-12-04")}
          onChange={this.handleChange}
          showYearDropdown={true}
          showMonthDropdown={true}
        />
        {touched && error && <span>{error}</span>}
      </div>
    );
  }
}

export default RenderDatePicker;
