import React, { Component } from "react";
import { connect } from "react-redux";
import { DebounceInput } from "react-debounce-input";
import {
  createNotification,
  NOTIFICATION_TYPE_WARNING,
} from "react-redux-notify";
import { Redirect } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";
import {
  Container,
  Col,
  Row,
  ListGroupItem,
  ListGroupItemHeading,
  ListGroupItemText,
} from "reactstrap";
import {
  Card,
  Icon,
  Input as SemanticInput,
  Dropdown,
  Button,
  Flag,
  Popup,
  Loader,
  Grid,
  Header,
} from "semantic-ui-react";
import { getLegalCategories } from "../../actions/configActions";
import {
  getAllLegalRegisterItems,
  getMyLegalRegisterItems,
  removeLegalItem,
  resetLegalItemEdit,
  setEditKey,
  setLegalItem,
} from "../../actions/legalRegister";
import LegalItemModal from "../Modals/LegalItemModal";
import { renderIconSymbol } from "../../constants/index";
import { countries } from "../../constants";
import { styles, buttonStyles } from "../../styles";

import { renderFlag } from "./flag";

class MyLegalRegisterList extends Component {
  state = {
    modal: false,
    editRoute: false,
    items: this.props.currentLegalItems,
    limit: 10,
    itemAmount: 0,
  };

  componentDidMount() {
    getLegalCategories();
    // if user has any previous legal items, make a request for them
    this.props.currentMyLegalItems && getMyLegalRegisterItems();
    resetLegalItemEdit();
  }

  fetchMoreData = () => {
    const itemAmount = this.state.itemAmount + 10;
    const limit = this.state.limit + 10;

    setTimeout(() => {
      this.setState({
        limit: limit,
        items: this.props.currentLegalItems.slice(0, itemAmount),
        itemAmount: itemAmount,
      });
    }, 1000);
  };

  handleSearch = e => {
    getMyLegalRegisterItems(
      this.props.myCategories,
      e.target.value,
      this.props.myCountries
    );
  };

  handleCategoryFilter = (e, data) => {
    getMyLegalRegisterItems(
      data.value,
      this.props.myQuery,
      this.props.myCountries
    );
  };

  handleCountryFilter = (e, data) => {
    getMyLegalRegisterItems(
      this.props.myCategories,
      this.props.myQuery,
      data.value
    );
  };

  handleSelectEdit = key => {
    setEditKey(this.props.currentUser.uid, key, this.props.allLegalItems);
    this.setState({ editRoute: true });
  };

  toggle = key => {
    if (typeof key === "string") {
      setLegalItem(key);
    }
    this.setState({ modal: !this.state.modal });
  };

  handleRemoveClick = item => {
    //Checks if user wants to remove the item and history and then deletes the item and all history from the users myLegal Items
    const { createNotification } = this.props;
    const sureRemoveNotification = {
      message: `Warning - this will erase all "${item.title.substr(0, 40)}${
        item.title.length > 50 ? " ..." : ""
      }" legal item notes and compliance history from your account, are you sure you want to do this ?`,
      type: NOTIFICATION_TYPE_WARNING,
      duration: 0,
      canDismiss: false,
      icon: <Icon name="warning sign" />,
      acceptBtn: {
        handler: (e, notification) => {
          removeLegalItem(this.props.currentUser.uid, item.key);
          // call action to update view and display item in AllLegalItemsList
          getAllLegalRegisterItems(
            this.props.allCategories,
            this.props.allQuery,
            this.props.allCountries
          );
          // update view to remove item from this component
          getMyLegalRegisterItems(
            this.props.myCategories,
            this.props.myQuery,
            this.props.myCountries
          );

          notification.handleDismiss(notification.id);
        },
        title: "Yup",
        icon: <Icon name="thumbs up" />,
      },
      denyBtn: {
        handler: (e, notification) => {
          notification.handleDismiss(notification.id);
        },
        title: "Nope",
        icon: <Icon name="thumbs down" />,
      },
    };
    createNotification(sureRemoveNotification);
  };

  renderAllMyLegalItems() {
    return this.props.currentLegalItems.length > 0 ? (
      this.props.currentLegalItems.map((item, index) => {
        return (
          <ListGroupItem
            style={index >= this.state.limit ? { display: "none" } : {}}
          >
            <Row>
              <Col>
                <ListGroupItemHeading>{item.title}</ListGroupItemHeading>
              </Col>
            </Row>
            <Row>
              <Col>
                <ListGroupItemText style={{ marginTop: 5 }}>
                  {item.filterByArea}
                </ListGroupItemText>

                {item.country &&
                  item.country.map((flag, i) => renderFlag(flag, i))}

                <div style={{ marginLeft: -5, marginTop: 5 }}>
                  {this.props.currentMyLegalItems &&
                    this.props.currentMyLegalItems[item.key] &&
                    renderIconSymbol(
                      this.props.currentMyLegalItems[`${item.key}`][
                        "userStatus"
                      ]
                    )}
                </div>
              </Col>

              <Col>
                <Button
                  style={buttonStyles.primaryButton}
                  color="teal"
                  onClick={() => this.toggle(item.key)}
                >
                  View
                </Button>
                {/* if user has legal register access display a different button  */}
                {this.props.currentUser.legalAccess === "on" ? (
                  <Button
                    onClick={() => {
                      this.handleSelectEdit(item.key);
                    }}
                    style={buttonStyles.editButton}
                    color="grey"
                  >
                    Edit
                  </Button>
                ) : (
                  <Popup
                    trigger={
                      <Button color="olive" style={{ margin: 3 }} type="button">
                        Edit
                      </Button>
                    }
                    content={
                      <Grid centered>
                        <Grid.Column textAlign="center">
                          <Header as="h4">Access Required</Header>
                          <p>
                            For more information please email:{" "}
                            <a href="mailto:georgina@theicon.org.uk?subject=Icon Subscription Enquiry">
                              georgina@theicon.org.uk
                            </a>
                          </p>
                        </Grid.Column>
                      </Grid>
                    }
                  />
                )}

                <Button
                  onClick={() => this.handleRemoveClick(item)}
                  style={buttonStyles.deleteButton}
                  color="grey"
                >
                  Remove
                </Button>
              </Col>
            </Row>
          </ListGroupItem>
        );
      })
    ) : this.props.allQuery !== "" ? (
      <p style={{ textAlign: "center", fontSize: 18 }}>
        We're sorry, no results for {<strong>"{this.props.myQuery}"</strong>}{" "}
        were found.
      </p>
    ) : (
      <p style={{ textAlign: "center", fontSize: 18 }}>
        We're sorry, no results were found.
      </p>
    );
  }

  render() {
    if (this.state.editRoute) {
      return <Redirect to="/EditLegalItem" />;
    }

    const legalCategoriesArray = [];

    this.props.legalCategories &&
      this.props.legalCategories.map((category, i) => {
        return (
          category.isActive &&
          legalCategoriesArray.push({
            key: i,
            text: category.legalCategoryName,
            value: category.legalCategoryName,
          })
        );
      });

    console.log("MY LEGAL REGISTER", { props: this.props });

    return (
      <Container style={{ marginBottom: 75 }}>
        <Card color="teal" fluid style={styles.profileComponentStyle}>
          <Card.Content style={styles.cardContentStyle}>
            <div style={styles.cardHeaderStyle}>
              My Legal Register
              {/* displays number of your legal registers */}
              {` (${this.props.currentLegalItems.length})`}
            </div>
            <div style={styles.divStyleRightbox} />
            <Row>
              <Col sm="12">
                <div style={{ padding: 15 }}>
                  <Card.Meta style={styles.textElementStyle}>
                    • This list contains your 'Legal Items'.
                  </Card.Meta>
                  <Card.Meta style={styles.textElementStyle}>
                    • The Icon shows what state each one is in.
                  </Card.Meta>
                  <Card.Meta style={styles.textElementStyle}>
                    • The edit button takes you to a page where you can add
                    information about your compliance for that legal item in
                    particular.
                  </Card.Meta>
                  <div>
                    <SemanticInput icon="search">
                      <DebounceInput
                        style={{
                          margin: "auto",
                          borderRadius: 20,
                          marginTop: 10,
                          width: 250,
                        }}
                        type="Search"
                        debounceTimeout={500}
                        minLength={2}
                        placeholder=" Search ... "
                        onChange={this.handleSearch}
                      />
                      <Icon name="search" style={{ marginTop: 4 }} />
                    </SemanticInput>

                    <Dropdown
                      style={{ marginTop: 15, marginBottom: 15 }}
                      placeholder="Filter By Legal Category"
                      fluid
                      multiple
                      selection
                      options={legalCategoriesArray}
                      onChange={this.handleCategoryFilter}
                    />

                    <Dropdown
                      style={{ marginTop: 15, marginBottom: 15 }}
                      placeholder="Filter By Country"
                      fluid
                      multiple
                      selection
                      options={countries}
                      onChange={this.handleCountryFilter}
                    />
                  </div>

                  <InfiniteScroll
                    style={{ overflowX: "hidden" }}
                    dataLength={this.state.items.length}
                    next={this.fetchMoreData}
                    hasMore={true}
                    height={550}
                    loader={
                      this.state.limit >=
                      this.props.currentLegalItems.length ? (
                        <h4> </h4>
                      ) : (
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            marginTop: 20,
                          }}
                        >
                          <Loader active inline content="Loading..." />
                        </div>
                      )
                    }
                  >
                    {this.renderAllMyLegalItems()}
                  </InfiniteScroll>
                </div>
              </Col>
            </Row>
          </Card.Content>
          {this.state.modal && (
            <LegalItemModal modal={this.state.modal} toggle={this.toggle} />
          )}
        </Card>
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  allLegalItems: state.legalItems.currentAllLegalItemsProps,
  currentUser: state.user.currentUserProps,
  currentLegalItems: state.legalItems.currentMyLegalItemsProps,
  currentMyLegalItems: state.user.currentUserProps.MyLegalItems,
  currentLegalItem: state.legalItems.currentLegalItemProps,
  legalCategories: state.appConfig.legalCategories,
  editRoute: state.legalItems.editRoute,
  // filters
  myCategories: state.legalItems.currentMyLegalItemsCategories,
  myCountries: state.legalItems.currentMyLegalItemsCountries,
  myQuery: state.legalItems.currentMyLegalItemsQuery,
  allCategories: state.legalItems.currentAllLegalItemsCategories,
  allCountries: state.legalItems.currentAllLegalItemsCountries,
  allQuery: state.legalItems.currentAllLegalItemsQuery,
});

const mapDispatchToProps = dispatch => ({
  createNotification: config => {
    dispatch(createNotification(config));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MyLegalRegisterList);
