import { firebase } from "../FireBase/firebase";
const uuidv4 = require("uuid/v4");

export const newStorageKey = firebase
  .database()
  .ref()
  .child("storage")
  .push().key;

export const newDbKey = firebase
  .database()
  .ref()
  .child("insights")
  .push().key;

export const addStorageRecord = (fireKey, url, file, folder) => {
  console.log("addStorageRecord: ", fireKey, url, file, folder);

  firebase
    .database()
    .ref(`storage/${fireKey}`)
    .set({
      key: fireKey,
      publicUrl: url,
      name: file.name,
      parentFolder: folder,
      dateAdded: Date.now()
    });
};
export const addImageRecord = (imageFireKey, imageUrl, file, imageFolder) => {
  console.log("addImageRecord ", imageFireKey, imageUrl, file, imageFolder);
  firebase
    .database()
    .ref(`images/${imageFireKey}`)
    .set({
      key: imageFireKey,
      publicUrl: imageUrl,
      name: file.name,
      parentFolder: imageFolder,
      dateAdded: Date.now()
    });
};

// I dont think we are using upload file
export const uploadFile = (file, author) => {
  const folder = uuidv4();
  console.log("Unique folder to be " + folder);
  firebase
    .storage()
    .ref()
    .child(`insights/${folder}/${file.name}`)
    .put(file)
    .then(snapshot => {
      return snapshot.ref.getDownloadURL(); // Will return a promise with the download link
    })
    .then(downloadURL => {
      const url = downloadURL;
      const fireKey = newStorageKey;
      addStorageRecord(fireKey, url, file, folder);
      console.log("File " + fireKey + " available at " + url);
    })
    .catch(function(error) {
      console.error("Upload failed:", error);
    });
};

export const removeFileFromInsight = fileName => {
  console.log("Storage remove here");
  const toDelete = firebase
    .storage()
    .ref()
    .child(`insights/${fileName}`);

  toDelete
    .delete()
    .then(function() {
      console.log("removed file from storage");
    })
    .catch(function(error) {
      console.error("Removing file failed:", error);
    });
};
