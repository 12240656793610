import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Container, Col, ListGroup, ListGroupItem, Badge } from "reactstrap";
import { chatroomStyles } from "../../styles";
import { getAllChatrooms, setCurrentChatroom } from "../../actions/Posts";
import {
  updateUserChatroomNotifications,
  setUserChatroomNotifications
} from "../../actions/subscribers";
import { Icon } from "semantic-ui-react";
import {
  createNotification,
  NOTIFICATION_TYPE_SUCCESS
} from "react-redux-notify";

const subscribed = {
  message: `Subscribed to chatroom`,
  type: NOTIFICATION_TYPE_SUCCESS,
  duration: 1000,
  canDismiss: true,
  icon: <Icon name="check" />
};

const unsubscribed = {
  message: `Unsubscribed from chatroom`,
  type: NOTIFICATION_TYPE_SUCCESS,
  duration: 1000,
  canDismiss: true,
  icon: <Icon name="check" />
};

class Chatrooms extends Component {
  componentDidMount() {
    getAllChatrooms();
  }

  handleSelectClick = key => {
    setCurrentChatroom(key);
  };

  render() {
    return (
      <Container>
        <Col style={chatroomStyles.chatroomStyle}>
          <h1>Chatrooms</h1>
          <p>
            Welcome to chatrooms, here you can pick a group and join in on a
            discussion
          </p>
          <p>Click the bell icon on the right to toggle notifications</p>
          <ListGroup>
            {this.props.AllChatrooms.map((chatroomData, i) => {
              const isNotified =
                this.props.currentUserProps &&
                this.props.currentUserProps.notifications &&
                this.props.currentUserProps.notifications.chatrooms &&
                this.props.currentUserProps.notifications.chatrooms[
                  chatroomData.key
                ] &&
                this.props.currentUserProps.notifications.chatrooms[
                  chatroomData.key
                ].subscribed;

              return (
                <ListGroupItem key={chatroomData.key}>
                  <Link
                    to="/Forum"
                    onClick={() => this.handleSelectClick(chatroomData.key)}
                  >
                    {chatroomData.chatroomTitle}{" "}
                    <Badge pill>{chatroomData.totalMessages}</Badge>
                  </Link>{" "}
                  {/* bell icon */}
                  <Icon
                    name={isNotified ? "bell" : "bell slash outline"}
                    style={{
                      float: "right",
                      fontSize: 20,
                      cursor: "pointer",
                      opacity: 0.8
                    }}
                    onClick={() => {
                      isNotified
                        ? updateUserChatroomNotifications(
                            chatroomData.key,
                            this.props.currentUserProps.uid,
                            !isNotified
                          )
                        : setUserChatroomNotifications(
                            chatroomData.key,
                            this.props.currentUserProps.uid,
                            chatroomData.chatroomTitle
                          );

                      isNotified
                        ? this.props.createNotification(unsubscribed)
                        : this.props.createNotification(subscribed);
                    }}
                  />
                </ListGroupItem>
              );
            })}
          </ListGroup>
        </Col>
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  AllChatrooms: state.chatroomsState.currentAllChatroomsProps,
  currentUserProps: state.user.currentUserProps
});

const mapDispatchToProps = dispatch => ({
  createNotification: config => {
    dispatch(createNotification(config));
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Chatrooms);
