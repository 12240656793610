import React from "react";
import { Link } from "react-router-dom";

export const PrivacyPolicy = () => (
  <div style={{ padding: 25 }}>
    <h1 style={{ textAlign: "center" }}>Icon Privacy Policy </h1>
    <hr />
    <p>
      Industrial & Commercial Operations Network Ltd (“We”) are committed to
      protecting and respecting your privacy. This policy (together with our 
      <Link to="/TermsAndConditions">terms of use</Link> and any other documents
      referred to on it) sets out the basis on which any personal data we
      collect from you, or that you provide to us, will be processed by us.
      Please read the following carefully to understand our views and practices
      regarding your personal data and how we will treat it. By visiting{" "}
      <a href="https://theicon.org.uk/">www.theicon.org.uk </a> you are
      accepting and consenting to the practices described in this policy. For
      the purpose of the General Data Protection Regulations (the
      “Regulations”), the data controller is Industrial & Commerical Operations
      Network Ltd. of Unit 1, First Floor, Courtney House, 12 Dudley Street,
      Luton, LU2 0NT. [Our nominated representative for the purpose of the
      Regulations is Georgina Penfold].
    </p>
    <p>
      <h3>1. Information we may collect from you </h3>
      <p>
        <p>We may collect and process the following data about you:</p>
        <p>
          <p>
            <p>1.1. Information you give us. </p>
            <p>
              You may give us information about you by filling in forms on our
              site <a href="https://theicon.org.uk/">www.theicon.org.uk </a>{" "}
              (“the Site”) or by corresponding with us by phone, e-mail or
              otherwise. This includes information you provide when you register
              to become a member of the Site (“Member”), place an order for
              services on the Site (“Order”), participate in discussion boards
              or other social media functions on the Site, enter a competition,
              promotion or survey, and when you report a problem with the Site.
              We advise that Members use their work contact details, not
              personal details, at all times.{" "}
            </p>
            <p>
              The information you give us may include your name, address, e-mail
              address and phone number, financial and credit card information,
              professional experience and skills, education, personal
              description and photograph and other personal details.{" "}
            </p>
            <p>
              We do not have visibility to your password; this is personal to
              you and is securely encrypted in a firebase database.
            </p>
          </p>
          <p>
            <p>
              <p>1.2. Information we collect about you.</p>{" "}
              <p>
                With regard to each of your visits to the Site we may
                automatically collect the following information:{" "}
              </p>
            </p>
            <p>
              1.2.1. technical information, including the Internet protocol (IP)
              address used to connect your computer to the Internet, your login
              information, browser type and version, time zone setting, browser
              plug-in types and versions, operating system and platform;
            </p>
            <p>
              1.2.2. information about your visit, including the full Uniform
              Resource Locators (URL) clickstream to, through and from the Site
              (including date and time); products you viewed or searched for;
              page response times, download errors, length of visits to certain
              pages, page interaction information (such as scrolling, clicks,
              and mouse-overs), and methods used to browse away from the page
              and any phone number used to call our customer service number.{" "}
            </p>
          </p>
          <p>
            1.3. Information we receive from other sources. We may receive
            information about you if you use any of the other websites we
            operate or the other services we provide. We are also working
            closely with third parties (including, for example, business
            partners, sub-contractors in technical, payment and delivery
            services, advertising networks, analytics providers, search
            information providers, credit reference agencies) and may receive
            information about you from them.
          </p>
        </p>
      </p>
    </p>
    <p>
      <h3>2. Cookies </h3>
      <p>
        Our website does not currently use cookies to distinguish you from other
        users of our website. Cookies may be deployed in future and if that
        happens, users will be notified of this change in an appropriate and
        timely manner.
      </p>
    </p>
    <p>
      <h3>3. Uses made of the information </h3>
      <p>
        <p>We use information held about you in the following ways: </p>
        <p>
          <p>3.1. Information you give us. We will use this information: </p>
          <p>
            3.1.1. to carry out our obligations arising from any contracts
            entered into between you and us and to provide you with the
            information, products and services that you request from us;{" "}
          </p>
          <p>
            3.1.2. to provide you with information about other goods and
            services we offer that are similar to those that you have already
            purchased or enquired about;{" "}
          </p>
          <p>
            3.1.3. to comprise your public Member profile on the Site, the
            content of which will be available to members of the public. [Your
            public Member profile will usually include [your name, job title and
            photograph to the extent that you have added these to your Member
            profile];]{" "}
          </p>
          <p>
            3.1.4. to comprise the area of your Member profile accessible only
            by other Members of the Site. This area of your Member profile will
            usually include [details of your current employer and further detail
            regarding your professional experience and interests to the extent
            that you have added these to your Member profile];{" "}
          </p>
          <p>
            3.1.5. to provide you with information about services we feel may
            interest you. We will contact you by electronic means only if you
            have consented to this. If you do not want us to use your data in
            this way, please tick the relevant box situated on the forms on
            which we collect your data (the Membership application form) or let
            us know by emailing{" "}
            <a href="mailto:hello@theicon.org.uk">hello@theicon.org.uk</a>
          </p>
          <p>
            3.1.6. to notify you about changes to the Site or the services
            offered by us;{" "}
          </p>
          <p>
            3.1.7. to ensure that content from the Site is presented in the most
            effective manner for you and for your computer.{" "}
          </p>
        </p>
        <p>
          3.2. Information we collect about you. We will use this information:
          3.2.1. to administer the Site and for internal operations, including
          troubleshooting, data analysis, testing, research, statistical and
          survey purposes; 3.2.2. to improve the Site to ensure that content is
          presented in the most effective manner for you and for your computer;
          3.2.3. to allow you to participate in interactive features of the
          Site, when you choose to do so; 3.2.4. as part of our efforts to keep
          the Site safe and secure; 3.2.5. to measure or understand the
          effectiveness of advertising we serve to you and others, and to
          deliver relevant advertising to you; 3.2.6. to make suggestions and
          recommendations to you and other users of the Site about goods or
          services that may interest you or them.{" "}
        </p>
        <p>
          3.3. Information we receive from other sources. We may combine this
          information with information you give to us and information we collect
          about you. We may us this information and the combined information for
          the purposes set out above (depending on the types of information we
          receive).
        </p>
      </p>
    </p>
    <p>
      <h3>4. Disclosure of your information </h3>
      <p>
        <p>
          4.1. We may share your information with selected third parties
          including:
        </p>
        <p>
          <p>
            4.1.1. Business partners, suppliers and sub-contractors for the
            performance of any contract we enter into with them or you.{" "}
          </p>
          <p>
            4.1.2. Advertisers and advertising networks that require the data to
            select and serve relevant adverts to you and others. [We do not
            disclose information about identifiable individuals to our
            advertisers, but we may provide them with aggregate information
            about our users (for example, we may inform them that 500 men aged
            under 30 have clicked on their advertisement on any given day). We
            may also use such aggregate information to help advertisers reach
            the kind of audience they want to target (for example, women in
            London). We may make use of the personal data we have collected from
            you to enable us to comply with our advertisers’ wishes by
            displaying their advertisement to that target audience].{" "}
          </p>
          <p>
            4.1.3. Analytics and search engine providers that assist us in the
            improvement and optimisation of the Site.{" "}
          </p>
          <p>
            4.1.4. [Credit reference agencies for the purpose of assessing your
            credit score where we need this before entering into a contract with
            you.]{" "}
          </p>
        </p>
        <p>4.2. We may disclose your personal information to third parties:</p>
        <p>
          <p>
            4.2.1. In the event that we sell or buy any business or assets, in
            which case we may disclose your personal data to the prospective
            seller or buyer of such business or assets.{" "}
          </p>
          <p>
            4.2.2. If icon or substantially all of its assets are acquired by a
            third party, in which case personal data held by it about its
            members or customers will be one of the transferred assets.{" "}
          </p>
          <p>
            4.2.3. If we are under a duty to disclose or share your personal
            data in order to comply with any legal obligation, or in order to
            enforce or apply our terms of use and other agreements; or to
            protect the rights, property, or safety of icon, our customers, or
            others. This includes exchanging information with other companies
            and organisations for the purposes of fraud protection and credit
            risk reduction.
          </p>
        </p>
      </p>
    </p>
    <p>
      <h3>5. Where we store your personal data </h3>
      <p>
        <p>
          5.1. The data that we collect from you may be transferred to, and
          stored at, a destination outside the European Economic Area (“EEA”).
          It may also be processed by staff operating outside the EEA who work
          for us or for one of our suppliers. Such staff maybe engaged in, among
          other things, the fulfilment of your Order, the processing of your
          payment details and the provision of support services. By submitting
          your personal data, you agree to this transfer, storing or processing.
          We will take all steps reasonably necessary to ensure that your data
          is treated securely and in accordance with this privacy policy.{" "}
        </p>
        <p>
          5.2. [All information you provide to us is stored on our secure
          servers.] [Any payment transactions will be encrypted [using SSL
          technology].] Where we have given you (or where you have chosen) a
          password which enables you to access the Member areas of the Site, you
          are responsible for keeping this password confidential. We ask you not
          to share a password with anyone.{" "}
        </p>
        <p>
          5.3. Unfortunately, the transmission of information via the internet
          is not completely secure. Although we will do our best to protect your
          personal data, we cannot guarantee the security of your data
          transmitted to the Site; any transmission is at your own risk. Once we
          have received your information, we will use strict procedures and
          security features to try to prevent unauthorised access.
        </p>
      </p>
    </p>
    <p>
      <h3>6. Your rights </h3>
      <p>
        <p>
          6.1. You have the right to ask us not to process your personal data
          for marketing purposes. We will usually inform you (before collecting
          your data) if we intend to use your data for such purposes or if we
          intend to disclose your information to any third party for such
          purposes. You can exercise your right to prevent such processing by
          checking certain boxes on the forms we use to collect your data. You
          can also exercise the right at any time by contacting us at 
          <a href="mailto:hello@theicon.org.uk">hello@theicon.org.uk</a>
        </p>
        <p>
          6.2. The Site may, from time to time, contain links to and from the
          websites of our partner networks, advertisers and affiliates. If you
          follow a link to any of these websites, please note that these
          websites have their own privacy policies and that we do not accept any
          responsibility or liability for these policies. Please check these
          policies before you submit any personal data to these websites.
        </p>
      </p>
    </p>
    <p>
      <h3>7. Access to information </h3>
      <p>
        The Act gives you the right to access information held about you. Your
        right of access can be exercised in accordance with the Act. Any access
        request may be subject to a fee of £10 to meet our costs in providing
        you with details of the information we hold about you.
      </p>
    </p>
    <p>
      <h3>8. Changes to our privacy policy </h3>
      <p>
        We may change our privacy policy at any time, and such changes may take
        immediate effect. Any changes we may make to our privacy policy in the
        future will be posted on this page and, where appropriate, notified to
        you by e-mail. Please check back frequently to see any updates or
        changes to our privacy policy.
      </p>
    </p>
    <p>
      <h3>9. Contact </h3>
      <p>
        Questions, comments and requests regarding this privacy policy are
        welcomed and should be addressed to{" "}
        <a href="mailto:hello@theicon.org.uk">hello@theicon.org.uk</a>
      </p>
    </p>
    <p>
      <h3>10. Disclaimer </h3>
      <p>
        The content accessible via this website is for information purposes
        only, and is no substitute for obtaining specific professional advice.
        You are responsible for having appropriate measures to protect your
        systems against viruses and other harmful components, and we will not be
        liable for any loss or damage you suffer as result of your use of this
        site or any information obtained from or via it. If you have any
        objection to any of the content on the site, please let us know by
        e-mailing:  
        <a href="mailto:hello@theicon.org.uk">hello@theicon.org.uk</a>, and we
        will investigate as soon as possible.
      </p>
    </p>
    <p>
      <h3>11. Copyright </h3>
      <p>
        Copyright in the content on this site belongs to Industrial & Commercial
        Operations Network Ltd. You may view and use the content for personal
        use (including in your professional capacity), but may not make any copy
        (electronic or otherwise) for distribution to others without our prior
        approval.
      </p>
    </p>
  </div>
);
