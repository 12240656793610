import React, { Component } from "react";
import { connect } from "react-redux";
import { Row, Col, Container, TabContent, TabPane } from "reactstrap";
import SupplierUpload from "./SupplierUpload";
import UserTable from "./UserTable";
import CompanyTypeUpload from "./CompanyTypeUpload";

class UserManager extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: "1"
    };
  }

  render() {
    return (
      <Container fluid>
        <div>
          <Row>
            <Col md="12" sm="12">
              <TabContent activeTab={this.state.activeTab}>
                <TabPane tabId="1">
                  <div className="animated fadeIn">
                    <UserTable />
                    <Row style={{ marginBottom: 20 }}>
                      <Col sm="6">
                        <SupplierUpload />
                      </Col>
                      <Col sm="6">
                        <CompanyTypeUpload />
                      </Col>
                    </Row>
                  </div>
                </TabPane>
              </TabContent>
            </Col>
          </Row>
        </div>
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  authUser: state.sessionState.authUser,
  currentUser: state.user.currentUserProps
});

export default connect(mapStateToProps)(UserManager);
