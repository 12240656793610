// This component will be the same as Sidebar but just map over all the categories in the category reducer.

import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { Icon, Sidebar, Button, Segment, Menu } from "semantic-ui-react";
import { getCategories } from "../../actions/configActions";
import { getAllInsights } from "../../actions/insights";
import AllInsights from "../Insights/AllInsights";
import { Container } from "reactstrap";

class CategorySidebar extends Component {
  constructor() {
    super();
    this.state = {
      visible: false,
      activeItem: "ALL INSIGHTS"
    };
  }

  componentDidMount() {
    getAllInsights("ALL INSIGHTS");
    getCategories();
  }

  componentWillReceiveProps(nextProps) {
    const updateActiveItem =
      nextProps.activeItem && nextProps.activeItem !== this.state.activeItem;

    if (updateActiveItem) {
      this.setState({ activeItem: nextProps.activeItem });
    }
  }

  handleButtonClick = () => this.setState({ visible: !this.state.visible });

  handleItemClick = (e, { name }) => {
    this.setState({ activeItem: name });
    this.setState({ visible: false });
  };

  renderPages = categorySlug => {
    const { activeItem } = this.state;
    switch (activeItem) {
      case `${categorySlug}`:
        getAllInsights(categorySlug);
        return <AllInsights header={categorySlug} />;
      default:
        getAllInsights("ALL INSIGHTS");
        return <AllInsights header="ALL INSIGHTS" />;
    }
  };

  render() {
    const { visible, activeItem } = this.state;
    const { categories } = this.props;

    if (this.props.currentUser.uid === null) {
      return <Redirect to="/" />;
    }
    return (
      <div>
        <div
          style={{
            backgroundImage: "linear-gradient(to right, #404040, #22262e)",
            marginBottom: -15
          }}
        >
          <Button
            onClick={this.handleButtonClick}
            size="tiny"
            style={{ margin: 15, marginBottom: 10, marginLeft: 10 }}
          >
            <Icon name="bars" /> Menu
          </Button>
        </div>

        <Sidebar.Pushable
          as={Segment}
          style={{
            borderWidth: 0,
            borderRadius: 0,
            backgroundColor: "#dfeff4"
          }}
        >
          <Sidebar
            as={Menu}
            animation="push"
            icon="labeled"
            inverted
            vertical
            visible={visible}
            width="wide"
            style={{
              backgroundImage: "linear-gradient(to right, #404040, #22262e)"
            }}
          >
            <Menu.Item
              name="ALL INSIGHTS"
              active={activeItem === "ALL INSIGHTS"}
              onClick={this.handleItemClick}
              to={`/Category/ALL INSIGHTS`}
              style={{ textAlign: "left" }}
            >
              <p>
                ALL INSIGHTS
                <Icon
                  style={styles.chevronStyle}
                  name="chevron right"
                  size="tiny"
                />
              </p>
            </Menu.Item>

            {categories.map((category, idx) => (
              <div key={idx}>
                {category.categoryVisable === true ? (
                  <Menu.Item
                    name={category.categorySlug}
                    active={activeItem === category.categorySlug}
                    onClick={this.handleItemClick}
                    to={`/Category/${category.categorySlug}`}
                    style={{ textAlign: "left" }}
                  >
                    <p>
                      {category.categoryName}{" "}
                      <Icon
                        style={styles.chevronStyle}
                        name="chevron right"
                        size="tiny"
                      />
                    </p>
                  </Menu.Item>
                ) : (
                  <div />
                )}
              </div>
            ))}
          </Sidebar>

          <Sidebar.Pusher>
            <Segment
              style={{
                minHeight: "100vh",
                borderWidth: 0,
                backgroundColor: "#dfeff4",
                padding: 10
              }}
            >
              <Container>{this.renderPages(activeItem)}</Container>
            </Segment>
          </Sidebar.Pusher>
        </Sidebar.Pushable>
      </div>
    );
  }
}

const styles = {
  chevronStyle: {
    fontSize: 12,
    marginLeft: 10
  }
};

const mapStateToProps = state => ({
  categories: state.appConfig.categories,
  authUser: state.sessionState.authUser,
  currentUser: state.user.currentUserProps,
  query: state.insights.query,
  tags: state.insights.tags
});

export default connect(mapStateToProps)(CategorySidebar);
