import React from "react";
import { Container, Row, Col } from "reactstrap";
import { Link } from "react-router-dom";

const Footer = () => (
  <div
    style={{
      backgroundColor: "black",
      color: "#f8f8f8",
      textAlign: "center",
      padding: 10
    }}
  >
    <Container>
      <Row>
        <Col />
        <Col>
          <Row style={{ justifyContent: "center", fontSize: 10 }}>
            <div>
              <Link to="/PrivacyPolicy" data-cy="footer-privacy-policy-link">
                Privacy Policy
              </Link>{" "}
              |{" "}
              <Link
                to="/TermsAndConditions"
                data-cy="footer-terms-and-conditions-link"
              >
                Terms and Conditions{" "}
              </Link>
              <p>
                Industrial & Commerical Operations Network Ltd. of Unit 1, First
                Floor, Courtney House, 12 Dudley Street, Luton, LU2 0NT
              </p>
            </div>
          </Row>
        </Col>
        <Col style={{ textAlign: "right" }}>
          <a href="http://www.offkey-ltd.com/" data-cy="footer-offkey-link">
            <p style={{ fontSize: 10 }}>
              powered by offkey © 2018 | built in Shoreditch & Brooklyn
            </p>
          </a>
        </Col>
      </Row>
    </Container>
  </div>
);

export default Footer;
