import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { auth } from "../FireBase/index";
import {
  Collapse,
  NavbarToggler,
  NavItem,
  NavLink,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownItem,
  DropdownMenu
} from "reactstrap";
import { Button } from "semantic-ui-react";
import Logo from "../img/Logo.png";
import jediLogo from "../img/jedi_logo.png";
import adminLogo from "../img/admin_logo.png";
import { headerStyles } from "../styles";
import { changeActiveItem } from "../actions/configActions";
import store from "../store";

const NavigationJediAuth = props => (
  <ul className="navbar-nav ml-auto">
    <NavItem style={headerStyles.navItemStyle}>
      <NavLink tag={Link} to="/browse" className="navbar-brand">
        <img src={jediLogo} alt="Logo" style={{ width: 50 }} />
      </NavLink>
    </NavItem>
    <NavItem style={headerStyles.navItemStyle}>
      <NavLink
        tag={Link}
        to="/myaccount"
        onClick={() => {
          props.closeNav();
          // changes the activeItem which causes Dashboard to be displayed everytime you click "dashboard"
          // even after you have interacted with events that change the activeitem.
          // Dashboard should always be the navlink that is displayed by default
          changeActiveItem("Dashboard");
        }}
      >
        Dashboard
      </NavLink>
    </NavItem>
    <NavItem style={headerStyles.navItemStyle} className="nav-item">
      <NavLink
        tag={Link}
        to="/browse"
        className="nav-pills"
        onClick={props.closeNav}
      >
        Insights Library
      </NavLink>
    </NavItem>

    <NavItem style={headerStyles.navItemStyle}>
      <NavLink tag={Link} to="/Chatrooms" onClick={props.closeNav}>
        Chatrooms
      </NavLink>
    </NavItem>
    <UncontrolledDropdown nav inNavbar style={headerStyles.navItemStyle}>
      <DropdownToggle nav caret>
        Admin Tools
      </DropdownToggle>
      <DropdownMenu right>
        <DropdownItem
          tag={Link}
          to="/dashboard"
          onClick={() => {
            props.closeNav();
            changeActiveItem("1");
          }}
        >
          Admin Dashboard
        </DropdownItem>
        <DropdownItem divider />
        <DropdownItem tag={Link} to="/admin" onClick={props.closeNav}>
          Insights Upload
        </DropdownItem>
        <DropdownItem divider />
        <DropdownItem
          tag={Link}
          to="/LegalRegisterUpload"
          onClick={props.closeNav}
        >
          Legal Register Upload
        </DropdownItem>
        <DropdownItem divider />
        <DropdownItem tag={Link} to="/Analytics" onClick={props.closeNav}>
          Analytics
        </DropdownItem>
      </DropdownMenu>
    </UncontrolledDropdown>
    <UncontrolledDropdown nav inNavbar style={headerStyles.navItemStyle}>
      <DropdownToggle nav caret>
        Jedi
      </DropdownToggle>
      <DropdownMenu right>
        <DropdownItem tag={Link} to="/CreateChatroom" onClick={props.closeNav}>
          Create Chatroom
        </DropdownItem>
        <DropdownItem divider />
        <DropdownItem tag={Link} to="/Analytics" onClick={props.closeNav}>
          Analytics
        </DropdownItem>
      </DropdownMenu>
    </UncontrolledDropdown>
    <NavItem style={headerStyles.navItemStyle} tag={Link} to="/">
      <Button
        type="button"
        onClick={() => {
          auth.doSignOut();
          store.dispatch({ type: "CLEAR_REDUX" });
        }}
      >
        Sign Out
      </Button>
    </NavItem>
    <UncontrolledDropdown nav inNavbar style={headerStyles.navItemStyle}>
      <DropdownToggle nav caret>
        Feedback
      </DropdownToggle>
      <DropdownMenu right>
        <DropdownItem href="mailto:christan@offkey-ltd.com?subject=BUG">
          I found a bug!
        </DropdownItem>
        <DropdownItem href="mailto:christan@offkey-ltd.com?subject=FEATURE_REQUEST">
          I have an idea!
        </DropdownItem>
      </DropdownMenu>
    </UncontrolledDropdown>
  </ul>
);

const NavigationAdminAuth = props => (
  <ul className="navbar-nav ml-auto">
    <NavItem style={headerStyles.navItemStyle}>
      <NavLink tag={Link} to="/Browse" className="navbar-brand">
        <img src={adminLogo} alt="Logo" style={{ width: 50 }} />
      </NavLink>
    </NavItem>
    <NavItem style={headerStyles.navItemStyle}>
      <NavLink
        tag={Link}
        to="/myaccount"
        onClick={() => {
          props.closeNav();
          changeActiveItem("Dashboard");
        }}
      >
        Dashboard
      </NavLink>
    </NavItem>
    <NavItem style={headerStyles.navItemStyle} className="nav-item">
      <NavLink
        tag={Link}
        to="/Browse"
        className="nav-pills"
        onClick={props.closeNav}
      >
        Insights Library
      </NavLink>
    </NavItem>
    <NavItem style={headerStyles.navItemStyle}>
      <NavLink tag={Link} to="/Chatrooms" onClick={props.closeNav}>
        Chatrooms
      </NavLink>
    </NavItem>
    <UncontrolledDropdown nav inNavbar style={headerStyles.navItemStyle}>
      <DropdownToggle nav caret>
        Admin Tools
      </DropdownToggle>
      <DropdownMenu right>
        <DropdownItem
          tag={Link}
          to="/dashboard"
          onClick={() => {
            props.closeNav();
            changeActiveItem("1");
          }}
        >
          Admin Dashboard
        </DropdownItem>
        <DropdownItem divider />
        <DropdownItem tag={Link} to="/admin" onClick={props.closeNav}>
          Insights Upload
        </DropdownItem>
        <DropdownItem divider />
        <DropdownItem
          tag={Link}
          to="/LegalRegisterUpload"
          onClick={props.closeNav}
        >
          Legal Register Upload
        </DropdownItem>
        <DropdownItem divider />
        <DropdownItem tag={Link} to="/Analytics" onClick={props.closeNav}>
          Analytics
        </DropdownItem>
      </DropdownMenu>
    </UncontrolledDropdown>
    <NavItem style={headerStyles.navItemStyle} tag={Link} to="/">
      <Button
        type="button"
        onClick={() => {
          auth.doSignOut();
          store.dispatch({ type: "CLEAR_REDUX" });
        }}
      >
        Sign Out
      </Button>
    </NavItem>
    <UncontrolledDropdown nav inNavbar style={headerStyles.navItemStyle}>
      <DropdownToggle nav caret>
        Feedback
      </DropdownToggle>
      <DropdownMenu right>
        <DropdownItem href="mailto:christan@offkey-ltd.com?subject=BUG">
          I found a bug!
        </DropdownItem>
        <DropdownItem href="mailto:christan@offkey-ltd.com?subject=FEATURE_REQUEST">
          I have an idea!
        </DropdownItem>
      </DropdownMenu>
    </UncontrolledDropdown>
  </ul>
);

const NavigationAuth = props => (
  <ul className="navbar-nav ml-auto">
    <NavItem style={headerStyles.navItemStyle}>
      <NavLink
        tag={Link}
        to="/myaccount"
        onClick={() => {
          props.closeNav();
          changeActiveItem("Dashboard");
        }}
      >
        Dashboard
      </NavLink>
    </NavItem>
    <NavItem style={headerStyles.navItemStyle} className="nav-item">
      <NavLink
        tag={Link}
        to="/Browse"
        className="nav-pills"
        onClick={props.closeNav}
      >
        Insights Library
      </NavLink>
    </NavItem>
    <NavItem style={headerStyles.navItemStyle}>
      <NavLink tag={Link} to="/Chatrooms" onClick={props.closeNav}>
        Chatrooms
      </NavLink>
    </NavItem>
    <NavItem style={headerStyles.navItemStyle} tag={Link} to="/">
      <Button
        type="button"
        onClick={() => {
          auth.doSignOut();
          store.dispatch({ type: "CLEAR_REDUX" });
        }}
      >
        Sign Out
      </Button>
    </NavItem>
    <UncontrolledDropdown nav inNavbar style={headerStyles.navItemStyle}>
      <DropdownToggle nav caret>
        Feedback
      </DropdownToggle>
      <DropdownMenu right>
        <DropdownItem href="mailto:christan@offkey-ltd.com?subject=BUG">
          I found a bug!
        </DropdownItem>
        <DropdownItem href="mailto:christan@offkey-ltd.com?subject=FEATURE_REQUEST">
          I have an idea!
        </DropdownItem>
      </DropdownMenu>
    </UncontrolledDropdown>
  </ul>
);

const NavigationGuestAuth = props => (
  <ul className="navbar-nav ml-auto">
    <NavItem style={headerStyles.navItemStyle}>
      <NavLink tag={Link} to="/myaccount" onClick={props.closeNav}>
        Dashboard
      </NavLink>
    </NavItem>
    <NavItem style={headerStyles.navItemStyle} className="nav-item">
      <NavLink
        tag={Link}
        to="/Browse"
        className="nav-pills"
        onClick={props.closeNav}
      >
        Insights Library
      </NavLink>
    </NavItem>
    <NavItem style={headerStyles.navItemStyle} tag={Link} to="/">
      <Button
        type="button"
        onClick={() => {
          auth.doSignOut();
          store.dispatch({ type: "CLEAR_REDUX" });
        }}
      >
        Sign Out
      </Button>
    </NavItem>
  </ul>
);

const NavigationNewUserAuth = props => (
  <ul className="navbar-nav ml-auto">
    <NavItem style={headerStyles.navItemStyle} tag={Link} to="/">
      <Button
        type="button"
        onClick={() => {
          auth.doSignOut();
          store.dispatch({ type: "CLEAR_REDUX" });
        }}
      >
        Sign Out
      </Button>
    </NavItem>
  </ul>
);

const NavigationNonAuth = props => (
  <ul className="navbar-nav ml-auto">
    <NavItem style={headerStyles.navItemStyle}>
      <NavLink
        tag={Link}
        to="/LogIn"
        onClick={props.closeNav}
        data-cy="LoginNonAuth-Link-HeaderNav"
      >
        Log In
      </NavLink>
    </NavItem>
    <NavItem style={headerStyles.navItemStyle}>
      <NavLink tag={Link} to="/Register" onClick={props.closeNav}>
        Register
      </NavLink>
    </NavItem>
  </ul>
);

class HeaderNav extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false
    };

    this.toggle = this.toggle.bind(this);
  }
  toggle() {
    this.setState({
      isOpen: !this.state.isOpen
    });
  }

  render() {
    // this.props.currentUser.userType;
    const userType = this.props.currentUser && this.props.currentUser.userType;
    let navAuth;
    if (this.props.currentUser === null) {
      navAuth = <NavigationNonAuth />;
    } else if (userType === "admin") {
      navAuth = <NavigationAdminAuth closeNav={this.toggle} />;
    } else if (userType === "jedi") {
      navAuth = <NavigationJediAuth closeNav={this.toggle} />;
    } else if (userType === "guest") {
      navAuth = <NavigationGuestAuth closeNav={this.toggle} />;
    } else if (userType === "newUser" || userType === "expiredUser") {
      navAuth = <NavigationNewUserAuth closeNav={this.toggle} />;
    } else if (this.props.authUser) {
      navAuth = (
        <NavigationAuth
          currentUser={this.props.currentUser}
          closeNav={this.toggle}
        />
      );
    } else {
      navAuth = <NavigationNonAuth closeNav={this.toggle} />;
    }

    return (
      <nav className="sticky-top" style={{ zIndex: 1000 }}>
        <div
          className="navbar navbar-expand-lg navbar-dark sticky-top"
          style={headerStyles.navbarStyle}
        >
          <Link
            className="navbar-brand"
            to="/"
            // style={{ display: "flex", flexDirection: "column" }}
          >
            <img src={Logo} alt="Logo" style={{ width: 87.04 }} />
          </Link>
          <div
            style={{
              fontSize: 8,
              display: "flex",
              alignSelf: "flex-end",
              justifyContent: "flex-end"
            }}
          >
            {process.env.REACT_APP_VERSION}
          </div>
          <NavbarToggler onClick={this.toggle} />
          <Collapse isOpen={this.state.isOpen} navbar>
            {navAuth}
          </Collapse>
        </div>
        <div style={headerStyles.headerDec} />
      </nav>
    );
  }
}

const mapStateToProps = state => ({
  authUser: state.sessionState.authUser,
  currentUser: state.user.currentUserProps
});

export default connect(mapStateToProps)(HeaderNav);
