import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Card,
  Segment,
  Dropdown,
  Item,
  Label,
  Button,
  Icon,
  Image,
  Loader,
  Input as SemanticInput
} from "semantic-ui-react";
import { Row, Col, Container } from "reactstrap";
import TimeAgo from "react-timeago";
import { DebounceInput } from "react-debounce-input";
import {
  setInsight,
  removeMyInsightAction,
  getAllMyInsights,
  getSavedInsights
} from "../../actions/insights";
import StoryImg from "../../img/StoryImg.png";
import BlankProfilePicture from "../../img/blank-profile-picture.png";
import InsightModal from "./InsightModal";
import SubscribeModal from "./SubscribeModal";
import { styles, buttonStyles } from "../../styles";
import InfiniteScroll from "react-infinite-scroll-component";
import { getIndustries } from "../../actions/configActions";

//  Notifications
import {
  createNotification,
  NOTIFICATION_TYPE_WARNING
} from "react-redux-notify";

class WishList extends Component {
  state = {
    query: "",
    tags: "",
    categories: "",
    modal: false,
    limit: 10,
    items: this.props.currentAllInsights,
    itemAmount: 0
  };

  componentDidMount = () => {
    window.scrollTo(0, 0);
    getAllMyInsights(this.props.authUser.uid);
    getSavedInsights();
    getIndustries();
  };

  handleRemoveClick = key => {
    const { createNotification } = this.props;

    const sureRemoveNotification = {
      message: "Do you really want to remove this insight?",
      type: NOTIFICATION_TYPE_WARNING,
      duration: 0,
      canDismiss: false,
      icon: <Icon name="warning sign" />,
      acceptBtn: {
        handler: (e, notification) => {
          removeMyInsightAction(this.props.currentUser.uid, key);
          notification.handleDismiss(notification.id);
          getSavedInsights();
        },
        title: "Yup",
        icon: <Icon name="thumbs up" />
      },
      denyBtn: {
        handler: (e, notification) => {
          notification.handleDismiss(notification.id);
        },
        title: "Nope",
        icon: <Icon name="thumbs down" />
      }
    };

    createNotification(sureRemoveNotification);
  };

  toggle = key => {
    // eslint-disable-next-line
    if (typeof key == "string") {
      setInsight(key);
    }
    this.setState({ modal: !this.state.modal });
  };

  fetchMoreData = () => {
    const itemAmount = this.state.itemAmount + 5;
    const limit = this.state.limit + 5;

    setTimeout(() => {
      this.setState({
        limit: limit,
        items: this.props.currentAllInsights.slice(0, itemAmount),
        itemAmount: itemAmount
      });
    }, 1000);
  };

  handleSearch = e => {
    this.setState({ query: e.target.value });
    getSavedInsights(this.state.categories, e.target.value, this.state.tags);
  };

  handleSelect = (e, data) => {
    this.setState({ tags: data.value });
    getSavedInsights(this.state.categories, this.state.query, data.value);
  };

  handleCategory = (e, data) => {
    this.setState({ categories: data.value });
    getSavedInsights(data.value, this.state.query, this.state.tags);
  };

  render() {
    const insightCategoriesArray = [];

    this.props.categories.map((category, i) => {
      return (
        category.categoryVisable &&
        insightCategoriesArray.push({
          key: i,
          text: category.categoryName,
          value: category.categorySlug
        })
      );
    });

    const insightIndustriesArray = [];

    this.props.industries &&
      this.props.industries.map((industry, i) => {
        return (
          industry.isActive &&
          insightIndustriesArray.push({
            key: i,
            text: industry.industryName,
            value: industry.industryName
          })
        );
      });

    return (
      <Container>
        <Card fluid style={styles.profileComponentStyle}>
          <Card.Content style={styles.cardContentStyle}>
            <div style={styles.cardHeaderStyle}>
              Saved Insights
              {/* displays how many insights you have */}
              {` (${this.props.savedInsights.length})`}
            </div>
            <div style={styles.divStyleRightbox} />
            <Row>
              <Col sm="12">
                <div style={{ padding: 15 }}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      flexWrap: "wrap",
                      alignItems: "flex-start",
                      justifyContent: "space-between"
                    }}
                  >
                    <SemanticInput icon="search">
                      <DebounceInput
                        style={{ borderRadius: 20, maxWidth: 250, margin: 5 }}
                        type="Search"
                        debounceTimeout={500}
                        minLength={2}
                        onChange={this.handleSearch}
                        placeholder=" Search ... "
                      />
                      <Icon name="search" />
                    </SemanticInput>

                    <Dropdown
                      placeholder="Choose an industry segment ..."
                      style={{ maxWidth: 500, margin: 5 }}
                      fluid
                      multiple
                      selection
                      options={insightIndustriesArray}
                      onChange={this.handleSelect}
                    />

                    <Dropdown
                      placeholder="Choose a Category ..."
                      style={{ maxWidth: 250, margin: 5 }}
                      fluid
                      multiple
                      selection
                      options={insightCategoriesArray}
                      onChange={this.handleCategory}
                      value={this.state.categories}
                    />
                  </div>

                  <Segment style={styles.segmentStyle}>
                    <InfiniteScroll
                      style={{ overflowX: "hidden" }}
                      dataLength={this.state.items.length}
                      next={this.fetchMoreData}
                      hasMore={true}
                      loader={
                        this.state.limit >= this.props.savedInsights.length ? (
                          <h4> </h4>
                        ) : (
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center"
                            }}
                          >
                            <Loader active inline content="Loading..." />
                          </div>
                        )
                      }
                    >
                      <Item.Group divided>
                        {this.props.savedInsights.length > 0 ? (
                          this.props.savedInsights.map((insight, index) => (
                            <Item
                              key={index}
                              style={
                                index >= this.state.limit
                                  ? { display: "none" }
                                  : {}
                              }
                            >
                              <Item.Image
                                size="tiny"
                                alt="Energy insights feature"
                                src={insight["featuredImage"] || StoryImg}
                              />
                              <Item.Content>
                                <Item.Header>{insight.title}</Item.Header>
                                <Item.Meta>
                                  <TimeAgo date={insight.time} />
                                </Item.Meta>
                                <Segment.Group
                                  horizontal
                                  style={styles.segmentProfile}
                                >
                                  <Segment inverted>
                                    <Label>
                                      <Icon
                                        name="hashtag"
                                        style={{ marginRight: 0 }}
                                      />
                                      {insight.category}
                                    </Label>
                                  </Segment>
                                  <Segment style={{ textAlign: "right" }}>
                                    <span style={styles.authorDisplayName}>
                                      {insight.authorDisplayName}
                                    </span>{" "}
                                    <Image
                                      src={
                                        insight.authorProfilePhoto ||
                                        BlankProfilePicture
                                      }
                                      circular
                                      avatar
                                      alt="insight author pic"
                                    />
                                  </Segment>
                                </Segment.Group>
                                <Item.Description>
                                  {insight.subTitle}
                                </Item.Description>
                                <Item.Extra>
                                  {/* <br /> */}
                                  <Row>
                                    {this.props.currentUser.userType ===
                                    "guest" ? (
                                      <div>
                                        <SubscribeModal buttonLabel="Subscribe" />
                                      </div>
                                    ) : (
                                      <div>
                                        <Button
                                          onClick={() =>
                                            this.toggle(insight.key)
                                          }
                                          style={buttonStyles.primaryButton}
                                          color="teal"
                                        >
                                          View
                                        </Button>
                                        <Button
                                          style={buttonStyles.deleteButton}
                                          color="teal"
                                          onClick={() =>
                                            this.handleRemoveClick(insight.key)
                                          }
                                        >
                                          <Icon name="remove circle" />
                                          Remove
                                        </Button>
                                      </div>
                                    )}
                                  </Row>
                                </Item.Extra>
                              </Item.Content>
                            </Item>
                          ))
                        ) : this.state.query !== "" ? (
                          <p style={{ textAlign: "center", fontSize: 18 }}>
                            We're sorry, no results for{" "}
                            {<strong>"{this.state.query}"</strong>} were found.
                          </p>
                        ) : (
                          <p style={{ textAlign: "center", fontSize: 18 }}>
                            We're sorry, no results were found.
                          </p>
                        )}
                      </Item.Group>
                      <InsightModal
                        tags={this.state.tags}
                        modal={this.state.modal}
                        toggle={this.toggle}
                      />
                    </InfiniteScroll>
                  </Segment>
                </div>
              </Col>
            </Row>
          </Card.Content>
        </Card>
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  authUser: state.sessionState.authUser,
  currentUser: state.user.currentUserProps,
  myInsights: state.insights.myInsightsProps,
  savedInsights: state.insights.savedInsights,
  currentAllInsights: state.insights.currentAllInsightsProps,
  categories: state.appConfig.categories,
  industries: state.appConfig.industries
});

const mapDispatchToProps = dispatch => ({
  createNotification: config => {
    dispatch(createNotification(config));
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WishList);
