import { db } from "./firebase";
// import * as firebase from "./firebase";

// User API

export const doCreateUser = (id, email, nameOne, nameTwo) => {
  const current = Date.now();
  const combineName = nameOne + " " + nameTwo;

  return db.ref(`users/${id}`).set({
    email,
    uid: id,
    firstName: nameOne,
    lastName: nameTwo,
    displayName: combineName,
    DOB: "",
    legalAccess: "off",
    userType: "newUser",
    termsAgree: true,
    privacyAgree: true,
    signUp: current
  });
};
export const onceGetUsers = () => db.ref("users").once("value");

//User Types
//"newUser",
//"admin",
//"guest",
//"subscriber"

// Other db APIs ...

export const getAdminEvents = () => async dispatch => {
  db.ref("adminEvents").once("value", snap => {
    const adminEvents = snap.val();
    dispatch({
      type: "GET_ADMIN_EVENTS",
      adminEvents
    });
  });
};

export const eventAction = (
  name,
  date,
  start,
  description,
  duration,
  uid,
  eid,
  type,
  typeSwitch
) => async dispatch => {
  const id = eid ? eid : db.ref(`users/${uid}/events`).push().key;
  const event = { id, name, date, start, duration, type, description, uid };

  if (type === "own") {
    db.ref(`users/${uid}/events/${id}`).set(event);
    if (typeSwitch) {
      db.ref(`adminEvents/${id}`).remove();
    }
  } else {
    db.ref(`adminEvents/${id}`).set(event);
    if (typeSwitch) {
      db.ref(`users/${uid}/events/${id}`).remove();
    }
  }

  dispatch({
    type: "EVENT_ACTION",
    event,
    typeSwitch
  });
};

export const deleteEvent = (uid, id, eType) => async dispatch => {
  eType === "own"
    ? db.ref(`users/${uid}/events/${id}`).remove()
    : db.ref(`adminEvents/${id}`).remove();
  dispatch({
    type: "DELETE_EVENT",
    eType,
    id
  });
};

export const writeInsight = (uid, company, phone) => {
  db.ref(`users/${uid}`).update({ company, phone });
};
