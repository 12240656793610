import React, { Component } from "react";
import { connect } from "react-redux";
import { Link, Redirect } from "react-router-dom";
import { Button } from "semantic-ui-react";
import { Container, Row, Col } from "reactstrap";
import Header from "./Header";
import Learn from "./Learn";
import StayInformed from "./StayInformed";
import Consult from "./Consult";
import Connect from "./Connect";
import { changeActiveItem } from "../../actions/configActions";
import ScrollAnimation from "react-animate-on-scroll";

class LandingPage extends Component {
  render() {
    const userType = this.props.currentUser && this.props.currentUser.userType;
    const { currentUser } = this.props;

    if (this.props.currentUser && userType === "newUser") {
      return <Redirect to="/NewUser" />;
    } else if (userType === "expiredUser") {
      return <Redirect to="/ExpiredUser" />;
    }

    if (currentUser && currentUser.uid) {
      changeActiveItem("Dashboard");
    }

    if (
      this.props.activeItemProps === "Dashboard" &&
      currentUser &&
      currentUser.uid
    ) {
      return <Redirect to="/myaccount" />;
    }

    return (
      <div style={styles.textStyle}>
        <Header />
        <Container style={{ padding: 50 }}>
          <Row>
            <Col lg="4" />
            <Col lg="4">
              <ScrollAnimation
                animateIn="tada"
                delay={300}
                duration={2}
                offset={1}
              >
                <Button
                  primary
                  fluid
                  style={{ padding: 15 }}
                  as={Link}
                  to="/Register"
                  data-cy="register-btn-LandingPage"
                >
                  REGISTER
                </Button>
              </ScrollAnimation>
            </Col>
            <Col lg="4" />
          </Row>
        </Container>
        <div
          style={{ backgroundColor: "white", padding: 50, color: "#404040" }}
        >
          <h1>The definitive tool for energy professionals</h1>
        </div>
        <Learn />
        <div style={styles.divStyle} />
        <StayInformed />
        <div style={styles.divStyle} />
        <Consult />
        <div style={styles.divStyle} />
        <Connect />
        <div style={styles.divStyle} />
        <Container style={{ padding: 50 }}>
          <Row>
            <Col lg="4" />
            <Col lg="4">
              <Button
                primary
                fluid
                style={{ padding: 15 }}
                as={Link}
                to="/Register"
                data-cy="register-btn-LandingPage"
              >
                REGISTER
              </Button>
            </Col>
            <Col lg="4" />
          </Row>
        </Container>
      </div>
    );
  }
}

const styles = {
  textStyle: {
    textAlign: "center",
    margin: 0,
    marginTop: -50,
  },
  divStyle: {
    height: 3,
    backgroundImage: "linear-gradient(to right, #FFDF00, #F63800, #6400B5)",
  },
};

const mapStateToProps = state => ({
  authUser: state.sessionState.authUser,
  currentUser: state.user.currentUserProps,
  activeItemProps: state.appConfig.activeItem,
});

export default connect(mapStateToProps)(LandingPage);
