import React, { Component } from "react";
import { Container } from "semantic-ui-react";
import styled from "styled-components";

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
`;

const GridItem = styled.div`
  text-align: center;
  padding: 10px;
  margin: 25px;
  border-style: solid;
  border-width: 1px 1px;
  border-radius: 7px;
  border-color: lightgrey;
  background-color: #dfeff4;
  box-shadow: 10px 10px 33px -12px rgba(0, 0, 0, 0.75);
`;

class Stats extends Component {
  render() {
    return (
      <Container fluid>
        <Grid>
          <GridItem>
            <iframe
              title="This week vs. Last Week (Pageviews)"
              width="600"
              height="371"
              seamless
              frameborder="0"
              scrolling="no"
              src="https://docs.google.com/spreadsheets/d/e/2PACX-1vT3N5FDz3Sk7OcHpQat4YO46jISZTZlcioITCvYGilqXkbs87QWaADYVCKxwcGjkecjfuaS5u4VG7Vv/pubchart?oid=823457298&amp;format=interactive"
            />
          </GridItem>
          <GridItem>
            {" "}
            <iframe
              title="This Month vs. Last Month (Pageviews)"
              width="600"
              height="371"
              seamless
              frameborder="0"
              scrolling="no"
              src="https://docs.google.com/spreadsheets/d/e/2PACX-1vT3N5FDz3Sk7OcHpQat4YO46jISZTZlcioITCvYGilqXkbs87QWaADYVCKxwcGjkecjfuaS5u4VG7Vv/pubchart?oid=875245792&amp;format=interactive"
            />
          </GridItem>
          <GridItem>
            <iframe
              title="Most Read Insights (Top 10 Last Month)"
              width="600"
              height="386.5"
              seamless
              frameborder="0"
              scrolling="no"
              src="https://docs.google.com/spreadsheets/d/e/2PACX-1vT3N5FDz3Sk7OcHpQat4YO46jISZTZlcioITCvYGilqXkbs87QWaADYVCKxwcGjkecjfuaS5u4VG7Vv/pubchart?oid=2035979817&amp;format=interactive"
            />
          </GridItem>
          <GridItem>
            <iframe
              title="Most Popular Categories (Last Month)"
              width="600"
              height="371"
              seamless
              frameborder="0"
              scrolling="no"
              src="https://docs.google.com/spreadsheets/d/e/2PACX-1vT3N5FDz3Sk7OcHpQat4YO46jISZTZlcioITCvYGilqXkbs87QWaADYVCKxwcGjkecjfuaS5u4VG7Vv/pubchart?oid=197237605&amp;format=interactive"
            />
          </GridItem>
          <GridItem>
            <iframe
              title="Top Content Providers (Last Month)"
              width="600"
              height="371"
              seamless
              frameborder="0"
              scrolling="no"
              src="https://docs.google.com/spreadsheets/d/e/2PACX-1vT3N5FDz3Sk7OcHpQat4YO46jISZTZlcioITCvYGilqXkbs87QWaADYVCKxwcGjkecjfuaS5u4VG7Vv/pubchart?oid=308746740&amp;format=interactive"
            />
          </GridItem>
          <GridItem>
            <iframe
              title="Downloaded Content (Last Month)"
              width="600"
              height="371"
              seamless
              frameborder="0"
              scrolling="no"
              src="https://docs.google.com/spreadsheets/d/e/2PACX-1vT3N5FDz3Sk7OcHpQat4YO46jISZTZlcioITCvYGilqXkbs87QWaADYVCKxwcGjkecjfuaS5u4VG7Vv/pubchart?oid=1328104109&amp;format=interactive"
            />
          </GridItem>
        </Grid>
      </Container>
    );
  }
}

export default Stats;
