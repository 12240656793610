import store from "../store";
import uuidv4 from "uuid/v4";
import { firebase } from "../FireBase/firebase";
import { loadProfile } from "./users";
import { DELETE_LEGALITEM } from "./types";
import { addStorageRecord } from "./storage";
import _ from "lodash";

export const snapshotToArray = snapshot => {
  let returnArr = [];

  snapshot.forEach(childSnapshot => {
    let item = childSnapshot.val();
    item.key = childSnapshot.key;
    returnArr.push(item);
  });

  return returnArr;
};

export const SET_CURRENT_MY_LEGAL_REGISTER_ITEMS =
  "SET_CURRENT_MY_LEGAL_REGISTER_ITEMS";
export const setCurrentMyLegalRegisterItems = (
  finalResult,
  categories,
  query,
  countries,
  uid
) => ({
  type: SET_CURRENT_MY_LEGAL_REGISTER_ITEMS,
  finalResult,
  categories,
  query,
  countries,
  uid,
});

export const SET_CURRENT_ALL_LEGAL_REGISTER_ITEMS =
  "SET_CURRENT_ALL_LEGAL_REGISTER_ITEMS";
export const setCurrentAllLegalRegisterItems = (
  finalResult,
  categories,
  query,
  countries
) => ({
  type: SET_CURRENT_ALL_LEGAL_REGISTER_ITEMS,
  finalResult,
  categories,
  query,
  countries,
});

export const SET_CURRENT_LEGAL_REGISTER_ITEM =
  "SET_CURRENT_LEGAL_REGISTER_ITEM";
export const setCurrentLegalItemProps = legalItem => ({
  type: SET_CURRENT_LEGAL_REGISTER_ITEM,
  legalItem,
});

export const SET_CURRENT_LEGAL_ITEM_EDIT = "SET_CURRENT_LEGAL_ITEM_EDIT";
export const setCurrentLegalItemEdit = (editItem, editRoute) => ({
  type: SET_CURRENT_LEGAL_ITEM_EDIT,
  editItem,
  editRoute,
});

export const SET_CURRENT_LEGAL_SUBSCRIBER_EDIT =
  "SET_CURRENT_LEGAL_SUBSCRIBER_EDIT";
export const setCurrentLegalSubscriberEdit = userObject => ({
  type: SET_CURRENT_LEGAL_SUBSCRIBER_EDIT,
  userObject,
});

export const SET_CURRENT_SUBSCRIBER_LEGAL_KEY =
  "SET_CURRENT_SUBSCRIBER_LEGAL_KEY";
export const setCurrentLegalKey = key => ({
  type: SET_CURRENT_SUBSCRIBER_LEGAL_KEY,
  key,
});

// if not viewing single legal item edit then reset to empty objects
export const resetLegalItemEdit = () => {
  store.dispatch(setCurrentLegalItemProps({}));
  store.dispatch(setCurrentLegalItemEdit({}, false));
};

export const getMyLegalRegisterItems = (categories, query, countries, uid) => {
  firebase
    .database()
    .ref("legalRegister")
    .orderByChild("time")
    .on("value", function (snapshot) {
      // all Legal register items
      const allLegalItems = snapshotToArray(snapshot);
      // gets my legal register items from the user reducer
      const myLegalItems = store.getState().user.currentUserProps.MyLegalItems;
      // get user id
      const uid = store.getState().user.currentUserProps.uid;

      let userList;
      let legalItemsList;

      myLegalItems && (userList = Object.values(myLegalItems));
      allLegalItems && (legalItemsList = Object.values(allLegalItems));

      if (userList && legalItemsList) {
        // filters over all legal items and returns only the ones where the key matches mylegalItems and their status is active (in the users MyLegalRegisterList)
        const compare = (legalItemsList, userList) => {
          let result = legalItemsList.filter(legalItem =>
            userList.find(
              userListItem =>
                legalItem.key === userListItem.key &&
                userListItem.status === "active"
            )
          );
          return result.sort((a, b) => {
            // if the legal item has a users node, sort it by users.timeAdded
            return (
              a.users &&
              a.users[uid] &&
              b.users &&
              b.users[uid] &&
              b.users[uid].timeAdded - a.users[uid].timeAdded
            );
          });
        };

        let finalResult = compare(legalItemsList, userList);

        // if there are any filter beings passed in, filters the legal items
        if (categories || countries || query || query === "") {
          // creates a regex based on the users search query
          const regex = new RegExp(query, "i");

          // filters the legal item array based on regex search query
          const filterLegalItemTitle = _.filter(finalResult, item =>
            regex.test(item.title)
          );

          const filterCountries = _.filter(filterLegalItemTitle, item =>
            // if there are no country filters, return all items regardless
            // eslint-disable-next-line
            countries == ""
              ? item
              : // else go over country property on each legal item and return the legal items that match the countries passed in by user
                item.country &&
                countries.some(countries => item.country.includes(countries))
          );
          const filteredLegalItemArray = _.filter(filterCountries, item =>
            // eslint-disable-next-line
            categories == ""
              ? item
              : item.filterByArea &&
                categories.some(category =>
                  item.filterByArea.includes(category)
                )
          );

          finalResult = filteredLegalItemArray;
        }

        store.dispatch(
          setCurrentMyLegalRegisterItems(
            finalResult,
            categories || "",
            query || "",
            countries || "",
            uid
          )
        );
      }
    });
};

// only for allLegalItemsList use getAllLegalItems instead
export const getAllLegalRegisterItems = (categories, query, countries) => {
  firebase
    .database()
    .ref("legalRegister")
    .orderByChild("time")
    .on("value", function (snapshot) {
      const currentLegalItems = snapshotToArray(snapshot);
      const currentMyLegalItems = store.getState().user.currentUserProps
        .MyLegalItems;

      let userList = [];
      let legalItemsList = [];
      // let result = [];

      if (currentMyLegalItems) userList = Object.values(currentMyLegalItems);
      if (currentLegalItems) legalItemsList = Object.values(currentLegalItems);

      const compare = (legalItemsList, userList) => {
        // finds all items except for ones in mylegalregister
        let result = legalItemsList.filter(
          legalItem =>
            !userList.find(
              userListItem =>
                legalItem.key === userListItem.key &&
                userListItem.status === "active"
            )
        );
        return result;
      };

      let finalResult = compare(legalItemsList, userList);

      if (
        categories ||
        countries ||
        query ||
        // this condition is needed incase a user "empties" the search bar again and wants to return all items
        query === ""
      ) {
        const regex = new RegExp(query, "i");

        const filterLegalItemTitle = _.filter(finalResult, item =>
          regex.test(item.title)
        );

        const filterCountries = _.filter(filterLegalItemTitle, item =>
          // eslint-disable-next-line
          countries == ""
            ? item
            : item.country &&
              countries.some(countries => item.country.includes(countries))
        );

        const filteredLegalItemArray = _.filter(filterCountries, item =>
          // eslint-disable-next-line
          categories == ""
            ? item
            : item.filterByArea &&
              categories.some(category => item.filterByArea.includes(category))
        );

        finalResult = filteredLegalItemArray;
      }

      console.log({ finalResult });

      store.dispatch(
        setCurrentAllLegalRegisterItems(
          finalResult,
          categories || "",
          query || "",
          countries || ""
        )
      );
    });
};

// for getting all legal items in the app
export const getAllLegalItems = (categories, query, countries) => {
  firebase
    .database()
    .ref("legalRegister")
    .orderByChild("time")
    .on("value", function (snapshot) {
      const registerItems = snapshotToArray(snapshot);
      const allLegalItems = Array.from(registerItems.reverse());
      store.dispatch({
        type: "SET_ALL_LEGAL_ITEMS",
        allLegalItems,
        categories,
        query,
        countries,
      });
    });
};

export const addLegalRegisterItem = (
  applicableTo,
  consequence,
  description,
  filterByArea,
  headlineDetails,
  link,
  tagsString,
  title,
  author,
  authorId,
  howThisApplies,
  country
) => {
  const newFireKey = firebase.database().ref().child("legalRegister").push()
    .key;
  firebase
    .database()
    .ref("legalRegister/" + newFireKey)
    .set({
      applicableTo,
      consequence,
      description,
      filterByArea,
      headlineDetails,
      link,
      regulator: tagsString,
      title,
      authorDisplayName: author,
      authorId,
      howThisApplies,
      key: newFireKey,
      time: Date.now(),
      country,
    })
    // .then(window.alert(`Your Legal Item has been successfully uploaded...`))
    .catch(function (error) {
      console.log("Add Legal Items Failed because : " + error.message);
    });
};

export const updateLegalRegisterItem = (
  applicableTo,
  consequence,
  description,
  filterByArea,
  headlineDetails,
  link,
  tagsString,
  title,
  author,
  authorId,
  key,
  howThisApplies,
  country
) => {
  firebase
    .database()
    .ref("legalRegister/" + key)
    .update({
      applicableTo,
      consequence,
      description,
      filterByArea,
      headlineDetails,
      link,
      regulator: tagsString,
      title,
      authorDisplayName: author,
      authorId,
      time: Date.now(),
      howThisApplies,
      country,
    })
    .catch(function (error) {
      console.log("Add Legal Items Failed because : " + error.message);
    });
};

// adds a node to legal register for each users so that you can sort by time added
export const updateLegalRegisterTimeAdded = (uid, key) => {
  firebase
    .database()
    .ref(`legalRegister/${key}/users/${uid}`)
    .update({
      timeAdded: Date.now(),
    })
    .catch(function (error) {
      console.log("Add Legal Items Failed because : " + error.message);
    });
};

//this is not being used in legal register right now, legal modal takes care of the item
export const setLegalItem = key => {
  firebase
    .database()
    .ref(`legalRegister/${key}`)
    .on("value", function (snap) {
      const legalItem = snap.val();
      store.dispatch(setCurrentLegalItemProps(legalItem));
    });
};

/*currently loading profile after add item since the user props has the legal register
But need to revisit this and only pass the one new item to the user state
*/
export const addMyLegalRegisterItem = (userId, legalItemId) => {
  firebase
    .database()
    .ref(`users/${userId}/MyLegalItems/${legalItemId}`)
    .update({
      key: legalItemId,
      userStatus: "Not Compliant",
      status: "active",
      timeAdded: Date.now(),
    })
    .then(() => {
      loadProfile(userId);
    })
    .catch(function (error) {
      console.log("Add My Legal Items Failed because : " + error.message);
    });
};

export const removeLegalItem = (userId, legalItemId) => {
  firebase
    .database()
    .ref(`users/${userId}/MyLegalItems/${legalItemId}`)
    .update({
      status: "inactive",
      timeRemoved: Date.now(),
    })
    .then(loadProfile(userId))
    .catch(function (error) {
      console.log("Remove legal item failed because : " + error.message);
    });
};

//Only being used for adding comments by admin to their legal register item//
export const updateSubscriberLegalRegisterItem = (
  userId,
  legalItemId,
  updatedItem
) => {
  firebase
    .database()
    .ref(`users/${userId}/MyLegalItems/${legalItemId}`)
    .update({
      adminStatus: updatedItem.adminComplianceCheck,
    })
    .catch(function (error) {
      console.log(
        "Update my legal register item Failed because : " + error.message
      );
    });

  //Removing load profile from this next function since we are the admin we dont want to become the user lol
  firebase
    .database()
    .ref(`users/${userId}/MyLegalItems/${legalItemId}/compliance`)
    .push({
      adminComplianceCheck: updatedItem.adminComplianceCheck,
      adminComplianceNote: updatedItem.adminComplianceNote,
      type: "admin",
      adminKey: updatedItem.adminUid,
      adminDisplayName: updatedItem.adminDisplayName,
      adminTimeAdded: Date.now(),
    })
    // .then(console.log("Could have a redux action here to update state? but cant be load profile since we are admin "))
    .catch(function (error) {
      console.log(
        "Update my legal register item Failed because : " + error.message
      );
    });
};

export const updateMyHowThisAppliesNoteItem = (
  userId,
  legalItemId,
  updatedHowThisApplies
) => {
  firebase
    .database()
    .ref(`users/${userId}/MyLegalItems/${legalItemId}`)
    .update({ howThisAppliesToUserByUser: updatedHowThisApplies })
    .then(loadProfile(userId))
    .catch(function (error) {
      console.log(
        "Update my how this applies note Failed because : " + error.message
      );
    });
};

//Only being used for adding comments for user to their legal register item//
export const updateMyLegalRegisterItem = (userId, legalItemId, updatedItem) => {
  if (updatedItem.document) {
    let file = updatedItem.document;
    let folder = uuidv4();

    firebase
      .database()
      .ref(`users/${userId}/MyLegalItems/${legalItemId}`)
      .update({
        userStatus: updatedItem.userComplianceCheck,
      });

    firebase
      .storage()
      .ref()
      .child(`/documents/${folder}/${file.name}`)
      .put(file)
      .then(snapshot => {
        return snapshot.ref.getDownloadURL(); // Will return a promise with the download link
      })
      .then(function (downloadURL) {
        const fireKey = firebase.database().ref().child("storage").push().key;
        addStorageRecord(fireKey, downloadURL, file, folder);
        firebase
          .database()
          .ref(`users/${userId}/documents/legalItems/${folder}`)
          .set({
            document: downloadURL,
            key: folder,
            storageKey: fireKey,
            timeAdded: Date.now(),
            legaItem: legalItemId,
          });
        firebase
          .database()
          .ref(`users/${userId}/MyLegalItems/${legalItemId}/compliance`)
          .push({
            document: downloadURL,
            userComplianceCheck: updatedItem.userComplianceCheck,
            userComplianceNote: updatedItem.userComplianceNote,
            type: "user",
            userTimeAdded: Date.now(),
          })
          .then(loadProfile(userId));
      })
      .catch(function (error) {
        console.log(
          "Update my legal register item Failed because : " + error.message
        );
      });
  } else {
    firebase
      .database()
      .ref(`users/${userId}/MyLegalItems/${legalItemId}`)
      .update({
        userStatus: updatedItem.userComplianceCheck,
      });

    firebase
      .database()
      .ref(`users/${userId}/MyLegalItems/${legalItemId}/compliance`)
      .push({
        document: updatedItem.document,
        userComplianceCheck: updatedItem.userComplianceCheck,
        userComplianceNote: updatedItem.userComplianceNote,
        type: "user",
        userTimeAdded: Date.now(),
      })
      .then(loadProfile(userId))
      .catch(function (error) {
        console.log(
          "Update my legal register item Failed because : " + error.message
        );
      });
  }
};
//Only being used for adding comments for user to their legal register item//

export const setEditKey = (userId, key, allItems) => {
  setLegalItem(key);

  firebase
    .database()
    .ref(`users/${userId}/MyLegalItems/${key}`)
    .on("value", function (snap) {
      const editItem = snap.val();
      store.dispatch(setCurrentLegalItemEdit(editItem));
    });
};

export const setLegalRegisterSubscriber = userId => {
  firebase
    .database()
    .ref(`users/${userId}`)
    .on("value", function (snap) {
      const userObject = snap.val();
      store.dispatch(setCurrentLegalSubscriberEdit(userObject));
    });
};

export const setLegalRegisterCurrentLegal = key => {
  store.dispatch(setCurrentLegalKey(key));
};

// to delete a Legal Item from Firebase

export const deleteLegalItemAction = legalItem => {
  console.log(legalItem.key);
  firebase
    .database()
    .ref(`legalRegister/${legalItem.key}`)
    .remove()
    .then(function () {
      const legalItemKey = legalItem.key;
      store.dispatch(deleteLegalItem(legalItemKey));
      // window.alert("Delete Insight Succeeded");
    })
    .catch(function (error) {
      console.log("Delete insight failed because: " + error.message);
    });
};

export const deleteLegalItem = legalItemKey => ({
  type: DELETE_LEGALITEM,
  legalItemKey,
});

//Not being used

//Admin action that is Updating the users items overall compliance
// export const updateCurrentOverallCompliance = (
//   userId,
//   legalItemId,
//   CurrentOverallCompliance
// ) => {
//   firebase
//     .database()
//     .ref(`users/${userId}/MyLegalItems/${legalItemId}`)
//     .update({
//       status: CurrentOverallCompliance
//     })
//     .then(loadProfile(userId))
//     .catch(function(error) {
//       console.log(
//         "Update the overall compliance of this item failed because : " +
//           error.message
//       );
//     });
// };

export const getLatestLegalItems = () => {
  const ref = firebase.database().ref("legalRegister");
  ref
    .orderByChild("time")
    .limitToLast(5)
    .on("value", snap => {
      const latestLegalItems = snapshotToArray(snap);
      store.dispatch(setLatestLegalItems(latestLegalItems.reverse()));
    });
};

export const SET_LATEST_LEGAL_ITEMS = "SET_LATEST_LEGAL_ITEMS";
export const setLatestLegalItems = latestLegalItems => ({
  type: SET_LATEST_LEGAL_ITEMS,
  latestLegalItems,
});
