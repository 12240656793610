import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import TimeAgo from "react-timeago";
import {
  Card,
  Segment,
  Icon,
  Divider,
  Dropdown,
  Button,
  Label as Lb,
  Popup,
  Message
} from "semantic-ui-react";
import {
  Col,
  Row,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Table
} from "reactstrap";
import {
  MegadraftEditor,
  editorStateFromRaw,
  editorStateToJSON
} from "megadraft";
import {
  updateMyLegalRegisterItem,
  updateMyHowThisAppliesNoteItem
} from "../../actions/legalRegister";
import { changeActiveItem } from "../../actions/configActions";

//  Notifications
import { createNotification, NOTIFICATION_TYPE_INFO } from "react-redux-notify";
import { complianceOptions, renderIconSymbol } from "../../constants/index";
import "../MegaDraft/megadraft.css";
import { styles } from "../../styles";

class MyLegalRegisterItemDetailPage extends Component {
  constructor(props) {
    super(props);

    let appliesPlaceholder = "";
    if (this.props.legalItem.howThisAppliesToUserByUser) {
      appliesPlaceholder = editorStateFromRaw(
        JSON.parse(this.props.legalItem.howThisAppliesToUserByUser)
      );
    }
    if (this.props.legalItem.howThisAppliesToUserByUser === undefined) {
      appliesPlaceholder = editorStateFromRaw(null);
    }

    this.state = {
      userComplianceCheck: false,
      userComplianceNoteEditorState: editorStateFromRaw(null),
      goBack: false,
      document: "",
      theInputKey: "",
      howThisAppliesUserEditorState: appliesPlaceholder
    };
  }

  onChangeuserComplianceNote = userComplianceNoteEditorState => {
    this.setState({ userComplianceNoteEditorState });
  };

  onChangeuserHowThisAppliesUserEditorState = howThisAppliesUserEditorState => {
    this.setState({ howThisAppliesUserEditorState });
  };

  handleGoBack = () => {
    changeActiveItem("Legal Register");
    this.setState({ goBack: true });
  };

  handleChange = event => {
    const { target } = event;
    const { value, name } = target;
    this.setState({ [name]: value });
  };

  handleComplianceChange = (event, data) => {
    const status = data.value;
    const theName = data.name;
    this.setState({ [theName]: status });
  };

  handlestorageUpload = e => {
    this.setState({
      document: e.target.files[0]
    });
  };

  handleUserComplianceNote = () => {
    const { createNotification } = this.props;
    const sureSaveNotification = {
      message: "Sure you want to save your notes?",
      type: NOTIFICATION_TYPE_INFO,
      duration: 0,
      canDismiss: false,
      icon: <Icon name="warning sign" />,
      acceptBtn: {
        handler: (e, notification) => {
          const userComplianceNotes = editorStateToJSON(
            this.state.userComplianceNoteEditorState
          );
          let updateditem = {
            userComplianceCheck: this.state.userComplianceCheck,
            userComplianceNote: userComplianceNotes,
            document: this.state.document
          };

          updateMyLegalRegisterItem(
            this.props.currentUser.uid,
            this.props.legalItem.key,
            updateditem
          );

          let randomString = Math.random().toString(36);
          this.setState({
            userComplianceCheck: false,
            userComplianceNoteEditorState: editorStateFromRaw(null),
            document: "",
            theInputKey: randomString
          });
          notification.handleDismiss(notification.id);
        },
        title: "Yup",
        icon: <Icon name="thumbs up" />
      },
      denyBtn: {
        handler: (e, notification) => {
          this.setState({
            userComplianceCheck: false,
            userComplianceNote: editorStateFromRaw(null),
            document: ""
          });
          notification.handleDismiss(notification.id);
        },
        title: "Nope",
        icon: <Icon name="thumbs down" />
      }
    };
    createNotification(sureSaveNotification);
  };

  handleUserHowThisApplies = () => {
    const { createNotification } = this.props;
    const sureSaveNotification = {
      message: "Sure you want to save your notes?",
      type: NOTIFICATION_TYPE_INFO,
      duration: 0,
      canDismiss: false,
      icon: <Icon name="warning sign" />,
      acceptBtn: {
        handler: (e, notification) => {
          const userHowThisApplies = editorStateToJSON(
            this.state.howThisAppliesUserEditorState
          );
          updateMyHowThisAppliesNoteItem(
            this.props.currentUser.uid,
            this.props.legalItem.key,
            userHowThisApplies
          );
          notification.handleDismiss(notification.id);
        },
        title: "Yup",
        icon: <Icon name="thumbs up" />
      },
      denyBtn: {
        handler: (e, notification) => {
          notification.handleDismiss(notification.id);
        },
        title: "Nope",
        icon: <Icon name="thumbs down" />
      }
    };
    createNotification(sureSaveNotification);
  };

  renderComplianceChildren = compliances => {
    let complianceArray = [];
    /*the compliances is the main object and then .compliance is where on the object the comments are landing right now*/
    if (
      compliances &&
      compliances.compliance !== undefined &&
      Object.values(compliances.compliance).length > 0
    ) {
      complianceArray = Object.values(compliances.compliance).reverse();
    }
    return complianceArray.map(compliance => (
      <tr key={compliance.userTimeAdded || compliance.adminTimeAdded}>
        <th>
          {renderIconSymbol(
            compliance.userComplianceCheck || compliance.adminComplianceCheck
          )}
        </th>
        <th>
          {" "}
          <TimeAgo
            date={compliance.userTimeAdded || compliance.adminTimeAdded}
          />
        </th>
        {compliance.type === "user" ? (
          <th>
            {this.props.currentUser.firstName} {this.props.currentUser.lastName}
          </th>
        ) : compliance.adminDisplayName ? (
          <th>
            {" "}
            <Popup
              trigger={
                <div>
                  <Icon name="user plus" color="yellow" />{" "}
                  {compliance.adminDisplayName}
                </div>
              }
              content="Administrator"
            />
          </th>
        ) : (
          <th>Admin</th>
        )}
        <th>
          <MegadraftEditor
            editorState={editorStateFromRaw(
              JSON.parse(
                compliance.userComplianceNote || compliance.adminComplianceNote
              )
            )}
            readOnly={true}
          />
        </th>
        <th>
          {compliance.document ? (
            <a
              href={compliance.document}
              rel="noopener noreferrer"
              target="_blank"
            >
              Document
            </a>
          ) : (
            ""
          )}
        </th>
      </tr>
    ));
  };

  render() {
    const { currentLegalItem } = this.props;

    if (this.state.goBack === true) {
      return <Redirect to="/myaccount" />;
    }

    let currentUserCompliances;
    if (this.props.currentUser && this.props.legalItem) {
      let userLegalItems = this.props.currentUser.MyLegalItems;
      let currentItemKey = this.props.legalItem.key;
      if (userLegalItems) {
        currentUserCompliances = userLegalItems[`${currentItemKey}`];
      }
    }

    let ComplianceApproved = "Not Compliant";
    if (currentUserCompliances) {
      ComplianceApproved = currentUserCompliances.userStatus;
    }

    return (
      <Container style={{ margin: "25px auto" }}>
        <Row>
          <Col>
            <Card color="teal" fluid style={styles.profileComponentStyle}>
              <Card.Content style={styles.cardContentStyle}>
                <div style={styles.cardHeaderStyle}>
                  <Row
                    style={{
                      display: "flex",
                      alignItems: "center"
                    }}
                  >
                    <Col sm="2" style={{ padding: 3 }}>
                      <Button
                        compact={true}
                        onClick={() => this.handleGoBack()}
                      >
                        <Icon name="chevron left" />
                        Back
                      </Button>
                    </Col>
                    <Col sm="10">
                      {/* <Title> */}
                      {currentLegalItem && (
                        <div style={{}}> Edit: {currentLegalItem.title}</div>
                      )}
                      {/* </Title> */}
                    </Col>
                  </Row>
                </div>
                <div style={styles.divStyle} />
                <Segment style={styles.segmentStyle}>
                  <Row>
                    <Col sm="10">
                      {currentLegalItem && <h1>{currentLegalItem.title} </h1>}
                      <Lb floated="right" disabled>
                        <Icon name="hashtag" />
                        {currentLegalItem && currentLegalItem.filterByArea}
                      </Lb>
                    </Col>
                    <Col sm="2" style={{ textAlign: "center" }}>
                      <Segment
                        style={{
                          backgroundColor: "transparent",
                          borderWidth: 0,
                          boxShadow: "none"
                        }}
                      >
                        {renderIconSymbol(ComplianceApproved)}
                      </Segment>
                    </Col>
                  </Row>
                  <Divider />
                  <Container>
                    <Row>
                      <Col>
                        <Segment>
                          <Segment inverted tertiary>
                            <h3>HEADLINE: </h3>
                          </Segment>
                          {currentLegalItem && (
                            <MegadraftEditor
                              editorState={editorStateFromRaw(
                                JSON.parse(currentLegalItem.headlineDetails)
                              )}
                              readOnly={true}
                            />
                          )}
                          <Segment.Group horizontal>
                            {currentLegalItem && (
                              <Segment>
                                <Segment inverted tertiary>
                                  <h4>APPLICABLE TO:</h4>
                                </Segment>
                                {currentLegalItem.applicableTo ? (
                                  currentLegalItem.applicableTo.map(
                                    (tag, idx) => (
                                      <div key={idx}>
                                        <Lb style={{ margin: 1 }}>
                                          <Icon name="tag" />
                                          {tag}
                                        </Lb>
                                      </div>
                                    )
                                  )
                                ) : (
                                  <div />
                                )}
                              </Segment>
                            )}
                            {currentLegalItem && (
                              <Segment>
                                <Segment inverted tertiary>
                                  <h4>REGULATED BY:</h4>
                                </Segment>
                                {currentLegalItem.regulator ? (
                                  currentLegalItem.regulator.map((tag, idx) => (
                                    <div key={idx}>
                                      <Lb style={{ margin: 1 }}>
                                        <Icon name="tag" />
                                        {tag}
                                      </Lb>
                                    </div>
                                  ))
                                ) : (
                                  <div />
                                )}
                              </Segment>
                            )}
                            {currentLegalItem && (
                              <Segment>
                                <Segment inverted tertiary compact>
                                  <h4>COUNTRIES:</h4>
                                </Segment>
                                {currentLegalItem.country ? (
                                  currentLegalItem.country.map((tag, idx) => (
                                    <div key={idx}>
                                      <Lb style={{ margin: 1 }}>
                                        <Icon name="tag" />
                                        {tag}
                                      </Lb>
                                    </div>
                                  ))
                                ) : (
                                  <div />
                                )}
                              </Segment>
                            )}
                          </Segment.Group>
                        </Segment>
                        {currentLegalItem && (
                          <Segment>
                            <Segment tertiary inverted>
                              <h3>HOW THIS APPLIES TO ME: </h3>
                            </Segment>
                            <Message
                              attached
                              header="How this applies in general:"
                              content={
                                <MegadraftEditor
                                  editorState={editorStateFromRaw(
                                    JSON.parse(currentLegalItem.howThisApplies)
                                  )}
                                  readOnly={true}
                                />
                              }
                            />
                            <Segment>
                              <Form id="">
                                <FormGroup>
                                  <MegadraftEditor
                                    editorState={
                                      this.state.howThisAppliesUserEditorState
                                    }
                                    onChange={
                                      this
                                        .onChangeuserHowThisAppliesUserEditorState
                                    }
                                    placeholder="Write how this legal item applies to your organisation here ..."
                                    name="userHowThisAppliesNote"
                                  />
                                </FormGroup>
                                <Button
                                  type="button"
                                  color="blue"
                                  onClick={this.handleUserHowThisApplies}
                                >
                                  Save
                                </Button>
                              </Form>
                            </Segment>
                          </Segment>
                        )}
                      </Col>
                      <br />
                      <Col>
                        {currentLegalItem && (
                          <Segment>
                            <Segment tertiary inverted>
                              <h3>DESCRIPTION: </h3>
                            </Segment>
                            <MegadraftEditor
                              editorState={editorStateFromRaw(
                                JSON.parse(currentLegalItem.description)
                              )}
                              readOnly={true}
                            />
                          </Segment>
                        )}
                      </Col>
                    </Row>
                    <Divider />
                    <Row>
                      <Col>
                        <Segment>
                          <Segment tertiary inverted>
                            <h3>COMPLIANCE:</h3>
                          </Segment>
                          <Form id="user-compliance-form">
                            <FormGroup>
                              <Segment>
                                <MegadraftEditor
                                  editorState={
                                    this.state.userComplianceNoteEditorState
                                  }
                                  onChange={this.onChangeuserComplianceNote}
                                  placeholder="Write your notes here ..."
                                  name="userComplianceNote"
                                />
                              </Segment>
                            </FormGroup>

                            <FormGroup style={{ textAlign: "left" }}>
                              <Dropdown
                                placeholder="Status"
                                selection
                                name="userComplianceCheck"
                                onChange={this.handleComplianceChange}
                                options={complianceOptions}
                              />
                            </FormGroup>
                            <FormGroup style={{ textAlign: "left" }}>
                              <Label htmlFor="documentUpload">
                                <h4>Optional Document Upload:</h4>
                              </Label>

                              <Input
                                type="file"
                                name="documentUpload"
                                key={this.state.theInputKey || ""}
                                onChange={this.handlestorageUpload}
                              />
                            </FormGroup>

                            <Button
                              type="button"
                              color="blue"
                              onClick={this.handleUserComplianceNote}
                            >
                              Save
                            </Button>
                          </Form>
                          <Divider />
                        </Segment>
                        <Segment>
                          <h3>Compliance History</h3>
                          <Table>
                            <thead>
                              <tr>
                                <th>#</th>
                                <th>Date Time</th>
                                <th>Username</th>
                                <th>Note</th>
                                <th>Document</th>
                              </tr>
                            </thead>
                            <tbody>
                              {this.renderComplianceChildren(
                                currentUserCompliances
                              )}
                            </tbody>
                          </Table>
                        </Segment>
                      </Col>
                    </Row>
                  </Container>
                </Segment>
              </Card.Content>
            </Card>
          </Col>
        </Row>
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  currentLegalItem: state.legalItems.currentLegalItemProps,
  currentUser: state.user.currentUserProps,
  editRoute: state.legalItems.editRoute,
  legalItem: state.legalItems.currentLegalItemUserEdit,
  legalItemAll: state.legalItems.currentLegalKey
});

const mapDispatchToProps = dispatch => ({
  createNotification: config => {
    dispatch(createNotification(config));
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MyLegalRegisterItemDetailPage);
