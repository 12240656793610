// this component reads the Header props that have been selected from the sidebar
// and passes then down to an AllInsights Component with the header props

import React, { Component } from "react";
import CategorySidebar from "./CategorySidebar";

class CategoryGeneral extends Component {
  render() {
    console.log("using the mutherfucking Gen Cat component");
    return (
      <div>
        <CategorySidebar />
      </div>
    );
  }
}

export default CategoryGeneral;
