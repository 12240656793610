import { combineReducers } from "redux";
import insightReducer from "./insightReducer";
import legalItemReducer from "./legalItemReducer";
import userReducer from "./user";
import sessionReducer from "./sessionReducer";
import subscriberReducer from "./subscriberReducer";
import postReducer from "./Posts";
import chatroomsReducer from "./ChatroomsReducer";
import configReducer from "./ConfigReducer";
import notifyReducer from "react-redux-notify";
import universityReducer from "./university";

//Import reducers here and then use combine reducer to export them

const appReducer = combineReducers({
  notifications: notifyReducer,
  sessionState: sessionReducer,
  insights: insightReducer,
  legalItems: legalItemReducer,
  subscriber: subscriberReducer,
  user: userReducer,
  postState: postReducer,
  chatroomsState: chatroomsReducer,
  appConfig: configReducer,
  university: universityReducer
});

const rootReducer = (state, action) => {
  if (action.type === "CLEAR_REDUX") {
    state = undefined;
  }
  return appReducer(state, action);
};

export default rootReducer;
