import { firebase } from "../FireBase/firebase";
import uuidv4 from "uuid/v4";
import axios from "axios";
import { snapshotToArray } from "./utils";
import store from "../store";

// Action Creator to set the user profile data to a payload of `user`
export const SET_CURRENT_USER_PROPS = "SET_CURRENT_USER_PROPS";
export const setCurrentUserProps = user => ({
  type: SET_CURRENT_USER_PROPS,
  user
});

// Action Creator to remove the user profile data from the current state - called in Authentication.js
export const REMOVE_CURRENT_USER_PROPS = "REMOVE_CURRENT_USER_PROPS";
export const removeCurrentUserProps = () => ({
  type: REMOVE_CURRENT_USER_PROPS
});

// Action Creator to set the user profile photo to new image
export const UPDATE_PROFILE_PHOTO_ACTION = "UPDATE_PROFILE_PHOTO_ACTION";
export const updateProfilePhotoAction = url => ({
  type: UPDATE_PROFILE_PHOTO_ACTION,
  url
});

// Writing the user profile data to the Firebase Realtime database
export const updateUserData = (
  userId,
  displayName,
  firstName,
  lastName,
  company,
  jobrole,
  bio,
  birthDate
) => {
  firebase
    .database()
    .ref(`/users/${userId}`)
    .update({
      uid: userId,
      displayName,
      firstName,
      lastName,
      company: company,
      jobRole: jobrole,
      bio: bio,
      DOB: birthDate
    });
};

export const updateUserProfilePic = (userId, profilePhoto) => {
  let folder = uuidv4();
  firebase
    .storage()
    .ref()
    .child(`profilePhotos/${folder}/${profilePhoto.name}`)
    .put(profilePhoto)
    .then(snapshot => {
      return snapshot.ref.getDownloadURL(); // Will return a promise with the download link
    })
    .then(function(downloadURL) {
      firebase
        .database()
        .ref(`/users/${userId}`)
        .update({
          profilePhoto: downloadURL,
          uid: userId
        });
      store.dispatch(updateProfilePhotoAction(downloadURL));
    });
};

// Loading the user profile from the Firebase Realtime database
//Change to on to wathc for changes, maybe changing back to once in future
export const loadProfile = userId => {
  firebase
    .database()
    .ref(`users/${userId}`)
    .on("value", function(snap) {
      const currentUser = snap.val();

      store.dispatch(setCurrentUserProps(currentUser));
    });
};

export const deleteUser = userId => {
  const user = firebase.auth().currentUser;
  const fbUserProps = firebase.database().ref(`users/${userId}`);
  user
    .delete()
    .then(function() {
      fbUserProps.remove().then(function() {
        store.dispatch(removeCurrentUserProps());
      });
    })
    .catch(function(error) {
      console.log("Error " + error);
    });
};

export const adminDeleteUser = userId => {
  // deletes user from db but curretly fb dosnt give you to the option to delete specific users from the auth db only the urrent logged in user

  axios
    .post(process.env.REACT_APP_DELETE_USER_FROM_AUTH_ENDPOINT, {
      uid: `${userId}`
    })
    .then(res => {
      console.log("RES ", res.data.message);
    });

  const fbUserProps = firebase.database().ref(`users/${userId}`);
  fbUserProps.remove().catch(function(error) {
    console.log("Error " + error);
  });
};

export const selectUserAction = uid => {
  const user = firebase.database().ref(`users/${uid}`);
  user.once("value", snap => {
    const selectedUser = snap.val();
    store.dispatch({ type: "SELECT_USER", selectedUser });
  });
};

// users deleted by admin *
export const getAllDeletedUsers = () => {
  firebase
    .database()
    .ref(`users`)
    .orderByChild("userType")
    .equalTo("deletedUser")
    .once("value", function(snapshot) {
      const deletedUsersArray = snapshotToArray(snapshot);
      // extract only the emails properties so that you can test them in .includes inside the login form
      const deletedUsers = deletedUsersArray.map(user => user.email);
      store.dispatch({ type: "GET_ALL_DELETED_USERS", deletedUsers });
    });
};
