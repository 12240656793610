import React, { Component } from "react";
import { Container, Col, Row } from "reactstrap";
import { Card, Segment } from "semantic-ui-react";
import "../MegaDraft/megadraft.css";
import { styles } from "../../styles";
import LegalRegisterUploadForm from "./LegalRegisterUploadForm";
import LegalRegisterTable from "./LegalRegisterTable";
import EditLegalItem from "./LegalRegisterEdit";

class LegalRegisterUpload extends Component {
  // this component displays the legal Register upload form and all created legal registers
  constructor(props) {
    super(props);
    this.state = {
      editMode: false
    };
    this.handleEditToggle = this.handleEditToggle.bind(this);
  }

  handleEditToggle() {
    this.setState({ editMode: !this.state.editMode });
  }

  render() {
    return (
      <Container style={{ marginTop: 25 }}>
        <Card fluid style={styles.profileComponentStyle} color="teal">
          <Card.Content style={styles.cardContentStyle}>
            <div style={styles.cardHeaderStyle}>
              Legal Register Item upload{" "}
            </div>
            <div style={styles.divStyle} />
            <Segment style={styles.segmentStyle}>
              <Row>
                <Col sm="6">
                  {this.state.editMode === false ? (
                    <LegalRegisterUploadForm
                      handleEditToggle={this.handleEditToggle}
                    />
                  ) : (
                    <EditLegalItem handleEditToggle={this.handleEditToggle} />
                  )}
                </Col>
                <Col sm="6">
                  <LegalRegisterTable
                    handleEditToggle={this.handleEditToggle}
                  />
                </Col>
              </Row>
            </Segment>
          </Card.Content>
        </Card>
      </Container>
    );
  }
}

export default LegalRegisterUpload;
