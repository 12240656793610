import React, { Component } from "react";
import { connect } from "react-redux";
import { Image, Icon, Label } from "semantic-ui-react";
import { styles } from "../../styles";
import { Col, Row } from "reactstrap";

class ProfileSmall extends Component {
  render() {
    const { currentUser } = this.props;
    return (
      <div className="animated bounceIn" style={styles.profileComponentStyle}>
        <div style={styles.cardHeaderStyle}>
          <p>{currentUser.displayName}</p>
        </div>

        <div style={styles.divStyle} />
        <Row>
          <Col>
            <div style={{ textAlign: "center", padding: 10 }}>
              {!currentUser.profilePhoto ? (
                <Icon
                  name="user circle"
                  size="huge"
                  style={{
                    color: "grey"
                  }}
                />
              ) : (
                <Image src={currentUser.profilePhoto} size="tiny" circular />
              )}
            </div>
          </Col>
          <Col>
            <Label
              color="yellow"
              ribbon="right"
              style={{ marginLeft: -14, marginTop: 5 }}
            >
              {currentUser.userType}
            </Label>
            <hr />
            <div style={{ textAlign: "center", marginTop: 15 }}>
              {currentUser.company}
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  currentUser: state.user.currentUserProps,
  authUser: state.sessionState.authUser
});

export default connect(mapStateToProps)(ProfileSmall);
