import { firebase, db } from "../FireBase/firebase";
import store from "../store";
import {
  SET_CURRENT_ALL_POSTS,
  CURRENTLY_SELECTED_CHATROOM,
  SET_CURRENT_ALL_CHATROOMS,
  SELECT_POST,
  DELETE_POST,
  SET_LATEST_POSTS,
  SET_INSIGHT_COMMENTS,
  SELECT_INSIGHT_COMMENT
} from "./types";

export const snapshotToArray = snapshot => {
  let returnArr = [];

  snapshot.forEach(childSnapshot => {
    let item = childSnapshot.val();

    item.key = childSnapshot.key;

    returnArr.push(item);
  });

  return returnArr;
};

export const setCurrentPostsProps = allPosts => ({
  type: SET_CURRENT_ALL_POSTS,
  allPosts
});

export const getAllposts = () => {
  db.ref("posts")
    // .limitToLast(100) // Use this to throttle the posts request
    .on("value", function(snapshot) {
      const posts = snapshotToArray(snapshot);
      store.dispatch(setCurrentPostsProps(Array.from(posts.reverse())));
    });
};

export const setCurrentChatroomsProps = allChatrooms => ({
  type: SET_CURRENT_ALL_CHATROOMS,
  allChatrooms
});

export const getAllChatrooms = () => {
  firebase
    .database()
    .ref("chatrooms")
    .orderByChild("time")
    .on("value", function(snapshot) {
      const chatrooms = snapshotToArray(snapshot);
      store.dispatch(setCurrentChatroomsProps(Array.from(chatrooms)));
    });
};

export const setMessage = (
  message,
  uid,
  displayName,
  profilePhoto,
  chatroomTitle,
  chatroomKey,
  firstName,
  lastName
) => {
  db.ref("posts").push({
    message,
    uid,
    displayName,
    profilePhoto,
    chatroomTitle,
    chatroomKey,
    firstName,
    lastName,
    time: Date.now(),
    likes: {},
    likeCount: 0
  });
  // transactional chatroom number increase
  const adaCount = db.ref(`chatrooms/${chatroomKey}/totalMessages`);
  adaCount.transaction(chatroom => {
    return chatroom + 1;
  });
};

export const replyToMessage = (
  content,
  key,
  uid,
  originalPost,
  displayName,
  profilePhoto,
  chatroomTitle,
  chatroomKey,
  firstName,
  lastName
) => {
  const adaReply = db.ref(`posts`);
  adaReply.push({
    message: content,
    // key,
    uid,
    originalPost: originalPost,
    displayName,
    profilePhoto,
    chatroomTitle,
    chatroomKey,
    firstName,
    lastName,
    time: Date.now(),
    likes: {},
    likeCount: 0
  });
  const adaCount = db.ref(`chatrooms/${chatroomKey}/totalMessages`);
  adaCount.transaction(chatroom => {
    return chatroom + 1;
  });
};

export const updateMessage = (content, key) => {
  db.ref(`posts/${key}`).update({ message: content, updateTime: Date.now() });
};

export const updateLike = (key, uid) => {
  const adaLike = db.ref(`posts/${key}/`);
  adaLike.transaction(post => {
    if (post) {
      if (post.likes && post.likes[uid]) {
        post.likeCount--;
        post.likes[uid] = null;
      } else {
        post.likeCount++;
        if (!post.likes) {
          post.likes = {};
        }
        post.likes[uid] = true;
      }
    }
    return post;
  });
};

export const setChatroom = (uid, chatroomTitle, chatroomDescription) => {
  const newChatRoomKey = db
    .ref()
    .child("chatrooms")
    .push().key;
  db.ref("chatrooms/" + newChatRoomKey).set({
    uid,
    chatroomTitle,
    chatroomDescription,
    time: Date.now(),
    chatroomKey: newChatRoomKey
  });
};

export const CurrentlySelectedChatroom = CurrentChatroom => ({
  type: CURRENTLY_SELECTED_CHATROOM,
  CurrentChatroom
});

export const setCurrentChatroom = key => {
  firebase
    .database()
    .ref(`chatrooms/${key}`)
    .on("value", function(snapshot) {
      const chatroom = snapshot.val();
      store.dispatch(CurrentlySelectedChatroom(chatroom));
    });
};

export const selectPost = key => {
  firebase
    .database()
    .ref(`posts/${key}`)
    .on("value", function(snap) {
      const post = snap.val();

      store.dispatch(CurrentlySelectedPost(post));
    });
};

export const CurrentlySelectedPost = CurrentPost => ({
  type: SELECT_POST,
  CurrentPost
});

export const DeleteSelectedPost = (key, chatroomKey) => {
  // console.log("were going to delete post: " + key);
  firebase
    .database()
    .ref(`posts/${key}`)
    .remove()
    .then(() => {
      const postKey = key;
      store.dispatch(deletePost(postKey));
      // window.alert("Delete Post Succeeded");
    })
    .catch(error => {
      console.log("Delete post is screwing up because: " + error.message);
    });
  const adaCount = db.ref(`chatrooms/${chatroomKey}/totalMessages`);
  adaCount.transaction(chatroom => {
    return chatroom - 1;
  });
};

export const deletePost = postKey => ({
  type: DELETE_POST,
  postKey
});

export const getLatestForumActivity = () => {
  const ref = firebase.database().ref("posts");
  ref
    .orderByChild("time")
    .limitToLast(5)
    .on("value", snap => {
      const latestPosts = snapshotToArray(snap);
      store.dispatch(setLatestPosts(latestPosts.reverse()));
    });
};

export const setLatestPosts = latestPosts => ({
  type: SET_LATEST_POSTS,
  latestPosts
});

// *** Other Workings ***

// export const GetRepliesWithMatchingKeys = postKey => {
//   firebase
//     .database()
//     .ref(`postReplies/${postKey}`)
//     .on("value", snap => {
//       const replyData = snap.val();
//       if (replyData) {
//         // console.log(replyData);
//         store.dispatch(updateReplies(replyData));
//       } else {
//         // console.log("this post has no replies");
//       }
//     });
// };

// export const updateReplies = replyData => ({
//   type: UPDATE_REPLIES,
//   replyData
// });

// INSIGHT COMMENTS

export const setInsightComment = (
  comment,
  uid,
  key,
  displayName,
  profilePhoto,
  firstName,
  lastName
) => {
  db.ref(`insights/${key}/comments`).push({
    comment,
    uid,
    displayName,
    profilePhoto,
    firstName,
    lastName,
    time: Date.now(),
    likes: {},
    likeCount: 0
  });
};

export const getInsightComments = key => {
  db.ref(`insights/${key}/comments`).on("value", function(snapshot) {
    const newArray = snapshotToArray(snapshot);
    const comments = Array.from(newArray.reverse());
    store.dispatch({
      type: SET_INSIGHT_COMMENTS,
      comments
    });
  });
};

export const selectInsightComment = (commentkey, insightKey) => {
  firebase
    .database()
    .ref(`insights/${insightKey}/comments/${commentkey}`)
    .on("value", function(snap) {
      const comment = snap.val();

      store.dispatch({
        type: SELECT_INSIGHT_COMMENT,
        comment
      });
    });
};

export const deleteInsightComment = (commentKey, insightKey) => {
  firebase
    .database()
    .ref(`insights/${insightKey}/comments/${commentKey}`)
    .remove();
};

export const updateInsightComment = (commentKey, insightKey, comment) => {
  firebase
    .database()
    .ref(`insights/${insightKey}/comments/${commentKey}`)
    .update({ comment, updateTime: Date.now() });
};

export const updateInsightCommentlike = (commentKey, insightKey, uid) => {
  const adaLike = db.ref(`insights/${insightKey}/comments/${commentKey}`);
  adaLike.transaction(comment => {
    if (comment) {
      if (comment.likes && comment.likes[uid]) {
        comment.likeCount--;
        comment.likes[uid] = null;
      } else {
        comment.likeCount++;
        if (!comment.likes) {
          comment.likes = {};
        }
        comment.likes[uid] = true;
      }
    }
    return comment;
  });
};

export const replyToComment = (
  comment,
  key,
  uid,
  originalPost,
  displayName,
  profilePhoto,
  firstName,
  lastName
) => {
  const adaReply = db.ref(`insights/${key}/comments`);
  adaReply.push({
    comment,
    // key,
    uid,
    originalPost,
    displayName,
    profilePhoto,
    firstName,
    lastName,
    time: Date.now(),
    likes: {},
    likeCount: 0
  });
};
