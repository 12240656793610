import { createStore, applyMiddleware, compose } from "redux";
import promiseMiddleware from "redux-promise";
import thunk from "redux-thunk";
import rootReducer from "./reducers";
// import * as Actions from "./actions/index";

const store = createStore(
  rootReducer,
  compose(
    applyMiddleware(promiseMiddleware, thunk),
    window.__REDUX_DEVTOOLS_EXTENSION__
      ? window.__REDUX_DEVTOOLS_EXTENSION__()
      : f => f
  )
);

// Actions.verifyAuth();

window.store = store;

export default store;
