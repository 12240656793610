// Specific Chatroom
import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { Container, Col, Row, Button, Badge } from "reactstrap";
import { Icon } from "semantic-ui-react";
import ThreadDisplay from "./ThreadDisplay";
import { chatroomStyles } from "../../styles";

class Chatroom extends Component {
  handleGoBack = () => {
    // Go back to previously rendered Page
    this.props.history.goBack();
  };

  render() {
    const { CurrentChatroom } = this.props;
    // Check if CurrentChatroom has a value of less than 1 (empty)
    const ObjectisNotEmpty = Object.values(CurrentChatroom).length < 1;
    if (ObjectisNotEmpty === true) {
      // If the CurrentChatroom is empty redirect to Chatroom(s) page
      return <Redirect to="/Chatrooms" />;
    }
    return (
      <Container>
        <Col style={chatroomStyles.chatroomStyle}>
          <Row>
            <Col>
              <Button onClick={this.handleGoBack}>
                <Icon name="chevron left" />
                Back
              </Button>
            </Col>
            <Col style={{ textAlign: "right" }}>
              <h1>
                {CurrentChatroom.chatroomTitle}{" "}
                <Badge color="secondary">{CurrentChatroom.totalMessages}</Badge>
              </h1>{" "}
              <p>{CurrentChatroom.chatroomDescription}</p>
            </Col>
          </Row>
        </Col>
        <ThreadDisplay />
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  CurrentChatroom: state.chatroomsState.currentlySelectedChatroom
});

export default connect(mapStateToProps)(Chatroom);
