// Functions
import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import moment from "moment";
import TimeAgo from "react-timeago";
import BootstrapTable from "react-bootstrap-table-next";
import filterFactory, {
  textFilter,
  dateFilter
} from "react-bootstrap-table2-filter";
import paginationFactory from "react-bootstrap-table2-paginator";
import cellEditFactory, { Type } from "react-bootstrap-table2-editor";
import ToolkitProvider from "react-bootstrap-table2-toolkit";

// Styles
import { Row, Col } from "reactstrap";
import {
  Card,
  Segment,
  Icon,
  Image,
  Button,
  Dropdown
} from "semantic-ui-react";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import { styles, buttonStyles } from "../../styles";

// Actions
import {
  getAllSubscribers,
  updateUserType,
  updateUserSupplier,
  updateUserCompanyType,
  updateUserExpiryDate
} from "../../actions/subscribers";
import { firebase } from "../../FireBase/firebase";
import { getSuppliers, getCompanyTypes } from "../../actions/configActions";

import { adminDeleteUser } from "../../actions/users";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import {
  createNotification,
  NOTIFICATION_TYPE_WARNING,
  NOTIFICATION_TYPE_SUCCESS
} from "react-redux-notify";

const mySuccessNotification = {
  message: `Membership  updated`,
  type: NOTIFICATION_TYPE_SUCCESS,
  duration: 3000,
  canDismiss: true,
  icon: <Icon name="check" />
};

class UserTable extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: {},
      loading: true,
      // activeTab: "1",
      startDate: null
    };
  }

  componentDidMount() {
    getAllSubscribers();
    getSuppliers();
    getCompanyTypes();
  }

  toggle = tab => {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      });
    }
  };

  handleCompanyType = (row, data) => {
    updateUserCompanyType(row.uid, data.value);
  };

  handleSupplier = (row, data) => {
    updateUserSupplier(row.uid, data.value);
  };

  handleDateChange = (date, row) => {
    // const newDate = moment(date).format("DD-MMMM-YYYY");
    // Add one hour on to the selected date so that if todays date is selected
    const sureNotification = {
      message: `Are you sure you want to change this users Membership Expiry date to ${moment(
        date
      ).format("DD-MMMM-YYYY")} ?`,
      type: NOTIFICATION_TYPE_WARNING,
      duration: 0,
      canDismiss: false,
      icon: <Icon name="warning sign" />,
      acceptBtn: {
        handler: (e, notification) => {
          this.setState({
            startDate: date
          });
          updateUserExpiryDate(date, row.uid);
          notification.handleDismiss(notification.id);
          this.props.createNotification(mySuccessNotification);
        },
        title: "Yup",
        icon: <Icon name="thumbs up" />
      },
      denyBtn: {
        handler: (e, notification) => {
          notification.handleDismiss(notification.id);
        },
        title: "Nope",
        icon: <Icon name="thumbs down" />
      }
    };
    this.props.createNotification(sureNotification);
  };

  handleTypeChange = (oldValue, newValue, row, column) => {
    updateUserType(row.uid, newValue);
  };

  render() {
    const suppliersArray = [];
    this.props.suppliers &&
      this.props.suppliers.map((supplier, i) => {
        return suppliersArray.push({
          key: i,
          text: supplier.supplierName,
          value: supplier.supplierName
        });
      });

    const companyTypesArray = [];

    this.props.companyTypes &&
      this.props.companyTypes.map((companyType, i) => {
        return companyTypesArray.push({
          key: i,
          text: companyType.companyTypeName,
          value: companyType.companyTypeName
        });
      });

    let datetimeFormatter = cell => {
      return <TimeAgo date={cell} />;
    };

    let emailFormatter = cell => {
      const mailto = `mailto:${cell}`;
      return <a href={mailto}>{cell}</a>;
    };

    const columns = [
      {
        dataField: "displayName",
        text: "Display name",
        sort: true,
        editable: false,
        filter: textFilter()
      },
      {
        dataField: "email",
        text: "Email Address",
        sort: true,
        editable: false,
        filter: textFilter(),
        formatter: emailFormatter
      },
      {
        dataField: "expiryDate",
        text: "Expiry Date",
        sort: true,
        editable: false,
        filter: textFilter(),
        formatter: datetimeFormatter
      },
      {
        dataField: "userType",
        text: "User Type",
        sort: true,
        filter: textFilter(),
        editor: {
          type: Type.SELECT,
          options: [
            {
              value: "guest",
              label: "guest",
              color: "red"
            },
            {
              value: "subscriber",
              label: "subscriber"
            },
            {
              value: "admin",
              label: "admin"
            },
            {
              value: "newUser",
              label: "newUser"
            }
          ]
        }
      },
      {
        dataField: "signUp",
        text: "Sign Up",
        sort: true,
        editable: false,
        filter: dateFilter(),
        formatter: datetimeFormatter
      },
      {
        dataField: "legalAccess",
        text: "Legal Access",
        sort: true,
        editor: {
          type: Type.SELECT,
          options: [
            {
              value: "on",
              label: "on"
            },
            {
              value: "off",
              label: "off"
            }
          ]
        }
      }
    ];

    const defaultSorted = [
      {
        dataField: "email", // if dataField is not match to any column you defined, it will be ignored.
        order: "asc" // desc or asc
      }
    ];

    const customTotal = (from, to, size) => (
      <span className="react-bootstrap-table-pagination-total">
        {" "}
        Showing {from} to {to} of {size} Results
      </span>
    );

    const options = {
      paginationSize: 4,
      pageStartIndex: 0,
      firstPageText: "First",
      prePageText: "Back",
      nextPageText: "Next",
      lastPageText: "Last",
      nextPageTitle: "First page",
      prePageTitle: "Pre page",
      firstPageTitle: "Next page",
      lastPageTitle: "Last page",
      showTotal: true,
      paginationTotalRenderer: customTotal,
      sizePerPageList: [
        {
          text: "10",
          value: 10
        },
        {
          text: "25",
          value: 25
        },
        {
          text: "50",
          value: 50
        },
        {
          text: "100",
          value: 100
        },
        {
          text: "All",
          value: this.props.subscribers.length
        }
      ] // A numeric array is also available. the purpose of above example is custom the text
    };

    const expandRow = {
      renderer: row => (
        <div>
          <Segment.Group horizontal>
            <div
              style={{
                textAlign: "center",
                justifyContent: "center",
                display: "block",
                padding: 10
              }}
            >
              {!row.profilePhoto ? (
                <Icon
                  name="user circle"
                  size="huge"
                  style={{
                    color: "grey"
                  }}
                />
              ) : (
                <Image src={row.profilePhoto} size="small" circular />
              )}
            </div>
            <Segment.Group>
              <Segment
                inverted
                size="tiny"
                style={{ backgroundColor: "#404040" }}
              >
                Public Information
              </Segment>
              <Segment>
                <Card.Description style={styles.textElementStyle}>
                  <strong>Display Name:</strong>{" "}
                  {!row.displayName ? "Display Name" : row.displayName}
                </Card.Description>
                <Card.Description style={styles.textElementStyle}>
                  <strong>Company:</strong>{" "}
                  {!row.company ? "Company" : row.company}
                </Card.Description>
                <Card.Description style={styles.textElementStyle}>
                  <strong>Job Title:</strong>{" "}
                  {!row.jobRole ? "Current Title" : row.jobRole}
                </Card.Description>
                <Card.Description style={styles.textElementStyle}>
                  <span>
                    <strong>Birthday:</strong>{" "}
                    {moment.parseZone(row.DOB).format("MMMM D")}
                  </span>
                </Card.Description>
                <Card.Description
                  floated="left"
                  style={styles.textElementStyle}
                >
                  <strong>Bio:</strong> {!row.bio ? "Curent Bio" : row.bio}
                </Card.Description>
              </Segment>
            </Segment.Group>
            <Segment.Group>
              <Segment
                inverted
                size="tiny"
                style={{ backgroundColor: "#404040" }}
              >
                Admin Information
              </Segment>
              <Segment>
                <Card.Description style={styles.textElementStyle}>
                  <strong>Company Type:</strong>{" "}
                  <Dropdown
                    onChange={(e, data) => {
                      this.handleCompanyType(row, data);
                    }}
                    options={companyTypesArray}
                    value={row.adminInfo ? row.adminInfo.companyType : ""}
                  />{" "}
                  <button
                    onClick={() => {
                      updateUserCompanyType(row.uid, "");
                    }}
                  >
                    Reset
                  </button>
                </Card.Description>
                <Card.Description style={styles.textElementStyle}>
                  <strong>Current Supplier:</strong>{" "}
                  <Dropdown
                    value={row.adminInfo ? row.adminInfo.supplier : ""}
                    onChange={(e, data) => {
                      this.handleSupplier(row, data);
                    }}
                    options={suppliersArray}
                  />{" "}
                  <button
                    onClick={() => {
                      updateUserSupplier(row.uid, "");
                    }}
                  >
                    Reset
                  </button>
                </Card.Description>
                <Card.Description style={styles.textElementStyle}>
                  <strong>Membership Expires:</strong>{" "}
                  <DatePicker
                    selected={
                      row.expiryDate
                        ? moment(
                            new Date(row.expiryDate),
                            "MMMM Do YYYY, h:mm:ss a"
                          )
                        : null
                    }
                    onChange={date => {
                      this.handleDateChange(date, row);
                    }}
                    dateFormat="DD-MM-YYYY"
                    showYearDropdown={true}
                    showMonthDropdown={true}
                    style={{ backgroundColor: "blue" }}
                  />
                  <hr />
                  <p>
                    <i>
                      <small>
                        N.B. the expiry date is non-inclusive so if you select
                        the 10th then the memebership will expire on the 9th.
                      </small>
                    </i>
                  </p>
                </Card.Description>
                <Fragment>
                  <button
                    style={{
                      background: "red",
                      color: "white",
                      fontWight: "bold",
                      border: 0,
                      padding: 5,
                      borderRadius: 5,
                      margin: 5,
                      cursor: "pointer"
                    }}
                    onClick={() => {
                      const sureDeleteNotification = {
                        message:
                          "Are you sure you want to PERMANANTLY delete this user? This action is irreversible",
                        type: NOTIFICATION_TYPE_WARNING,
                        duration: 0,
                        canDismiss: false,
                        icon: <Icon name="warning sign" />,
                        acceptBtn: {
                          handler: (e, notification) => {
                            notification.handleDismiss(notification.id);
                            adminDeleteUser(row.uid);
                          },
                          title: "Yup",
                          icon: <Icon name="thumbs up" />
                        },
                        denyBtn: {
                          handler: (e, notification) => {
                            console.log("PHEW, USER IS SAFE");
                            notification.handleDismiss(notification.id);
                          },
                          title: "Nope",
                          icon: <Icon name="thumbs down" />
                        }
                      };
                      this.props.createNotification(sureDeleteNotification);
                    }}
                  >
                    DELETE USER
                  </button>
                </Fragment>
              </Segment>
            </Segment.Group>
          </Segment.Group>
        </div>
      )
    };

    const MyExportCSV = props => {
      const handleClick = () => {
        props.onExport();
      };
      return (
        <div>
          <Button
            color="black"
            style={buttonStyles.primaryButton}
            onClick={handleClick}
          >
            Export CSV <Icon name="download" />
          </Button>
        </div>
      );
    };

    return (
      <div className="animated bounceInLeft" style={{ marginLeft: 10 }}>
        <Row>
          <Col>
            <Card fluid style={styles.profileComponentStyle}>
              <Card.Content style={styles.cardContentStyle}>
                <div style={styles.cardHeaderStyle}>User Table</div>
                <div style={styles.divStyle} />
                <div style={{ padding: 15 }}>
                  <Card.Description style={styles.textElementStyle}>
                    <ToolkitProvider
                      keyField="email"
                      data={this.props.subscribers}
                      columns={columns}
                      exportCSV={true}
                    >
                      {props => (
                        <div>
                          <BootstrapTable
                            {...props.baseProps}
                            hover
                            bordered={false}
                            striped
                            condensed
                            responsive
                            size="sm"
                            defaultSorted={defaultSorted}
                            bootstrap4={true}
                            filter={filterFactory()}
                            pagination={paginationFactory(options)}
                            noDataIndication={
                              <div>
                                <Icon name="setting" loading />
                                Loading ...
                              </div>
                            }
                            expandRow={expandRow}
                            cellEdit={cellEditFactory({
                              mode: "click",
                              beforeSaveCell: (
                                oldValue,
                                newValue,
                                row,
                                column
                              ) => {
                                if (row.legalAccess === undefined) {
                                  row.legalAccess = newValue;
                                  console.log(
                                    row.legalAccess +
                                      " no legal access key so were going to create one first"
                                  );
                                  firebase
                                    .database()
                                    .ref(`users/${row.uid}`)
                                    .update({ legalAccess: newValue })
                                    .then(function() {
                                      console.log(row.legalAccess + " After");
                                    })
                                    .catch(function(error) {
                                      console.error(
                                        "Adding legal access",
                                        error
                                      );
                                    });
                                } else if (row.legalAccess !== undefined) {
                                  console.log(
                                    "We have a legalAccess key and it's: " +
                                      row.legalAccess
                                  );
                                }
                              },
                              afterSaveCell: (
                                oldValue,
                                newValue,
                                row,
                                column
                              ) =>
                                this.handleTypeChange(
                                  oldValue,
                                  newValue,
                                  row,
                                  column
                                )
                            })}
                          />
                          <hr />
                          <MyExportCSV {...props.csvProps} />
                        </div>
                      )}
                    </ToolkitProvider>
                  </Card.Description>
                </div>
              </Card.Content>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

function mapStateToProps(state, props) {
  return {
    currentUser: state.user.currentUserProps,
    subscribers: state.subscriber.subscribers,
    suppliers: state.appConfig.suppliers,
    companyTypes: state.appConfig.companyTypes
  };
}

const mapDispatchToProps = dispatch => ({
  createNotification: config => {
    dispatch(createNotification(config));
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserTable);

// comment
