// This is an upload form for creating forum topics
// and assigning chatroom monitor rights potentially
import React, { Component } from "react";
import { connect } from "react-redux";
import { setChatroom } from "../../actions/Posts";
import { Container, Col, Row, Form, FormGroup } from "reactstrap";
import { styles } from "./forumStyle";
import { Heading, Label, Input, Card, Small, Divider, Button } from "rebass";
import Chatrooms from "./Chatrooms";

class CreateChatroom extends Component {
  constructor(props) {
    super(props);

    this.state = {
      chatroomTitle: "",
      chatroomDescription: ""
    };

    this.createChatroom = this.createChatroom.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
  }

  handleInputChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }

  async createChatroom(event) {
    event.preventDefault();
    this.setState({ [event.target.name]: event.target.value });
    const { authUser } = this.props;
    const uid = authUser.uid;
    const chatroomTitle = this.state.chatroomTitle;
    const chatroomDescription = this.state.chatroomDescription;
    setChatroom(uid, chatroomTitle, chatroomDescription);
  }

  render() {
    return (
      <Container>
        <Row>
          <Col style={styles.chatroomStyle}>
            <Card p={4}>
              <Heading fontSize={3}>Create A Chatroom</Heading>
              <Divider />
              <Form onSubmit={this.createChatroom}>
                <FormGroup>
                  <Label>Chatroom title</Label>
                  <Input
                    type="text"
                    name="chatroomTitle"
                    placeholder="Enter chatroom title"
                    onChange={this.handleInputChange}
                  />
                  <Small my={4}>
                    Give the chatroom a name that will make it clear what the
                    topic is to users of the platform.
                  </Small>
                </FormGroup>
                <Divider />
                <FormGroup>
                  <Label>Chatroom description</Label>
                  <Input
                    type="textarea"
                    name="chatroomDescription"
                    placeholder="Enter chatroom description"
                    onChange={this.handleInputChange}
                  />
                </FormGroup>
                <Button color="white" bg="orange">
                  Submit
                </Button>
              </Form>
            </Card>
          </Col>
          <Col>
            <Chatrooms />
          </Col>
        </Row>
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  authUser: state.sessionState.authUser,
  currentUser: state.user.currentUserProps
});

export default connect(mapStateToProps)(CreateChatroom);
