import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Col,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Container
} from "reactstrap";
import {
  Segment,
  Button,
  Icon,
  Feed,
  Loader,
  Card,
  Message,
  Dimmer
} from "semantic-ui-react";
import { chatroomStyles } from "../../styles";
import TimeAgo from "react-timeago";
import {
  setInsightComment,
  getInsightComments,
  selectInsightComment,
  deleteInsightComment,
  updateInsightComment,
  updateInsightCommentlike,
  replyToComment
} from "../../actions/Posts";
import InfiniteScroll from "react-infinite-scroll-component";
import {
  editorStateFromRaw,
  MegadraftEditor,
  editorStateToJSON
} from "megadraft";
import "../MegaDraft/megadraft.css";
import emptyUserImg from "../../img/blank-profile-picture.png";
import { Link } from "react-router-dom";
import {
  createNotification,
  NOTIFICATION_TYPE_WARNING
} from "react-redux-notify";
import { styles } from "../../styles";
import { setUserInsightNotifications } from "../../actions/subscribers";
// new draft js icon editor
import { EditorState } from "draft-js";
import IconEditor from "../Forum/Editor";
import Editor from "draft-js-plugins-editor";

import createLinkifyPlugin from "draft-js-linkify-plugin";
import "draft-js-linkify-plugin/lib/plugin.css";
const linkifyPlugin = createLinkifyPlugin();

const emptyCommentWarning = {
  message: "Sorry cannot submit an empty comment",
  type: NOTIFICATION_TYPE_WARNING,
  duration: 3000,
  canDismiss: true,
  icon: <Icon name="warning sign" />
};

class InsightComments extends Component {
  constructor(props) {
    super(props);

    this.state = {
      items: this.props.insightComments,
      itemAmount: 10,
      editorState: EditorState.createEmpty(),
      editorEditState: editorStateFromRaw(null),
      editorReplyState: editorStateFromRaw(null),
      myReplyEditorState: EditorState.createEmpty(),
      userName: "",
      profilePhoto: "",
      editPost: false,
      selectedPostState: {},
      modal: false,
      replyModal: false,
      currentCommentkey: ""
    };
  }

  componentDidMount() {
    // Dispatch an action to retrieve all the posts from the post node in FB
    // and return this.props.currentAllPosts
    getInsightComments(this.props.insightKey);
  }

  fetchMoreData = () => {
    const itemAmount = this.state.itemAmount + 5;

    setTimeout(() => {
      this.setState({
        items: this.props.insightComments.slice(0, itemAmount),
        itemAmount: itemAmount
      });
    }, 1000);
  };

  onChange = editorState => {
    // handle the update of the editor state
    this.setState({ editorState });
  };

  onEditChange = editorEditState => {
    // handle the update of the editor state
    this.setState({ editorEditState });
  };

  onReplyChange = myReplyEditorState => {
    // handle the update of the editor state
    this.setState({ myReplyEditorState });
  };

  handleSelectClick = (commentkey, insightKey) => {
    // Dispatch an action with the selected post
    selectInsightComment(commentkey, insightKey);
  };

  toggleReply = comment => {
    if (typeof comment === "string") {
      this.setState({
        replyModal: !this.state.replyModal,
        editorReplyState: editorStateFromRaw(JSON.parse(comment))
      });
    } else {
      this.setState({
        replyModal: !this.state.replyModal
      });
    }
  };

  toggleEdit = comment => {
    // This is the trick //
    // Set the edit state with the props message
    // when you toggle the model off you dont want to pass in comment argument but simply set the modal state
    if (typeof comment === "string") {
      this.setState({
        modal: !this.state.modal,
        editorEditState: editorStateFromRaw(JSON.parse(comment))
      });
    } else {
      this.setState({
        modal: !this.state.modal
      });
    }
  };

  onDeleteClick = () => {
    this.setState({ modal: false });
    deleteInsightComment(this.state.currentCommentkey, this.props.insightKey);
  };

  onSaveClick = () => {
    // Deconstruct the text from the editorState object
    const currentContent = this.state.editorEditState.getCurrentContent();
    const currentComment = currentContent.getPlainText();
    // Check its isn't empty
    const { createNotification } = this.props;
    if (
      currentComment === "" ||
      currentComment.replace(/\s+/g, "").length === 0
    ) {
      // If it is invoke notification
      return createNotification(emptyCommentWarning);
    } else {
      const { editorEditState } = this.state;
      const comment = editorStateToJSON(editorEditState);
      const key = this.props.insightKey;
      updateInsightComment(this.state.currentCommentkey, key, comment);
      this.setState({
        modal: false
      });
    }
  };

  onSaveReplyClick = () => {
    // Deconstruct the text from the editorState object
    const currentContent = this.state.myReplyEditorState.getCurrentContent();
    const currentComment = currentContent.getPlainText();
    // Check its isn't empty
    const { createNotification } = this.props;

    // props coming from InsightModal
    const key = this.props.insightKey;
    const title = this.props.insightTitle;

    const { uid } = this.props.currentUser;
    if (
      currentComment === "" ||
      currentComment.replace(/\s+/g, "").length === 0
    ) {
      // If it is invoke notification
      console.log("error, comment is empty");
      return createNotification(emptyCommentWarning);
    } else {
      const comment = editorStateToJSON(this.state.myReplyEditorState);
      const originalPost = this.props.insightComment;
      replyToComment(
        comment,
        key,
        uid,
        originalPost,
        this.props.currentUser.displayName,
        this.props.currentUser.profilePhoto || "",
        this.props.currentUser.firstName,
        this.props.currentUser.lastName
      );
    }

    if (
      this.props.currentUser.notifications &&
      this.props.currentUser.notifications.insights &&
      this.props.currentUser.notifications.insights[key] &&
      this.props.currentUser.notifications.insights[key].subscribed === false
    ) {
      return;
    } else {
      setUserInsightNotifications(key, uid, title);
    }

    this.setState({
      replyModal: false,
      myReplyEditorState: editorStateFromRaw(null)
    });
  };

  createComment = () => {
    // Deconstruct the text from the editorState object
    const currentContent = this.state.editorState.getCurrentContent();
    const currentComment = currentContent.getPlainText();
    // Check its isn't empty
    const { createNotification } = this.props;
    if (
      currentComment === "" ||
      currentComment.replace(/\s+/g, "").length === 0
    ) {
      // If it is invoke notification
      return createNotification(emptyCommentWarning);
    }

    // Define properties to send to FB
    const {
      displayName,
      profilePhoto,
      firstName,
      lastName,
      uid
    } = this.props.currentUser;

    // props coming from insightModal
    const key = this.props.insightKey;
    const title = this.props.insightTitle;

    const comment = editorStateToJSON(this.state.editorState);
    // Dispatch the message Object to FB
    setInsightComment(
      comment,
      uid,
      key,
      displayName,
      profilePhoto || "",
      firstName,
      lastName
    );

    // if user has turned off notifications, dont subscribe them
    if (
      this.props.currentUser.notifications &&
      this.props.currentUser.notifications.insights &&
      this.props.currentUser.notifications.insights[key] &&
      this.props.currentUser.notifications.insights[key].subscribed === false
    ) {
      return;
    } else {
      setUserInsightNotifications(key, uid, title);
    }

    // Reset the state of the editor to null
    this.setState({
      editorState: editorStateFromRaw(null)
    });
  };

  handleLikeClick = (commentKey, insightKey, uid) => {
    // Dispatch and action to update the database with the like state of the post
    updateInsightCommentlike(commentKey, insightKey, uid);
  };

  render() {
    const myCommentMessageStyle = {
      backgroundColor: "rgba(59, 205, 59, .4)",
      boxShadow: `1px 3px 10px #9E9E9E`,
      borderRadius: 7,
      padding: "1rem",
      overflowWrap: "break-word",
      wordWrap: "break-word",
      wordBreak: "break-word",
      hyphens: "auto"
    };

    const otherCommentMessageStyle = {
      backgroundColor: "rgb(204,226,255, .4)",
      boxShadow: `1px 3px 10px #9E9E9E`,
      borderRadius: 7,
      padding: "1rem"
    };

    const replyStyle = {
      width: "90%",
      overflowWrap: "break-word",
      wordWrap: "break-word",
      wordBreak: "break-word",
      hyphens: "auto"
    };

    return (
      <div>
        <h2 style={{ marginLeft: 20 }}>Share your thoughts...</h2>
        <hr />
        <InfiniteScroll
          style={{
            overflowX: "hidden"
          }}
          height={
            this.props.insightComments.length > 2
              ? 520
              : this.props.insightComments.length === 2
              ? this.props.insightComments.length === 1
              : 200
              ? this.props.insightComments.length === 1
              : 0
          }
          dataLength={this.state.items.length}
          next={this.fetchMoreData}
          hasMore={true}
          loader={
            this.state.itemAmount >= this.props.insightComments.length ? (
              ""
            ) : (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <Loader active inline content="Loading..." />
              </div>
            )
          }
        >
          {/* below is the logic for how to render each individual comment depending on if you are the original poster / if you have edited or replied to a comment */}
          {this.props.insightComments.map((comment, i) => {
            return (
              <div
                style={
                  this.props.currentUser.uid === comment.uid
                    ? { marginLeft: "10%" }
                    : { marginRight: "10%" }
                }
                key={i}
              >
                <Col>
                  <Row>
                    <Col>
                      <Feed style={{ marginBottom: 30 }}>
                        <Feed.Event>
                          <Feed.Label
                            image={comment.profilePhoto || emptyUserImg}
                          />
                          <Feed.Content>
                            <Feed.Summary>
                              {comment.displayName ? (
                                <div>
                                  <Link to="">{comment.displayName}</Link>
                                </div>
                              ) : (
                                <div>
                                  {comment.firstName} {comment.lastName}
                                </div>
                              )}
                              <Feed.Date>
                                <TimeAgo
                                  date={comment.time}
                                  style={{ textAlign: "right" }}
                                />
                              </Feed.Date>
                            </Feed.Summary>
                            <Row>
                              {/* render this edit option only if its the creators post */}
                              {this.props.currentUser.uid === comment.uid ? (
                                <a
                                  onClick={() => {
                                    this.handleSelectClick(
                                      comment.key,
                                      this.props.insightKey
                                    );
                                    this.setState({
                                      currentCommentkey: comment.key
                                    });
                                  }}
                                >
                                  {/* this toggles the edit post modal */}
                                  <Button
                                    style={{ backgroundColor: "transparent" }}
                                    onClick={() =>
                                      this.toggleEdit(comment.comment)
                                    }
                                    compact
                                  >
                                    {"•••"}
                                  </Button>
                                </a>
                              ) : (
                                ""
                              )}
                              <a
                                onClick={() => {
                                  this.handleSelectClick(
                                    comment.key,
                                    this.props.insightKey
                                  );
                                  this.setState({
                                    currentCommentkey: comment.key
                                  });
                                }}
                              >
                                {/* toggle the reply modal */}
                                <Button
                                  style={{ backgroundColor: "transparent" }}
                                  onClick={() =>
                                    this.toggleReply(comment.comment)
                                  }
                                  compact
                                >
                                  <Icon name="reply" size="large" />
                                </Button>
                              </a>
                            </Row>

                            <Feed.Extra
                              text
                              style={
                                this.props.currentUser.uid === comment.uid
                                  ? myCommentMessageStyle
                                  : otherCommentMessageStyle
                              }
                            >
                              {comment.originalPost ? (
                                <Segment
                                  style={{
                                    padding: 0,
                                    borderRadius: 7,
                                    backgroundColor: "rgba(240, 240, 240, 0.2)"
                                  }}
                                >
                                  <Row>
                                    <div
                                      style={{
                                        width: 5,
                                        backgroundImage:
                                          "linear-gradient(to bottom, #FFDF00, #F63800)",
                                        borderTopLeftRadius: 7,
                                        borderBottomLeftRadius: 7,
                                        marginLeft: 15
                                      }}
                                    />
                                    <Col style={{ padding: 5 }}>
                                      <Feed style={replyStyle}>
                                        <Feed.Event>
                                          <Feed.Label
                                            image={
                                              comment.originalPost
                                                .profilePhoto || emptyUserImg
                                            }
                                          />
                                          <Feed.Content>
                                            <Feed.Summary>
                                              {comment.originalPost
                                                .displayName ? (
                                                <Link to="">
                                                  {
                                                    comment.originalPost
                                                      .displayName
                                                  }
                                                </Link>
                                              ) : (
                                                <div>
                                                  {
                                                    comment.originalPost
                                                      .firstName
                                                  }{" "}
                                                  {
                                                    comment.originalPost
                                                      .lastName
                                                  }
                                                </div>
                                              )}
                                            </Feed.Summary>
                                            <Feed.Extra text>
                                              {/* this is showing the original post that is being replied to */}
                                              <Editor
                                                editorState={editorStateFromRaw(
                                                  JSON.parse(
                                                    comment.originalPost.comment
                                                  )
                                                )}
                                                readOnly={true}
                                                plugins={[linkifyPlugin]}
                                                style={{
                                                  color: "white"
                                                }}
                                              />
                                            </Feed.Extra>
                                          </Feed.Content>
                                        </Feed.Event>
                                      </Feed>
                                    </Col>
                                  </Row>
                                </Segment>
                              ) : (
                                ""
                              )}
                              {/* this is displayed if a post has been edited */}
                              {!this.state.editPost && (
                                <Editor
                                  editorState={editorStateFromRaw(
                                    JSON.parse(comment.comment)
                                  )}
                                  readOnly={true}
                                  plugins={[linkifyPlugin]}
                                  style={{ color: "white" }}
                                />
                              )}
                              {this.state.editPost && (
                                <Segment>
                                  <Editor
                                    editorState={this.state.editorState}
                                    onChange={this.onChange}
                                    plugins={[linkifyPlugin]}
                                  />
                                </Segment>
                              )}
                            </Feed.Extra>
                            <Feed.Meta>
                              <Feed.Like
                                onClick={() =>
                                  this.handleLikeClick(
                                    comment.key,
                                    this.props.insightKey,
                                    this.props.currentUser.uid
                                  )
                                }
                              >
                                <Icon name="like" />
                                {comment.likeCount} Likes
                              </Feed.Like>
                              {comment.updateTime === undefined ? (
                                ""
                              ) : (
                                <div style={{ textAlign: "right" }}>
                                  Edited: <TimeAgo date={comment.updateTime} />
                                </div>
                              )}
                            </Feed.Meta>
                          </Feed.Content>
                        </Feed.Event>
                      </Feed>
                    </Col>
                  </Row>
                </Col>
              </div>
            );
          })}
        </InfiniteScroll>
        {/* COMMENT EDITOR */}
        <Col style={chatroomStyles.postStyle}>
          <Row>
            <Col lg="11" sm="10" xs="9">
              <Segment style={{ boxShadow: "none", borderRadius: 7 }}>
                <IconEditor
                  editorState={this.state.editorState}
                  onChange={this.onChange}
                  ref={element => {
                    this.editor = element;
                  }}
                />
              </Segment>
            </Col>
            <Col
              lg="1"
              sm="2"
              xs="3"
              style={{
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column"
              }}
            >
              <Button
                primary
                circular
                icon="send"
                onClick={this.createComment}
                style={{ marginTop: 10 }}
              />
            </Col>
          </Row>
        </Col>
        {/* EDIT MODAL */}
        <Modal
          isOpen={this.state.modal}
          toggle={this.toggleEdit}
          className={this.props.className}
          size="lg"
        >
          <ModalHeader toggle={this.toggleEdit}>
            Edit post by:{" "}
            {this.props.insightComment && this.props.insightComment.displayName}
          </ModalHeader>
          <ModalBody>
            <Card fluid style={styles.profileComponentStyle}>
              <Card.Content style={styles.cardContentStyle}>
                <Segment style={styles.segmentStyle}>
                  <Container>
                    <IconEditor
                      editorState={this.state.editorEditState}
                      onChange={this.onEditChange}
                      ref={element => {
                        this.editor = element;
                      }}
                    />
                  </Container>
                </Segment>
              </Card.Content>
            </Card>
          </ModalBody>
          <ModalFooter>
            <Button color="red" onClick={this.onDeleteClick}>
              Delete
            </Button>
            <Button color="yellow" onClick={this.toggleEdit}>
              Cancel
            </Button>
            <Button
              circular
              primary
              onClick={this.onSaveClick}
              style={{ borderRadius: 7 }}
              icon="send"
            />
          </ModalFooter>
        </Modal>
        {/* REPLY POST MODAL */}
        <Modal
          isOpen={this.state.replyModal}
          toggle={this.toggleReply}
          className={this.props.className}
          size="lg"
        >
          <ModalHeader toggle={this.toggleReply}>
            <Icon name="reply" />
            Reply{" "}
          </ModalHeader>
          {this.props.insightComment ? (
            <ModalBody>
              <Message
                content={
                  <Feed>
                    <Feed.Event>
                      <Feed.Label
                        image={
                          this.props.insightComment.profilePhoto || emptyUserImg
                        }
                      />

                      <Feed.Content>
                        <Feed.Summary>
                          {this.props.insightComment.displayName ? (
                            <div>{this.props.insightComment.displayName}</div>
                          ) : (
                            <div>
                              {this.props.insightComment.firstName}{" "}
                              {this.props.insightComment.lastName}
                            </div>
                          )}
                        </Feed.Summary>
                        <Feed.Extra text>
                          {/* this displays the read only reply message in the reply modal */}
                          <MegadraftEditor
                            editorState={this.state.editorReplyState}
                            readOnly={true}
                          />
                        </Feed.Extra>
                      </Feed.Content>
                    </Feed.Event>
                  </Feed>
                }
              />
              <Segment>
                <IconEditor
                  editorState={this.state.myReplyEditorState}
                  onChange={this.onReplyChange}
                  placeholder="Write reply here ..."
                />
              </Segment>
            </ModalBody>
          ) : (
            <Dimmer active inverted>
              <Loader>Loading</Loader>
            </Dimmer>
          )}
          <ModalFooter>
            <Button color="yellow" onClick={this.toggleReply}>
              Cancel
            </Button>
            <Button
              circular
              primary
              onClick={this.onSaveReplyClick}
              style={{ borderRadius: 7 }}
              icon="send"
            />
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  authUser: state.sessionState.authUser,
  currentUser: state.user.currentUserProps,
  insightComments: state.postState.insightComments,
  insightComment: state.postState.insightComment
});

const mapDispatchToProps = dispatch => ({
  createNotification: config => {
    dispatch(createNotification(config));
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InsightComments);
