import React, { Component } from "react";
import { connect } from "react-redux";
import { Button, Form } from "semantic-ui-react";
import { Container, Row, Col } from "reactstrap";
import { auth } from "../../FireBase/index";

class PasswordForgetPage extends Component {
  render() {
    return (
      <Container style={{ padding: 30 }}>
        <Row full>
          <Col md="2" />
          <Col md="8">
            <h1>Reset Password</h1>
            <PasswordForgetForm />
          </Col>
          <Col md="2" />
        </Row>
      </Container>
    );
  }
}

const updateByPropertyName = (propertyName, value) => () => ({
  [propertyName]: value
});

const INITIAL_STATE = {
  email: "",
  error: null,
  confirmation: ""
};

class PasswordForgetForm extends Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };
  }

  //On Submit should we send user back to log in screen with a popup saying to visit their email?
  //Right now will just send them email and display small message saying they have email to reset password now.

  onSubmit = event => {
    const { email } = this.state;

    auth
      .doPasswordReset(email)
      .then(() => {
        this.setState(() => ({
          email: "",
          error: null,
          confirmation: "Check your email for reset link."
        }));
      })
      .catch(error => {
        this.setState(updateByPropertyName("error", error));
      });

    event.preventDefault();
  };

  render() {
    const { email, error } = this.state;

    const isInvalid = email === "";

    return (
      <div>
        <Form onSubmit={this.onSubmit}>
          <input
            value={this.state.email}
            onChange={event =>
              this.setState(updateByPropertyName("email", event.target.value))
            }
            type="email"
            placeholder="Email Address"
            style={styles.formElementStyle}
          />
          <Button disabled={isInvalid} type="submit" style={styles.buttonStyle}>
            Reset My Password
          </Button>

          {error && <p>{error.message}</p>}
        </Form>
        <h3>{this.state.confirmation}</h3>
      </div>
    );
  }
}

const styles = {
  formElementStyle: {
    marginTop: 10,
    maxWidth: 600
  },
  buttonStyle: {
    marginTop: 10,
    backgroundColor: "#4A90E2",
    color: "white"
  }
};

const mapStateToProps = state => ({
  currentUser: state.user.currentUserProps
});

export default connect(mapStateToProps)(PasswordForgetPage);
