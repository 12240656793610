import {
  SET_SKILL_LEVELS,
  SET_COURSES,
  SET_INSTRUCTORS,
  SET_ACTIVE_COURSE_SECTION,
  SET_ACTIVE_LESSON_COURSE
} from "../actions/types";
const INITIAL_STATE = {
  skillLevels: [],
  courses: [],
  activeCourseSection: {},
  activeLessonCourse: {}
};

export default function universityReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case SET_SKILL_LEVELS: {
      return { ...state, skillLevels: action.skillLevels };
    }
    case SET_COURSES: {
      return { ...state, courses: action.courses };
    }
    case SET_INSTRUCTORS: {
      return { ...state, instructors: action.instructors };
    }
    case SET_ACTIVE_COURSE_SECTION: {
      return { ...state, activeCourseSection: action.activeCourseSection };
    }
    case SET_ACTIVE_LESSON_COURSE: {
      return { ...state, activeLessonCourse: action.activeLessonCourse };
    }
    default:
      return state;
  }
}
