import React, { Component } from "react";
import { connect } from "react-redux";
import { Card, Segment, List, Button } from "semantic-ui-react";
import { Row, Col } from "reactstrap";
import { styles, buttonStyles } from "../../styles";
import { getLatestInsights, setInsight } from "../../actions/insights";
import InsightModal from "../Insights/InsightModal";
import SubscribeModal from "../Insights/SubscribeModal";

class LatestInsights extends Component {
  state = {
    modal: false
  };

  componentDidMount = () => {
    getLatestInsights();
  };

  toggle = key => {
    // eslint-disable-next-line
    if (typeof key == "string") {
      setInsight(key, this.props.currentUser);
    }
    this.setState({ modal: !this.state.modal });
  };

  render() {
    const { latestInsightsList } = this.props;
    return (
      <Card color="teal" fluid style={styles.profileComponentStyle}>
        <Card.Content style={styles.cardContentStyle}>
          <div style={styles.cardHeaderStyle}>Latest Insights Activity</div>
          <div style={styles.divStyleLeftbox} />
          <Segment style={styles.segmentStyle}>
            <List divided verticalAlign="middle">
              {Object.values(latestInsightsList).map((insight, idx) => (
                <List.Item key={idx}>
                  <Row>
                    <Col sm="8">
                      <List.Header>{insight.title}</List.Header>
                    </Col>
                    <Col sm="4">
                      {this.props.currentUser.userType === "guest" ? (
                        <SubscribeModal buttonLabel="Subscribe" />
                      ) : (
                        <div>
                          <Button
                            onClick={() => this.toggle(insight.key)}
                            style={buttonStyles.primaryButton}
                            color="teal"
                          >
                            View
                          </Button>
                        </div>
                      )}
                    </Col>
                  </Row>
                </List.Item>
              ))}
              <InsightModal modal={this.state.modal} toggle={this.toggle} />
            </List>
          </Segment>
        </Card.Content>
      </Card>
    );
  }
}

const mapStateToProps = state => ({
  latestInsightsList: state.insights.latestInsights,
  currentUser: state.user.currentUserProps
});

export default connect(mapStateToProps)(LatestInsights);
