import Background from "../img/pattern-bg.jpg";

export const styles = {
  cardHeaderStyle: {
    backgroundColor: "#404040",
    color: "white",
    padding: 3,
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    paddingLeft: 25,
    margin: 0
  },
  divStyle: {
    height: 6,
    backgroundImage: "linear-gradient(to right, #FFDF00, #F63800, #6400B5)"
  },
  divStyleLeftbox: {
    height: 6,
    backgroundImage: "linear-gradient(to right, #FFDF00, #F63800)"
  },
  divStyleRightbox: {
    height: 6,
    backgroundImage: "linear-gradient(to right, #F63800, #6400B5)"
  },
  profileComponentStyle: {
    marginTop: 0,
    padding: 0,
    marginBottom: 10,
    backgroundImage: `url(${Background})`,
    boxShadow: "none",
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    borderColor: "#000",
    borderBottomWidth: 0,
    shadowColor: "#000",
    shadowOffset: { width: 10, height: 2 },
    shadowOpacity: 0.8
  },
  unorderedListStyle: {
    listStyle: "none"
  },
  cardContentStyle: {
    padding: 0,
    margin: 0,
    borderWidth: 0
  },
  segmentStyle: {
    backgroundImage: `url(${Background})`,
    borderWidth: 0,
    boxShadow: "none"
  },
  segmentProfile: {
    backgroundImage: `url(${Background})`,
    borderWidth: 0,
    boxShadow: "none"
  },
  textElementStyle: {
    margin: 5
  },
  authorDisplayName: {
    fontWeight: "bold",
    textAlign: "right"
  },
  authorNameStyle: {
    fontWeight: "bold",
    textAlign: "right"
  },
  iframeStyle: {
    padding: 3
  }
};

export const headerStyles = {
  navbarStyle: {
    borderRadius: 0,
    marginTop: 0,
    backgroundColor: "#404040",
    color: "#f8f8f8",
    fontFamily: "avenir"
  },
  buttonStyle: {
    backgroundColor: "transparent",
    borderWidth: 0,
    color: "#E9E9E9",
    fontWeight: "normal"
  },
  navItemStyle: {
    marginLeft: 20,
    marginRight: 20,
    fontSize: 15
  },
  navStyle: {
    color: "white"
  },
  headerDec: {
    backgroundImage: "linear-gradient(to right, #FFDF00, #F63800, #6400B5)",
    position: "absolute",
    width: "100%",
    height: 7
  }
};

export const modalStyles = {
  segmentStyle: {
    backgroundColor: "D8D8D8"
  },
  headerModalStyle: {
    backgroundColor: "#404040",
    color: "#D8D8D8"
  }
};

export const buttonStyles = {
  primaryButton: {
    backgroundImage: "linear-gradient(to bottom, #FFDF00, #F63800)",
    margin: 3,
    height: 35
  },
  deleteButton: {
    backgroundImage: "linear-gradient(to bottom, #F63800, #6400B5)",
    margin: 3,
    height: 35
  },
  editButton: {
    backgroundImage: "linear-gradient(to bottom, #88FAF7, #1F13B5)",
    margin: 3,
    height: 35
  }
};

export const chatroomStyles = {
  chatroomStyle: {
    backgroundImage: `url(${Background})`,
    borderRadius: 7,
    padding: 20,
    marginTop: 5,
    marginBottom: 25,
    borderWidth: 5,
    border: "black",
    boxShadow: "0px 5px 10px #888888"
  },
  profileComponentStyle: {
    padding: 0,
    marginBottom: 10,
    borderWidth: 10,
    boxShadow: "none",
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    backgroundImage: `url(${Background})`
  },
  cardHeaderStyle: {
    backgroundColor: "#404040",
    color: "white",
    padding: 3,
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    paddingLeft: 25,
    margin: 0
  },
  postStyle: {
    backgroundImage: `url(${Background})`,
    borderRadius: 7,
    padding: 20,
    marginTop: 5,
    marginBottom: 25,
    borderWidth: 5,
    border: "black"
  }
};
