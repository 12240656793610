import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import queryString from "query-string";

import LandingPageComponent from "./landingpage";
import { firebase } from "../../FireBase/firebase";

import { Dimmer, Loader } from "semantic-ui-react";

const LandingPage = () => {
  const history = useHistory();
  const [trying, setTrying] = React.useState(false);

  useEffect(() => {
    const query = queryString.parse(history.location.pathname);
    if (query.t) {
      setTrying(true);
      setTimeout(() => {
        SSO(query.t, history, setTrying);
      }, 3000);
    }
  }, []);

  if (trying) {
    return (
      <Dimmer inverted active>
        <Loader size="large">Attempting Login...</Loader>
      </Dimmer>
    );
  }

  return <LandingPageComponent />;
};

export default LandingPage;

const SSO = (token, history, setTrying) => {
  const ref = firebase.functions().httpsCallable("generateCustomToken");
  ref({ token })
    .then(result => {
      firebase
        .auth()
        .signInWithCustomToken(result.data)
        .then(history.push("myaccount"))
        .catch(e => console.log(e));
    })
    .catch(e => {
      if (e.code) history.push("login");
    })
    .then(() => setTrying(false));
};
