import store from "../store";
import { firebase } from "../FireBase/firebase";

export const snapshotToArray = snapshot => {
  let returnArr = [];

  snapshot.forEach(childSnapshot => {
    let item = childSnapshot.val();
    item.key = childSnapshot.key;
    returnArr.push(item);
  });

  return returnArr;
};

export const SET_CURRENT_SUBSCRIBERS = "SET_CURRENT_SUBSCRIBERS";
export const setCurrentSubscribers = subscribers => ({
  type: SET_CURRENT_SUBSCRIBERS,
  subscribers
});

export const getAllSubscribers = () => {
  firebase
    .database()
    .ref("users")
    .orderByChild("time")
    .on("value", function(snapshot) {
      const subscribers = snapshotToArray(snapshot);
      store.dispatch(setCurrentSubscribers(subscribers));
    });
};

// updates the user object in firebase using the key
export const updateUserType = (uid, newType) => {
  const userUpdate = firebase.database().ref(`users/${uid}`);
  console.log(newType);
  switch (newType) {
    case "on": {
      userUpdate
        .update({ legalAccess: newType })
        .then(console.log("legal Access Set ON"));
      break;
    }
    case "off": {
      userUpdate
        .update({ legalAccess: newType })
        .then(console.log("legal Access Set OFF"));
      break;
    }
    case "subscriber": {
      userUpdate
        .update({ userType: newType })
        .then(console.log("userType set SUBSCRIBER"));
      break;
    }
    case "guest": {
      userUpdate
        .update({ userType: newType })
        .then(console.log("userType set GUEST"));
      break;
    }
    case "admin": {
      userUpdate
        .update({ userType: newType })
        .then(console.log("userType set ADMIN"));
      break;
    }
    case "newUser": {
      userUpdate
        .update({ userType: newType })
        .then(console.log("userType set NEW USER"));
      break;
    }
    case "deletedUser": {
      userUpdate
        .update({ userType: newType })
        .then(console.log("userType set DELETED USER"));
      break;
    }
    default:
      console.log("Unrecognised Update field");
  }
};

export const updateUserSupplier = (uid, supplier) => {
  firebase
    .database()
    .ref(`users/${uid}/adminInfo`)
    .update({ supplier });
};

export const updateUserCompanyType = (uid, companyType) => {
  console.log("UID ", uid);
  console.log("company Type ", companyType);
  firebase
    .database()
    .ref(`users/${uid}/adminInfo`)
    .update({ companyType });
};

export const updateUserExpiryDate = (expiryDate, uid) => {
  firebase
    .database()
    .ref(`users/${uid}`)
    .update({ expiryDate });
};

// User notification subscriptions

export const setUserChatroomNotifications = (
  chatroomKey,
  uid,
  chatroomTitle
) => {
  firebase
    .database()
    .ref(`users/${uid}/notifications/chatrooms/${chatroomKey}`)
    .update({ subscribed: true, chatroomTitle });
};

export const updateUserChatroomNotifications = (chatroomKey, uid, boolean) => {
  firebase
    .database()
    .ref(`users/${uid}/notifications/chatrooms/${chatroomKey}`)
    .update({ subscribed: boolean });
};

export const subscribeToAllChatrooms = uid => {
  let newChatrooms = {};
  firebase
    .database()
    .ref(`chatrooms`)
    .on("value", snapshot => {
      const chatrooms = snapshot.val();

      // map over all chatrooms in firebase db
      Object.values(chatrooms).map(chatroom => {
        const { chatroomKey, chatroomTitle } = chatroom;

        // create a new user object containing all chatrooms they will be subscribed to
        newChatrooms = {
          ...newChatrooms,
          [chatroomKey]: { chatroomKey, chatroomTitle, subscribed: true }
        };

        // append new object to user
        return firebase
          .database()
          .ref(`users/${uid}/notifications/chatrooms`)
          .set(newChatrooms);
      });
    });
};

export const setUserInsightNotifications = (insightKey, uid, insightTitle) => {
  firebase
    .database()
    .ref(`users/${uid}/notifications/insights/${insightKey}`)
    .update({ subscribed: true, insightTitle });
};

export const updateUserInsightNotifications = (insightKey, uid, boolean) => {
  firebase
    .database()
    .ref(`users/${uid}/notifications/insights/${insightKey}`)
    .update({ subscribed: boolean });
};
