import React, { Component } from "react";
import Editor from "draft-js-plugins-editor";
import createToolbarLinkPlugin from "draft-js-toolbar-link-plugin";
import createToolbarPlugin, { Separator } from "draft-js-static-toolbar-plugin";
import {
  ItalicButton,
  BoldButton,
  UnderlineButton,
  UnorderedListButton,
  OrderedListButton,
  BlockquoteButton
} from "draft-js-buttons";
import "draft-js-static-toolbar-plugin/lib/plugin.css";
// import buttonStyles from "./buttonStyles.css";
// import toolbarStyles from "./toolbarStyles.css";
// import editorStyles from "./editorStyles.css";

// const staticToolbarPlugin = createToolbarPlugin();
const toolbarLinkPlugin = createToolbarLinkPlugin();
const { LinkButton } = toolbarLinkPlugin;
const toolbarPlugin = createToolbarPlugin({
  structure: [
    BoldButton,
    ItalicButton,
    UnderlineButton,
    LinkButton, // Use `LinkButton` as an item button
    Separator,
    UnorderedListButton,
    OrderedListButton,
    BlockquoteButton
  ]
  //   theme: { buttonStyles, toolbarStyles }
});
const { Toolbar } = toolbarPlugin;
const plugins = [toolbarPlugin];

class IconEditor extends Component {
  render() {
    const { editorState, onChange } = this.props;
    return (
      <div
      //   className={editorStyles.editor}
      >
        <Toolbar>
          {externalProps => (
            <React.Fragment>
              <BoldButton {...externalProps} />
              <ItalicButton {...externalProps} />
              <UnderlineButton {...externalProps} />
              <LinkButton {...externalProps} />
              <Separator {...externalProps} />
              <UnorderedListButton {...externalProps} />
              <OrderedListButton {...externalProps} />
              <BlockquoteButton {...externalProps} />
            </React.Fragment>
          )}
        </Toolbar>
        <br />
        <Editor
          editorState={editorState}
          onChange={onChange}
          plugins={plugins}
          ref={element => {
            this.editor = element;
          }}
        />
      </div>
    );
  }
}

export default IconEditor;
