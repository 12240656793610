import React from "react";
import { connect } from "react-redux";
import { Link, Redirect } from "react-router-dom";
import { auth } from "../FireBase/firebase";
import styled from "styled-components";
import { Button } from "semantic-ui-react";
import { Container, Row, Col } from "reactstrap";

const StyledInputDiv = styled.div`
  padding: 5;
  width: 100%;
`;

const StyledInputLabel = styled.label`
  font-weight: 200;
  font-size: 1rem;
  width: 80%;
  @media (max-width: 500px) {
    width: 100%;
  }
`;

const StyledInput = styled.input`
  font-weight: 100;
  border: 1px solid #e0e4e4;
  border-radius: 7px;
  box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.05) inset;
  font-size: 1rem;
  padding: 0.75rem;
  width: 100%;
  &:focus {
    outline: none;
    box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.05) inset, 0 0 1.5pt 1.5pt #4488dd;
  }
`;

const StyledSemanticButton = styled(Button)`
  margin: 5px !important;
`;

export class LoginForm extends React.Component {
  constructor() {
    super();
    this.state = {
      emailError: "",
      passwordError: "",
      error: "",
      deletedUserError: "",
      email: "",
      password: ""
    };
  }

  handleChange = event => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
  };

  onSubmit = e => {
    e.preventDefault();
    const { email, password } = this.state;
    auth.signInWithEmailAndPassword(email, password).catch(error => {
      if (error.code === "auth/user-not-found") {
        this.setState({
          emailError:
            "Sorry this email is not registered, please visit the register link to sign up. ",
          passwordError: ""
        });
      } else if (error.code === "auth/wrong-password") {
        this.setState({
          emailError: "",
          passwordError:
            "Sorry password is incorrect. Please try again or use forgot password to receive password reset link via email."
        });
      } else {
        this.setState({
          emailError: "",
          passwordError: "",
          error: `Sorry you hit an error :  ${error.message}  Please try again.`
        });
      }
    });
  };

  render() {
    if (
      this.props.currentUser.uid !== null &&
      this.props.currentUser.userType !== "newUser"
    ) {
      return <Redirect to="/myaccount" />;
    } else if (
      this.props.currentUser.uid !== null &&
      this.props.currentUser.userType === "newUser"
    ) {
      return <Redirect to="/NewUser" />;
    }
    return (
      <Container>
        <Row>
          <Col lg="2" />
          <Col lg="10" style={styles.formStyle}>
            <form className="login-form" onSubmit={e => this.onSubmit(e)}>
              <br />
              <StyledInputDiv>
                <StyledInputLabel htmlFor="email">
                  Email
                  <StyledInput
                    autoComplete="email"
                    id="email"
                    name="email"
                    onChange={this.handleChange}
                    required
                    type="email"
                  />
                </StyledInputLabel>
                {this.state.emailError && (
                  <h4 style={{ color: "red" }}>{this.state.emailError}</h4>
                )}
                {this.state.deletedUserError && (
                  <h4 style={{ color: "red" }}>
                    {this.state.deletedUserError}
                  </h4>
                )}
              </StyledInputDiv>
              <br />
              <StyledInputDiv>
                <StyledInputLabel htmlFor="password">
                  Password
                  <StyledInput
                    autoComplete="password"
                    id="password"
                    name="password"
                    onChange={this.handleChange}
                    required
                    type="password"
                  />
                </StyledInputLabel>
                {this.state.passwordError && (
                  <h4 style={{ color: "red" }}>{this.state.passwordError}</h4>
                )}
                <br />
                {this.state.error && (
                  <h4 style={{ color: "red" }}>{this.state.error}</h4>
                )}
              </StyledInputDiv>
              <StyledSemanticButton
                className="form-button"
                disabled={this.state.email === "" || this.state.password === ""}
              >
                Log in
              </StyledSemanticButton>
              <StyledSemanticButton
                className="form-button"
                disabled={
                  this.state.email === "" || !this.state.email.includes("@")
                }
              >
                <Link to={"/PasswordReset"}>Forgot Password?</Link>
              </StyledSemanticButton>
            </form>
          </Col>
          <Col lg="2" />
        </Row>
      </Container>
    );
  }
}

const styles = {
  formStyle: {
    margin: 30,
    maxWidth: 600,
    alignItems: "center"
  }
};
const mapStateToProps = state => ({
  authUser: state.sessionState.authUser,
  currentUser: state.user.currentUserProps,
  deletedUsers: state.user.deletedUsers
});

export default connect(mapStateToProps)(LoginForm);

// END
