const INITIAL_STATE = {
  authUser: {},
  registrationError: false
};

const applySetAuthUser = (state, action) => ({
  ...state,
  authUser: action.authUser
});

function sessionReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case "AUTH_USER_SET": {
      return applySetAuthUser(state, action);
    }
    case "THROW_REGISTER_ERROR":
      return {
        ...state,
        registrationError: action.error
      };

    default:
      return state;
  }
}

export default sessionReducer;
