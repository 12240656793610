import React, { Component } from "react";
import { connect } from "react-redux";
import { Col, Row, Container } from "reactstrap";
import { Segment, Card, Form, Button, Icon, Dropdown } from "semantic-ui-react";
import { styles } from "../../styles";
import {
  getLegalRegulators,
  setLegalRegulator,
  deleteLegalRegulator
} from "../../actions/configActions";

import {
  createNotification,
  NOTIFICATION_TYPE_WARNING,
  NOTIFICATION_TYPE_SUCCESS
} from "react-redux-notify";

const mySuccessNotification = {
  message: `Regulator added`,
  type: NOTIFICATION_TYPE_SUCCESS,
  duration: 3000,
  canDismiss: true,
  icon: <Icon name="check" />
};

class LegalRegulatorUpload extends Component {
  constructor() {
    super();
    // create a state that can handle the change in the form input values
    this.state = {
      legalRegulatorName: "",
      errors: {},
      options: [
        { key: "1", text: "Active", value: true },
        { key: "2", text: "Inactive", value: false }
      ]
    };
  }

  componentDidMount() {
    getLegalRegulators();
  }

  handleValidation = () => {
    let { legalRegulatorName } = this.state;
    let errors = {};
    let formIsValid = true;

    // categoryName
    if (legalRegulatorName === "") {
      formIsValid = false;
      errors["legalRegulatorName"] = (
        <p style={{ color: "red" }}>Please enter a Legal regulator Name</p>
      );
    }

    this.setState({ errors: errors });
    return formIsValid;
  };

  handleSubmit = e => {
    e.preventDefault();

    if (this.handleValidation()) {
      const { legalRegulatorName } = this.state;

      // check for invalid characters
      if (
        legalRegulatorName.includes("/") ||
        legalRegulatorName.includes(".") ||
        legalRegulatorName.includes("#") ||
        legalRegulatorName.includes("$") ||
        legalRegulatorName.includes("[") ||
        legalRegulatorName.includes("]")
      ) {
        const myArray = legalRegulatorName.split("");
        const newArray = [];

        myArray.map((char, i) => {
          if (
            char === "." ||
            char === "/" ||
            char === "#" ||
            char === "$" ||
            char === "[" ||
            char === "]"
          ) {
            console.log("not operator !== is not working, wtf");
          } else {
            newArray.push(char);
          }
          return newArray;
        });

        const backToSting = newArray.join("");

        setLegalRegulator(backToSting, legalRegulatorName, true);
      } else {
        setLegalRegulator(legalRegulatorName, legalRegulatorName, true);
      }

      this.props.createNotification(mySuccessNotification);

      // make div scroll to bottom when adding new item
      const scroller = document.querySelector(".scroller3");
      function scrollToBottom() {
        scroller.scrollTop = scroller.scrollHeight;
      }
      const observer = new MutationObserver(scrollToBottom);
      const config = { childList: true };
      observer.observe(scroller, config);
      scrollToBottom();

      this.setState({
        legalRegulatorName: ""
      });
    }
  };

  handleInputChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleDelete = legalRegulatorPath => {
    const sureDeleteNotification = {
      message: "Do you really want to delete this regulator?",
      type: NOTIFICATION_TYPE_WARNING,
      duration: 0,
      canDismiss: false,
      icon: <Icon name="warning sign" />,
      acceptBtn: {
        handler: (e, notification) => {
          deleteLegalRegulator(legalRegulatorPath);
          notification.handleDismiss(notification.id);
        },
        title: "Yup",
        icon: <Icon name="thumbs up" />
      },
      denyBtn: {
        handler: (e, notification) => {
          notification.handleDismiss(notification.id);
        },
        title: "Nope",
        icon: <Icon name="thumbs down" />
      }
    };
    this.props.createNotification(sureDeleteNotification);
  };

  handleActivity = (e, data, legalRegulatorPath, legalRegulatorName) => {
    setLegalRegulator(legalRegulatorPath, legalRegulatorName, data.value);
  };

  render() {
    const { legalRegulators } = this.props;

    return (
      <div className="animated bounceInLeft" style={{ marginTop: 10 }}>
        <Row>
          <Col>
            <Container>
              <Card fluid style={styles.profileComponentStyle} color="teal">
                <div style={{ height: 220 }}>
                  <Card.Content style={styles.cardContentStyle}>
                    <div style={styles.cardHeaderStyle}>
                      Legal Regulator Upload
                    </div>
                    <div style={styles.divStyleLeftbox} />
                    <Segment style={styles.segmentStyle}>
                      <Form
                        id="categoryUploadForm"
                        onSubmit={this.handleSubmit}
                        style={styles.formStyle}
                      >
                        <Form.Field>
                          <label>Legal regulator</label>
                          {this.state.errors["legalRegulatorName"]}
                          <input
                            placeholder="Enter a legal regulator name ..."
                            name="legalRegulatorName"
                            value={this.state.legalRegulatorName}
                            onChange={this.handleInputChange}
                          />
                        </Form.Field>
                        <Button type="submit">Submit</Button>
                      </Form>
                    </Segment>
                  </Card.Content>
                </div>
              </Card>
              <Card fluid style={styles.profileComponentStyle} color="teal">
                <Card.Content style={styles.cardContentStyle}>
                  <div style={styles.cardHeaderStyle}>Legal Regulators</div>
                  <div style={styles.divStyleLeftbox} />
                  <div
                    className="scroller3"
                    style={{
                      height: 280,
                      overflowY: "scroll",
                      overflowX: "hidden"
                    }}
                  >
                    <Segment style={styles.segmentStyle}>
                      {legalRegulators &&
                        legalRegulators.map((regulator, idx) => (
                          <div key={idx}>
                            <Row>
                              <Col sm="6" style={{ marginBottom: 15 }}>
                                <strong
                                  style={
                                    regulator.isActive
                                      ? {}
                                      : {
                                          fontStyle: "italic",
                                          textDecoration: "line-through",
                                          color: "grey"
                                        }
                                  }
                                >
                                  {regulator.legalRegulatorName}
                                </strong>
                              </Col>
                              <Col>
                                <Dropdown
                                  onChange={(e, data) => {
                                    this.handleActivity(
                                      e,
                                      data,
                                      regulator.legalRegulatorPath,
                                      regulator.legalRegulatorName
                                    );
                                  }}
                                  value={regulator.isActive}
                                  options={this.state.options}
                                  style={
                                    regulator.isActive
                                      ? { color: "green" }
                                      : { color: "red" }
                                  }
                                />
                              </Col>
                              <Col>
                                <Icon
                                  style={{
                                    marginBottom: 10,
                                    cursor: "pointer"
                                  }}
                                  color="red"
                                  name="remove circle"
                                  onClick={() => {
                                    this.handleDelete(
                                      regulator.legalRegulatorPath
                                    );
                                  }}
                                />
                              </Col>
                            </Row>
                          </div>
                        ))}
                    </Segment>
                  </div>
                </Card.Content>
              </Card>
            </Container>
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  legalRegulators: state.appConfig.legalRegulators
});

const mapDispatchToProps = dispatch => ({
  createNotification: config => {
    dispatch(createNotification(config));
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LegalRegulatorUpload);
