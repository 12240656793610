import React, { Component } from "react";
import { connect } from "react-redux";
import { Col, Row, Container } from "reactstrap";
import { Segment, Card, Form, Button, Icon } from "semantic-ui-react";
import { styles } from "../../styles";
import {
  getCompanyTypes,
  setCompanyType,
  deleteCompanyType
} from "../../actions/configActions";

import {
  createNotification,
  NOTIFICATION_TYPE_WARNING,
  NOTIFICATION_TYPE_SUCCESS
} from "react-redux-notify";

const mySuccessNotification = {
  message: `Company type added`,
  type: NOTIFICATION_TYPE_SUCCESS,
  duration: 3000,
  canDismiss: true,
  icon: <Icon name="check" />
};

class CompanyTypeUpload extends Component {
  constructor() {
    super();
    // create a state that can handle the change in the form input values
    this.state = {
      companyTypeName: "",
      errors: {}
    };
  }

  componentDidMount() {
    getCompanyTypes();
  }

  handleValidation = () => {
    let { companyTypeName } = this.state;
    let errors = {};
    let formIsValid = true;

    // categoryName
    if (companyTypeName === "") {
      formIsValid = false;
      errors["companyTypeName"] = (
        <p style={{ color: "red" }}>Please enter a Legal regulator Name</p>
      );
    }

    this.setState({ errors: errors });
    return formIsValid;
  };

  handleSubmit = e => {
    e.preventDefault();

    if (this.handleValidation()) {
      const { companyTypeName } = this.state;

      // check for invalid characters
      if (
        companyTypeName.includes("/") ||
        companyTypeName.includes(".") ||
        companyTypeName.includes("#") ||
        companyTypeName.includes("$") ||
        companyTypeName.includes("[") ||
        companyTypeName.includes("]")
      ) {
        const myArray = companyTypeName.split("");
        const newArray = [];

        myArray.map((char, i) => {
          if (
            char === "." ||
            char === "/" ||
            char === "#" ||
            char === "$" ||
            char === "[" ||
            char === "]"
          ) {
            console.log("not operator !== is not working, wtf");
          } else {
            newArray.push(char);
          }
          return newArray;
        });

        const backToSting = newArray.join("");

        setCompanyType(backToSting, companyTypeName, true);
      } else {
        setCompanyType(companyTypeName, companyTypeName, true);
      }

      this.props.createNotification(mySuccessNotification);

      // make div scroll to bottom when adding new item
      const scroller = document.querySelector(".scrollerCompany");
      function scrollToBottom() {
        scroller.scrollTop = scroller.scrollHeight;
      }
      const observer = new MutationObserver(scrollToBottom);
      const config = { childList: true };
      observer.observe(scroller, config);
      scrollToBottom();

      this.setState({
        companyTypeName: ""
      });
    }
  };

  handleInputChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleDelete = companyTypePath => {
    const sureDeleteNotification = {
      message: "Do you really want to delete this Company type?",
      type: NOTIFICATION_TYPE_WARNING,
      duration: 0,
      canDismiss: false,
      icon: <Icon name="warning sign" />,
      acceptBtn: {
        handler: (e, notification) => {
          deleteCompanyType(companyTypePath);
          notification.handleDismiss(notification.id);
        },
        title: "Yup",
        icon: <Icon name="thumbs up" />
      },
      denyBtn: {
        handler: (e, notification) => {
          notification.handleDismiss(notification.id);
        },
        title: "Nope",
        icon: <Icon name="thumbs down" />
      }
    };
    this.props.createNotification(sureDeleteNotification);
  };

  render() {
    const { companyTypes } = this.props;

    return (
      <div className="animated bounceInLeft" style={{ marginTop: 10 }}>
        <Row>
          <Col>
            <Container>
              <Card fluid style={styles.profileComponentStyle} color="teal">
                <div style={{ height: 220 }}>
                  <Card.Content style={styles.cardContentStyle}>
                    <div style={styles.cardHeaderStyle}>
                      User Company Type Upload
                    </div>
                    <div style={styles.divStyleLeftbox} />
                    <Segment style={styles.segmentStyle}>
                      <Form
                        id="categoryUploadForm"
                        onSubmit={this.handleSubmit}
                        style={styles.formStyle}
                      >
                        <Form.Field>
                          <label>Supplier</label>
                          {this.state.errors["companyTypeName"]}
                          <input
                            placeholder="Enter a Company type name ..."
                            name="companyTypeName"
                            value={this.state.companyTypeName}
                            onChange={this.handleInputChange}
                          />
                        </Form.Field>
                        <Button type="submit">Submit</Button>
                      </Form>
                    </Segment>
                  </Card.Content>
                </div>
              </Card>
              <Card fluid style={styles.profileComponentStyle} color="teal">
                <Card.Content style={styles.cardContentStyle}>
                  <div style={styles.cardHeaderStyle}>Company Types</div>
                  <div style={styles.divStyleLeftbox} />
                  <div
                    className="scrollerCompany"
                    style={{
                      height: 280,
                      overflowY: "scroll",
                      overflowX: "hidden"
                    }}
                  >
                    <Segment style={styles.segmentStyle}>
                      {companyTypes &&
                        companyTypes.map((companyType, idx) => (
                          <div key={idx}>
                            <Row>
                              <Col sm="10" style={{ marginBottom: 15 }}>
                                <strong>{companyType.companyTypeName}</strong>
                              </Col>
                              <Col sm="2">
                                <Icon
                                  style={{
                                    marginBottom: 10,
                                    cursor: "pointer"
                                  }}
                                  color="red"
                                  name="remove circle"
                                  onClick={() => {
                                    this.handleDelete(
                                      companyType.companyTypePath
                                    );
                                  }}
                                />
                              </Col>
                            </Row>
                          </div>
                        ))}
                    </Segment>
                  </div>
                </Card.Content>
              </Card>
            </Container>
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  companyTypes: state.appConfig.companyTypes
});

const mapDispatchToProps = dispatch => ({
  createNotification: config => {
    dispatch(createNotification(config));
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CompanyTypeUpload);
