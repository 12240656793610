import React, { Component } from "react";
import { Segment, Card, Form, Button, Dropdown, Icon } from "semantic-ui-react";
import { styles } from "../../styles";
import {
  getCategories,
  getParentCategories,
  setParentCategory,
  setParentCategoryActivity,
  deleteParentCategory,
} from "../../actions/configActions";
import { connect } from "react-redux";
import { Col, Row } from "reactstrap";
import {
  createNotification,
  NOTIFICATION_TYPE_WARNING,
  NOTIFICATION_TYPE_SUCCESS,
} from "react-redux-notify";

const mySuccessNotification = {
  message: `Parent category added`,
  type: NOTIFICATION_TYPE_SUCCESS,
  duration: 3000,
  canDismiss: true,
  icon: <Icon name="check" />,
};

class ParentCategoryUpload extends Component {
  state = {
    parentCategoryName: "",
    childCategories: [],
    errors: {},
    options: [
      { key: "1", text: "Active", value: true },
      { key: "2", text: "Inactive", value: false },
    ],
  };

  componentDidMount() {
    getParentCategories();
    getCategories();
  }

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  // make parent Category active or inactive. this component has its own handleActivity action unlike the others because it would be inconvientient and redundant to pass childCategories as an argument in setParentCategory to satisfy the correct order of arguments
  handleActivity = (e, data, parentCategoryName) => {
    setParentCategoryActivity(parentCategoryName, data.value);
  };

  handleSelect = (e, data) => {
    this.setState({ childCategories: data.value });
  };

  // handles the select for the already upload child catergoies in Parent Categories section
  handleUpdateSelect = (e, data, parentName) => {
    // dispatch and action which goes to the DB and updates the childCategories with the new array of data
    // updateChildCategories;
    setParentCategory(parentName, data.value);
  };

  handleDelete = parentCategoryName => {
    const sureDeleteNotification = {
      message: "Do you really want to delete this Parent Category?",
      type: NOTIFICATION_TYPE_WARNING,
      duration: 0,
      canDismiss: false,
      icon: <Icon name="warning sign" />,
      acceptBtn: {
        handler: (e, notification) => {
          deleteParentCategory(parentCategoryName);
          notification.handleDismiss(notification.id);
        },
        title: "Yup",
        icon: <Icon name="thumbs up" />,
      },
      denyBtn: {
        handler: (e, notification) => {
          notification.handleDismiss(notification.id);
        },
        title: "Nope",
        icon: <Icon name="thumbs down" />,
      },
    };
    this.props.createNotification(sureDeleteNotification);
  };

  handleSubmit = e => {
    e.preventDefault();

    let errors = {};

    if (this.state.parentCategoryName === "") {
      errors["categoryName"] = (
        <p style={{ color: "red" }}>Please enter a Category Name</p>
      );
      return this.setState({ errors: errors });
    }
    if (this.state.parentCategoryName.length > 35) {
      errors["categoryNameLength"] = (
        <p style={{ color: "red" }}>
          Category name must be 35 characters or less
        </p>
      );
      return this.setState({ errors: errors });
    }
    if (this.state.childCategories.length === 0) {
      errors["childCategories"] = (
        <p style={{ color: "red" }}>Please select atleast one child category</p>
      );
      return this.setState({ errors: errors });
    }

    setParentCategory(
      this.state.parentCategoryName,
      this.state.childCategories
    );
    this.props.createNotification(mySuccessNotification);
    // make div scroll to bottom when adding new item
    const scroller = document.querySelector(".scrollParentCategory");
    function scrollToBottom() {
      scroller.scrollTop = scroller.scrollHeight;
    }
    const observer = new MutationObserver(scrollToBottom);
    const config = { childList: true };
    observer.observe(scroller, config);
    scrollToBottom();
    // clear out the state after sending the data to the firebase database
    this.setState({
      parentCategoryName: "",
      childCategories: [],
      errors: {},
    });
  };

  render() {
    const insightCategoriesArray = [];

    this.props.categories.map((category, i) => {
      return (
        category.categoryVisable &&
        insightCategoriesArray.push({
          key: i,
          text: category.categoryName,
          // important for value to be categorySlug for filtering
          value: category.categorySlug,
        })
      );
    });

    return (
      <div>
        <Card fluid style={styles.profileComponentStyle} color="teal">
          <Card.Content style={styles.cardContentStyle}>
            <div style={styles.cardHeaderStyle}>Parent Category Upload</div>
            <div style={styles.divStyleLeftbox} />
            <Segment style={styles.segmentStyle}>
              <Form
                id="categoryUploadForm"
                onSubmit={this.handleSubmit}
                style={styles.formStyle}
              >
                <Form.Field>
                  <label>Parent Category Name</label>
                  {this.state.errors["categoryName"]}
                  {this.state.errors["categoryNameLength"]}
                  <input
                    placeholder="Enter Name of Category ..."
                    name="parentCategoryName"
                    value={this.state.parentCategoryName}
                    onChange={this.handleChange}
                  />
                </Form.Field>
                <Form.Field>
                  <label>Child Categories</label>
                  {this.state.errors["childCategories"]}
                  <Dropdown
                    placeholder="Choose one or more categories"
                    selection
                    multiple
                    options={insightCategoriesArray}
                    onChange={this.handleSelect}
                    value={this.state.childCategories}
                  />
                </Form.Field>
                {/* This button submits the entire form as an object into local state */}
                <Button type="submit">Submit</Button>
              </Form>
            </Segment>

            <Card fluid style={styles.profileComponentStyle} color="teal">
              <Card.Content style={styles.cardContentStyle}>
                <div style={styles.cardHeaderStyle}>Parent Categories</div>
                <div style={styles.divStyleLeftbox} />
                <div
                  className="scrollParentCategory"
                  style={{
                    height: 280,
                    overflowY: "scroll",
                    overflowX: "hidden",
                  }}
                >
                  <Segment style={styles.segmentStyle}>
                    {/* Map over the array of objects that are brought into the component in the componentDidMount,
                    display this list here. */}
                    {this.props.parentCategories.map((category, i) => (
                      <div key={i}>
                        {/* if all of the parents child categories do not exist (i.e they become inactive), then make the parent category inactive aswell */}
                        {/* {insightCategoriesArray.filter(
                          insight =>
                            category.childCategories &&
                            category.childCategories.includes(insight.value)
                        ).length === 0 &&
                          setParentCategoryActivity(
                            category.parentCategoryName,
                            false
                          )} */}
                        <Row>
                          <Col sm="3" style={{ marginBottom: 15 }}>
                            <strong
                              style={
                                category.isActive
                                  ? {}
                                  : {
                                      fontStyle: "italic",
                                      textDecoration: "line-through",
                                      color: "grey",
                                    }
                              }
                            >
                              {category.parentCategoryName}:
                            </strong>
                          </Col>
                          <Col sm="5">
                            {/* Update already uploaded child categories */}
                            <Dropdown
                              selection
                              multiple
                              style={
                                category.isActive
                                  ? {}
                                  : { border: "0.5px dotted red" }
                              }
                              options={insightCategoriesArray}
                              value={category.childCategories}
                              onChange={(e, data) => {
                                this.handleUpdateSelect(
                                  e,
                                  data,
                                  category.parentCategoryName
                                );
                              }}
                            />
                          </Col>
                          <Col sm="2">
                            <Dropdown
                              onChange={(e, data) => {
                                this.handleActivity(
                                  e,
                                  data,
                                  category.parentCategoryName
                                );
                              }}
                              value={category.isActive}
                              options={this.state.options}
                              style={
                                category.isActive
                                  ? { color: "green" }
                                  : { color: "red" }
                              }
                            />
                          </Col>
                          <Col sm="2">
                            <Icon
                              style={{ marginBottom: 10, cursor: "pointer" }}
                              color="red"
                              name="remove circle"
                              onClick={() => {
                                this.handleDelete(category.parentCategoryName);
                              }}
                            />
                          </Col>
                        </Row>
                        <hr />
                      </div>
                    ))}
                  </Segment>
                </div>
              </Card.Content>
            </Card>
          </Card.Content>
        </Card>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  categories: state.appConfig.categories,
  parentCategories: state.appConfig.parentCategories,
});

const mapDispatchToProps = dispatch => ({
  createNotification: config => {
    dispatch(createNotification(config));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ParentCategoryUpload);
