import Background from "../../img/pattern-bg.jpg";

export const styles = {
  chatroomStyle: {
    backgroundColor: "lightblue",
    borderRadius: 7,
    padding: 20,
    margin: 5,
    marginTop: 25,
    borderWidth: 5,
    border: "black"
  },
  profileComponentStyle: {
    padding: 0,
    marginBottom: 10,
    borderWidth: 10,
    boxShadow: "none",
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    backgroundImage: `url(${Background})`
  },
  cardHeaderStyle: {
    backgroundColor: "#404040",
    color: "white",
    padding: 3,
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    paddingLeft: 25,
    margin: 0
  }
};
