import React, { Component } from "react";
import { setInsight } from "../../actions/insights";
import { connect } from "react-redux";
import { Dimmer, Loader, Button, Label, Icon, Image } from "semantic-ui-react";
import { Container, Row, Col } from "reactstrap";

import { Link, withRouter, Redirect } from "react-router-dom";
import TimeAgo from "react-timeago";
import BlankProfilePicture from "../../img/blank-profile-picture.png";
import renderHTML from "react-render-html";
import { MegadraftEditor, editorStateFromRaw } from "megadraft";
import Iframe from "react-iframe";
import styled from "styled-components";
import { getAllInsights } from "../../actions/insights";
import _ from "lodash";

import {
  LinkedinShareButton,
  LinkedinIcon,
  WhatsappShareButton,
  WhatsappIcon,
  EmailShareButton,
  EmailIcon
} from "react-share";

// can only be one export default on a page, if more then you have to wrap what you're importing in coat hangers

class InsightPage extends Component {
  state = {
    redirect: false,
    params: "",
    hovering: false
  };

  componentDidMount = () => {
    window.scrollTo(0, 0);
    setInsight(this.props.match.params.id);
    getAllInsights();
  };

  componentDidUpdate() {
    // if allInsights are empty, get all insights of the same category as the insight on this page

    if (this.state.redirect) {
      this.setState({ redirect: false });
    }

    // I have no idea why this was implemented:
    // this.props.allInsights &&
    // 	this.props.allInsights.length === 0 &&
    // 	this.props.insight &&
    // 	getAllInsights(this.props.insight.category);
  }

  render() {
    const { insight } = this.props;

    // takes you to related insight when you click on it
    if (this.state.redirect && this.state.params) {
      setInsight(this.state.params);
      return <Redirect to={"/InsightPage/" + this.state.params} />;
    }

    // allInsight = this.props.allInsights
    // insight = this.props.insight (current insight on this page)
    // first filter out the insight that is currently displayed on this page out of the array
    const relatedInsights = this.props.allInsights
      .filter(allinsight => allinsight.key !== insight.key)
      // then check if any of the insights in the array have common tags with the insight on this page
      .filter(
        allinsight =>
          allinsight.tags &&
          insight.tags.some(tag => allinsight.tags.includes(tag))
      )
      // finally, sort all of the related insights in descending order of ones with the most tags in common first
      .sort((a, b) => {
        return (
          _.filter(b.tags, btag => insight.tags.indexOf(btag) !== -1).length -
          _.filter(a.tags, atag => insight.tags.indexOf(atag) !== -1).length
        );
      })
      .slice(0, 3);

    let videoNineDigits;
    let videoTenDigits;
    let videoElevenDigits;
    let videoFinalUrl;
    const SITE_URL = window.location.origin.toString();
    const shareURL = `${SITE_URL}/InsightPage/${this.props.insight.key}`;
    if (insight.videoUrl && insight.videoUrl.includes("youtube")) {
      videoElevenDigits = insight.videoUrl.slice(-11);
      videoFinalUrl = `https://www.youtube.com/embed/${videoElevenDigits}`;
    }
    if (insight.videoUrl && insight.videoUrl.includes("vimeo")) {
      videoNineDigits = insight.videoUrl.slice(-9);
      videoFinalUrl = `https://player.vimeo.com/video/${videoNineDigits}`;
    }
    if (insight.videoUrl && insight.videoUrl.includes("wistia")) {
      let crop = insight.videoUrl.lastIndexOf("fast.wistia.com/embed/iframe/");
      videoTenDigits = insight.videoUrl.slice(crop + 29, crop + 39);
      videoFinalUrl = `https://fast.wistia.com/embed/iframe/${videoTenDigits}`;
    }

    if (insight.key) {
      return (
        <Container fluid>
          <Row>
            <Col style={{ textAlign: "center" }}>
              {/* TITLE */}
              <Row
                style={{
                  background: "#C1C1C1",
                  // background: "white",
                  padding: 25,
                  alignItems: "center",
                  justifyContent: "center"
                }}
              >
                <h1
                  style={{
                    fontFamily: "Avenir Next",
                    fontSize: "2.1rem",
                    fontWeight: 400,
                    color: "white",
                    textShadow: "1px 1px 1px black"
                  }}
                >
                  <span>{insight.title}</span>
                </h1>
              </Row>

              {/* SUBTITLE */}
              {insight.subTitle && (
                <Row
                  style={{
                    padding: 25,
                    alignItems: "center",
                    justifyContent: "center"
                  }}
                >
                  <p
                    style={{
                      fontFamily: "Avenir Next",
                      fontSize: 25,
                      fontWeight: 250
                    }}
                  >
                    {insight.subTitle}
                  </p>
                </Row>
              )}

              {/* IMAGE */}
              <Row>
                <div
                  style={{
                    alignItems: "center",
                    justifyContent: "center",
                    display: "flex",
                    width: "100%"
                  }}
                >
                  <div
                    style={{
                      width: "100%",
                      height: 200,
                      textAlign: "center",
                      position: "relative",
                      backgroundAttachment: "fixed",
                      backgroundPosition: "center bottom",
                      backgroundRepeat: "no-repeat",
                      backgroundSize: "cover",
                      // border: "1px solid black",
                      borderBottom: 0,
                      // borderTop: insight.subTitle ? "1px solid black" : 0,
                      backgroundImage: `url(${
                        insight.featuredImage
                          ? insight.featuredImage
                          : BlankProfilePicture
                      })`
                    }}
                  />
                </div>
              </Row>

              <Row
                style={{
                  padding: 25,
                  background: "#F7F7F7",
                  alignItems: "center",
                  justifyContent: "center"
                }}
              >
                <LinkedinShareButton
                  url={shareURL}
                  title="Christan's Title"
                  description="Christan's Description"
                  style={{ paddingLeft: 1, paddingRight: 1 }}
                >
                  <LinkedinIcon size={32} round={true} />
                </LinkedinShareButton>{" "}
                <WhatsappShareButton
                  url={shareURL}
                  title={insight.title}
                  separator=": "
                  style={{ paddingLeft: 1, paddingRight: 1 }}
                >
                  <WhatsappIcon size={32} round={true} />
                </WhatsappShareButton>{" "}
                <EmailShareButton
                  url={shareURL}
                  subject={insight.title}
                  style={{ paddingLeft: 1, paddingRight: 1 }}
                >
                  <EmailIcon size={32} round={true} />
                </EmailShareButton>
              </Row>

              {/* METADATA */}
              <Row
                style={{
                  padding: 25,
                  background: "#F7F7F7",
                  alignItems: "center",
                  justifyContent: "center"
                }}
              >
                <div style={{ paddingLeft: 2, paddingRight: 2 }}>
                  <Image
                    src={insight.authorProfilePhoto || BlankProfilePicture}
                    circular
                    alt="insight author pic"
                    avatar
                  />{" "}
                  <span style={styles.authorNameStyle}>
                    {insight.authorDisplayName}{" "}
                  </span>{" "}
                  <span>{" | "} </span>
                </div>

                <div style={{ paddingLeft: 2, paddingRight: 2 }}>
                  <TimeAgo date={insight.time} />
                </div>
                <div style={{ paddingLeft: 2, paddingRight: 2 }}>
                  <Label style={{ border: "1px solid #cecece" }}>
                    <Icon name="hashtag" />
                    {insight.category}
                  </Label>
                </div>
              </Row>

              {/* TAGS */}
              <Row style={{ padding: 25, background: "#F7F7F7" }}>
                <div
                  style={{
                    margin: "0px auto",
                    maxWidth: 1100
                  }}
                >
                  {insight.tags &&
                    insight.tags.map((tag, i) => {
                      return (
                        typeof tag === "string" && (
                          <a
                            style={{
                              marginRight: 5,
                              marginBottom: 5,
                              border: "1px solid #cecece",
                              color: "rgba(0,0,0,.6)"
                            }}
                            className="ui tag label"
                            key={i}
                          >
                            {tag}
                          </a>
                        )
                      );
                    })}
                </div>
              </Row>

              {/* CONTENT */}
              <Row style={{ padding: 25, textAlign: "left" }}>
                <Content
                  style={{
                    border: "1px solid #cecece",
                    background: "#F7F7F7",
                    width: "100%"
                  }}
                >
                  {/* INSIGHT CONTENT */}
                  <div>
                    {insight.contentJSON ? (
                      <div>
                        {editorStateFromRaw(JSON.parse(insight.contentJSON))
                          .getCurrentContent()
                          .getPlainText().length > 1 ? (
                          <MegadraftEditor
                            editorState={editorStateFromRaw(
                              JSON.parse(insight.contentJSON)
                            )}
                            readOnly={true}
                          />
                        ) : (
                          // <h1>Hello</h1>
                          <div>
                            {renderHTML(`<div>${insight.content}</div>`)}
                          </div>
                        )}
                      </div>
                    ) : (
                      <div>{renderHTML(`<div>${insight.content}</div>`)}</div>
                    )}
                  </div>

                  {/* VIDEOPLAYER */}
                  <div>
                    {insight.videoUrl ? (
                      <Row style={{ padding: 30 }}>
                        <Iframe
                          url={videoFinalUrl}
                          width="100%"
                          height="450px"
                          id="myId"
                          className="myClassname"
                          display="initial"
                          position="relative"
                          allowFullScreen
                          scrolling="no"
                        />
                      </Row>
                    ) : (
                      ""
                    )}
                  </div>
                </Content>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col lg="4" />
            <Col lg="4">
              <Button
                primary
                fluid
                style={{ padding: 15, marginBottom: 70, marginTop: 50 }}
                as={Link}
                to="/Register"
              >
                REGISTER
              </Button>
            </Col>
            <Col lg="4" />
          </Row>
          {console.log("RELATED INSIGHTS ", relatedInsights)}
          {relatedInsights.length > 0 && (
            <h2 style={{ textAlign: "center" }}>
              You may also be interested in ...
            </h2>
          )}
          <Row
            style={{
              marginBottom: 30,
              marginTop: 30,
              display: "flex",
              justifyContent: "Center"
            }}
          >
            {relatedInsights &&
              relatedInsights.map((insight, i) => {
                return (
                  <Col
                    sm="3"
                    style={{
                      textAlign: "center",
                      margin: 10
                    }}
                  >
                    <h5
                      key={i}
                      style={{ marginBottom: 5, cursor: "pointer" }}
                      onClick={() => {
                        this.setState({ redirect: true, params: insight.key });
                        window.scrollTo(0, 0);
                      }}
                    >
                      {insight.title}
                    </h5>
                    <p
                      key={i}
                      onClick={() => {
                        this.setState({ redirect: true, params: insight.key });
                        window.scrollTo(0, 0);
                      }}
                      style={
                        insight.subTitle
                          ? { margin: 5 }
                          : { margin: 5, visibility: "hidden" }
                      }
                    >
                      {insight.subTitle ? insight.subTitle : "No sub title"}
                    </p>
                    <img
                      onClick={() => {
                        this.setState({ redirect: true, params: insight.key });
                        window.scrollTo(0, 0);
                      }}
                      alt=""
                      src={
                        insight.featuredImage
                          ? insight.featuredImage
                          : BlankProfilePicture
                      }
                      style={{
                        height: 200,
                        width: 250,
                        display: "block",
                        margin: "0px auto",
                        objectFit: "cover",
                        cursor: "pointer"
                      }}
                    />
                    {/* <div
                      style={{
                        height: 200,
                        width: 250,

                        backgroundImage: `url(${
                          insight.featuredImage
                            ? insight.featuredImage
                            : BlankProfilePicture
                        })`,
                        backgroundSize: "cover"
                      }}
                    /> */}
                  </Col>
                );
              })}
          </Row>
        </Container>
      );
    } else {
      return (
        <Dimmer inverted active>
          <Loader size="large">Loading... </Loader>
        </Dimmer>
      );
    }
  }
}

const styles = {
  authorNameStyle: {
    fontWeight: "bold"
  },
  iframeStyle: {
    paddingBottom: 20
  }
};

const Content = styled.div`
  padding: 15px;
  max-width: 1100px;
  margin: 30px auto;
  margin-bottom: 20px;
  border-radius: 10px;
  border: "2px solid black";
  background: rgba(255, 255, 255, 0.85);
`;

const mapStateToProps = state => ({
  insight: state.insights.currentInsightProps,
  allInsights: state.insights.currentAllInsightsProps
});

export default withRouter(connect(mapStateToProps)(InsightPage));
