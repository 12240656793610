import React, { Component } from "react";
import { connect } from "react-redux";
import { deleteUser, loadProfile, updateUserData } from "../../actions/users";
import RenderDatePicker from "../FormComponents/DatePicker";
import Emoji from "react-emoji-render";
import { Form, Button, Divider, TextArea, Icon } from "semantic-ui-react";
import { withRouter } from "react-router-dom";
//  Notifications
import {
  createNotification,
  NOTIFICATION_TYPE_SUCCESS,
  NOTIFICATION_TYPE_WARNING
} from "react-redux-notify";

const mySuccessNotification = {
  message: "Profile Updated",
  type: NOTIFICATION_TYPE_SUCCESS,
  duration: 3000,
  canDismiss: true,
  icon: <Icon name="check" />
};

const emptyInsightWarning = {
  message: "Sorry cannot submit an empty Display Name",
  type: NOTIFICATION_TYPE_WARNING,
  duration: 3000,
  canDismiss: true,
  icon: <Icon name="warning sign" />
};

const invalidFileSize = {
  message: "Please upload a valid file size",
  type: NOTIFICATION_TYPE_WARNING,
  duration: 3000,
  canDismiss: true,
  icon: <Icon name="warning sign" />
};

class ProfileForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      displayName: this.props.currentUser.displayName || "",
      firstName: this.props.currentUser.firstName || "",
      lastName: this.props.currentUser.lastName || "",
      company: this.props.currentUser.company || "",
      jobRole: this.props.currentUser.jobRole || "",
      bio: this.props.currentUser.bio || "",
      profilePhoto: this.props.currentUser.profilePhoto || "",
      DOB: this.props.currentUser.DOB,
      deleteButtonStatus: false,
      errors: ""
    };

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleInputChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  toggleDeleteButtonStatus = () => {
    this.setState({
      deleteButtonStatus: !this.state.deleteButtonStatus
    });
  };

  handleImageSelect = e => {
    // if file is greater than 5mb
    if (e.target.files[0] && e.target.files[0].size > 5000000) {
      return this.setState({ errors: "File size is too big!" });
    }

    // if file is less than 5kb
    if (e.target.files[0] && e.target.files[0].size < 5000) {
      return this.setState({ errors: "File size is too small!" });
    }

    this.setState(
      {
        profilePhoto: e.target.files[0] ? e.target.files[0] : null,
        errors: ""
      },
      function() {
        console.log(this.state.profilePhoto, " Profile Photo");
      }
    );
  };
  deleteUserProfileForever = () => {
    // this.props.history.push("/");
    deleteUser(this.props.authUserId);
  };

  async handleSubmit(event) {
    const { createNotification } = this.props;

    if (
      this.state.displayName === "" ||
      this.state.displayName.replace(/\s+/g, "").length === 0
    ) {
      return createNotification(emptyInsightWarning);
    }

    if (this.state.errors) {
      return createNotification(invalidFileSize);
    }

    this.setState({ [event.target.name]: event.target.value });
    if (event.target.birthDate.valueAsDate) {
      await this.setState({ DOB: event.target.birthDate.valueAsDate });
    }
    this.props.toggle();
    updateUserData(
      this.props.authUserId,
      this.state.displayName,
      this.state.firstName,
      this.state.lastName,
      this.state.company,
      this.state.jobRole,
      this.state.bio,
      this.state.DOB
    );
    loadProfile(this.props.authUserId);

    createNotification(mySuccessNotification);

    this.setState({ errors: "" });
  }

  render() {
    return (
      <div>
        <Form onSubmit={this.handleSubmit}>
          <div>
            <label>Display/Author Name</label>
            <input
              name="displayName"
              id="displayName"
              type="text"
              onChange={this.handleInputChange}
              placeholder={this.state.displayName}
            />
          </div>
          <br />
          <div>
            <label>First Name</label>
            <input
              name="firstName"
              id="firstName"
              type="text"
              onChange={this.handleInputChange}
              placeholder={this.state.firstName}
            />
          </div>
          <br />
          <div>
            <label>Last Name</label>
            <input
              name="lastName"
              id="lastName"
              type="text"
              onChange={this.handleInputChange}
              placeholder={this.state.lastName}
            />
          </div>
          <br />
          <div>
            <label> Company</label>
            <input
              name="company"
              // component="input"
              type="text"
              onChange={this.handleInputChange}
              placeholder={this.state.company}
            />
          </div>
          <br />
          <div>
            <label> Job Role</label>
            <input
              name="jobRole"
              // component="input"
              type="text"
              onChange={this.handleInputChange}
              placeholder={this.state.jobRole}
            />
          </div>
          <br />
          <div>
            <label> About Me</label>
            <Form.Field
              name="bio"
              control={TextArea}
              type="text"
              onChange={this.handleInputChange}
              placeholder={this.state.bio}
            />
          </div>
          <br />
          <div>
            <label>
              <Emoji
                text="Birthday 🎂 – optional, but we like to say happy birthday to our
          friends 🎉🎉🎉"
              />
            </label>
            <input name="birthDate" type="date" component={RenderDatePicker} />
          </div>
          <br />
          <Button
            className="ui blue"
            type="submit"
            disabled={this.props.pristine || this.props.submitting}
          >
            Update
          </Button>
          <Button
            className="ui yellow"
            type="button"
            onClick={() => this.props.toggle()}
          >
            Cancel
          </Button>
        </Form>

        <br />
        <br />
        <Divider horizontal>Danger Zone</Divider>
        {/*First they click delete to toggle the view to see the actual red button for total deletion"*/}
        {this.state.deleteButtonStatus === false && (
          <Button
            className="ui orange"
            type="button"
            onClick={() => this.toggleDeleteButtonStatus()}
          >
            Delete Profile
          </Button>
        )}
        {this.state.deleteButtonStatus === true && (
          <div>
            <h3>
              {" "}
              Warning: This will erase your profile completely. With no way to
              recover.
            </h3>
            <Button
              className="ui red"
              type="button"
              onClick={() => this.deleteUserProfileForever()}
            >
              Delete Profile Forever
            </Button>
            <Button
              className="ui yellow"
              type="button"
              onClick={() => this.toggleDeleteButtonStatus()}
            >
              Cancel
            </Button>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  currentUser: state.user.currentUserProps,
  authUserId: state.sessionState.authUser.uid
});

const mapDispatchToProps = dispatch => ({
  createNotification: config => {
    dispatch(createNotification(config));
  }
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(ProfileForm)
);
