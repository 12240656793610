import React, { Component } from "react";
import { connect } from "react-redux";
import renderHTML from "react-render-html";
import TimeAgo from "react-timeago";
import { Card, Segment, Label, Item, Image } from "semantic-ui-react";
import { Row } from "reactstrap";
import {
  addUserWishlistItem,
  getAllInsights,
  setInsight
} from "../../actions/insights";
import SubscribeModal from "./SubscribeModal";
import { ContentSnippet } from "../../helpers";
import BlankProfilePicture from "../../img/blank-profile-picture.png";
import StoryImg from "../../img/StoryImg.png";
import { styles } from "../../styles";

class GuestAllInsights extends Component {
  componentDidMount() {
    getAllInsights();
  }

  handleSelectClick = key => {
    setInsight(key);
  };

  handleAddClick = insight => {
    const myInsights = this.props.myInsights;
    let myArray = [];
    let i;
    for (i = 0; i < myInsights.length; i++) {
      myArray.push(myInsights[i].key);
    }
    if (myArray.indexOf(insight.key) === -1) {
      addUserWishlistItem(this.props.currentUser.uid, insight);
    } else {
      window.alert("Sorry you already have this one saved");
    }
  };

  render() {
    let renderedComponent;

    let filteredArray;
    let resultArray = this.props.currentAllInsights;
    if (this.props.children.header === "ALL INSIGHTS") {
      filteredArray = resultArray;
    } else {
      filteredArray = resultArray.filter(
        insight => insight.category === this.props.children.header
      );
    }
    return (
      <Card color="teal" fluid style={styles.profileComponentStyle}>
        <Card.Content style={styles.cardContentStyle}>
          <Card.Header style={styles.cardHeaderStyle}>
            {this.props.children.header}
          </Card.Header>
          <div style={styles.divStyle} />
          <Segment style={styles.segmentStyle}>
            <Item.Group divided>
              {filteredArray.map((insight, index) => (
                <Item key={index}>
                  <Item.Image
                    size="small"
                    alt="Energy insights feature"
                    src={filteredArray[index].featuredImage || StoryImg}
                  />
                  <Item.Content>
                    <Item.Header>{filteredArray[index].title}</Item.Header>
                    <Item.Description>
                      {filteredArray[index].subTitle}
                    </Item.Description>
                    <Item.Meta>
                      <TimeAgo
                        date={filteredArray[index].time}
                        style={{ textAlign: "right" }}
                      />
                      <div
                        style={{
                          justifyContent: "space-around",
                          height: 35
                        }}
                      >
                        <Image
                          src={
                            filteredArray[index].authorProfilePhoto ||
                            BlankProfilePicture
                          }
                          size="mini"
                          circular
                          // inline
                          floated="right"
                          alt="insight author pic"
                        />
                        <p style={{ fontWeight: "bold", textAlign: "right" }}>
                          {filteredArray[index].authorDisplayName}
                        </p>
                      </div>
                    </Item.Meta>
                    <Item.Extra>
                      {/*Putting an && on insight.category so if there isn't a category then no hash will show */}
                      {filteredArray[index].category && (
                        <Label
                          icon="hashtag"
                          content={filteredArray[index].category}
                        />
                      )}
                      <Item.Description>
                        {renderHTML(
                          ContentSnippet(
                            `<div>${filteredArray[index].content}</div>`
                          )
                        )}
                      </Item.Description>
                      <Row>
                        <a
                          onClick={() =>
                            this.handleSelectClick(filteredArray[index].key)
                          }
                          style={{
                            padding: 0,
                            backgroundColor: "transparent"
                          }}
                        >
                          <SubscribeModal buttonLabel="Subscribe" />
                        </a>
                      </Row>
                    </Item.Extra>
                  </Item.Content>
                </Item>
              ))}
              {renderedComponent}
            </Item.Group>
          </Segment>
        </Card.Content>
      </Card>
    );
  }
}

const mapStateToProps = state => ({
  currentUser: state.user.currentUserProps,
  myInsights: state.insights.myInsightsProps,
  currentAllInsights: state.insights.currentAllInsightsProps,
  currentInsight: state.insights.currentInsightProps,
  currentCategorisedInsights: state.insights.currentCategorisedInsights
});

export default connect(mapStateToProps)(GuestAllInsights);
