import React, { Component } from "react";
import { connect } from "react-redux";
import {
  MegadraftEditor,
  editorStateFromRaw,
  editorStateToJSON,
} from "megadraft";
import { Container } from "reactstrap";
import { Form, Segment, Button } from "semantic-ui-react";
import {
  setDashboardMessage,
  getDashboardMessage,
} from "../../actions/configActions";

// In this component we want to have a message writing function to write to the database.
// A rich text area

class AdminMessage extends Component {
  constructor() {
    super();

    this.state = {
      message: editorStateFromRaw(null),
    };
  }

  componentDidMount = () => {
    getDashboardMessage();
  };

  onChange = message => {
    this.setState({ message });
  };

  handleSubmit = e => {
    e.preventDefault();
    const content = editorStateToJSON(this.state.message);
    setDashboardMessage(content);
    this.setState({
      message: editorStateFromRaw(null),
    });
  };

  render() {
    let ObjectisNotEmpty = false;
    const { dashboardMessage } = this.props;
    if (this.props && typeof this.props.dashboardMessage === "string") {
      ObjectisNotEmpty = Object.values(dashboardMessage).length > 1;
    }
    return (
      <Container className="animated bounceInLeft" style={{ marginTop: 25 }}>
        <label>Current Message</label>
        <Segment style={{ minHeight: 100 }}>
          {ObjectisNotEmpty && (
            <MegadraftEditor
              editorState={editorStateFromRaw(JSON.parse(dashboardMessage))}
              readOnly={true}
            />
          )}
        </Segment>
        <Form onSubmit={this.handleSubmit} style={{ marginTop: 25 }}>
          <label>Write your new message</label>
          <Form.Field>
            <Segment style={{ minHeight: 100 }}>
              <MegadraftEditor
                editorState={this.state.message}
                onChange={this.onChange}
                placeholder="Write a new message to all users..."
              />
            </Segment>
          </Form.Field>

          <Button type="submit">Submit</Button>
        </Form>
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  dashboardMessage: state.appConfig.dashboardMessage,
});

export default connect(mapStateToProps)(AdminMessage);
