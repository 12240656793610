// This is a component that will create a reply like in whats App
// It will copy and dull out the original message and then show the response below.
// It may be able to use the post editor to write the reply?? or a new ReplyPost Editor will have to be displayed.
import React, { Component } from "react";
import { connect } from "react-redux";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import {
  Segment,
  Message,
  Icon,
  Feed,
  Button,
  Dimmer,
  Loader
} from "semantic-ui-react";
import {
  MegadraftEditor,
  editorStateFromRaw,
  editorStateToJSON
} from "megadraft";
import "../MegaDraft/megadraft.css";
import emptyUserImg from "../../img/blank-profile-picture.png";

import {
  // getAllposts,
  replyToMessage
} from "../../actions/Posts";
import { setUserChatroomNotifications } from "../../actions/subscribers";

import {
  createNotification,
  NOTIFICATION_TYPE_WARNING
} from "react-redux-notify";

import { EditorState } from "draft-js";
import IconEditor from "./Editor";

const emptyCommentWarning = {
  message: "Sorry cannot submit an empty comment",
  type: NOTIFICATION_TYPE_WARNING,
  duration: 3000,
  canDismiss: true,
  icon: <Icon name="warning sign" />
};

class ReplyPostModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      modal: false,
      editorState: editorStateFromRaw(JSON.parse(this.props.item.message)),
      replyEditorState: EditorState.createEmpty()
    };

    this.toggle = this.toggle.bind(this);
  }

  onChange = replyEditorState => {
    this.setState({ replyEditorState });
  };

  onSaveClick = () => {
    // Deconstruct the text from the editorState object
    const currentContent = this.state.replyEditorState.getCurrentContent();
    const comment = currentContent.getPlainText();
    // Check its isn't empty
    const { createNotification } = this.props;
    if (comment === "" || comment.replace(/\s+/g, "").length === 0) {
      // If it is invoke notification
      console.log("error, comment is empty");
      return createNotification(emptyCommentWarning);
    } else {
      const { uid } = this.props.currentUser;
      const { replyEditorState } = this.state;
      const content = editorStateToJSON(replyEditorState);
      const { key } = this.props.item;
      const originalPost = this.props.item;
      replyToMessage(
        content,
        key,
        uid,
        originalPost,
        this.props.currentUser.displayName,
        this.props.currentUser.profilePhoto || "",
        this.props.CurrentChatroom.chatroomTitle,
        this.props.CurrentChatroom.chatroomKey,
        this.props.currentUser.firstName,
        this.props.currentUser.lastName
      );
    }

    // if user has turned off notifications, dont subscribe them
    if (
      this.props.currentUser.notifications &&
      this.props.currentUser.notifications.chatrooms &&
      this.props.currentUser.notifications.chatrooms[
        this.props.CurrentChatroom.chatroomKey
      ] &&
      this.props.currentUser.notifications.chatrooms[
        this.props.CurrentChatroom.chatroomKey
      ].subscribed === false
    ) {
    } else {
      setUserChatroomNotifications(
        this.props.CurrentChatroom.chatroomKey,
        this.props.currentUser.uid,
        this.props.CurrentChatroom.chatroomTitle
      );
    }

    this.setState({ modal: false, replyEditorState: editorStateFromRaw(null) });
  };

  toggle() {
    this.setState({
      modal: !this.state.modal
    });
  }

  render() {
    return (
      <div>
        <div>
          <Button
            style={{ backgroundColor: "transparent" }}
            onClick={this.toggle}
            compact
          >
            {this.props.buttonLabel}
          </Button>

          <Modal
            isOpen={this.state.modal}
            toggle={this.toggle}
            className={this.props.className}
            size="lg"
          >
            <ModalHeader toggle={this.toggle}>
              <Icon name="reply" />
              Reply{" "}
            </ModalHeader>
            {this.props.PostItem ? (
              <ModalBody>
                <Message
                  content={
                    <Feed>
                      <Feed.Event>
                        <Feed.Label
                          image={this.props.item.profilePhoto || emptyUserImg}
                        />

                        <Feed.Content>
                          <Feed.Summary>
                            {this.props.item.displayName ? (
                              <div>{this.props.item.displayName}</div>
                            ) : (
                              <div>
                                {this.props.item.firstName}{" "}
                                {this.props.item.lastName}
                              </div>
                            )}
                          </Feed.Summary>
                          <Feed.Extra text>
                            <MegadraftEditor
                              editorState={editorStateFromRaw(
                                JSON.parse(this.props.item.message)
                              )}
                              readOnly={true}
                            />
                          </Feed.Extra>
                        </Feed.Content>
                      </Feed.Event>
                    </Feed>
                  }
                />
                <Segment>
                  <IconEditor
                    editorState={this.state.replyEditorState}
                    onChange={this.onChange}
                    placeholder="Write reply here ..."
                  />
                </Segment>
              </ModalBody>
            ) : (
              <Dimmer active inverted>
                <Loader>Loading</Loader>
              </Dimmer>
            )}
            <ModalFooter>
              <Button color="yellow" onClick={this.toggle}>
                Cancel
              </Button>
              <Button
                circular
                primary
                onClick={this.onSaveClick}
                style={{ borderRadius: 7 }}
                icon="send"
              />
            </ModalFooter>
          </Modal>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  PostItem: state.postState.CurrentPostProps,
  currentUser: state.user.currentUserProps,
  CurrentChatroom: state.chatroomsState.currentlySelectedChatroom
});

const mapDispatchToProps = dispatch => ({
  createNotification: config => {
    dispatch(createNotification(config));
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ReplyPostModal);
