import React, { Component } from "react";
import { connect } from "react-redux";
import PostEditor from "./PostEditor";
import Post from "./Post";
import { getAllposts } from "../../actions/Posts";
import { Pagination, PaginationItem, PaginationLink } from "reactstrap";

class ThreadDisplay extends Component {
  constructor(props) {
    super(props);

    this.state = {
      posts: [],
      currentPage: 1,
      postsPerPage: 10
    };
  }

  componentDidMount() {
    // Dispatch an action to retrieve all the posts from the post node in FB
    // and return this.props.currentAllPosts
    getAllposts();
  }

  handlePageClick = e => {
    // Scroll to top of window
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
    // Set the state of the selected page
    this.setState({
      currentPage: Number(e.target.id)
    });
  };

  firstPage = () => {
    // Scroll to top of window
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
    // Set the state of the selected page to the first page
    this.setState({ currentPage: 1 });
  };

  lastPage = lastPage => {
    // Scroll to top of window
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
    // Set state to the selected page to the last page
    this.setState({ currentPage: lastPage });
  };

  previousPage = () => {
    // Scroll to top of window
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
    // Define the current page
    const { currentPage } = this.state;
    // If on the first page
    if (currentPage === 1) {
      // Do nothing
    } else {
      // If on any other page -1 from the current page number
      this.setState({
        currentPage: currentPage - 1
      });
    }
  };

  nextPage = lastPage => {
    // Scroll to top of window
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
    // Define the current page from state
    const { currentPage } = this.state;
    // if current page is not equal to last page
    if (currentPage !== lastPage) {
      this.setState({
        currentPage: currentPage + 1
      });
    }
    // if current page is equal to last page do nothing
  };

  render() {
    const { currentPage, postsPerPage } = this.state;

    // *** Logic for filtering posts that correspond to the chatroom ***

    // Define the array of all thr posts retrieved
    let resultArray = this.props.currentAllPosts;
    // Filter through all the posts and pull out those that have the same chatroom key
    let filteredArray = resultArray.filter(
      postBody =>
        postBody.chatroomTitle === this.props.CurrentChatroom.chatroomTitle
    );

    // *** logic for displaying posts by page number ***

    // find the index of the last post by:
    // multiplying the current selected page by the number of posts per page
    const indexOfLastPost = currentPage * postsPerPage;
    // find the index of the first post** by:
    // subtracting the index of the last post by the number of posts per page
    const indexOfFirstPost = indexOfLastPost - postsPerPage;
    // example: if we were on page 1 and there were 10 posts per page
    // indexOfLastPost = 1 * 10 = 10;
    // indexOfFirstPost = 10 - 10 = 0;
    // Define current displayed posts as:
    // filter the chatroom posts and slice between the pages first post and last page
    const currentPosts = filteredArray.slice(indexOfFirstPost, indexOfLastPost);
    // Render the filtered posts to the current page
    const renderPosts = currentPosts.map((postBody, index) => {
      return <Post key={index} postBody={postBody} />;
    });

    // logic for displaying page numbers
    const pageNumbers = [];
    const lastPage = Math.ceil(filteredArray.length / postsPerPage);
    for (let i = 1; i <= lastPage; i++) {
      pageNumbers.push(i);
    }

    // Render the page numbers to display
    const renderPageNumbers = pageNumbers.map(number => {
      if (this.state.currentPage === number) {
        return (
          <PaginationItem
            key={number}
            active={this.state.currentPage === number ? true : false}
          >
            <PaginationLink id={number} onClick={this.handlePageClick}>
              {number}
            </PaginationLink>
          </PaginationItem>
        );
      }
      return "";
    });

    // Render previous pages to display
    const renderPreviousPages = pageNumbers.map(number => {
      if (this.state.currentPage === number && this.state.currentPage !== 1) {
        return (
          <PaginationItem key={number}>
            <PaginationLink id={number} onClick={this.previousPage}>
              {number - 1}
            </PaginationLink>
          </PaginationItem>
        );
      }
      return "";
    });

    // Render next pages to display
    const renderNextPages = pageNumbers.map(number => {
      if (
        this.state.currentPage === number &&
        this.state.currentPage !== lastPage
      ) {
        return (
          <PaginationItem key={number}>
            <PaginationLink id={number} onClick={this.nextPage}>
              {number + 1}
            </PaginationLink>
          </PaginationItem>
        );
      }
      return "";
    });

    // Render Pagination component
    const renderPagination = (
      <Pagination aria-label="Page navigation example">
        {this.state.currentPage === 1 || this.state.currentPage === 2 ? (
          ""
        ) : (
          <PaginationItem
            onClick={this.firstPage}
            active={this.state.currentPage === 1 ? true : false}
          >
            <PaginationLink>First</PaginationLink>
          </PaginationItem>
        )}
        <PaginationItem onClick={this.previousPage}>
          <PaginationLink previous />
        </PaginationItem>
        {renderPreviousPages}
        {renderPageNumbers}
        {renderNextPages}
        <PaginationItem onClick={() => this.nextPage(lastPage)}>
          <PaginationLink next />
        </PaginationItem>
        {this.state.currentPage === lastPage ||
        this.state.currentPage === lastPage - 1 ? (
          ""
        ) : (
          <PaginationItem
            onClick={() => this.lastPage(lastPage)}
            active={this.state.currentPage === lastPage ? true : false}
          >
            <PaginationLink>Last</PaginationLink>
          </PaginationItem>
        )}
      </Pagination>
    );

    return (
      <div>
        <PostEditor />
        {renderPagination}
        {renderPosts}
        {renderPagination}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  currentAllPosts: state.postState.currentAllPostsProps,
  CurrentChatroom: state.chatroomsState.currentlySelectedChatroom
});

export default connect(mapStateToProps)(ThreadDisplay);
