const initialState = {
  currentUserProps: { uid: null },
  selectedUser: {},
  deletedUsers: []
};

// The user profile reducer to store the user profile state
export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case "SELECT_USER": {
      return {
        ...state,
        selectedUser: action.selectedUser
      };
    }
    case "SET_CURRENT_USER_PROPS": {
      return {
        ...state,
        currentUserProps: action.user
      };
    }
    case "REMOVE_CURRENT_USER_PROPS": {
      return {
        ...state,
        currentUserProps: { uid: null }
      };
    }
    case "UPDATE_PROFILE_PHOTO_ACTION": {
      return Object.assign({}, state, {
        currentUserProps: {
          ...state.currentUserProps,
          profilePhoto: action.url
        }
      });
    }
    case "GET_ADMIN_EVENTS": {
      const { adminEvents } = action;
      return {
        ...state,
        adminEvents
      };
    }

    case "GET_ALL_DELETED_USERS": {
      const { deletedUsers } = action;
      return {
        ...state,
        deletedUsers
      };
    }

    case "EVENT_ACTION": {
      const { event, typeSwitch } = action;
      const adminEvents = { ...state.adminEvents };
      const events = { ...state.currentUserProps.events };
      if (event.type === "own") {
        events[event.id] = event;
        if (typeSwitch) {
          delete adminEvents[event.id];
        }
      } else {
        adminEvents[event.id] = event;
        if (typeSwitch) {
          delete events[event.id];
        }
      }
      return {
        ...state,
        adminEvents,
        currentUserProps: {
          ...state.currentUserProps,
          events
        }
      };
    }
    case "DELETE_EVENT":
      const { eType, id } = action;
      const events = { ...state.currentUserProps.events };
      const adminEvents = { ...state.adminEvents };
      if (eType === "own") {
        delete events[id];
      } else {
        delete adminEvents[id];
      }
      return {
        ...state,
        adminEvents,
        currentUserProps: {
          ...state.currentUserProps,
          events
        }
      };
    default:
      return state;
  }
}

// import ProfilePic from "../img/Profilepicture.png";

// // An initial state for a user profile
// const initialState = {
//   currentUserProps: {
//     displayName: "Jean-Claude Van Damme",
//     bio:
//       "Born 18 October 1960 professionally known as Jean-Claude Van Damme and abbreviated as JCVD, is a Belgian actor, martial artist, screenwriter, film producer, and director best known for his martial arts action films.",
//     company: "Time Cop",
//     jobRole: "Energy Manager",
//     uid: null,
//     profilePhoto: ProfilePic
//   }
// };
