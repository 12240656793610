import React, { Component } from "react";
import { connect } from "react-redux";
import { Col, Row, Container } from "reactstrap";
import styled from "styled-components";
import { Statistic } from "semantic-ui-react";
import { getAllSubscribers } from "../../actions/subscribers";
import { getAllInsights } from "../../actions/insights";
import { getAllposts } from "../../actions/Posts";
import Stats from "./stats";
// import html2canvas from "html2canvas";
// import jsPDF from "jspdf";
// import "./adminCMS.css";

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
`;

const GridItem = styled.div`
  padding: 3px;
  text-align: center;
  margin: 3px;
  border-style: solid;
  border-width: 1px 1px;
  :hover {
    background-color: white;
    color: white;
  }
`;

class AdminCMS extends Component {
  // state = {
  //   scrolled: 0,
  //   visible: false
  // }
  componentDidMount = async () => {
    window.scrollTo(0, 0);
    getAllSubscribers();
    getAllInsights();
    getAllposts();
    console.log(this.props.location.hash, "THIS.PROPS");
    // window.addEventListener("scroll", this.handleScroll);
  };

  // handleScroll = () => {
  //   const scrolled = document.documentElement.scrollTop
  //   this.setState({ scrolled })
  // }

  // printDocument = () => {
  //   this.setState({ visible: true })
  //   const input = document.getElementById("divToPrint");
  //   html2canvas(input, {
  //     // height: 1800,
  //     scale: 1
  //   }).then(canvas => {
  //     this.setState({ visible: false })
  //     const imgData = canvas.toDataURL("image/png");
  //     // window.scrollTo(0, 0)
  //     const pdf = new jsPDF('p', 'mm', [1000, 1200]);;
  //     pdf.addImage(imgData, "JPEG", 0, 0);
  //     // pdf.output("dataurlnewwindow");
  //     pdf.save("download.pdf");
  //   });
  // };

  render() {
    // if (this.state.scrolled >= 2000) {
    //   window.scrollTo({ top: 200, behavior: 'smooth' })
    //   this.setState({ isVisible: false })
    // }

    const { subscribers, insights, posts } = this.props;
    let adminArray = subscribers.filter(user => {
      return user.userType === "admin";
    });
    let subscriberArray = subscribers.filter(user => {
      return user.userType === "subscriber";
    });
    let guestArray = subscribers.filter(user => {
      return user.userType === "guest";
    });
    let newUserArray = subscribers.filter(user => {
      return user.userType === "newUser";
    });
    let jediArray = subscribers.filter(user => {
      return user.userType === "jedi";
    });

    // when screenshot is taken with pupateer, it will pass parameter in the header
    if (
      this.props.currentUser.userType === "jedi" ||
      this.props.currentUser.userType === "admin" ||
      this.props.location.hash === "#sdffklekm53424m23423409099msda"
    ) {
      return (
        <Container fluid style={{ marginTop: 25 }}>
          <div
            style={{
              fontFamily: "avenir",
              color: "grey",
              textAlign: "center"
            }}
          >
            <h1>ICON ANALYTICS</h1>
          </div>
          <hr />
          <Row>
            <Col>
              <Grid>
                <GridItem>
                  <Statistic>
                    <Statistic.Value>{subscribers.length}</Statistic.Value>
                    <Statistic.Label>Users</Statistic.Label>
                  </Statistic>
                </GridItem>
                <GridItem>
                  <Statistic>
                    <Statistic.Value>{adminArray.length}</Statistic.Value>
                    <Statistic.Label>Admins</Statistic.Label>
                  </Statistic>
                </GridItem>
                <GridItem>
                  <Statistic>
                    <Statistic.Value>{subscriberArray.length}</Statistic.Value>
                    <Statistic.Label>Subscribers</Statistic.Label>
                  </Statistic>
                </GridItem>
                <GridItem>
                  <Statistic>
                    <Statistic.Value>{guestArray.length}</Statistic.Value>
                    <Statistic.Label>Guests</Statistic.Label>
                  </Statistic>
                </GridItem>
                <GridItem>
                  <Statistic>
                    <Statistic.Value>{newUserArray.length}</Statistic.Value>
                    <Statistic.Label>New Users</Statistic.Label>
                  </Statistic>
                </GridItem>
                <GridItem>
                  <Statistic>
                    <Statistic.Value>{jediArray.length}</Statistic.Value>
                    <Statistic.Label>Jedis</Statistic.Label>
                  </Statistic>
                </GridItem>
                <GridItem>
                  <Statistic>
                    <Statistic.Value>{insights.length}</Statistic.Value>
                    <Statistic.Label>insights</Statistic.Label>
                  </Statistic>
                </GridItem>
                <GridItem>
                  <Statistic>
                    <Statistic.Value>{posts.length}</Statistic.Value>
                    <Statistic.Label>chatroom posts</Statistic.Label>
                  </Statistic>
                </GridItem>
              </Grid>
            </Col>
          </Row>
          <Row>
            <Stats />
          </Row>
          <Row>
            {/* <Button color="secondary" onClick={this.printDocument}>
              Generate PDF
            </Button> */}
            <a
              style={{
                height: 50,
                margin: "40px 0 40px 40px",
                textAlign: "center",
                fontSize: 28,
                textDecoration: "underline",
                textShadow: "1px 1px black"
              }}
              href={require("./analytics.png" || "")}
              download="analytics.png"
            >
              Download.png
            </a>
          </Row>
          {/* 
          <div id="divToPrint" style={!this.state.visible ? { display: "none" } : { marginTop: 200 }}>
            <img alt="" src={require("./analytics.png")} />
          </div> */}
        </Container>
      );
    } else {
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "90vh",
            fontWeight: 400,
            fontSize: 30,
            color: "red"
          }}
        >
          Authorisation denied
        </div>
      );
    }
  }
}

const mapStateToProps = state => ({
  subscribers: state.subscriber.subscribers,
  insights: state.insights.currentAllInsightsProps,
  posts: state.postState.currentAllPostsProps,
  currentUser: state.user.currentUserProps
});

export default connect(mapStateToProps)(AdminCMS);
