import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Container, Col, Row, ListGroup, ListGroupItem } from "reactstrap";
import { Button, Card, Icon } from "semantic-ui-react";
import "../../MegaDraft/megadraft.css";
import { renderIconSymbol } from "../../../constants/index";
import {
  setLegalRegisterCurrentLegal,
  getAllLegalItems
} from "../../../actions/legalRegister";
import { styles } from "../../../styles";
import { changeActiveItem } from "../../../actions/configActions";

class SubscribedLegalRegisterList extends Component {
  componentDidMount() {
    getAllLegalItems();
  }

  handleSelectClick = key => {
    setLegalRegisterCurrentLegal(key);
  };

  compare = (firstArray, secondArray) => {
    // does allLegalItems key match the key in my legal items if so return that item and map over the array of items returned
    let finalResult = firstArray.filter(e1 =>
      secondArray.find(e2 => e1.key === e2.key)
    );
    return finalResult;
  };

  renderAllMyLegalItems = (allLegalItems, myLegalItems) => {
    let userList = Object.values(myLegalItems);
    let legalItemsList = Object.values(allLegalItems);
    let result = this.compare(legalItemsList, userList);

    if (myLegalItems) {
      return result.map(legalItem => {
        return (
          <ListGroupItem key={legalItem.key}>
            <Row>
              <Col sm="3">{legalItem.title}</Col>
              <Col sm="3">
                <Link
                  to="/AdminLegalRegister"
                  onClick={() => this.handleSelectClick(legalItem.key)}
                >
                  <Button type="button">Review</Button>
                </Link>
              </Col>
              <Col sm="2">
                Admin:{" "}
                {renderIconSymbol(myLegalItems[`${legalItem.key}`].adminStatus)}
              </Col>
              <Col sm="2">
                User:{" "}
                {renderIconSymbol(myLegalItems[`${legalItem.key}`].userStatus)}
              </Col>
              <Col sm="2">
                <span
                  style={
                    myLegalItems[`${legalItem.key}`].status === "active"
                      ? { color: "green" }
                      : { color: "red" }
                  }
                >
                  {myLegalItems[`${legalItem.key}`].status}
                </span>
              </Col>
            </Row>
          </ListGroupItem>
        );
      });
    }
  };
  render() {
    return (
      <Container>
        <Card color="teal" fluid style={styles.profileComponentStyle}>
          <Card.Content style={styles.cardContentStyle}>
            <Card.Header style={styles.cardHeaderStyle}>
              <Row>
                <Col sm="2" style={{ padding: 3 }}>
                  <Link to="/dashboard">
                    <Button
                      onClick={() => {
                        changeActiveItem("2");
                      }}
                    >
                      <Icon name="chevron left" />
                      Back
                    </Button>
                  </Link>
                </Col>
                <Col sm="10">
                  <h1>
                    {" "}
                    {this.props.currentSubscriber.displayName ||
                      `${this.props.currentSubscriber.firstName} ${
                        this.props.currentSubscriber.lastName
                      }`}{" "}
                    Legal Register History
                  </h1>
                </Col>
              </Row>
            </Card.Header>
            <div style={styles.divStyle} />
            <Row>
              <Col sm="12">
                <div style={{ padding: 15 }}>
                  <Card.Meta style={styles.textElementStyle}>
                    • This list contains{" "}
                    {this.props.currentSubscriber.displayName ||
                      `${this.props.currentSubscriber.firstName} ${
                        this.props.currentSubscriber.lastName
                      }`}
                    's 'Legal Items'.
                  </Card.Meta>
                  <Card.Meta style={styles.textElementStyle}>
                    • The Icon shows what state each one is in.
                  </Card.Meta>
                  <Card.Meta style={styles.textElementStyle}>
                    • As an administrator, you can make a users legal Item
                    compliant by clicking Review and leaving a compliance note.
                  </Card.Meta>
                  <ListGroup>
                    {this.props.currentMyLegalItems &&
                      this.renderAllMyLegalItems(
                        this.props.allLegalItems,
                        this.props.currentMyLegalItems
                      )}
                  </ListGroup>
                </div>
              </Col>
            </Row>
          </Card.Content>
        </Card>
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  // maps over this which changes depending on which users profile you are viewing
  currentSubscriber: state.legalItems.currentLegalSubscriber,
  currentLegalItems: state.legalItems.currentAllLegalItemsProps,
  // gives you all your legal items but not the full detail
  currentMyLegalItems: state.legalItems.currentLegalSubscriber.MyLegalItems,
  // the entire history of your legal items with full details
  allLegalItems: state.legalItems.allLegalItems
});

export default connect(mapStateToProps)(SubscribedLegalRegisterList);
