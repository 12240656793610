import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import TimeAgo from "react-timeago";
import {
  Card,
  Segment,
  Icon,
  Divider,
  Dropdown,
  Label as Lb,
  Button,
  Popup
} from "semantic-ui-react";
import { Col, Row, Container, Form, FormGroup, Table } from "reactstrap";
import {
  MegadraftEditor,
  editorStateFromRaw,
  editorStateToJSON
} from "megadraft";
import { complianceOptions, renderIconSymbol } from "../../../constants/index";
import {
  updateSubscriberLegalRegisterItem,
  getAllLegalItems
} from "../../../actions/legalRegister";
import { styles } from "../../../styles";
//  Notifications
import {
  createNotification,
  NOTIFICATION_TYPE_SUCCESS
} from "react-redux-notify";

const mySuccessNotification = {
  message: `Complaince Successfully Uploaded`,
  type: NOTIFICATION_TYPE_SUCCESS,
  duration: 3000,
  canDismiss: true,
  icon: <Icon name="check" />
};

class AdminLegalRegisterItemDetailPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      adminComplianceCheck: false,
      adminComplianceNote: "",
      adminComplianceNoteEditorState: editorStateFromRaw(null),
      adminComplianceNoteError: "",
      noteAdded: false
    };
  }

  componentDidMount() {
    getAllLegalItems();
  }

  onChangeAdminComplianceNote = adminComplianceNoteEditorState => {
    this.setState({
      adminComplianceNoteEditorState,
      noteAdded: true
    });
  };

  handleGoBack = () => {
    this.props.history.goBack();
  };

  handleChange = event => {
    const { target } = event;
    const { value, name } = target;
    this.setState({ [name]: value });
  };

  handleComplianceChange = (event, data) => {
    const status = data.value;
    const theName = data.name;
    this.setState({ [theName]: status });
  };

  handleAdminComplianceNote = () => {
    this.setState({
      adminComplianceNoteError: ""
    });

    const adminComplianceNotes = editorStateToJSON(
      this.state.adminComplianceNoteEditorState
    );

    let adminName = "";
    if (this.props.currentUser.displayName) {
      adminName = this.props.currentUser.displayName;
    } else {
      adminName =
        this.props.currentUser.firstName +
        " " +
        this.props.currentUser.lastName;
    }

    let updateditem = {
      adminComplianceCheck: this.state.adminComplianceCheck,
      adminComplianceNote: adminComplianceNotes,
      adminUid: this.props.currentUser.uid,
      adminDisplayName: adminName
    };
    if (this.state.noteAdded === false) {
      this.setState({
        adminComplianceNoteError: "Please add a note before submitting update"
      });
    } else if (this.state.adminComplianceCheck === false) {
      this.setState({
        adminComplianceNoteError: "Please select status of compliance"
      });
    } else {
      updateSubscriberLegalRegisterItem(
        this.props.currentSubscriber.uid,
        this.props.legalItem,
        updateditem
      );

      this.setState({
        adminComplianceCheck: false,
        adminComplianceNoteEditorState: editorStateFromRaw(null),
        adminComplianceNote: "",
        adminComplianceNoteError: "",
        noteAdded: false
      });
      const { createNotification } = this.props;
      createNotification(mySuccessNotification);
    }
  };

  renderComplianceChildren = compliances => {
    let complianceArray = [];
    /*the compliances is the main object and then .compliance is where on the object the comments are landnig right now*/
    if (
      compliances &&
      compliances.compliance !== undefined &&
      Object.values(compliances.compliance).length > 0
    ) {
      complianceArray = Object.values(compliances.compliance).reverse();
    }
    return complianceArray.map(compliance => (
      <tr key={compliance.userTimeAdded || compliance.adminTimeAdded}>
        <th>
          {renderIconSymbol(
            compliance.userComplianceCheck || compliance.adminComplianceCheck
          )}
        </th>
        <th>
          {" "}
          <TimeAgo
            date={compliance.userTimeAdded || compliance.adminTimeAdded}
          />
        </th>
        {compliance.type === "user" ? (
          <th>
            {this.props.currentUser.firstName} {this.props.currentUser.lastName}
          </th>
        ) : compliance.adminDisplayName ? (
          <th>
            {" "}
            <Popup
              trigger={
                <div>
                  <Icon name="user plus" color="yellow" />{" "}
                  {compliance.adminDisplayName}
                </div>
              }
              content="Administrator"
            />
          </th>
        ) : (
          <th>Admin</th>
        )}
        <th>
          {/* {compliance.userComplianceNote || compliance.adminComplianceNote} */}
          <MegadraftEditor
            editorState={editorStateFromRaw(
              JSON.parse(
                compliance.userComplianceNote || compliance.adminComplianceNote
              )
            )}
            readOnly={true}
          />
        </th>
        <th>
          {compliance.document ? (
            <a
              href={compliance.document}
              rel="noopener noreferrer"
              target="_blank"
            >
              Document
            </a>
          ) : (
            ""
          )}
        </th>
      </tr>
    ));
  };

  render() {
    const {
      currentLegalItems,
      currentLegalKey,
      currentSubscriber,
      legalItem
    } = this.props;

    let current = currentLegalItems.filter(
      item => item.key === currentLegalKey
    );
    let currentItem = current[0];

    if (currentItem === undefined) {
      return <Redirect to="/LegalRegisterDash" />;
    }
    let currentUserCompliances;
    if (currentSubscriber && legalItem) {
      let userLegalItems = currentSubscriber.MyLegalItems;
      let currentItemKey = legalItem;
      if (userLegalItems) {
        currentUserCompliances = userLegalItems[`${currentItemKey}`];
      }
    }

    let AdminComplianceApproved = "Not Compliant";
    if (currentUserCompliances.adminStatus) {
      AdminComplianceApproved = currentUserCompliances.adminStatus;
    }

    let UserComplianceApproved = "Not Compliant";
    if (currentUserCompliances.userStatus) {
      UserComplianceApproved = currentUserCompliances.userStatus;
    }

    return (
      <Container style={{ marginTop: 25 }}>
        <Row>
          <Col>
            <Card color="teal" fluid style={styles.profileComponentStyle}>
              <Card.Content style={styles.cardContentStyle}>
                <div style={styles.cardHeaderStyle}>
                  <Row
                    style={{
                      display: "flex",
                      alignItems: "center"
                    }}
                  >
                    <Col sm="2" style={{ padding: 3 }}>
                      <Button onClick={this.handleGoBack} type="button">
                        <Icon name="chevron left" />
                        Back
                      </Button>
                    </Col>
                    <Col sm="10">
                      {currentItem && <div> Edit: {currentItem.title}</div>}
                    </Col>
                  </Row>
                </div>
                <div style={styles.divStyle} />
                <Segment style={styles.segmentStyle}>
                  <Row>
                    <Col sm="8" style={{ textAlign: "left" }}>
                      {currentItem && <h1>{currentItem.title} </h1>}
                    </Col>
                  </Row>
                  <Row>
                    <Col sm="4" style={{ textAlign: "left" }}>
                      <Segment
                        style={{
                          backgroundColor: "transparent",
                          borderWidth: 0,
                          boxShadow: "none"
                        }}
                      >
                        Admin : {renderIconSymbol(AdminComplianceApproved)}
                        User : {renderIconSymbol(UserComplianceApproved)}
                      </Segment>
                    </Col>
                  </Row>
                  <Divider />
                  <Container>
                    <Row>
                      <Col>
                        <Segment>
                          <Segment inverted tertiary>
                            <h3>HEADLINE: </h3>
                          </Segment>
                          <MegadraftEditor
                            editorState={editorStateFromRaw(
                              JSON.parse(currentItem.headlineDetails)
                            )}
                            readOnly={true}
                          />
                          <Segment.Group horizontal>
                            {currentItem && (
                              <Segment>
                                <Segment inverted tertiary compact>
                                  <h4>APPLICABLE TO:</h4>
                                </Segment>
                                {currentItem.applicableTo ? (
                                  currentItem.applicableTo.map((tag, idx) => (
                                    <div key={idx}>
                                      <Lb style={{ margin: 1 }}>
                                        <Icon name="tag" />
                                        {tag}
                                      </Lb>
                                    </div>
                                  ))
                                ) : (
                                  <div />
                                )}
                              </Segment>
                            )}
                            {currentItem && (
                              <Segment>
                                <Segment inverted tertiary compact>
                                  <h4>REGULATED BY:</h4>
                                </Segment>
                                {currentItem.regulator ? (
                                  currentItem.regulator.map((tag, idx) => (
                                    <div key={idx}>
                                      <Lb style={{ margin: 1 }}>
                                        <Icon name="tag" />
                                        {tag}
                                      </Lb>
                                    </div>
                                  ))
                                ) : (
                                  <div />
                                )}
                              </Segment>
                            )}
                          </Segment.Group>
                        </Segment>
                        {currentItem && (
                          <Segment>
                            <Segment tertiary inverted>
                              <h3>HOW THIS APPLIES TO ME: </h3>
                            </Segment>
                            <MegadraftEditor
                              editorState={editorStateFromRaw(
                                JSON.parse(currentItem.howThisApplies)
                              )}
                              readOnly={true}
                            />
                          </Segment>
                        )}
                      </Col>
                      <br />
                      <Col>
                        {currentItem && (
                          <Segment>
                            <Segment tertiary inverted>
                              <h3>DESCRIPTION: </h3>
                            </Segment>

                            <MegadraftEditor
                              editorState={editorStateFromRaw(
                                JSON.parse(currentItem.description)
                              )}
                              readOnly={true}
                            />
                          </Segment>
                        )}
                      </Col>
                    </Row>
                    <Divider />
                    <Row>
                      <Col>
                        <Segment>
                          <h3>COMPLIANCE:</h3>
                          <Segment tertiary inverted>
                            <h3>Admin Notes</h3>
                          </Segment>
                          <Form>
                            <FormGroup>
                              <Segment>
                                <MegadraftEditor
                                  editorState={
                                    this.state.adminComplianceNoteEditorState
                                  }
                                  onChange={this.onChangeAdminComplianceNote}
                                  placeholder="Write your notes here ..."
                                  name="adminComplianceNote"
                                />
                              </Segment>
                            </FormGroup>
                            <FormGroup style={{ textAlign: "left" }}>
                              <Dropdown
                                placeholder="Status"
                                selection
                                name="adminComplianceCheck"
                                onChange={this.handleComplianceChange}
                                options={complianceOptions}
                              />
                            </FormGroup>
                            <Button
                              color="blue"
                              type="button"
                              onClick={this.handleAdminComplianceNote}
                            >
                              Save
                            </Button>
                            <FormGroup style={{ textAlign: "left" }}>
                              <h3> {this.state.adminComplianceNoteError}</h3>
                            </FormGroup>
                          </Form>
                          <Divider />
                        </Segment>
                        <Segment>
                          <h3>Compliance History</h3>
                          <Table>
                            <thead>
                              <tr>
                                <th>#</th>
                                <th>Date Time</th>
                                <th>Username</th>
                                <th>Note</th>
                                <th>Document</th>
                              </tr>
                            </thead>
                            <tbody>
                              {this.renderComplianceChildren(
                                currentUserCompliances
                              )}
                            </tbody>
                          </Table>
                        </Segment>
                      </Col>
                    </Row>
                  </Container>
                </Segment>
              </Card.Content>
            </Card>
          </Col>
        </Row>
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  currentSubscriber: state.legalItems.currentLegalSubscriber,
  legalItem: state.legalItems.currentLegalKey,
  currentLegalItems: state.legalItems.allLegalItems,
  currentLegalKey: state.legalItems.currentLegalKey,
  currentUser: state.user.currentUserProps
});

const mapDispatchToProps = dispatch => ({
  createNotification: config => {
    dispatch(createNotification(config));
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AdminLegalRegisterItemDetailPage);
