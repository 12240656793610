import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { Row, Col } from "reactstrap";
import {
  Card,
  Image,
  Button,
  Icon,
  Transition,
  Segment,
  Divider
} from "semantic-ui-react";
import moment from "moment";
import TimeAgo from "react-timeago";
import ProfileForm from "./ProfileForm";
import { styles } from "../../styles";
import { updateUserProfilePic } from "../../actions/users";

import {
  createNotification,
  NOTIFICATION_TYPE_SUCCESS
} from "react-redux-notify";
import ManageNotifications from "./ManageNotifications";

const mySuccessNotification = {
  message: "Profile Picture Updated",
  type: NOTIFICATION_TYPE_SUCCESS,
  duration: 3000,
  canDismiss: true,
  icon: <Icon name="check" />
};

class Profile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editProfile: false,
      manageNotifications: false,
      errors: "",
      currentProfilePhoto: this.props.currentUser.profilePhoto || "",
      selectedProfilePhoto: ""
    };
  }

  toggleEditProfile = () =>
    this.setState({
      editProfile: !this.state.editProfile,
      manageNotifications: false
    });

  toggleManageNotifications = () =>
    this.setState({
      manageNotifications: !this.state.manageNotifications,
      editProfile: false
    });

  renderName() {
    const { currentUser } = this.props;

    if (currentUser && currentUser.firstName && currentUser.lastName) {
      return `${currentUser.firstName} ${currentUser.lastName}`;
    } else if (currentUser && currentUser.displayName) {
      return `${currentUser.displayName}`;
    } else {
      return `${currentUser && currentUser.email}`;
    }
  }

  handleImageSelect = e => {
    // if file is greater than 2mb
    if (e.target.files[0] && e.target.files[0].size > 2000000) {
      return this.setState({ errors: "File size is too big! Maximum 2mb" });
    }

    // if file is less than 5kb
    if (e.target.files[0] && e.target.files[0].size < 5000) {
      return this.setState({ errors: "File size is too small! Minimum 5kb" });
    }

    this.setState({
      selectedProfilePhoto: e.target.files[0] ? e.target.files[0] : null,
      errors: ""
    });
  };

  render() {
    if (
      this.props.currentUser &&
      this.props.currentUser.userType === "newUser"
    ) {
      return <Redirect to="/NewUser" />;
    }
    const { currentUser } = this.props;
    const { authUser } = this.props;
    const showEditProfileForm =
      this.state.editProfile === false ? (
        " "
      ) : (
        <Transition
          editProfile={this.state.editProfile}
          animation="scale"
          duration={200}
        >
          <div>
            <Divider horizontal>Edit Profile</Divider>
            <ProfileForm toggle={this.toggleEditProfile} />
          </div>
        </Transition>
      );

    const showManageNotifications =
      this.state.manageNotifications === false ? (
        " "
      ) : (
        <Transition
          manageNotifications={this.state.manageNotifications}
          animation="scale"
          duration={200}
        >
          <div>
            <Divider horizontal>Manage Notifications</Divider>
            <ManageNotifications toggle={this.toggleManageNotifications} />
          </div>
        </Transition>
      );

    let userDOB =
      currentUser && !currentUser.DOB
        ? ""
        : moment.parseZone(currentUser && currentUser.DOB).format("MMMM D");

    if (currentUser && currentUser.userType === "newUser") {
      return <Redirect to="/NewUser" />;
    }

    if (authUser === null) {
      return <Redirect to="/" />;
    }

    return (
      <Card color="teal" fluid style={styles.profileComponentStyle}>
        <Card.Content style={styles.cardContentStyle}>
          <div style={styles.cardHeaderStyle}>{this.renderName()}</div>
          <div style={styles.divStyleLeftbox} />
          <Row style={{ padding: 15 }}>
            <Col sm="3" style={{ paddingBottom: 15 }}>
              <div style={{ textAlign: "center" }}>
                {currentUser && !currentUser.profilePhoto ? (
                  <Icon
                    name="user circle"
                    size="huge"
                    style={{
                      color: "grey"
                    }}
                  />
                ) : (
                  <Image
                    src={currentUser && currentUser.profilePhoto}
                    size="small"
                    circular
                  />
                )}

                <input
                  style={
                    this.state.errors
                      ? {
                          border: "1px solid red",
                          borderRadius: 7,
                          width: 200,
                          margin: "15px auto",
                          color: "red"
                        }
                      : this.state.selectedProfilePhoto
                      ? {
                          border: "1px solid green",
                          borderRadius: 7,
                          width: 200,
                          margin: "15px auto",
                          color: "green"
                        }
                      : { width: 200, margin: "15px auto" }
                  }
                  type="file"
                  name="profilePhoto"
                  id="profilePhoto"
                  onChange={this.handleImageSelect}
                />
                <p style={{ color: "red" }}>{this.state.errors}</p>
                {this.state.selectedProfilePhoto && !this.state.errors && (
                  <Button
                    className="ui blue"
                    type="submit"
                    style={styles.textElementStyle}
                    onClick={() => {
                      updateUserProfilePic(
                        currentUser && currentUser.uid,
                        this.state.selectedProfilePhoto
                      );
                      this.props.createNotification(mySuccessNotification);
                      this.setState({ errors: "", selectedProfilePhoto: "" });
                    }}
                  >
                    Update
                  </Button>
                )}
              </div>
            </Col>
            <Col sm="9">
              <Segment.Group>
                <Segment
                  inverted
                  size="tiny"
                  style={{ backgroundColor: "#404040" }}
                >
                  Public Information
                </Segment>
                <Segment>
                  <Card.Description style={styles.textElementStyle}>
                    <strong>Display Name:</strong>{" "}
                    {currentUser && !currentUser.displayName
                      ? "Display Name"
                      : currentUser && currentUser.displayName}
                  </Card.Description>
                  <Card.Description style={styles.textElementStyle}>
                    <strong>Company:</strong>{" "}
                    {currentUser && !currentUser.company
                      ? ""
                      : currentUser && currentUser.company}
                  </Card.Description>
                  <Card.Description style={styles.textElementStyle}>
                    <strong>Job Title:</strong>{" "}
                    {currentUser && !currentUser.jobRole
                      ? ""
                      : currentUser && currentUser.jobRole}
                  </Card.Description>
                  <Card.Description style={styles.textElementStyle}>
                    <span>
                      <strong>Birthday:</strong> {userDOB}
                    </span>
                  </Card.Description>
                  <Card.Description
                    floated="left"
                    style={styles.textElementStyle}
                  >
                    <strong>About Me:</strong>{" "}
                    {currentUser && !currentUser.bio
                      ? ""
                      : currentUser && currentUser.bio}
                  </Card.Description>
                </Segment>
              </Segment.Group>
              <Segment.Group>
                <Segment
                  inverted
                  size="tiny"
                  style={{ backgroundColor: "#404040" }}
                >
                  Private Information
                </Segment>
                <Segment>
                  <Card.Description style={styles.textElementStyle}>
                    <span>
                      <strong>Email address:</strong> {authUser.email}
                    </span>
                  </Card.Description>
                  {/* NEED TO MAKE THIS HUMAN READABLE */}
                  <Card.Description style={styles.textElementStyle}>
                    <span>
                      <strong>Membership expiry: </strong>
                      <TimeAgo date={currentUser && currentUser.expiryDate} />
                      {/* {moment
												.parseZone(currentUser.expiryDate)
												.format("MMMM DD YYYY")} */}
                    </span>
                  </Card.Description>
                </Segment>
              </Segment.Group>
              <Button
                floated="left"
                color="teal"
                style={styles.textElementStyle}
                onClick={this.toggleEditProfile}
              >
                {this.state.editProfile ? "Cancel" : "Edit Profile"}
                <Icon name="edit right" />
              </Button>
              <Button
                floated="left"
                color="red"
                style={styles.textElementStyle}
                onClick={this.toggleManageNotifications}
              >
                {this.state.manageNotifications
                  ? "Cancel"
                  : "Manage Notifications"}
                <Icon name="cogs right" />
              </Button>
            </Col>
          </Row>
          {this.state.editProfile ? (
            <div style={{ padding: 10 }}>{showEditProfileForm}</div>
          ) : this.state.manageNotifications ? (
            <div style={{ padding: 10 }}>{showManageNotifications}</div>
          ) : (
            ""
          )}
        </Card.Content>
      </Card>
    );
  }
}

const mapStateToProps = state => ({
  currentUser: state.user.currentUserProps,
  authUser: state.sessionState.authUser
});

const mapDispatchToProps = dispatch => ({
  createNotification: config => {
    dispatch(createNotification(config));
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Profile);
