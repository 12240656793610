import React from "react";
import { Container, Row, Col } from "reactstrap";
import "./index.css";
import ScrollAnimation from "react-animate-on-scroll";

// image
import consult from "../../img/LandingPageIcons/consulting.png";
import influence from "../../img/LandingPageIcons/advertising.png";

const Consult = () => (
  <div className="LightBacking" style={{ padding: 15, paddingTop: 25 }}>
    {/* <h1>Learn</h1> */}
    <Container style={{ backgroundColor: "" }}>
      {/* <Row>
        <Col>
          
        </Col>
      </Row> */}
      <Row>
        <Col sm="3">
          <ScrollAnimation animateIn="bounceInLeft" offset={1}>
            <img
              src={consult}
              className="img"
              style={styles.imgStyle}
              alt="consult"
            />
          </ScrollAnimation>
        </Col>
        <Col sm="9" style={{ textAlign: "left" }}>
          <h3>Consult</h3>
          <p>
            We can provide technical assistance to members needing a little
            support to develop an energy reduction plan or business strategy.
            This is a chargeable consultancy service, offered at preferential
            rates to member organisations.
          </p>
        </Col>
      </Row>
    </Container>
    <Container style={{ backgroundColor: "", padding: 15 }}>
      <Row>
        <Col sm="9" style={{ textAlign: "right" }}>
          <h3>Influence</h3>
          <p>
            Join our policy working groups to address the practical issues
            around policy implementation. We have strong relationships with
            BEIS, Ofgem, the Energy Networks Association and other key agencies
            and departments that work on the policy and legislation that
            directly impacts you. Engage with our policy work and contribute to
            consultation responses, influence work and white papers. Access
            wider industry discussions on Smart Systems, DNO-DSO transition and
            network charging futures as a representative of ICON. Raise your own
            profile and that of your company by being involved in creating the
            energy system of tomorrow.
          </p>
        </Col>
        <Col sm="3">
          <ScrollAnimation animateIn="bounceInRight" offset={1}>
            <img
              src={influence}
              className="img"
              style={styles.imgStyle}
              alt="influence"
            />
          </ScrollAnimation>
        </Col>
      </Row>
    </Container>
  </div>
);

const styles = {
  imgStyle: {
    width: 150,
    padding: 15
    // shadowOffset: { height: 20, width: 20 },
    // shadowColor: "black",
    // shadowOpacity: 1.0
  }
};

export default Consult;
