import React from "react";
import { Col, Row } from "reactstrap";
import CreateChatroom from "../Forum/CreateChatroom";

const CMSForum = props => (
  <div>
    <Row>
      <Col>
        <CreateChatroom />
      </Col>
    </Row>
  </div>
);

export default CMSForum;
