import React from "react";
import Scrollchor from "react-scrollchor";

export const TermsAndConditions = () => (
  <div style={{ padding: 25 }}>
    <h1 style={{ textAlign: "center" }}>icon hub Terms of Use</h1>
    <hr />
    <div>
      <p>
        icon hub Terms of Use Industrial & Commercial Operations Network Ltd.
        (“icon”) membership rules and terms and conditions (referred to
        collectively as the “Terms”).{" "}
      </p>
      <p>
        As a registered member of icon (“Member”), you agree to abide by these
        Terms, and these Terms shall apply to any use you may make of our
        website
        <a href="https://theicon.org.uk/">www.theicon.org.uk </a> (the “Site”)
        and to any order you make (“Order”) for services.{" "}
      </p>
      <p>
        Each Order will be a separate contract incorporating these Terms and any
        specific terms agreed in writing between us regarding that Order.{" "}
      </p>
      <p>
        Use of the Site includes accessing and browsing the Site and registering
        as a Member. Please read these Terms carefully and make sure that you
        understand them, before making any use the Site or placing an Order. If
        you accept these Terms, please click on the button marked “I Accept” at
        the end of this page. If you refuse to accept these Terms, you will not
        be able to become a Member or place an Order and you should not make any
        use the Site.{" "}
      </p>
      <p>
        You should print a copy of these Terms or save them to your computer for
        future reference.{" "}
      </p>
      <p>
        We may amend these Terms from time to time as set out in{" "}
        <Scrollchor
          animate={{ offset: -60, duration: 600 }}
          to="#clause14"
          // className="nav-link"
        >
          clause 14
        </Scrollchor>
        . Every time you wish to place an Order or update your Membership
        Profile, please check these Terms to ensure you understand the terms
        which will apply at that time{" "}
      </p>
      <p>
        These Terms, and any contract between us, are only in the English
        language.{" "}
      </p>
      <p>
        These Terms refer to the following additional terms, which also apply to
        your use of the Site:{" "}
      </p>
      <p>
        Our Privacy Policy, which sets out the terms on which we process any
        personal data we collect from you, or that you provide to us. By using
        the Site, you consent to such processing and you warrant that all data
        provided by you is accurate.{" "}
      </p>
      <p>We do not use Cookies so we do not have a cookie policy.</p>
    </div>

    <h3>1. Information about us </h3>
    <ol>
      <li>
        We operate the website{" "}
        <a href="https://theicon.org.uk/">www.theicon.org.uk </a>. We are the
        Industrial & Commercial Operations Network Ltd. a company registered in
        England and Wales under company number 08738022 and with our registered
        office and main trading address at Unit 1, First Floor, Courtney House,
        12 Dudley Street, Luton, LU2 0NT
      </li>
      <li>
        To contact us, please contact us by email at{" "}
        <a href="mailto:hello@theicon.org.uk">hello@theicon.org.uk</a>{" "}
      </li>
    </ol>
    <h3>2. Membership Rules </h3>
    <ol>
      <li>
        All Membership applications are subject to verification and approval by
        icon. Icon will notify you in the event that your Membership application
        has been accepted.{" "}
      </li>
      <li>
        You are obliged to provide correct details when you apply for icon
        Membership and your failure to do so may invalidate your Membership and
        any subsequent Order (at icon’s sole discretion). Your responsibility to
        provide accurate information is a continuing obligation and you must
        notify Icon in the event that any information provided by you changes.{" "}
      </li>
      <li>
        Your log-on details are personal to you. You are responsible for
        ensuring that no one other than you uses your Membership or site access
        details.{" "}
      </li>
      <li>
        Access to and use of the Members section of the Site is through a
        combination of user name or email address and password (“Your ID”) and
        is reserved exclusively for Members. You are solely responsible for
        maintaining the confidentiality of Your ID and for all use made of the
        Site and Orders placed using Your ID and you agree to notify Icon
        immediately if you believe that there has been any breach of security or
        unauthorised use of Your ID.{" "}
      </li>
      <li>
        You may use the Site only for lawful purposes. You may not use the Site:
        I. In any way that breaches any applicable local, national or
        international law or regulation; II. In any way that is unlawful or
        fraudulent, or has any unlawful or fraudulent purpose or effect; III.
        For the purpose of harming or attempting to harm minors in any way; IV.
        To send, knowingly receive, upload, download, use or re-use any material
        which does not comply with our content standards at clause 8 below; V.
        To transmit, or procure the sending of, any unsolicited or unauthorised
        advertising or promotional material or any other form of similar
        solicitation (spam); VI. For any other purpose that infringes the rights
        of others.{" "}
      </li>
      <li>
        Icon reserves the absolute right to cancel or suspend your Membership or
        your access to any part of the Site (at its sole discretion) for any
        reason whatsoever.{" "}
      </li>
    </ol>
    <h3>3. Membership services </h3>
    <ol>
      <li>
        Icon provides its Members with a variety of services the details of
        which are available on the Site (the “Services”). Icon reserves the
        right to withdraw any of the Services and/or not to supply the Services
        requested.{" "}
      </li>
      <li>
        You may only make requests for support and information provided to
        Members by telephone, email or through the Site, once you have been
        accepted as a Member.{" "}
      </li>
      <li>
        If Icon is unable to deal with any request, it will inform you as soon
        as reasonably practicable.{" "}
      </li>
      <li>
        You must confirm your agreement to Icon performing Services by
        completing an Order on the Site.{" "}
      </li>
      <p>
        Unless otherwise stated in the Order, or agreed in advance in writing
        with Icon, payment for any of the Services is due upon placement of your
        Order. Icon shall not be obliged (but shall have the discretion) to
        commence provision of the Services, unless otherwise agreed in
        accordance with this clause, until payment has been received.{" "}
      </p>
    </ol>

    <h3>4. Accessing the site </h3>
    <ol>
      <li>
        Access to public areas of The Site is made available free of charge.
        Subscriptions are charged for access to protected areas of the site
        including the user forum and certain documents. Please refer to our
        Subscription Rates for more details.{" "}
      </li>
      <li>
        We do not guarantee that the Site, or any content on it, will always be
        available or be uninterrupted. Access to the Site is permitted on a
        temporary basis. We may suspend, withdraw, discontinue or change all or
        any part of Site without notice. We will not be liable to you if for any
        reason the Site is unavailable at any time or for any period.{" "}
      </li>
      <li>
        We may update the Site from time to time, and may change the content at
        any time. However, please note that any of the content on the Site may
        be out of date at any given time, and we are under no obligation to
        update it.{" "}
      </li>
      <li>
        Other Members may update their Member profiles on the Site from time to
        time. However, please note that any of the content on any Member’s
        profile may be out of date at any given time.{" "}
      </li>
      <li>
        We do not guarantee that the Site, or any content on it, will be free
        from errors or omissions.{" "}
      </li>
      <li>
        You are responsible for making all arrangements necessary for you to
        have access to the Site. 7. You are also responsible for ensuring that
        all persons who access the Site through your internet connection are
        aware of these Terms and other applicable terms and conditions, and that
        they comply with them.{" "}
      </li>
    </ol>

    <h3>5. No reliance on information </h3>
    <ol>
      <li>
        The content on the Site is provided for general information only. It is
        not intended to amount to advice on which you should rely. You must
        obtain professional or specialist advice before taking, or refraining
        from, any action on the basis of the content on the Site.{" "}
      </li>
      <li>
        Although we make reasonable efforts to update the information on the
        Site, we make no representations, warranties or guarantees, whether
        express or implied, that the content on the Site is accurate, complete
        or up-to-date.{" "}
      </li>
    </ol>

    <h3>6. Services</h3>
    <ol>
      <li>
        If you order any Services from us, such Order is subject to acceptance
        by us.{" "}
      </li>
      <li>
        We will supply the Services to you from the date set out in the Order
        for the period of time set out in the Order.{" "}
      </li>
      <li>
        We will make every effort to complete the Services on time. However, any
        such dates shall be estimates only and time for performance by us shall
        not be of the essence of these Terms.{" "}
      </li>
      <li>
        We may need certain information from you that is necessary for us to
        provide the Services. We will contact you in writing about this. We will
        not be liable for any delay or non-performance where you have not
        provided this information to us after we have asked.{" "}
      </li>
      <li>
        We may have to suspend the Services if we have to deal with technical
        problems. We will contact you to let you know in advance where this
        occurs, unless the problem is urgent or an emergency.{" "}
      </li>
      <li>
        The price of the Services will be set out in the Order. Our prices may
        change at any time, but price changes will not affect Orders that we
        have confirmed with you.
      </li>
      <li>
        While we may send you an automatic email confirming receipt of an Order,
        this does not amount to acceptance of such Order. Acceptance does not
        occur (and therefore an Order is not binding on us) until is it
        expressly accepted by us.{" "}
      </li>
      <li>
        It is always possible that, despite our best efforts, some of the
        Services may be incorrectly priced. We will normally check prices as
        part of our Order confirmation process so that, where the Services’
        correct price is more or less than our stated price, we will contact you
        to tell you and for your instructions. If the pricing error is obvious
        and unmistakeable and could have reasonably been recognised by you as a
        mispricing, we do not have to provide the Services to you at the
        incorrect (lower) price.{" "}
      </li>
      <li>
        If you are a consumer, you have legal rights in relation to Services not
        carried out with reasonable skill and care, or if the materials we use
        are faulty or not as described. Nothing in these Terms will affect these
        legal rights.
      </li>
    </ol>

    <h3>7. Intellectual Property Rights </h3>
    <ol>
      <li>
        We are the owner or the licensee of all intellectual property rights in
        the Site, and in the material published on it. Those works are protected
        by copyright laws and treaties around the world. All such rights are
        reserved.{" "}
      </li>
      <li>
        You may print off one copy, and may download extracts, of any page(s)
        from the Site for your own business or personal use and you may draw the
        attention of others within your organisation (where relevant) to content
        posted on the Site, but you may not provide non-members with copies of
        any such content in any medium.{" "}
      </li>
      <li>
        You must not modify the paper or digital copies of any materials you
        have printed off or downloaded in any way, and you must not use any
        illustrations, photographs, video or audio sequences or any graphics
        separately from any accompanying text.{" "}
      </li>
      <li>
        Our status (and that of any identified contributors) as the authors of
        content on the Site must always be acknowledged.{" "}
      </li>
      <li>
        You must not use any part of the content on our site for commercial
        purposes without obtaining permission to do so. Subscribed Members are
        granted such permissions immediate upon receipt of payment for the
        annual licence.{" "}
      </li>
      <li>
        If you print off, copy or download any part of the Site in breach of
        these terms of use, your right to use the Site will cease immediately
        and you must, at our option, return or destroy any copies of the
        materials you have made.
      </li>
    </ol>

    <h3>8. Uploading content to the site</h3>
    <ol>
      <li>
        Whenever you make use of a feature that allows you to upload content to
        the Site, or to make contact with other Members of the Site, you must
        comply with the following content standards:
        <ol type="I">
          <li>
            These content standards apply to any and all material which you
            contribute to the Site (“contributions”), and to any interactive
            services associated with it. You must comply with the spirit and the
            letter of the following standards. The standards apply to each part
            of any contribution as well as to its whole.
          </li>
          <li>
            Contributions must:
            <ol type="I">
              <li>Be accurate (where they state facts). </li>
              <li>Be genuinely held (where they state opinions). </li>
              <li>
                Comply with applicable law in the UK and in any country from
                which they are posted.{" "}
              </li>
            </ol>
          </li>
          <li>
            Contributions must not:
            <ol type="I">
              <li>
                Contain any material which is defamatory of any person. I.
                Contain any material which is obscene, offensive, hateful or
                inflammatory.{" "}
              </li>
              <li>Promote sexually explicit material. </li>
              <li>Promote violence. </li>
              <li>
                Promote discrimination based on race, sex, religion,
                nationality, disability, sexual orientation or age.{" "}
              </li>
              <li>
                Infringe any copyright, database right, trade mark or other
                right of any other person.{" "}
              </li>
              <li>Be likely to deceive any person.</li>
              <li>
                Be made in breach of any legal duty owed to a third party, such
                as a contractual duty or a duty of confidence.{" "}
              </li>
              <li>Promote any illegal activity. </li>
              <li>
                Be threatening, abuse or invade another’s privacy, or cause
                annoyance, inconvenience or needless anxiety.{" "}
              </li>
              <li>
                Be likely to harass, upset, embarrass, alarm or annoy any other
                person.{" "}
              </li>
              <li>
                Be used to impersonate any person, or to misrepresent your
                identity or affiliation with any person.{" "}
              </li>
              <li>
                Give the impression that they emanate from us, if this is not
                the case.{" "}
              </li>
              <li>
                Advocate, promote or assist any unlawful act such as (by way of
                example only) copyright infringement or computer misuse.{" "}
              </li>
            </ol>
          </li>
        </ol>
      </li>
      <li>
        You warrant that any such contribution does comply with those standards,
        and you will be liable to us and indemnify us for any breach of that
        warranty. This means you will be responsible for any loss or damage we
        suffer as a result of your breach of warranty.{" "}
      </li>
      <li>
        Any content you upload to the Site will be considered non-confidential
        and non-proprietary, and we have the right to use, copy, distribute and
        disclose to third parties any such content for any purpose.{" "}
      </li>
      <li>
        We also have the right to disclose your identity to any third party who
        is claiming that any content posted or uploaded by you to the Site
        constitutes a violation of their intellectual property rights, or of
        their right to privacy.{" "}
      </li>
      <li>
        We do not exercise editorial control over all content posted by Members
        on the Site. We will not be responsible, or liable to any third party,
        for the content or accuracy of any content posted by you or any other
        Member of the Site.{" "}
      </li>
      <li>
        We reserve the right to remove any posting you make on the Site if, in
        our opinion, your post does not comply with the content standards set
        out above.{" "}
      </li>
      <li>
        The views expressed by other Members on the Site do not represent our
        views or values.{" "}
      </li>
      <li>
        If you are concerned that content on the Site infringes your rights,
        please contact us by the methods set out in these Terms, and we will
        investigate.
      </li>
    </ol>

    <h3>9. Viruses </h3>
    <ol>
      <li>
        We do not guarantee that the Site will be secure or free from bugs or
        viruses.{" "}
      </li>
      <li>
        You are responsible for configuring your information technology,
        computer programmes and platform in order to access the Site. You should
        use your own virus protection software.{" "}
      </li>
      <li>
        You must not introduce to the Site any viruses, trojans, worms, logic
        bombs or other material which is malicious or technologically harmful.
        You must not attempt to gain unauthorised access to the Site or any part
        of it, the server on which the Site is stored or any server, computer or
        database connected to the Site. You must not attack the Site via a
        denial-of-service attack or a distributed denial-of service attack. By
        breaching this provision, you would commit a criminal offence under the
        Computer Misuse Act 1990. We will report any such breach to the relevant
        law enforcement authorities and we will co-operate with those
        authorities by disclosing your identity to them. In the event of such a
        breach, your Membership and your right to use the Site will cease
        immediately.
      </li>
    </ol>

    <h3>10. Linking to the site</h3>
    <ol>
      <li>
        You may link to our home page and/or your Member page, provided you do
        so in a way that is fair and legal and does not damage our reputation or
        take advantage of it.{" "}
      </li>
      <li>
        You must not establish a link in such a way as to suggest any form of
        association, approval or endorsement on our part where none exists.{" "}
      </li>
      <li>
        You must not establish a link to the Site in any website that is not
        owned by you.{" "}
      </li>
      <li>
        The Site must not be framed on any other site, nor may you create a link
        to any part of the Site other than the home page [and/or your Member
        page].{" "}
      </li>
      <li>
        We reserve the right to withdraw linking permission without notice.{" "}
      </li>
      <li>
        The website in which you are linking must comply in all respects with
        the content standards set out above.{" "}
      </li>
      <li>
        If you wish to make any use of content on the Site other than that set
        out above, please contact georgina@theicon.org.uk
      </li>
    </ol>

    <h3>11. Third party links and resources on the site</h3>
    <ol>
      <li>
        Where the Site contains links to other sites and resources provided by
        third parties, these links are provided for your information only.{" "}
      </li>
      <li>We have no control over the contents of those sites or resources.</li>
    </ol>

    <h3>12. How we use your personal information </h3>
    <ol>
      <li>
        We only use your personal information in accordance our Privacy Policy.
        For details, please see our Privacy Policy. Please take the time to read
        this, as it includes important terms which apply to you.
      </li>
    </ol>

    <h3>13. If you are an individual and/or consumer </h3>
    <ol>
      <li>
        If you are registering as an individual, you may only make an Order from
        our site if you are at least 18 years old.{" "}
      </li>
      <li>
        If you are a consumer as defined in section 3 of The Consumer Protection
        (Distance Selling) Regulations 2000 you have certain legal rights.
        Nothing in these Terms affects your statutory rights.{" "}
      </li>
    </ol>

    <h3 id="clause14">14. Our right to vary these terms </h3>
    <ol>
      <li>
        We may revise these Terms from time to time and the revised terms will
        be available on the Site. Every time you place an Order, the Terms in
        force at that time will apply to the contract between you and us.
      </li>
    </ol>

    <h3>15. Your consumer right of return and refund</h3>
    <ol>
      <li>
        If you are a consumer, you may have a legal right to cancel an Order for
        any Services under the Consumer Protection (Distance Selling)
        Regulations 2000 and the Consumer Contracts (Information, Cancellation
        and Additional Payments) Regulations 2013 as amended or replaced and in
        force from time to time. The statutory cancellation period starts on the
        day after the contract is concluded and shall end on the expiry of the
        period of seven working days from the date the contract was concluded.
        Working days means that Saturdays, Sundays or public holidays are not
        included in this period.{" "}
      </li>
      <li>
        Please note that you cannot cancel an Order for a Service once we have
        started to perform the Service or where you have agreed to us starting
        to perform the Services before the end of the statutory period.
      </li>
      <li>
        To cancel a Contract, you must contact us in writing by sending an
        e-mail to <a href="mailto:hello@theicon.org.uk">hello@theicon.org.uk</a>{" "}
        or by sending a letter to icon Ltd., Unit 1, First Floor, Courtney
        House, 12 Dudley Street, Luton, LU2 0NT. You may wish to keep a copy of
        your cancellation notification for your own records. If you send us your
        cancellation notice by email or by post, then your cancellation is
        effective from the date you sent us the e-mail or posted the letter to
        us.{" "}
      </li>
      <li>
        If you cancel an Order in accordance with clause 15.1 and you have made
        any payment in advance, icon will refund these amounts to you.{" "}
      </li>
      <li>
        Please note that if you are a Member of icon in your professional
        capacity, and/or you place an Order for the purposes of your trade or
        profession, you are not classified under law as a consumer so the rights
        set out in this clause 15 do not apply to you.
      </li>
    </ol>

    <h3>16. How to pay</h3>
    <ol>
      <li>You can pay for Services using the methods indicated on the Site.</li>
      <li>
        Unless otherwise stated, payment for the Services and any applicable
        delivery charges is in advance.
      </li>
    </ol>

    <h3>17. Our liability </h3>
    <ol>
      <li>
        If we fail to comply with these Terms, we are responsible for loss or
        damage you (but not any other person) suffer that is a foreseeable and
        direct result of our breach of these Terms or our negligence, but we are
        not responsible for any loss or damage that is not foreseeable. Loss or
        damage is foreseeable if they were an obvious consequence of our breach
        or if they were contemplated by you and us at the time we entered into
        the contract.{" "}
      </li>
      <li>
        We will not be liable to you for any loss or damage, whether in
        contract, tort (including negligence), breach of statutory duty, or
        otherwise, even if foreseeable, arising under or in connection with: .
        use of, or inability to use, the Site; or I. use of or reliance on any
        content displayed on the Site.{" "}
      </li>
      <li>
        If you are a commercial user of this site, please note that in
        particular, we will not be liable for: . loss of profits, sales,
        business, or revenue;
        <ol type="I">
          <li>business interruption; </li>
          <li>loss of anticipated savings; </li>
          <li>
            loss of business opportunity, goodwill or reputation; or IV. any
            indirect or consequential loss or damage.{" "}
          </li>
        </ol>
      </li>
      <li>
        We will not be liable for any loss or damage caused by a virus,
        distributed denial-of-service attack, or other technologically harmful
        material that may infect your computer equipment, computer programs,
        data or other proprietary material due to your use of the Site or to
        your downloading of any content on it, or on any website linked to it.{" "}
      </li>
      <li>
        We assume no responsibility for the content of websites linked on the
        Site. Such links should not be interpreted as endorsement by us of those
        linked websites. We will not be liable for any loss or damage that may
        arise from your use of them.{" "}
      </li>
      <li>
        We accept no liability for any losses or claims arising from any failure
        to complete an Order. If the requested service is not available we may
        offer you substitute services of a similar description and standard.
        icon will contact you in order to agree any relevant changes to the
        Order. You may refuse the substitute services and receive a full refund
        in respect of that Order.{" "}
      </li>
      <li>
        Nothing in these Terms excludes or limits our liability for death or
        personal injury arising from our negligence, or our fraud or fraudulent
        misrepresentation, or any other liability that cannot be excluded or
        limited by English law.{" "}
      </li>
    </ol>

    <h3>18. Events outside our control </h3>
    <ol>
      <li>
        We will not be liable or responsible for any failure to perform, or
        delay in performance of, any of its obligations under these Terms or an
        Order that is caused by an Event Outside Our Control.{" "}
      </li>
      <li>
        An Event Outside Our Control means any act or event beyond our
        reasonable control, including without limitation non-performance by
        Suppliers, strikes, lock-outs or other industrial action by third
        parties, civil commotion, riot, invasion, terrorist attack or threat of
        terrorist attack, war (whether declared or not) or threat or preparation
        for war, fire, explosion, storm, flood, earthquake, subsidence, epidemic
        or other natural disaster, or failure of public or private
        telecommunications networks or impossibility of the use of railways,
        shipping, aircraft, motor transport or other means of public or private
        transport.{" "}
      </li>
      <li>
        If an Event Outside Our Control takes place that affects the performance
        of our obligations under an Order:{" "}
        <ol type="I">
          <li>
            We will contact you as soon as reasonably possible to notify you;
            and
          </li>
          <li>
            Our obligations under an Order will be suspended and the time for
            performance of our obligations will be extended for the duration of
            the Event Outside Our Control. Where the Event Outside Our Control
            affects a supplier’s delivery of items to you (or to us as the case
            may be), we will contact the supplier arrange a new delivery date
            after the Event Outside Our Control is over. Where the Event Outside
            Our Control affects our receipt or delivery of items, it will
            arrange a new delivery date with you after the Event Outside Our
            Control is over.
          </li>
        </ol>
      </li>
    </ol>

    <h3>19. Communications between us </h3>
    <ol>
      <li>
        When we refer, in these Terms, to “in writing”, this will include email
        and Orders agreed on the Site.{" "}
      </li>
      <li>
        If you wish to contact us in writing for any reason, you can send this
        to us by e-mail or by pre-paid post to: icon, Unit 1, First Floor,
        Courtney House, 12 Dudley Street, Luton, LU2 0NT.{" "}
      </li>
      <li>
        If we have to contact you or give you notice in writing, we will do so
        by e-mail or by pre-paid post to the address you provide to us in your
        Membership application.
      </li>
    </ol>

    <h3>20. Other important terms </h3>
    <ol>
      <li>
        We may transfer our rights and obligations under the Terms to another
        organisation, but this will not affect your rights or our obligations
        under these Terms. We will always notify you in writing or by posting on
        this webpage if this happens.{" "}
      </li>
      <li>
        You may only transfer your rights or your obligations under these Terms
        to another person if we agree in writing.{" "}
      </li>
      <li>
        This contract is between you and us. No other person shall have any
        rights to enforce any of its terms, whether under the Contracts (Rights
        of Third Parties Act) 1999 or otherwise.{" "}
      </li>
      <li>
        Each of the paragraphs of these Terms operates separately. If any court
        or relevant authority decides that any of them are unlawful or
        unenforceable, the remaining paragraphs will remain in full force and
        effect.{" "}
      </li>
      <li>
        If we fail to insist that you perform any of your obligations under
        these Terms, or if we do not enforce our rights against you, or if we
        delay in doing so, that will not mean that we have waived our rights
        against you and will not mean that you do not have to comply with those
        obligations. If we do waive a default by you, we will only do so in
        writing, and that will not mean that we will automatically waive any
        later default by you.{" "}
      </li>
      <li>
        These Terms are governed by English law. This means an Order through our
        site and any dispute or claim arising out of or in connection with it
        will be governed by English law. You and we both agree to that the
        courts of England and Wales will have non-exclusive jurisdiction.{" "}
      </li>
      <li>
        For the purposes of Regulation 9(1)(b) of the Electronic Commerce (EC
        Directive) Regulations 2002, we hereby confirm that we will not file a
        copy of our contract with you.
      </li>
    </ol>
  </div>
);
