import React, { Component } from "react";
import { connect } from "react-redux";
import renderHTML from "react-render-html";
import Iframe from "react-iframe";
import { Modal, ModalFooter, Row } from "reactstrap";
import { Button, Icon, Label, Segment, Image } from "semantic-ui-react";
import { Link } from "react-router-dom";
import TimeAgo from "react-timeago";
import ReactGA from "react-ga";
import { MegadraftEditor, editorStateFromRaw } from "megadraft";
import { buttonStyles } from "../../styles";
import StoryImg from "../../img/StoryImg.png";
import BlankProfilePicture from "../../img/blank-profile-picture.png";
import styled from "styled-components";
import InsightComments from "./InsightComments";
import { appConfig } from "../../appConfig";

// Css Component Styles
const HeaderImage = styled.img`
  width: 100%;
`;
const Meta = styled.div`
  display: grid;
  grid-template-rows: 1fr 1fr 1fr;
`;
const MetaItem = styled.div``;
const Content = styled.div`
  padding: 15px;
`;
const HeaderRow = styled.div`
  margin: 15px;
  display: grid;
  grid-template-columns: 3fr 1fr;
`;
const Heading = styled.p`
  font-family: "avenir next";
  font-size: 30px;
  font-weight: 300;
  position: absolute;
  bottom: 8px;
  left: 16px;
  text-shadow: 2px 2px 10px #000000;
  text-align: left;
`;
const Subheading = styled.p`
  font-family: "avenir next";
  font-size: 16px;
  font-weight: 200;
  text-shadow: 2px 2px 10px #000000;
  text-align: left;
`;
const Header = styled.div`
  position: relative;
  text-align: center;
  color: white;
`;
const ModalClose = styled.p`
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
  text-shadow: 2px 2px 10px #000000;
  text-align: left;
  :hover {
    color: grey;
  }
`;

class InsightModal extends Component {
  render() {
    let insightProps = this.props.currentInsight;
    let videoNineDigits;
    let videoTenDigits;
    let videoElevenDigits;
    let videoFinalUrl;
    if (
      insightProps &&
      insightProps.videoUrl &&
      insightProps.videoUrl.includes("youtube")
    ) {
      videoElevenDigits = insightProps.videoUrl.slice(-11);
      videoFinalUrl = `https://www.youtube.com/embed/${videoElevenDigits}`;
    }
    if (
      insightProps &&
      insightProps.videoUrl &&
      insightProps.videoUrl.includes("vimeo")
    ) {
      videoNineDigits = insightProps.videoUrl.slice(-9);
      videoFinalUrl = `https://player.vimeo.com/video/${videoNineDigits}`;
    }
    if (
      insightProps &&
      insightProps.videoUrl &&
      insightProps.videoUrl.includes("wistia")
    ) {
      let crop = insightProps.videoUrl.lastIndexOf(
        "fast.wistia.com/embed/iframe/"
      );
      videoTenDigits = insightProps.videoUrl.slice(crop + 29, crop + 39);
      videoFinalUrl = `https://fast.wistia.com/embed/iframe/${videoTenDigits}`;
    }

    return (
      <div>
        <Modal
          isOpen={this.props.modal}
          toggle={this.props.toggle}
          className={this.props.className}
          size="lg"
        >
          <div style={{ padding: 0 }}>
            <Header>
              <HeaderImage
                size="large"
                alt="Energy insights feature"
                src={(insightProps && insightProps.featuredImage) || StoryImg}
              />
              <Heading
                style={{
                  margin: 0,
                  padding: 10,
                  borderRadius: 7,
                  width: "96%",
                  background: "rgba(0,0,0,0.7)"
                }}
              >
                {insightProps && insightProps.title}
                <Subheading style={{ margin: 0, padding: 0 }}>
                  {insightProps && insightProps.subTitle}
                </Subheading>
              </Heading>
              <ModalClose onClick={this.props.toggle}>X</ModalClose>
            </Header>
            <HeaderRow>
              <Meta>
                <div>
                  <Image
                    src={
                      (insightProps && insightProps.authorProfilePhoto) ||
                      BlankProfilePicture
                    }
                    circular
                    alt="insight author pic"
                    avatar
                  />
                  <span style={styles.authorNameStyle}>
                    {insightProps && insightProps.authorDisplayName}
                  </span>
                </div>

                <div>
                  <TimeAgo date={insightProps && insightProps.time} />
                </div>

                <div>
                  <Label>
                    <Icon name="hashtag" />
                    {insightProps && insightProps.category}
                  </Label>
                </div>

                <div style={{ marginTop: 15 }}>
                  {insightProps &&
                    insightProps.tags &&
                    insightProps.tags.map((tag, i) => {
                      return (
                        typeof tag === "string" && (
                          <Label
                            color={
                              this.props.tags && this.props.tags.includes(tag)
                                ? "teal"
                                : ""
                            }
                            style={{ marginRight: 5, marginBottom: 5 }}
                            as="a"
                            tag
                            key={i}
                            content={tag}
                          />
                        )
                      );
                    })}
                </div>
              </Meta>

              <MetaItem>
                {insightProps && insightProps.fileName ? (
                  <Segment>
                    <h4>Download File:</h4>
                    <Label color="blue">
                      <Icon name="download" />
                      <ReactGA.OutboundLink
                        eventLabel={insightProps.fileName || "no file"}
                        to={insightProps.fileLocation}
                        target="_blank"
                      >
                        {" " + insightProps.fileName + " "}
                      </ReactGA.OutboundLink>
                    </Label>
                  </Segment>
                ) : (
                  ""
                )}
              </MetaItem>
            </HeaderRow>
            <Content>
              <div>
                {insightProps && insightProps.contentJSON ? (
                  <div>
                    {editorStateFromRaw(JSON.parse(insightProps.contentJSON))
                      .getCurrentContent()
                      .getPlainText().length > 1 ? (
                      <MegadraftEditor
                        editorState={editorStateFromRaw(
                          JSON.parse(insightProps.contentJSON)
                        )}
                        readOnly={true}
                      />
                    ) : (
                      <div>
                        {renderHTML(`<div>${insightProps.content}</div>`)}
                      </div>
                    )}
                  </div>
                ) : (
                  <div>
                    {insightProps && insightProps.content ? (
                      <div>
                        {renderHTML(`<div>${insightProps.content}</div>`)}
                      </div>
                    ) : (
                      <div />
                    )}
                  </div>
                )}
              </div>
              <div>
                {insightProps && insightProps.videoUrl ? (
                  <Row>
                    <Iframe
                      url={videoFinalUrl}
                      width="100%"
                      height="450px"
                      id="myId"
                      className="myClassname"
                      display="initial"
                      position="relative"
                      allowFullScreen
                      scrolling="no"
                    />
                  </Row>
                ) : (
                  ""
                )}
              </div>
            </Content>
          </div>
          <div>
            {/* renders insight comments only if its toggled in appConfig.js */}
            {appConfig.insightComments && (
              <InsightComments
                insightKey={this.props.currentInsight.key}
                insightTitle={this.props.currentInsight.title}
              />
            )}
          </div>
          <ModalFooter>
            <Button
              color="yellow"
              style={buttonStyles.primaryButton}
              as={Link}
              target="_blank"
              to={"/InsightPage/" + insightProps.key}
            >
              GO TO INSIGHT PAGE
            </Button>
            <Button style={{ marginLeft: 5 }} onClick={this.props.toggle}>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

const styles = {
  authorNameStyle: {
    fontWeight: "bold"
  },
  iframeStyle: {
    padding: 0
  }
};

const mapStateToProps = state => ({
  currentInsight: state.insights.currentInsightProps
});

export default connect(mapStateToProps)(InsightModal);
