import { auth } from "./firebase";

// Sign Up
export const doCreateUserWithEmailAndPassword = (email, password) =>
  auth.createUserWithEmailAndPassword(email, password);

// Sign In

// Moving into login component for easier update state if error
//
// export const doSignInWithEmailAndPassword = (email, password) =>
//   auth.signInWithEmailAndPassword(email, password).catch(function(error) {
//     if (error.code === "auth/user-not-found") {
//       window.alert(
//         "Sorry this email is not registered, please visit the register link to sign up. "
//       );
//     } else if (error.code === "auth/wrong-password") {
//       window.alert(
//         "Sorry password is incorrect. Please try again or use forgot password button to receive password reset link via email. "
//       );
//     } else {
//       window.alert(
//         "Sorry you hit an error : " + error.message + " Please try again. "
//       );
//     }
//   });

// Sign out
export const doSignOut = () => {
  auth.signOut();
};

// Password Reset
export const doPasswordReset = email => auth.sendPasswordResetEmail(email);

// Password Change
export const doPasswordUpdate = password =>
  auth.currentUser.updatePassword(password);
