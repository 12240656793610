// A post component
import React, { Component } from "react";
import { connect } from "react-redux";
import { Col, Row } from "reactstrap";
import { Icon, Feed, Segment } from "semantic-ui-react";
import TimeAgo from "react-timeago";
import { editorStateFromRaw } from "megadraft";
import "../MegaDraft/megadraft.css";
import EditPostModal from "./EditPostModal";
import ReplyPostModal from "./ReplyPost";
import { selectPost, updateLike } from "../../actions/Posts";
import emptyUserImg from "../../img/blank-profile-picture.png";

import { Link } from "react-router-dom";
import { selectUserAction } from "../../actions/users";

// WIP
import Editor from "draft-js-plugins-editor";
import createLinkifyPlugin from "draft-js-linkify-plugin";
import "draft-js-linkify-plugin/lib/plugin.css";
const linkifyPlugin = createLinkifyPlugin();

class Post extends Component {
  constructor() {
    super();
    this.state = {
      userName: "",
      profilePhoto: "",
      editPost: false,
      selectedPostState: {},
      editorState: editorStateFromRaw(null)
    };
  }

  onChange = editorState => {
    // Handle the updating of the editor state as the user types
    this.setState({ editorState });
  };

  handleSelectClick = key => {
    // Dispatch an action with the selected post
    selectPost(key);
  };

  handleLikeClick = (key, uid) => {
    // Dispatch and action to update the database with the like state of the post
    updateLike(key, uid);
  };

  selectUser = uid => {
    selectUserAction(uid);
  };

  render() {
    const { props } = this;
    // Define message styling
    let messageStyle;
    let bubbleStyle;
    // If the message is the same as the user ID
    if (props.userId === props.postBody.uid) {
      // my message
      messageStyle = {
        backgroundColor: "rgba(59, 205, 59, .5)",
        boxShadow: `1px 3px 10px #9E9E9E`,
        borderRadius: 7,
        padding: "1rem",
        overflowWrap: "break-word",
        wordWrap: "break-word",
        wordBreak: "break-word",
        hyphens: "auto"
      };
      bubbleStyle = {
        marginBottom: 10
      };
      // If the message has a different user ID
    } else if (props.userId !== props.postBody.uid) {
      // other message
      messageStyle = {
        backgroundColor: "rgba(255, 255, 255, 0.5)",
        boxShadow: `1px 3px 10px #9E9E9E`,
        borderRadius: 7,
        padding: "1rem"
      };
      bubbleStyle = {
        marginBottom: 10
      };
    }
    const replyStyle = {
      width: "90%",
      overflowWrap: "break-word",
      wordWrap: "break-word",
      wordBreak: "break-word",
      hyphens: "auto"
    };

    return (
      <div
        style={
          this.props.currentUser.uid === props.postBody.uid
            ? { margin: "15px 0 15px 15%" }
            : { margin: "15px 15% 15px 0" }
        }
      >
        <Col>
          <Row>
            <Col>
              <Feed style={bubbleStyle}>
                <Feed.Event>
                  <Feed.Label
                    image={props.postBody.profilePhoto || emptyUserImg}
                  />
                  <Feed.Content>
                    <Feed.Summary>
                      {props.postBody.displayName ? (
                        <Link
                          onClick={() => this.selectUser(props.postBody.uid)}
                          // to={`/Profile/${props.postBody.displayName}`}
                          to=""
                        >
                          {props.postBody.displayName}
                        </Link>
                      ) : (
                        <div>
                          {props.postBody.firstName} {props.postBody.lastName}
                        </div>
                      )}
                      <Feed.Date>
                        <TimeAgo
                          date={props.postBody.time}
                          style={{ textAlign: "right" }}
                        />
                      </Feed.Date>
                    </Feed.Summary>
                    <Row>
                      {this.props.userId === this.props.postBody.uid ? (
                        <a
                          onClick={() =>
                            this.handleSelectClick(props.postBody.key)
                          }
                        >
                          <EditPostModal
                            buttonLabel={<Icon name="edit" size="large" />}
                            item={props.postBody}
                          />
                        </a>
                      ) : (
                        ""
                      )}
                      <a
                        onClick={() =>
                          this.handleSelectClick(props.postBody.key)
                        }
                      >
                        <ReplyPostModal
                          buttonLabel={<Icon name="reply" size="large" />}
                          item={props.postBody}
                        />
                      </a>
                    </Row>
                    <Feed.Extra text style={messageStyle}>
                      {props.postBody.originalPost ? (
                        <Segment
                          style={{
                            padding: 0,
                            borderRadius: 7,
                            backgroundColor: "rgba(240, 240, 240, 0.2)"
                          }}
                        >
                          <Row>
                            <div
                              style={{
                                width: 5,
                                backgroundImage:
                                  "linear-gradient(to bottom, #FFDF00, #F63800)",
                                borderTopLeftRadius: 7,
                                borderBottomLeftRadius: 7,
                                marginLeft: 15
                              }}
                            />
                            <Col style={{ padding: 5 }}>
                              <Feed style={replyStyle}>
                                <Feed.Event>
                                  <Feed.Label
                                    image={
                                      props.postBody.originalPost
                                        .profilePhoto || emptyUserImg
                                    }
                                  />
                                  <Feed.Content>
                                    <Feed.Summary>
                                      {props.postBody.originalPost
                                        .displayName ? (
                                        <div>
                                          <Link
                                            onClick={() =>
                                              this.selectUser(
                                                props.postBody.originalPost.uid
                                              )
                                            }
                                            // to={`/Profile/${
                                            //   props.postBody.originalPost.displayName
                                            // }`}
                                            to=""
                                          >
                                            {
                                              props.postBody.originalPost
                                                .displayName
                                            }
                                          </Link>
                                        </div>
                                      ) : (
                                        <div>
                                          {
                                            props.postBody.originalPost
                                              .firstName
                                          }{" "}
                                          {props.postBody.originalPost.lastName}
                                        </div>
                                      )}
                                    </Feed.Summary>
                                    <Feed.Extra text>
                                      <Editor
                                        editorState={editorStateFromRaw(
                                          JSON.parse(
                                            props.postBody.originalPost.message
                                          )
                                        )}
                                        readOnly={true}
                                        plugins={[linkifyPlugin]}
                                        style={{ color: "white" }}
                                      />
                                    </Feed.Extra>
                                  </Feed.Content>
                                </Feed.Event>
                              </Feed>
                            </Col>
                          </Row>
                        </Segment>
                      ) : (
                        ""
                      )}
                      {/* edit post hide show here */}
                      {!this.state.editPost && (
                        <Editor
                          editorState={editorStateFromRaw(
                            JSON.parse(props.postBody.message)
                          )}
                          readOnly={true}
                          plugins={[linkifyPlugin]}
                        />
                      )}
                      {this.state.editPost && (
                        <Segment>
                          <Editor
                            editorState={this.state.editorState}
                            onChange={this.onChange}
                            plugins={[linkifyPlugin]}
                          />
                        </Segment>
                      )}
                    </Feed.Extra>
                    <Feed.Meta>
                      <Feed.Like
                        onClick={() =>
                          this.handleLikeClick(
                            props.postBody.key,
                            this.props.userId
                          )
                        }
                      >
                        <Icon name="like" />
                        {props.postBody.likeCount} Likes
                      </Feed.Like>
                      {props.postBody.updateTime === undefined ? (
                        ""
                      ) : (
                        <div style={{ textAlign: "right" }}>
                          Edited: <TimeAgo date={props.postBody.updateTime} />
                        </div>
                      )}
                    </Feed.Meta>
                  </Feed.Content>
                </Feed.Event>
              </Feed>
            </Col>
          </Row>
        </Col>
        {/* {this.props.userId === this.props.postBody.uid ? (
          <div
            style={{
              content: "",
              position: "relative",
              left: "85%",
              bottom: 0,
              width: 0,
              height: 0,
              border: "26px solid transparent",
              borderTopColor: "rgba(59, 205, 59, .5)",
              borderRight: 0,
              borderBottom: 0,
              marginLeft: 0,
              marginBottom: 0
            }}
          />
        ) : (
          <div
            style={{
              content: "",
              position: "relative",
              left: "7%",
              bottom: 0,
              width: 0,
              height: 0,
              border: "26px solid transparent",
              borderTopColor: "rgba(255, 255, 255, .5)",
              borderLeft: 0,
              borderBottom: 0,
              marginLeft: 0,
              marginBottom: 0
            }}
          />
        )} */}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  userId: state.sessionState.authUser.uid,
  PostItem: state.postState.CurrentPostProps,
  currentUser: state.user.currentUserProps
});

export default connect(mapStateToProps)(Post);
