import React, { Component } from "react";
import { connect } from "react-redux";
import { Col, Row, Container } from "reactstrap";
// import { Sidebar } from "./Sidebar";
import { getCategories, getIndustries } from "../../actions/configActions";
import CategoryUpload from "../Categories/CategoryUpload";
import ParentCategoryUpload from "../Categories/ParentCategoryUpload";

class InsightManager extends Component {
  constructor() {
    super();
    // create a state that can handle the change in the form input values
    this.state = {
      categoryName: "",
      categorySlug: "",
      categoryVisable: false,
      errors: {},
      activity: "",
      options: [
        { key: "1", text: "Active", value: true },
        { key: "2", text: "Inactive", value: false }
      ]
    };
  }

  componentDidMount() {
    // get the list of categories from where they are stored in firebase, and show in the list
    getCategories();
    getIndustries();
  }

  render() {
    return (
      <div className="animated bounceInLeft" style={{ marginTop: 10 }}>
        <Container fluid>
          <Row>
            <Col md="12" sm="12">
              <Row style={{ marginBottom: 20 }}>
                <Col sm="6">
                  <ParentCategoryUpload />
                </Col>
                <Col sm="6">
                  <CategoryUpload />
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  categories: state.appConfig.categories
});

export default connect(mapStateToProps)(InsightManager);
