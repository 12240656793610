import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Form,
  Button,
  Input,
  Select,
  List,
  Icon,
  TextArea
} from "semantic-ui-react";
import styled from "styled-components";

// Actions
import {
  createSection,
  removeSection,
  setActiveSectionCourse
} from "../../../../actions/university";
// import { object } from "firebase-functions/lib/providers/storage";

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-top: 25px;
`;

const GridItem = styled.div`
  padding: 10px;
  test-align: center;
  margin: 25px;
  border-style: solid;
  border-width: 1px 1px;
  border-radius: 7px;
  border-color: rgba(0, 0, 0, 0.3);
  background-color: white;
  box-shadow: 10px 10px 33px -12px rgba(0, 0, 0, 0.75);
`;

class CourseSections extends Component {
  state = {
    activeCourseSectionKey: "",
    sectionTitle: "",
    sectionDescription: "",
    courseSectionErrors: false
  };

  handleSectionSubmit = e => {
    if (!this.state.activeCourseSectionKey) {
      return this.setState({ courseSectionErrors: true });
    }
    e.preventDefault();
    createSection(this.state.sectionTitle, this.props.activeCourseSection.key);
    this.setState({
      activeCourseSectionKey: "",
      sectionTitle: "",
      courseSectionErrors: false
    });
  };

  handleInputChange = (e, data) => {
    this.setState({ [data.name]: data.value });
  };

  handleSectionCourse = (e, data) => {
    this.setState({ activeCourseSectionKey: data.value });
    setActiveSectionCourse(data.value);
  };

  handleRemoveSection = sectionKey => {
    const courseKey = this.props.activeCourseSection.key;
    removeSection(courseKey, sectionKey);
  };

  render() {
    const allCourses = [];

    this.props.courses &&
      this.props.courses.map((course, i) => {
        return allCourses.push({
          key: i,
          text: course.courseTitle,
          value: course.key
        });
      });
    return (
      <Grid>
        <GridItem>
          <Form onSubmit={this.handleSectionSubmit}>
            <h2>Create a course section</h2>
            <Form.Field
              control={Select}
              label="Select A Course"
              name="sectionCourse"
              options={allCourses}
              placeholder="Select a course ..."
              onChange={this.handleSectionCourse}
              value={this.state.activeCourseSectionKey}
              required
              error={
                this.state.courseSectionErrors &&
                !this.state.activeCourseSectionKey
                  ? true
                  : false
              }
            />
            <Form.Field
              control={Input}
              label="title"
              name="sectionTitle"
              placeholder="Enter a title ..."
              onChange={this.handleInputChange}
              value={this.state.sectionTitle}
              required
            />
            <Form.Field
              control={TextArea}
              label="description"
              name="sectionDescription"
              placeholder="Enter a section description ..."
              onChange={this.handleInputChange}
              value={this.state.sectionDescription}
              // required
            />
            <Button type="submit">Submit</Button>
          </Form>
        </GridItem>
        <GridItem>
          <h3>Sections</h3>
          {this.props.activeCourseSection ? (
            <div key={this.props.activeCourseSection.key}>
              <h5 style={{ color: "green", marginBottom: 20 }}>
                {this.props.activeCourseSection.courseTitle}
              </h5>
              {this.props.activeCourseSection.sections ? (
                <div>
                  <List divided relaxed>
                    {Object.values(this.props.activeCourseSection.sections).map(
                      (section, idx) => {
                        return (
                          <h5 key={idx}>
                            {section.sectionTitle}
                            <Icon
                              name="delete"
                              style={{
                                color: "red",
                                cursor: "pointer",
                                marginLeft: 15
                              }}
                              onClick={() =>
                                this.handleRemoveSection(section.key)
                              }
                            />
                            <hr />
                          </h5>
                        );
                      }
                    )}
                  </List>
                </div>
              ) : (
                <div>No Sections ...</div>
              )}
            </div>
          ) : (
            <div>Select a course</div>
          )}
        </GridItem>
      </Grid>
    );
  }
}

const mapStateToProps = state => ({
  courses: state.university.courses,
  activeCourseSection: state.university.activeCourseSection
});

export default connect(mapStateToProps)(CourseSections);
