import React, { Component } from "react";
import { connect } from "react-redux";
import { Container, Col, Row } from "reactstrap";
import { Redirect } from "react-router-dom";
import SubscriberLegalRegisterList from "./SubscriberLegalRegisterList";

class LegalRegisterUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {}
    };
  }
  render() {
    let stuff = Object.keys(this.props.legalSubscriber);
    if (stuff.length < 2) {
      return <Redirect to="/LegalRegisterDash" />;
    }
    return (
      <Container>
        <p style={{ textAlign: "left", paddingTop: 20 }} />
        <Row>
          <Col>
            <SubscriberLegalRegisterList />
          </Col>
        </Row>
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  authUser: state.sessionState.authUser,
  currentUser: state.user.currentUserProps,
  subscribers: state.subscriber.subscribers,
  legalSubscriber: state.legalItems.currentLegalSubscriber
});

export default connect(mapStateToProps)(LegalRegisterUser);
