import React, { Component } from "react";
import { Container, Col, Row } from "reactstrap";
// import ScrollAnimation from "react-animate-on-scroll";
import { Divider } from "semantic-ui-react";
// import ReactFitText from "react-fittext";
import ScrollAnimation from "react-animate-on-scroll";

// Styling
import "./Header.css";
import Logo_Light from "../../img/Logo_A.png";
import COLogo from "../../img/CogenerationChannel LOGO.png";
import PLMark from "../../img/PlanetMark.png";
import TELogo from "../../img/TE.png";
import CPD from "../../img/CPD.png";

class Header extends Component {
  render() {
    return (
      <Container>
        <header className="App-header">
          {/* <div className="row Title"> */}
          <Row
            style={{ padding: "2.5rem", paddingTop: "4.3rem", color: "grey" }}
          >
            <Col
              style={{
                flexDirection: "column",
                alignSelf: "center"
              }}
            >
              <div style={styles.titleStyle}>
                <ScrollAnimation animateIn="bounceInLeft" offset={1}>
                  <Divider horizontal>
                    <h4>Knowledge Partners</h4>
                  </Divider>
                  <Row>
                    <Col>
                      <a
                        target="blank"
                        href="https://www.theplanetmark.com/"
                        data-cy="planetmark-a-Header"
                      >
                        <img src={PLMark} alt="PLMark" style={{ height: 75 }} />
                      </a>
                    </Col>
                    <Col style={{ alignSelf: "center" }}>
                      <a
                        target="blank"
                        href="https://thecpdaccreditation.group/"
                      >
                        <img
                          src={CPD}
                          alt="TELogo"
                          style={{ height: 50, padding: 5 }}
                        />
                      </a>
                    </Col>
                  </Row>
                </ScrollAnimation>
              </div>
            </Col>
            <Col>
              <div style={styles.titleStyle}>
                <div>
                  <ScrollAnimation
                    animateIn="bounceInDown"
                    // offset={1}
                  >
                    <img
                      src={Logo_Light}
                      alt="logo-light"
                      // style={{ marginLeft: 15 }}
                      style={{
                        height: 300
                      }}
                    />
                  </ScrollAnimation>
                </div>

                <ScrollAnimation
                  animateIn="bounceInDown"
                  style={{ marginTop: 50 }}
                >
                  <h4 style={{ lineHeight: 0 }}>Information. Compliance.</h4>
                  <h4 style={{ lineHeight: 0 }}>Operational Efficiency. </h4>
                  <h4 style={{ lineHeight: 0 }}>Networking.</h4>{" "}
                </ScrollAnimation>
              </div>
              {/* <div className="row" /> */}
            </Col>
            <Col
              style={{
                flexDirection: "column",
                alignSelf: "center"
                // backgroundColor: "blue"
              }}
            >
              <div style={styles.titleStyle}>
                <ScrollAnimation animateIn="bounceInRight" offset={1}>
                  <Divider horizontal>
                    <h4>Media Partners</h4>
                  </Divider>
                  <Row>
                    <Col>
                      <a
                        target="blank"
                        href="https://www.cogenerationchannel.com/en/"
                      >
                        <img
                          src={COLogo}
                          alt="COLogo"
                          // style={{ marginLeft: 15 }}
                          style={{ height: 50, padding: 5 }}
                          // onMouseEnter={hoverStyle}
                        />
                      </a>
                    </Col>
                    <Col style={{ alignSelf: "center" }}>
                      <a target="blank" href="https://theenergyst.com/">
                        <img
                          src={TELogo}
                          alt="TELogo"
                          // style={{ marginLeft: 15 }}
                          style={{ height: 29, padding: 5 }}
                        />
                      </a>
                    </Col>
                  </Row>
                </ScrollAnimation>
              </div>
            </Col>
          </Row>

          {/* </div> */}
        </header>
      </Container>
    );
  }
}

const styles = {
  titleStyle: {
    color: "#404040"
  },
  rowStyle: {
    // margin: 10
  },
  hoverStyle: {
    backgroundColor: "blue"
  }
};

export default Header;
