// CONFIG
export const SAVE_DASHBOARD_MESSAGE = "SAVE_DASHBOARD_MESSAGE";
export const CHANGE_ACTIVE_ITEM = "CHANGE_ACTIVE_ITEM'";

// FORUM
export const SET_CURRENT_ALL_POSTS = "SET_CURRENT_ALL_POSTS";
export const CURRENTLY_SELECTED_CHATROOM = "CURRENTLY_SELECTED_CHATROOM";
export const SET_CURRENT_CHATROOM_POSTS = "SET_CURRENT_CHATROOM_POSTS";
export const SET_CURRENT_ALL_CHATROOMS = "SET_CURRENT_ALL_CHATROOMS";
export const SELECT_POST = "SELECT_POST";
export const DELETE_POST = "DELETE_POST";
export const UPDATE_POST = "UPDATE_POST";
export const GET_ALL_POST_REPLIES = "GET_ALL_POST_REPLIES";
export const SET_ALL_DISPLAY_NAMES = "SET_ALL_DISPLAY_NAMES";
export const UPDATE_REPLIES = "UPDATE_REPLIES";
export const SET_LATEST_POSTS = "SET_LATEST_POSTS";

// INSIGHT COMMENTS
export const SET_INSIGHT_COMMENTS = "SET_ALL_INSIGHT_COMMENTS";
export const SELECT_INSIGHT_COMMENT = "SELECT_INSIGHT_COMMENT";

// LEGAL REGISTER
export const DELETE_LEGALITEM = "DELETE_LEGALITEM";

// INSIGHT CATEGORIES
export const SET_CATEGORIES = "SET_CATEGORIES";
export const SET_PARENT_CATEGORIES = "SET_PARENT_CATEGORIES";

//INDUSTRIES
export const SET_INDUSTRIES = "SET_INDUSTRIES";

//LEGAL CATEGORIES
export const SET_LEGAL_CATEGORIES = "SET_LEGAL_CATEGORIES";

// REGULATORS
export const SET_LEGAL_REGULATORS = "SET_LEGAL_REGULATORS";

// SUPPLIERS
export const SET_SUPPLIER = "SET_SUPPLIER";

// COMPANY TYPES
export const SET_COMPANY_TYPE = "SET_COMPANY_TYPE";

// University
export const SET_SKILL_LEVELS = "SET_SKILL_LEVELS";
export const SET_COURSES = "SET_COURSES";
export const SET_INSTRUCTORS = "SET_INSTRUCTORS";
export const SET_ACTIVE_COURSE_SECTION = "SET_ACTIVE_COURSE_SECTION";
export const SET_ACTIVE_LESSON_COURSE = "SET_ACTIVE_LESSON_COURSE";
