import React from "react";
import { Icon, Popup } from "semantic-ui-react";

export const complianceOptions = [
  {
    text: "Compliant",
    value: "Compliant",
    image: <Icon color="green" name="check" size="large" />
  },
  {
    text: "In Progress",
    value: "In Progress",
    image: <Icon color="yellow" name="warning sign" size="large" />
  },
  {
    text: "Not Compliant",
    value: "Not Compliant",
    image: <Icon color="red" name="x" size="large" />
  }
];

export const renderIconSymbol = complianceCheck => {
  if (complianceCheck === "Compliant") {
    return (
      <Popup
        trigger={<Icon color="green" name="check" size="large" />}
        content="Compliant"
      />
    );
  } else if (complianceCheck === "In Progress") {
    return (
      <Popup
        trigger={<Icon color="yellow" name="warning sign" size="large" />}
        content="In Progress"
      />
    );
  } else {
    return (
      <Popup
        trigger={<Icon color="red" name="x" size="large" />}
        content="Non Compliant"
      />
    );
  }
};

export const regulators_unsorted = [
  <option key="1" value="Ofgem">
    Ofgem
  </option>,
  <option key="2" value="Ofwat">
    Ofwat
  </option>,
  <option key="3" value="Environment Agency">
    Environment Agency
  </option>,
  <option key="4" value="Scottish Environmental Protection Agency">
    Scottish Environmental Protection Agency
  </option>,
  <option key="5" value="Natural England">
    Natural England
  </option>,
  <option key="6" value="Scottish Natural Heritage">
    Scottish Natural Heritage
  </option>,
  <option key="7" value="Natural Resources Wales">
    Natural Resources Wales
  </option>,
  <option key="8" value="Northern Ireland Environment Agency">
    Northern Ireland Environment Agency
  </option>,
  <option key="9" value="Committee on Climate Change">
    Committee on Climate Change
  </option>,
  <option
    key="10"
    value="Department for Environment, Food and Rural Affairs (DEFRA)"
  >
    Department for Environment, Food and Rural Affairs (DEFRA)
  </option>,
  <option
    key="11"
    value="Department of Agriculture, Environment and Rural Affairs Northern Ireland (DAERA-NI)"
  >
    Department of Agriculture, Environment and Rural Affairs Northern Ireland
    (DAERA-NI)
  </option>,
  <option key="12" value="Welsh Assembly Government">
    Welsh Assembly Government
  </option>,
  <option key="13" value="Scottish Government">
    Scottish Government
  </option>,
  <option key="14" value="Marine Management Organisation">
    Marine Management Organisation
  </option>,
  <option key="15" value="Joint Nature Conservation Committee">
    Joint Nature Conservation Committee
  </option>,
  <option key="16" value="Her Majesty’s Police (The Police)">
    Her Majesty’s Police (The Police)
  </option>,
  <option key="17" value="Her Majesty’s Revenue and Customs (HMRC)">
    Her Majesty’s Revenue and Customs (HMRC)
  </option>,
  <option key="18" value="Health and Safety Executive (HSE)">
    Health and Safety Executive (HSE)
  </option>,
  <option key="19" value="National Wildlife Crime Unit (NWCU)">
    National Wildlife Crime Unit (NWCU)
  </option>,
  <option key="20" value="Forestry Commission">
    Forestry Commission
  </option>,
  <option key="21" value="Drinking Water Inspectorate">
    Drinking Water Inspectorate
  </option>,
  <option key="22" value="Drinking Water Quality Regulator (Scotland)">
    Drinking Water Quality Regulator (Scotland)
  </option>,
  <option key="23" value="Drinking Water Inspectorate (Northern Ireland)">
    Drinking Water Inspectorate (Northern Ireland)
  </option>,
  <option key="24" value="Building Control">
    Building Control
  </option>,
  <option
    key="25"
    value="Weights and Measures Authorities (Trading Standards function in Local Authorities)"
  >
    Weights and Measures Authorities (Trading Standards function in Local
    Authorities)
  </option>,
  <option
    key="26"
    value="Department of Enterprise, Trade and Investment Northern Ireland (DETI)"
  >
    Department of Enterprise, Trade and Investment Northern Ireland (DETI)
  </option>,
  <option key="27" value="Financial Conduct Authority (FCA)">
    Financial Conduct Authority (FCA)
  </option>,
  <option key="28" value="Prudential Regulation Authority (PRA)">
    Prudential Regulation Authority (PRA)
  </option>,
  <option key="29" value="Non-Government Organisations (e.g. RSPB, WWF, RSPCA)">
    Non-Government Organisations (e.g. RSPB, WWF, RSPCA)
  </option>,
  <option key="30" value="Local Authorities">
    Local Authorities
  </option>,
  <option key="" value="">
    Choose a Regulator ...
  </option>,
  <option key="31" value="Water Authority">
    Water Authority
  </option>
];

export const regulators = regulators_unsorted.sort(function(a, b) {
  if (a.props.value < b.props.value) return -1;
  else if (a.props.value > b.props.value) return 1;
  return 0;
});

export const newRegulators = [
  { key: "1", text: "Ofgem", value: "Ofgem" },
  { key: "2", text: "Commercial", value: "Commercial" },
  { key: "3", text: "Environment Agency", value: "Environment Agency" },
  {
    key: "4",
    text: "Scottish Environmental Protection Agency",
    value: "Scottish Environmental Protection Agency"
  },
  {
    key: "5",
    text: "Scottish Natural Heritage",
    value: "Scottish Natural Heritage"
  },
  {
    key: "6",
    text: "Natural Resources Wales",
    value: "Natural Resources Wales"
  },
  {
    key: "7",
    text: "Northern Ireland Environment Agency",
    value: "Northern Ireland Environment Agency"
  },
  {
    key: "8",
    text: "Committee on Climate Change",
    value: "Committee on Climate Change"
  },
  {
    key: "9",
    text: "Department for Environment, Food and Rural Affairs (DEFRA)",
    value: "Department for Environment, Food and Rural Affairs (DEFRA)"
  },
  {
    key: "10",
    text: "Welsh Assembly Government",
    value: "Welsh Assembly Government"
  },
  { key: "11", text: "Scottish Government", value: "Scottish Government" },
  {
    key: "12",
    text: "Marine Management Organisation",
    value: "Marine Management Organisation"
  },
  {
    key: "13",
    text: "Joint Nature Conservation Committee",
    value: "Joint Nature Conservation Committee"
  },
  {
    key: "14",
    text: "Her Majesty’s Police (The Police)",
    value: "Her Majesty’s Police (The Police)"
  },
  {
    key: "15",
    text: "Health and Safety Executive (HSE)",
    value: "Health and Safety Executive (HSE)"
  },
  {
    key: "16",
    text: "National Wildlife Crime Unit (NWCU",
    value: "National Wildlife Crime Unit (NWCU"
  },
  { key: "17", text: "Forestry Commission", value: "Forestry Commission" },
  {
    key: "18",
    text: "Drinking Water Inspectorate",
    value: "Drinking Water Inspectorate"
  },
  { key: "19", text: "Building Control", value: "Building Control" },
  {
    key: "20",
    text:
      "Weights and Measures Authorities (Trading Standards function in Local Authorities)",
    value:
      "Weights and Measures Authorities (Trading Standards function in Local Authorities)"
  },
  {
    key: "21",
    text:
      "Department of Enterprise, Trade and Investment Northern Ireland (DETI)",
    value:
      "Department of Enterprise, Trade and Investment Northern Ireland (DETI)"
  },
  {
    key: "22",
    text: "Financial Conduct Authority (FCA)",
    value: "Financial Conduct Authority (FCA)"
  },
  {
    key: "23",
    text: "Prudential Regulation Authority (PRA)",
    value: "Prudential Regulation Authority (PRA)"
  },
  {
    key: "24",
    text: "Non-Government Organisations (e.g. RSPB, WWF, RSPCA)",
    value: "Non-Government Organisations (e.g. RSPB, WWF, RSPCA)"
  },
  { key: "25", text: "Local Authorities", value: "Local Authorities" },
  { key: "26", text: "Water Authority", value: "Water Authority" },
  { key: "27", text: "Higher Education", value: "Higher Education" },
  { key: "28", text: "Land Management", value: "Land Management" },
  { key: "29", text: "SME", value: "SME" },
  { key: "30", text: "Residential", value: "Residential" }
].sort((a, b) => {
  if (a.value < b.value) return -1;
  else if (a.value > b.value) return 1;
  return 0;
});

export const industryVerticles_unsorted = [
  <option key="1" value="Industrial/Heavy Manufacturing">
    Industrial/Heavy Manufacturing
  </option>,
  <option key="2" value="Commercial">
    Commercial
  </option>,
  <option key="3" value="Light Manufacturing">
    Light Manufacturing
  </option>,
  <option key="4" value="Retail">
    Retail
  </option>,
  <option key="5" value="Leisure & Hospitality">
    Leisure & Hospitality
  </option>,
  <option key="6" value="Public Sector">
    Public Sector
  </option>,
  <option key="7" value="Higher Education">
    Higher Education
  </option>,
  <option key="8" value="Land Management">
    Land Management
  </option>,
  <option key="9" value="SME">
    SME
  </option>,
  <option key="10" value="Residential">
    Residential
  </option>,
  <option key="" id="1" value="">
    Choose an industry segment ...
  </option>
];

export const industryVerticles = industryVerticles_unsorted.sort((a, b) => {
  if (a.props.value < b.props.value) return -1;
  else if (a.props.value > b.props.value) return 1;
  return 0;
});

export const newIndustryVerticles = [
  {
    key: "1",
    text: "Industrial/Heavy Manufacturing",
    value: "Industrial/Heavy Manufacturing"
  },
  { key: "2", text: "Commercial", value: "Commercial" },
  {
    key: "3",
    text: "Light Manufacturing Design",
    value: "Light Manufacturing"
  },
  { key: "4", text: "Retail", value: "Retail" },
  { key: "5", text: "Leisure & Hospitality", value: "Leisure & Hospitality" },
  { key: "6", text: "Public Sector", value: "Public Sector" },
  { key: "7", text: "Higher Education", value: "Higher Education" },
  { key: "8", text: "Land Management", value: "Land Management" },
  { key: "9", text: "SME", value: "SME" },
  { key: "10", text: "Residential", value: "Residential" }
].sort((a, b) => {
  if (a.value < b.value) return -1;
  else if (a.value > b.value) return 1;
  return 0;
});

export const legalCategories_unsorted = [
  // <option value="*">Choose One</option>,
  <option key="1" id="5" value="Air" sort="Air">
    {" "}
    Air{" "}
  </option>,
  <option key="2" id="5" value="Energy and Carbon" sort="Energy and Carbon">
    Energy and Carbon
  </option>,
  <option key="3" id="5" value="Waste" sort="Waste">
    Waste
  </option>,
  <option
    key="4"
    id="5"
    value="Hazardous Substances"
    sort="Hazardous Substances"
  >
    Hazardous Substances
  </option>,
  <option
    key="5"
    id="5"
    value="Conservation and Biodiversity"
    sort="Conservation and Biodiversity"
  >
    Conservation and Biodiversity
  </option>,
  <option key="6" id="5" value="Noise and Nuisance" sort="Noise and Nuisance">
    Noise and Nuisance
  </option>,
  <option key="7" id="5" value="Water" sort="Water">
    {" "}
    Water
  </option>,
  <option
    key="8"
    id="5"
    value="Construction and Buildings"
    sort="Construction and Buildings"
  >
    Construction and Buildings
  </option>,
  <option
    key="9"
    id="5"
    value="Whistleblowing and FOI"
    sort="Whistleblowing and FOI"
  >
    Whistleblowing and FOI
  </option>,
  <option key="10" id="5" value="Other" sort="zOther">
    {" "}
    Other
  </option>,
  <option key="11" id="1" value="" sort="00">
    Choose an area ...
  </option>,
  <option
    key="12"
    id="2"
    value="Environmental Permitting"
    sort="Environmental Permitting"
  >
    Environmental Permitting
  </option>
];

export const NewLegalCategories = [
  { key: 1, value: "Air", text: "Air" },
  { key: 2, value: "Energy and Carbon", text: "Energy and Carbon" },
  { key: 3, value: "Waste", text: "Waste" },
  { key: 4, value: "Hazardous Substances", text: "Hazardous Substances" },
  {
    key: 5,
    value: "Conservation and Biodiversity",
    text: "Conservation and Biodiversity"
  },
  { key: 6, value: "Noise and Nuisance", text: "Noise and Nuisance" },
  { key: 7, value: "Water", text: "Water" },
  {
    key: 8,
    value: "Construction and Buildings",
    text: "Construction and Buildings"
  },
  { key: 9, value: "Whistleblowing and FOI", text: "Whistleblowing and FOI" },
  { key: 10, value: "Other", text: "Other" },
  {
    key: 11,
    value: "Environmental Permitting",
    text: "Environmental Permitting"
  }
];

export const legalCategories = legalCategories_unsorted.sort(function(a, b) {
  if (a.props.sort < b.props.sort) return -1;
  else if (a.props.sort > b.props.sort) return 1;
  return 0;
});

export const countries = [
  { key: "1", text: "England", value: "England", flag: "england" },
  { key: "2", text: "Scotland", value: "Scotland", flag: "gb sct" },
  { key: "3", text: "Ireland", value: "Ireland", flag: "ie" },
  { key: "4", text: "Wales", value: "Wales", flag: "gb wls" },
  {
    key: "5",
    text: "Northern Ireland",
    value: "Northern Ireland",
    flag: "niflag"
  },
  { key: "6", text: "United Kingdom", value: "United Kingdom", flag: "gb" },
  { key: "7", text: "European Union", value: "European Union", flag: "eu" }
];

export const newLegalCategories = [
  { key: "1", text: "Air", value: "Air" },
  { key: "2", text: "Energy and Carbon", value: "Energy and Carbon" },
  { key: "4", text: "Waste", value: "Waste" },
  { key: "5", text: "Hazardous Substances", value: "Hazardous Substances" },
  {
    key: "6",
    text: "Conservation and Biodiversity",
    value: "Conservation and Biodiversity"
  },
  { key: "7", text: "Noise and Nuisance", value: "Noise and Nuisance" },
  {
    key: "8",
    text: "Construction and Buildings",
    value: "Construction and Buildings"
  },
  { key: "9", text: "Water", value: "Water" },
  {
    key: "10",
    text: "Whistleblowing and FOI",
    value: "Whistleblowing and FOI"
  },
  {
    key: "11",
    text: "Environmental Permitting",
    value: "Environmental Permitting"
  }
].sort((a, b) => {
  if (a.value < b.value) return -1;
  else if (a.value > b.value) return 1;
  return 0;
});
