import React from "react";
import { Popup } from "semantic-ui-react";

import England from "../../img/flags/England.png";
import Scotland from "../../img/flags/Scotland.png";
import Ireland from "../../img/flags/Ireland.png";
import Wales from "../../img/flags/Wales.png";
import EU from "../../img/flags/EU.png";
import UK from "../../img/flags/UK.png";

export const renderFlag = (flag, i) => {
  let flagimg = England;

  switch (flag) {
    case "England":
      flagimg = England;
      break;
    case "Scotland":
      flagimg = Scotland;
      break;
    case "Ireland":
      flagimg = Ireland;
      break;
    case "Wales":
      flagimg = Wales;
      break;
    case "United Kingdom":
      flagimg = UK;
      break;
    case "European Union":
      flagimg = EU;
      break;
    default:
      flagimg = England;
  }

  const FlagIMG = (
    <img
      src={flagimg}
      style={{
        marginLeft: "auto",
        marginRight: "auto",
        margin: 8,
        borderRadius: "50%",
        height: 22,
        width: 22,
        objectFit: "cover",
      }}
    />
  );

  return <Popup key={i} trigger={FlagIMG} content={flag} />;
};
