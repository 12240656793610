import bugsnag from "bugsnag-js";

import "babel-polyfill";

import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
import store from "./store";
import "./index.css";
import App from "./App";
import registerServiceWorker from "./registerServiceWorker";
import "./styles/style.css";

import createPlugin from "bugsnag-react";
const bugsnagClient = bugsnag("23f92c570201db8671a3a105e8e5b68f");
const ErrorBoundary = bugsnagClient.use(createPlugin(React));

// Load css

ReactDOM.render(
  <Provider store={store}>
    <Router>
      <ErrorBoundary>
        <App />
      </ErrorBoundary>
    </Router>
  </Provider>,
  document.getElementById("root")
);
registerServiceWorker();

/* cache images with service workers

firebase offline caching*/
