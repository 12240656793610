import React, { Component } from "react";
import { connect } from "react-redux";
import { Form, Button, TextArea, Input, List, Icon } from "semantic-ui-react";
import styled from "styled-components";

// Actions
import {
  createInstructor,
  removeInstructor
} from "../../../../actions/university";
// import { object } from "firebase-functions/lib/providers/storage";

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-top: 25px;
`;

const GridItem = styled.div`
  padding: 10px;
  test-align: center;
  margin: 25px;
  border-style: solid;
  border-width: 1px 1px;
  border-radius: 7px;
  border-color: rgba(0, 0, 0, 0.3);
  background-color: white;
  box-shadow: 10px 10px 33px -12px rgba(0, 0, 0, 0.75);
`;

class Instructors extends Component {
  state = {
    firstName: "",
    lastName: "",
    instructorDescription: ""
  };

  handleInstructorSubmit = e => {
    e.preventDefault();
    createInstructor(
      this.state.firstName,
      this.state.lastName,
      this.state.instructorDescription || ""
    );
    this.setState({ instructorName: "", instructorDescription: "" });
  };

  handleInputChange = (e, data) => {
    this.setState({ [data.name]: data.value });
  };

  handleRemoveInstructor = key => {
    removeInstructor(key);
  };

  render() {
    const allInstructors = [];

    this.props.instructors &&
      Object.values(this.props.instructors).map((instructor, i) => {
        return allInstructors.push({
          key: i,
          text: instructor.firstName + " " + instructor.lastName,
          value: instructor.firstName + " " + instructor.lastName
        });
      });
    return (
      <Grid>
        <GridItem>
          <Form onSubmit={this.handleInstructorSubmit}>
            <h2>Create an instructor</h2>
            <Form.Field
              control={Input}
              label="First Name"
              name="firstName"
              placeholder="Enter first name ..."
              onChange={this.handleInputChange}
              value={this.state.firstName}
              required
            />
            <Form.Field
              control={Input}
              label="Last Name"
              name="lastName"
              placeholder="Enter last name ..."
              onChange={this.handleInputChange}
              value={this.state.lastName}
              required
            />
            <Form.Field
              control={TextArea}
              label="Description"
              name="instructorDescription"
              placeholder="Enter instructor description ..."
              onChange={this.handleInputChange}
              value={this.state.instructorDescription}
            />
            <Button type="submit">Submit</Button>
          </Form>
        </GridItem>
        <GridItem>
          <h3>Instructors</h3>
          <List divided relaxed>
            {this.props.instructors &&
              Object.values(this.props.instructors).map((instructor, i) => {
                return (
                  <List.Item key={i}>
                    <h5>
                      {instructor.firstName} {instructor.lastName}
                      <Icon
                        name="delete"
                        style={{ color: "red", cursor: "pointer" }}
                        onClick={() =>
                          this.handleRemoveInstructor(instructor.key)
                        }
                      />
                    </h5>
                    <p>{instructor.description}</p>
                  </List.Item>
                );
              })}
          </List>
        </GridItem>
      </Grid>
    );
  }
}

const mapStateToProps = state => ({
  instructors: state.university.instructors
});

export default connect(mapStateToProps)(Instructors);
